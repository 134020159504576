import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';

import axios from "axios";
import { toast } from 'react-toastify';
import { Validator } from '../exportcomponents/ValidatorExports';

const AdminAdd = ({ individual, liveRefresh=undefined }) => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [admins, setAdmins] = useState([])
    const [form, setForm] = useState({});
    const [referenceId, setReferenceId] = useState("");

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form)
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            const valid = Validator(form, ['newRef']);
            if (valid.length == 0) {
                setForm({ ...form, admin_email: referenceId });
                // console.log(userData.user.login)
                await axios.put(`${process.env.REACT_APP_API_URL}/account/adminchange/${individual.id}/${form.newRef}`, { admin_change: true });

                toast.success(langData.toast.employer_added);
                if (liveRefresh) await liveRefresh()
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        }

        catch (err) {
            toast.error(err.message);
        }

    };
    
    async function asyncSetupAdminAdd() {
        const admin_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/admins`)
        setAdmins(admin_data.data)
    }

    useEffect(() => {
        asyncSetupAdminAdd()
    })

    return (
        <div>
            <form className="form-background" onSubmit={submit}>
                <div className="form-group">
                    <strong className="h3 mb-5">{langData.admin_add.new_ref}</strong>
                    {/* <input
                        type="reference"
                        className="form-control my-3"
                        name="newRef"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_email}
                    /> */}
                    <select className="w-100 mb-3 mt-4 pt-0 pb-1" onChange={onChange} name="newRef">
                        <option value='' key={-1}>Select a CTP</option>
                        {admins.map((admin, i) => 
                            <option key={i} value={admin.business_number}>{admin.business_name}</option>
                        )}
                    </select>
                </div>
                <div>
                    <button disabled={!form.newRef} type="submit" className="btn btn-primary submit mt-3 w-100">
                        {langData.admin_add.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AdminAdd
