import React, { useState, useEffect, useContext } from 'react'
import ACTImage from "../images/act.png"

import Navbar from './Navbar';
import FunctionalButtons from "./FunctionalButtons"
import MissionStatement from './MissionStatement';
import Info from './Info/Info'
import Home from './Home/Home'
import UserContext from '../context/UserContext';
import FAQAccordion from './FAQAccordion';



const HomePage = () => {
    const { userData } = useContext(UserContext)
    const [isNavbar, setIsNavbar] = useState(false)

    useEffect(() => {
        if (userData.user) {
            if (userData.user.isSuper == false && userData.user.isAdmin == false ) {
                setIsNavbar(false)
            } else {
                setIsNavbar(true)
            }
        }
    }, [userData])


    return (
        <div>

            {/* <MissionStatement /> */}
            {/* <Info /> */}
            <Home />
            {/* <FAQAccordion /> */}
        </div >
    )
}

export default HomePage
