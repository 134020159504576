import React, { useEffect, useState, useContext } from 'react'
import Navbar from './Navbar';
import { useNavigate, Link } from "react-router-dom";
import ACTImage from "../images/act.png";

import { toast } from 'react-toastify'
import Popup from "reactjs-popup";
import axios from "axios"

import FunctionalButtons from "../components/FunctionalButtons";
import ResetPassword from './ResetPassword';
import ResetAdminPassword from './ResetAdminPassword';
import ChangeSuperPass from '../supercomponents/ChangeSuperPass'
import AdminChange from './AdminChange';
import AdminAdd from './AdminAdd';
import { PassConfirm, IndividualClassChange } from '../exportcomponents/PopupExports';
import PaymentClassDisplay from '../exportcomponents/UserListExports';

import './styles.css'
import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';

const UserControls = () => {
    // Global variables
    const { userData, setUserData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const navigate = useNavigate();
    // State Variables
    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState(null);
    const [customerID, setCustomerID] = useState("");
    const [isIndividual, setIsIndividual] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuper, setIsSuper] = useState(false)
    const [paymentInfo, setPaymentInfo] = useState({});
    const [adminPaymentInfo, setAdminPaymentInfo] = useState([]);
    const [paymentUsers, setPaymentUsers] = useState({}); //{ fitter: [], apprentice: [], trainee: [], other: [] }

    // Format raw data from Stripe API / payment routes into renderable strings
    const formatPaymentInfo = (invoices, individual_data) => {
        if (userData.user.isIndividual) {
            let raw_desc = individual_data.description;
            let amount_string = raw_desc.split(" (at ")[1];
            amount_string = amount_string.substring(0, amount_string.length - 1);

            // console.log(invoices);
            // console.log(amount_string);

            // const cents = String(invoices[0].plan.amount).slice(-2);
            // const dollars = String(invoices[0].plan.amount).slice(0, -2);
            // const dueString = `$${dollars}.${cents}`;

            const dateString = String(Date(individual_data.next_billing_date)).slice(4, 15);

            setPaymentInfo({ plan: individual_data.plan, amount: amount_string, next_date: dateString, last4: individual_data.last4 });
        } else if (userData.user.isAdmin) {
            // console.log(invoices);
            let charges = [];
            for (const invoice of invoices) {
                const dateString = String(Date(invoice.current_period_end)).slice(4, 15);
                
                let plan = invoice.items.data[0].price.nickname ? invoice.items.data[0].price.nickname : "Admin Plan";
                let checkUnique = charges.filter((charge) => charge.plan == plan);

                if (checkUnique.length == 0) {
                    const cents = String(invoice.plan.amount).slice(-2);
                    const dollars = String(invoice.plan.amount).slice(0, -2);
                    const dueString = `$${dollars}.${cents}`;

                    // console.log(dueString);

                    charges.push({
                        plan: plan,
                        amount: dueString,
                        next_date: dateString
                    });
                } else {
                    let i = charges.map((charge) => { return charge.plan }).indexOf(plan);
                    // console.log(i);
                    // console.log(invoice.plan.amount, parseInt(charges[i].amount.slice(1, -3) * 100));
                    const cents = String(invoice.plan.amount + parseInt(charges[i].amount.slice(-2) * 100)).slice(-2);
                    const dollars = String(invoice.plan.amount + parseInt(charges[i].amount.slice(1, -3) * 100)).slice(0, -2);
                    const dueString = `$${dollars}.${cents}`;
                    
                    // console.log(dueString);
                    
                    charges[i] = {...charges[i], amount: dueString};
                }
            }

            setAdminPaymentInfo(charges);
        }
    }

    const loadCustomerID = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/id/${userData.userId}`);
        setCustomerID(data.customerId);
    }

    const removeRefId = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/account/adminremove/${userId}`)
        // console.log(userId)

        toast.success(langData.toast.admin_ref_id_removed);
    }

    const customerPortal = async (e) => {
        e.preventDefault();
        try {
            const portalSession = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-customer-portal-session`, { customer_id: customerID });
            // console.log(portalSession.data.redirectURL);
            window.location.href = portalSession.data.redirectURL;
        } catch (err) {
            // console.log(err);
        }
    }

    const deleteAccount = async (accountId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email`,{
                to: 'actedu.super@gmail.com',
                subject: 'Account Deletion Notification',
                body: `The following account was deleted by the user:\n${userData.user.login}`
            });
            await axios.delete(`${process.env.REACT_APP_API_URL}/account/user/${accountId}`);
            setUserData({
                token: undefined,
                user: undefined,
            });
            toast.success(langData.toast.account_deleted);
            localStorage.setItem("auth-token", "");
            navigate('/')
        }
        catch (err) {
            toast.error(err.message)
        }
    }

    async function asyncSetupUserControls() {
        // Get Payment Information
        const upcommingInvoices = await axios.get(`${process.env.REACT_APP_API_URL}/payment/upcoming-invoice/${userData.userId}/?isAdmin=${userData.user.isAdmin}&isIndividual=${userData.user.isIndividual}`);
        // console.log(upcommingInvoices.data);
        formatPaymentInfo(upcommingInvoices.data.invoices, upcommingInvoices.data.individual_data);
        // console.log(upcommingInvoices.data.individual_data);
        const adminData = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`);
        const classPaymentData = await axios.get(`${process.env.REACT_APP_API_URL}/payment/admin/${adminData.data.admin_email}`);
        // console.log(classPaymentData.data);
        classPaymentData.data && setPaymentUsers(classPaymentData.data);
    }

    useEffect(() => {
        // Assign User Context to state variables
        if (!userData.user) return navigate("/");
        setUserId(userData.userId);
        setEmail(userData.user.login);
        setIsIndividual(userData.user.isIndividual);
        setIsAdmin(userData.user.isAdmin);
        setIsSuper(userData.user.isSuper);
        loadCustomerID();

        asyncSetupUserControls()
    }, []);

    return (
        <div>
            <div className="user-control-buttons">
                {isAdmin ? (
                    <>
                        <Popup
                            modal
                            trigger={<button className="btn btn-primary mt-2">{langData.settings.reset_emp_pass}</button>}
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <div>
                                    <a className="close close-size" onClick={close}>
                                        &times;
                                    </a>
                                    <ResetPassword />
                                    
                                </div>	
					        )}
                        </Popup>
                        <p className="lead">Reset a specific employees password</p>
                        <Popup
                            modal
                            trigger={<button className="btn btn-primary mt-2">Reset Password</button>}
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <div>
                                    <a className="close close-size" onClick={close}>
                                        &times;
                                    </a>
                                    <ResetAdminPassword />
                                    
                                </div>	
					        )}
                        </Popup>
                        <p className="lead">Reset your own password</p>
                    </>
                ) : (
                    null

                )}
                {/* TODO (awaiting stripe overhaul): Migrate admin settings to super controls */}
                {isIndividual ? (
                    <>
                        <Popup
                            modal
                            trigger={<button className="btn btn-primary mt-2">{langData.settings.add_ID}</button>}
                        >
                            <AdminAdd /> 
                        </Popup>
                        <p className="lead">Switch from individual to employee</p>
                    </>
                ) : (
                    null
                )}
                {isSuper ? (
                    <div className="d-inline">
                        <Popup
                        modal
                        trigger={<button className="btn btn-primary mt-2">{langData.settings.change_pass}</button>}
                        >
                            <ChangeSuperPass />
                        </Popup>
                        <p className="lead">Change Super Administrators Password</p>
                    </div>
                    
                ) : (
                    null
                )}
                {isSuper || isAdmin || isIndividual ? (
                    <div>

                    </div>
                ) : (
                    <div>
                    {/* TODO (awaiting stripe overhaul): Migrate admin settings to super controls */}
                        <Popup
                            modal
                            trigger={<button className="btn btn-primary mt-2">{langData.settings.change_id}</button>}
                        >
                            <AdminChange />
                        </Popup>
                        <p className="lead">Switch from your current business to another</p>
                        <Popup
                            modal
                            trigger={<button className="btn btn-danger mt-2">{langData.settings.remove_id}</button>}
                        >
                            <PassConfirm email={email} onFinishCallback={removeRefId} />
                        </Popup>
                        <p className="lead">Switch from an employee to an individual</p>
                    </div>
                )}
            </div>
            {isIndividual || isAdmin ? (
                <div className="payment-break">
                    <div className="payment-info card m-3">
                        <strong className="card-header">
                            {langData.settings.payment_info_header}
                        </strong>
                        {isIndividual ? (
                            <div className="card-body  text-left">
                                <div className="payment-spacing d-flex">
                                    <strong className="card-title">{langData.settings.plan}</strong>
                                    <p className="card-text ml-2">{paymentInfo.plan}</p>
                                </div>
                                <div className="payment-spacing d-flex">
                                    <strong className="card-title">{langData.settings.amount}</strong>
                                    <p className="card-text ml-2">{paymentInfo.amount}</p>
                                </div>
                                <div className="payment-spacing d-flex">
                                    <strong className="card-title">{langData.settings.next_bill_date}</strong>
                                    <p className="card-text ml-2">{paymentInfo.next_date}</p>
                                </div>
                                {/* <div className="payment-spacing d-flex">
                                    <strong className="card-title">Billing Country: </strong>
                                    <p className="card-text ml-2">Placeholder Text</p>
                                </div> */}
                                {/* <div className="payment-spacing d-flex">
                                    <a href="#" className="btn btn-danger">{langData.settings.cancel_plan}</a>
                                    <a href="#" className="btn btn-primary ml-5">{langData.settings.change_plan}</a>
                                </div> */}
                            </div>
                        ) : (
                            <div className="card-body  text-left">
                                {adminPaymentInfo.map((invoice, i) => {
                                    return (
                                        <>
                                            {/* <hr style={{ background: 'black' }} /> */}

                                            <div className="payment-spacing d-flex">
                                                <strong className="card-title">{langData.settings.plan}Plan: </strong>
                                                <p className="card-text ml-2">{invoice.plan}</p>
                                            </div>
                                            <div className="payment-spacing d-flex">
                                                <strong className="card-title">{langData.settings.amount}</strong>
                                                <p className="card-text ml-2">{invoice.amount}</p>
                                            </div>

                                            <div className="payment-spacing d-flex">
                                                <strong className="card-title">{langData.settings.next_bill_date}</strong>
                                                <p className="card-text ml-2">{invoice.next_date}</p>
                                            </div>
                                            <PaymentClassDisplay users={paymentUsers[invoice.plan.toLowerCase()]} />
                                            <hr style={{ background: 'black' }} />
                                        </>
                                    )
                                })}
                                <div className="payment-spacing d-flex">
                                    <a href="#" className="btn btn-danger">{langData.settings.cancel_plan}</a>
                                    <a href="#" className="btn btn-primary ml-5">{langData.settings.change_plan}</a>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="payment-info card m-3">
                        <strong className="card-header">
                            {langData.settings.payment_settings_header}
                    </strong>
                        <div className="card-body  text-left">
                            <div className="card payment-spacing d-flex p-2">
                                <strong className="card-title">{langData.settings.payment_method}</strong>
                                <div className="d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-credit-card-2-back" viewBox="0 0 16 16">
                                        <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                    </svg>
                                    <p className="card-text lead ml-2">{paymentInfo.last4}</p>
                                </div>
                            </div>
                            <div className="payment-spacing d-flex mt-5">
                                <form onClick={customerPortal}>
                                    <button type="submit" className="btn btn-primary">{langData.settings.update_payment_download_invoice}</button>
                                </form>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            ) : (
                null
            )
            }
            {isIndividual || isAdmin ? (
                <Popup
                    modal
                    trigger={<button className="btn btn-danger">{langData.settings.delete_account}</button>}
                    >
                    <PassConfirm email={email} onFinishCallback={() => deleteAccount(userId)} />
                </Popup>
            ) : (
                null
            )

            }

        </div>
    )
}

export default UserControls
