import * as FileSaver from 'file-saver'
import * as xlsx from 'xlsx'

const test_data = [
    { a: 1, b: 2 },
    { a: 3, b: 4 }
]

export async function writeExcel(data, name='Attendees') {
    const worksheet = xlsx.utils.json_to_sheet(data)
    const workbook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(workbook, worksheet, name)
    const blub = new Blob([xlsx.write(workbook, { type: 'array' })])
    FileSaver.saveAs(blub, `${name}.xlsx`)
}