import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import Popup from "reactjs-popup";
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OnboardAdmin from './OnboardAdmin';
import sendNotification from '../exportcomponents/NotificationExports';
import { 
	UserInfo,
    ViewQuotePricing,
    SetOnboardPrice,
    PaymentRedirect
} from '../exportcomponents/PopupExports';
import { collapsedStyle } from '../exportcomponents/StyleExports'

const PendingAdminList = ({ onLastClicked }) => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const navigate = useNavigate()
    const [adminSearch, setAdminSearch] = useState("")
    const [superEmail, setSuperEmail] = useState("");
    const [redirectMessage, setRedirectMessage] = useState("");
	const [redirectQuoteID, setRedirectQuoteID] = useState("");
    const [redirectSession, setRedirectSession] = useState(null);
    const [redirectURL, setRedirectURL] = useState(null);
    const [redirectLater, setRedirectLater] = useState(undefined);
    const [redirect, setRedirect] = useState(false);
    const [onboard, setOnboard] = useState(false);
    const [pendList, setPendList] = useState([]);
    const [buttonDisable, setButtonDisable] = useState({});
    const [pendAdminTab, setPendAdminTab] = useState()
    const [selectedPend, setSelectedPend] = useState({})

	const adminResults = !adminSearch
    ? pendList
    : pendList.filter(pend =>
        (pend.first_name.toString() + " " + pend.last_name.toString()).toLowerCase().includes(adminSearch.toLocaleLowerCase()) ||
        pend.email.toString().toLowerCase().includes(adminSearch.toLocaleLowerCase())
    );

    const onPaymentRedirect = (session_id, redirect_msg, redirect_later) => {
        console.log(redirect_later)
		if (!redirect_later) {
			setRedirectMessage(redirect_msg);
			setRedirectSession(session_id);
			// setRedirectURL()
			setRedirect(true);
		}
	}
    
	const onPricePopup = (session_id, quote_id, redirect_msg, later) => {
        // TODO: CTP SIGN UP
        setOnboard(true);
        setRedirectSession(session_id);
        setRedirectMessage(redirect_msg);
        setRedirectQuoteID(quote_id);
        setRedirectLater(later);
	}

    const onPendApprove = async (e) => {
        setButtonDisable({ ...buttonDisable, pend_admin_approve: true });
        setTimeout(() => setButtonDisable({ ...buttonDisable, pend_admin_approve: false }), 3000);

		// console.log(`Approve ${e.target.value}`);
		const getQuote = await axios.get(`${process.env.REACT_APP_API_URL}/quote/account/${e.target.value}`)
		try {
			const adminApprovalRes = await axios.put(`${process.env.REACT_APP_API_URL}/account/user/approve/${e.target.value}`, { 
				subscription_price: parseInt(getQuote.data.subscription_price),
				down_price: parseInt(getQuote.data.down_price),
				fitter_price: parseInt(getQuote.data.fitter_price),
				apprentice_price: parseInt(getQuote.data.apprentice_price),
				act_apprentice_price: parseInt(getQuote.data.act_apprentice_price),
				trainee_price: parseInt(getQuote.data.trainee_price),
				other_price: parseInt(getQuote.data.other_price)
			});

			if(adminApprovalRes.data == "No Payment Method") {
				toast.error("No Payment Method");
			} else {
				
				const pend_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/admin`);

				setPendList(pend_data.data);
				sendNotification(e.target.value, "Looks like your account was just approved, Welcome to ACT!");
				toast.success(langData.toast.admin_approved);
			}

		} catch (err) {
			toast.error('An Error Occurred')
		}
		
	}

	const onPendDelete = async (e) => {
		await axios.delete(`${process.env.REACT_APP_API_URL}/account/user/${e.target.value}`);
		const pend_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/admin`);

		setPendList(pend_data.data);
		toast.error(langData.toast.admin_denied);
	}

    const resendEmailLink = async (account_id, email, business_name) => {
        const quote_data = await axios.get(`${process.env.REACT_APP_API_URL}/quote/account/${account_id}`);
        const { subscription_price, down_price, fitter_price, apprentice_price, trainee_price, other_price, act_apprentice_price } = quote_data.data

        await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email/admin-onboard`, {
            to: email,
            business_name
        })
        // await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email/admin-onboard`, {
        //     to: email,
        //     subject: `Finish Onboarding ${business_name} with ACTeducate.com`,
        //     header: `Congratulations, you're business (${business_name}) has been registered with ACTeducate!`,
        //     body_1: `The pricing for your organization is as follows: `,
        //     italic_1: `These fees will be charged once you input your payment information using the link below)`,
        //     body_2: `Individual Fees charged at the time that the employees are approved by ACT to be added to your account: `,
        //     italic_2: `(These are monthly fees)`,
        //     footer: `Click Here to Finish the Onboarding Process: ${process.env.REACT_APP_VIEW_URL}/onboard?done=${account_id}`,
        //     prices: {
        //         subscription_price: subscription_price,
        //         down_price: down_price,
        //         fitter_price: fitter_price,
        //         apprentice_price: apprentice_price,
        //         trainee_price: trainee_price,
        //         other_price: other_price,
        //         act_apprentice_price: act_apprentice_price
        //     }
        // });
        toast.success(langData.toast.finish_sign_email_sent);
    }

    function closeOnboardPopup() {
        console.log("CLOSE THAT POPUP BOIIIII")
    }

    async function asyncSetupPendingAdminList() {
        try {
            const pend_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/admin`);
            setPendList(pend_data.data);
        } catch (err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
		if (!userData.user) return navigate("/");
		setSuperEmail(userData.user.login);
		asyncSetupPendingAdminList()

	}, []);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                <h3 className='h3 text-secondary'>{langData.super_functionality.pending_admin}</h3>
                <div className='d-flex justify-content-end'>
                    <Popup
                        modal
                        trigger={<button className="btn btn-outline-primary mr-2">{langData.super_functionality.sign_up_admin}</button>}
                    >
                        <OnboardAdmin redirectCallback={onPricePopup} />
                    </Popup>
                    <Popup
                        modal
                        onClose={() => {
                            setRedirect(false);
                            setOnboard(true);
                        }}
                        open={redirect}
                        closeOnDocumentClick={false}
                    >
                        <PaymentRedirect
                            message={redirectMessage}
                            session_id={redirectSession}
                            redirect_url={redirectURL}
                        />
                    </Popup>
                    <Popup
                        modal
                        onClose={() => setOnboard(false)}
                        open={onboard}
                        closeOnDocumentClick={false}>
                            {close => (
                                <div>
                                    <a className="close close-size" onClick={close}>
                                        &times;
                                    </a>
                                    <SetOnboardPrice
                                        session_id={redirectSession}
                                        quote_id={redirectQuoteID}
                                        redirect_message={redirectMessage}
                                        redirect_later={redirectLater}
                                        redirectCallback={onPaymentRedirect}
                                        closeCallback={closeOnboardPopup}
                                        super_email={superEmail} 
                                    />
                                </div>	
                            )}
                    </Popup>
                    <div className="tab-title apprentice rounded">
                        <h1>{adminResults.length}</h1>
                    </div>
                    <span onClick={(e) => asyncSetupPendingAdminList()} className="input-group-text rounded border-0 ml-3">
                        <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z"/></g>
                        </svg>
                    </span>
                </div>
            </div>
            {/* <div
                className={collapsedStyle(!pendAdminTab)}
                // type="button" 
                // data-toggle="collapse" 
                // data-target="#pendAdminCollapse" 
                // aria-expanded="false" 
                // aria-controls="pendAdminCollapse"
                // onClick={() => {setPendAdminTab(!pendAdminTab); onLastClicked();}}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div>
                    <strong className="pending-text">{langData.super_functionality.pending_admin}</strong>
                </div>
                <Popup
                    modal
                    trigger={<button className="btn btn-danger mr-1">{langData.super_functionality.sign_up_admin}</button>}
                >
                    <OnboardAdmin redirectCallback={onPricePopup} />
                </Popup>
                <Popup
                    modal
                    onClose={() => {
                        setRedirect(false);
                        setOnboard(true);
                    }}
                    open={redirect}
                    closeOnDocumentClick={false}
                >
                    <PaymentRedirect
                        message={redirectMessage}
                        session_id={redirectSession}
                        redirect_url={redirectURL}
                    />
                </Popup>
                <Popup
                    modal
                    onClose={() => setOnboard(false)}
                    open={onboard}
                    closeOnDocumentClick={false}>
                        {close => (
                            <div>
                                <a className="close close-size" onClick={close}>
                                    &times;
                                </a>
                                <SetOnboardPrice
                                    session_id={redirectSession}
                                    quote_id={redirectQuoteID}
                                    redirect_message={redirectMessage}
                                    redirect_later={redirectLater}
                                    redirectCallback={onPaymentRedirect}
                                    closeCallback={closeOnboardPopup}
                                    super_email={superEmail} 
                                />
                            </div>	
                        )}
                </Popup>
                {adminResults &&
                    <div className="tab-title">
                        <h1>{adminResults.length}</h1>
                    </div>
                }
            </div> */}
            <div className='min-vh-50'>
                {adminResults.map((pend, i) => {
                    return (
                        <div key={i} className={`card card-body mt-3 ${selectedPend.id == pend.id && 'select-highlight'}`} onClick={() => setSelectedPend(pend)}>
                            <div className='row'>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.name}
                                    </strong>
                                    <p>
                                        {pend.first_name} {pend.last_name}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.email}
                                    </strong>
                                    <p>
                                        {pend.email}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.company}
                                    </strong>
                                    <p>
                                        {pend.business_name}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.business_number}
                                    </strong>
                                    <p>
                                        {pend.admin_email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='d-flex justify-content-center position-sticky bottom-0'>
                <div className='opaque p-3 w-75 d-flex flex-wrap justify-content-evenly align-items-center'>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">Admin Info</button>}
                    >
                        <UserInfo
                            id={selectedPend.id}
                        />
                    </Popup>
                    <button disabled={buttonDisable.pend_admin_approve} className="text-nowrap m-1" onClick={onPendApprove} value={selectedPend.id}>{langData.super_functionality.approve}</button>
                    {/* <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">Edit Quote</button>}
                    >
                        <ViewQuotePricing 
                            id={selectedPend.id}
                        />
                    </Popup> */}
                    <button className="text-nowrap m-1" onClick={() => resendEmailLink(selectedPend.id, selectedPend.email, selectedPend.business_name)}>Resend Quote</button>
                    <button className="text-nowrap m-1" onClick={onPendDelete} value={selectedPend.id}>{langData.super_functionality.delete}</button>
                </div>
            </div>
        </div>
    )
}

export default PendingAdminList
