// Imports
import { toast } from 'react-toastify';

// Require
let XLSX
if (typeof XLSX == 'undefined') XLSX = require('xlsx');

export const saveAdminJobs = (jsonData) => {
	try {
		var wb = XLSX.utils.book_new();
		let jobs = [];
		let applicants = [];
		let requirements = [];

		for (const row of jsonData) {
			jobs.push({
				"Job ID": row.id,
				"Title": row.title,
				"Description": row.description,
				"Industry": row.industry
			});
			for (const applicant of row.applicants) {
				applicants.push({
					"Job ID": row.id,
					"Invited": applicant.progress == 0 ? 'no' : 'yes',
					"First Name": applicant.first_name,
					"Last Name": applicant.last_name,
					"Phone": applicant.phone,
					"Email": applicant.email,
					"Address 1": applicant.address_1,
					"Address 2": applicant.address_2,
					"City": applicant.city,
					"State": applicant.state,
					"Zip": applicant.zip,
					"Language": applicant.language,
					"Credentials": ""
				});
				for (const credential of applicant.credentials) {
					applicants.push({
						"Job ID": "",
						"Invited": "",
						"First Name": "",
						"Last Name": "",
						"Phone": "",
						"Email": "",
						"Address 1": "",
						"Address 2": "",
						"City": "",
						"State": "",
						"Zip": "",
						"Language": "",
						"Credentials": credential.name
					});
				}
			}
			applicants.push({});
			for (const requirement of row.requirements) {
				requirements.push({
					"Job ID": row.id,
					"Requirement": requirement
				});
			}
		}

		var jobSheet = XLSX.utils.json_to_sheet(jobs);
		var applicantSheet = XLSX.utils.json_to_sheet(applicants);
		var requirementsSheet = XLSX.utils.json_to_sheet(requirements);
		
		XLSX.utils.book_append_sheet(wb, jobSheet, "Jobs");
		XLSX.utils.book_append_sheet(wb, applicantSheet, "Applicants");
		XLSX.utils.book_append_sheet(wb, requirementsSheet, "Requirements");

		XLSX.writeFile(wb, "ACTeduData.xlsx");
	} catch (err) {
		// console.log(err);
		toast.error(err);
	}
}

export default saveAdminJobs;