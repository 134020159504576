import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Validator } from '../exportcomponents/ValidatorExports';
import { toast } from 'react-toastify'
import axios from 'axios';
import '../components/News/news.css'

import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';

const PostNews = () => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({});
    const [newsImage, setNewsImage] = useState(null);
    

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            const valid = Validator(form, ['title', 'content']);
            if (valid.length == 0) {
                let url_string = '';
                // // console.log(userData)
                // // console.log(form);
                if (newsImage) {
                    const newsData = new FormData();
                    newsData.append('image', newsImage, `${Date.now()}-${newsImage.name}`);
                    const imageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, newsData);
                    url_string = imageData.data.downloadURL;
                }
                
                await axios.post(`${process.env.REACT_APP_API_URL}/news/post/${userData.userId}`,
                    { title: form.title, content: form.content.replace(/(\r\n|\r|\n)/g, '<br>'), img_url: url_string });
                toast.success(langData.toast.news_posted);
                navigate("/");
                navigate("/feed");
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <div>
            <form className="form-background news" onSubmit={submit}>
                <div className="form-group">
                <div className="d-flex justify-content-between">
                        <strong htmlFor="email">{langData.post_news.title}</strong>
                        {!form.title ? 
                            <p className="float-right text-danger">{langData.post_news.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="title"
                        className="form-control"
                        name="title"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_title}
                    />
                </div>
                <div className="form-group mt-4 mb-2">
                    <strong htmlFor="name">{langData.post_news.image}</strong>
                    <input type="file" className="ml-3 form-control-file" onChange={(e) => setNewsImage(e.target.files[0])} />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <strong htmlFor="email">{langData.post_news.content}</strong>
                        {!form.content ? 
                            <p className="float-right text-danger">{langData.post_news.required}</p>
                            : 
                            null
                        }
                    </div>
                    <textarea
                        type="content"
                        className="form-control"
                        name="content"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_content}
                    />
                </div>
                <div>
                    <button type="submit" className="btn btn-primary submit mt-2">
                        {langData.post_news.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default PostNews
