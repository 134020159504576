import React, { useContext, useState, useEffect, useCallback } from 'react'
import axios from "axios";
import { Validator } from '../exportcomponents/ValidatorExports';
import { toast } from 'react-toastify';

import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';

const ResetPassword = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({});
    const [email, setEmail] = useState()
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmp, setSelectedEmp] = useState();

    const onEmpChange = (e) => {
        // console.log(e.target.value);
        setSelectedEmp(e.target.value);
    };

    const changePassword = (e) => {
        // console.log(selectedEmp)
    }

    const submit = async (e) => {
        e.preventDefault()

        try {
            const valid = Validator(form, ['password']);
            if (valid.length == 0) {
                await axios.put(`${process.env.REACT_APP_API_URL}/account/resetpassword`,
                {
                    employee: selectedEmp,
                    password: form.password,
                })
                toast.success(langData.toast.employee_pass_changed);
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch {
            toast.error(langData.toast.invalid_credentials);
        }
    }

    const onChange = (e) => {
        setForm({ ...form, employee: selectedEmp, [e.target.name]: e.target.value });
        // console.log(form);
    };

    useEffect(() => {
        (async () => {
            let approvedEmployees = []

            const userEmail = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`)
            // console.log(userEmail.data.status)

            await axios.get(`${process.env.REACT_APP_API_URL}/account/employees/${userEmail.data.admin_email}`)
                .then((emps) => {
                    let empList = emps.data;
                    empList.sort();
                    for (let employee of empList) {
                        if (employee.status === true) {
                            approvedEmployees.push(employee)
                        }
                    }
                    setEmployeeList(approvedEmployees);
                    // console.log(employeeList);
                });

        })()
    }, []);

    return (
        <div>
            <form className="form-background" onSubmit={submit}>
                <div className="form-group">
                    <label>{langData.reset_password.change_pass_header}</label>
                    <select onChange={onEmpChange} className="form-select" aria-label="Default select example">
                        <option key="default">Select Employee</option>
                        {employeeList.map((emp, i) => {
                            return <option onChange={changePassword} value={emp.id} key={i}>{emp.first_name}&nbsp;{emp.last_name}</option>
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="password">{langData.reset_password.new_pass_header}</label>
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.reset_password.new_pass_placeholder}
                    />
                </div>
                <div>
                    <button type="submit" className="btn btn-primary submit mt-2">
                        {langData.reset_password.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ResetPassword
