import EventsApi from "./api"
import { useEffect, useState } from 'react'

const EventForm = ({ formState, benefitsState }) => {
    const api = new EventsApi()
    const [form, setForm] = formState
    const [benefits, setBenefits] = benefitsState
    const [courses, setCourses] = useState([])

    function onFormChange(e) {
        const { name, value, selected } = e.target
        let new_form = { ...form, [name]: value }
        if (name == 'subscriber_cost') new_form.public_cost = 2 * value
        setForm(new_form)
    }

    function onBenefitChange(e) {
        const { name, value } = e.target
        let updated_benefits = benefits

        const no_id = name.match(/^\d$/)
        // return
        const benefit_index = no_id
            ? name
            : benefits.findIndex(({ id }) => id == name)


        if (no_id) updated_benefits[benefit_index] = value
        else updated_benefits[benefit_index].benefit = value


        setBenefits(updated_benefits)
    }

    function onConflictChange(e) {
        console.log("e.gartet.uvaleu: ", e.target.value)
        setForm({ ...form, conflict_id: e.target.value })
    }

    function defaultTime(start = true) {
        if (start) return '09:00:00'
        return '17:00:00'
        // const d = new Date(Date.now())
        // const h = d.getHours()
        // const m = d.getMinutes()
        // return `${h}:${m}:00` // returns current time in HH:MM:00 format (seconds ignore)
    }

    function defaultDate() {
        const date = new Date()
        const y = date.getFullYear()
        const d = date.getDate()
        let m = date.getMonth() + 1
        if (m < 10) m = `0${m}`
        const date_string = `${y}-${m}-${d}`
        return date_string
    }

    async function asyncEventFormSetup() {
        const resp = await api.getAbsorbConflictOptions()
        if (!resp?.data) return
        const course_options = resp?.data?.map(({ Id, CourseNumber, Name }) => ({ id: Id, course_number: CourseNumber, name: Name })) 
        setCourses(course_options)
    }

    useEffect(() => { 
        asyncEventFormSetup()
    }, [])

    return (
        <div>
            {/* Basic Info */}
            <div className="mt-3">
                <b>Title *</b>
                <input placeholder={form.title || 'Title...'} name="title" onChange={onFormChange} type="text" className="form-control mt-1" />
            </div>
            <div className="mt-3">
                <b>Description *</b>
                <textarea placeholder={form.description || 'Description...'} name="description" onChange={onFormChange} className="form-control mt-1" />
            </div>

            <hr />

            {/* Location */}
            <div className="mt-3">
                <b>Address 1 *</b>
                <input placeholder={form.address_1 || 'Address 1...'} name="address_1" onChange={onFormChange} type="text" className="form-control mt-1" />
            </div>
            <div className="d-flex justify-content-between mt-2">
                <div className="w-100 pr-3">
                    <span>
                        <b>Address 2</b>
                        <span> (Optional)</span>
                    </span>
                    <input placeholder={form.address_2 || 'Address 2...'} name="address_2" onChange={onFormChange} type="text" className="form-control mt-1" />
                </div>
                <div className="w-50 pl-3">
                    <b>City *</b>
                    <input placeholder={form.city || 'City...'} name="city" onChange={onFormChange} type="text" className="form-control mt-1" />
                </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
                <div className="w-50 pr-3">
                    <b>State *</b>
                    <input placeholder={form.state || 'State...'} name="state" onChange={onFormChange} type="text" className="form-control mt-1" />
                </div>
                <div className="w-50 pl-3">
                    <b>ZIP *</b>
                    <input placeholder={form.zip || 'ZIP...'} name="zip" onChange={onFormChange} type="text" className="form-control mt-1" />
                </div>
            </div>
            <div className="w-50 mt-3 pr-3">
                <b>Geographic Area</b>
                <select name="geo_area" onChange={onFormChange} className="form-control mt-1" value={form.geo_area || 'bay_area'} >  {/* IT'S MY BIRTHDAY, AND i WISH TO PARTY WITH HUGH JACKMAN */}
                    <option value={null}>Select a Geo Area...</option>
                    <option value="virtual">Virtual</option>
                    <option value="bay_area">Bay Area</option>
                    <option value="central_california">Central California</option>
                    <option value="northern_california">Northern California</option>
                    <option value="southern_california">Southern California</option>
                </select>
            </div>

            <hr />

            {/* Time & Date */}
            <div className="d-flex justify-content-between">
                <div className="w-50 pr-3">
                    <b>Start Time *</b>
                    <input value={form.start_time || defaultTime()} name="start_time" onChange={onFormChange} type="time" className="form-control mt-1" />
                </div>
                <div className="w-50 pl-3">
                    <b>End Time *</b>
                    <input value={form.end_time || defaultTime(false)} name="end_time" onChange={onFormChange} type="time" className="form-control mt-1" />
                </div>
            </div>
            <div className="d-flex justify-content-between mt-3 ">
                <div className="w-50 pr-3">
                    <b>Date *</b>
                    <input value={form.date || defaultDate()} name="date" onChange={onFormChange} type="date" className="form-control mt-1" />
                </div>
                <div className="w-50 pl-3">
                    <b>Seats</b>
                    <input value={form.seats} placeholder="40" name="seats" onChange={onFormChange} type="number" className="form-control mt-1" />
                </div>
            </div>
            <div className="d-flex justify-content-between mt-3 ">
                <div className="w-50 pr-3">
                    <b>Credits</b>
                    <input value={form.credits} placeholder="4.0" name="credits" onChange={onFormChange} type="number" className="form-control mt-1" />
                </div>

                <div className="w-50 pl-3">
                    <b>Absorb Duplicate</b>
                    <select onChange={onConflictChange} className="form-control mt-1" value={form.conflict_id}>
                        <option value=''>No Course Conflicts...</option>
                        {courses.map((course) => (<option value={course.id}>{course.name}</option>))}
                    </select>
                </div>
            </div>

            <hr />

            {/* Pricing */}
            <div className="d-flex justify-content-between">
                <div className="w-50 pr-3">
                    <b>Subscriber Cost *</b>
                    <input placeholder={form.subscriber_cost || '5.00'} name="subscriber_cost" onChange={onFormChange} type="number" min={5} step={0.01} className="form-control mt-1 cost-input" />
                </div>
                <div className="w-50 pl-3">
                    <span>
                        <b className='mr-2'>Public Cost</b>
                        <span>(Optional)</span>
                    </span>
                    <input placeholder={form.public_cost || '10.00'} name="public_cost" onChange={onFormChange} type="number" min={5} step={0.01} className="form-control mt-1 cost-input" />
                </div>
            </div>

            <hr />

            {/* Benefits */}
            <b>Course Benefits</b>
            <div className='d-flex flex-column align-items-center text-secondary mb-1 mt-4'>
                {[...Array(3).keys()].map((key) => (
                    <>
                        <div className='d-flex w-100 justify-content-between align-items-center' key={`benefit-input-wrapper-${key}`}>
                            <input name={benefits[key]?.id || key} onChange={onBenefitChange} className='form-control' placeholder={benefits[key]?.benefit || benefits[key]} key={`benefit-input-${key}`} />
                            {/* <button className='btn btn-outline-danger'>-</button> */}
                        </div>

                        <hr className='benefit-divider mt-3' />
                    </>
                ))}

                {/* <div className='d-flex w-100 justify-content-between align-items-center'>
                    <span className="ml-0">Add a benefit...</span>
                    <button className='btn btn-outline-info'>+</button>
                </div> */}

            </div>
        </div>
    )
}

export default EventForm