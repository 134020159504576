import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react'
import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import "bulma"
import axios from 'axios'

// import { Provider } from 'react-redux';
// import store from './redux/store';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';

import HomePage from './components/HomePage';
import Profile from './components/Profile/Profile';
import Apprentice from './apprenticecomponents/Apprentice';
import LicensesPage from './components/Fitters/LicensesPage'
import CoursePage from './components/CoursePage';
// import DragDropPage from './components/DragDropPage';
import Feed from './components/News/Feed'
import Notifications from './components/Notifications'
import Jobs from './components/Jobs/Jobs';
import Onboard from './components/DeepLinks/Onboard';
import ChangeApproval from './components/ChangeApproval';
import Footer from './modules/navigation/Footer'
import Unsubscribe from './components/DeepLinks/Unsubscribe';
import ConfirmEmail from './components/DeepLinks/ConfirmEmail'
import EventsPage from './components/Events/Page'

import SuperAdmin from './supercomponents/SuperFunctionality'
import Admin from './admincomponents/AdminFunctionality'
import UserControls from './components/UserControls'

import SuperContext from './context/SuperContext';
import UserContext from './context/UserContext';
import LangContext from './context/LangContext';
import { en, sp } from './context/Translations';
import LearnMore from './components/Home/LearnMore';
import Header from './modules/navigation/Header';
import RTITracker from './supercomponents/RTITracker';

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
  const [userData, setUserData] = useState({
    user: undefined,
    token: undefined
  });
  const [superData, setSuperData] = useState({
    active_subs: undefined
  });
  const [langData, setLangData] = useState(en);

  const checkLoggedIn = async () => {
    console.log("checkLoggedIn")
    let token = localStorage.getItem("auth-token")

    if (token === null) {
      localStorage.setItem("auth-token", "")
    }

    try {
        const tokenRes = await axios.post(`${process.env.REACT_APP_API_URL}/account/tokenIsValid`, null, {
            headers: { "x-auth-token": token }
        });

      setUserData({
        token,
        user: tokenRes.data.user,
        userId: tokenRes.data.user.userId,
        // adminId:
      });
      const lang_dict = { English: en, Spanish: sp };
      if (tokenRes.data.language && Object.keys(lang_dict).includes(tokenRes.data.language)) {
        setLangData(lang_dict[tokenRes.data.language]);
      }
    } catch (err) {
      localStorage.setItem("auth-token", "")
    }
  }

  useLayoutEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <div className="App">
      <Router>
            <SuperContext.Provider value={{ superData, setSuperData }}>
            <LangContext.Provider value={{ langData, setLangData }}>
            <UserContext.Provider value={{ userData, setUserData }}>
            <ToastContainer />
            <Elements stripe={stripePromise}>
                <Header />
                <div className="App-router">
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route exact path="/learn-more" element={<LearnMore />} />
                      <Route exact path="/profile/:id" element={<Profile />} />
                      <Route exact path="/apprentice" element={<Apprentice />} />
                      <Route exact path="/licenses" element={<LicensesPage />} />
                      <Route exact path="/courses" element={<CoursePage />} />
                      <Route exact path="/jobs" element={<Jobs />} />
                      <Route exact path="/feed" element={<Feed />} />
                      <Route exact path="/super" element={<SuperAdmin />} />
                      <Route exact path="/rti" element={<RTITracker />} />
                      <Route exact path="/admin" element={<Admin />} />
                      <Route exact path="/settings" element={<UserControls />} />
                      {/* <Route exact path="/dragdrop" element={<DragDropPage />} /> */}
                      <Route exact path="/notifications/:id" element={<Notifications />} />
                      <Route exact path="/onboard" element={<Onboard />} />
                      <Route exact path="/change" element={<ChangeApproval />} />
                      <Route exact path="/unsubscribe" element={<Unsubscribe />} />
                      <Route exact path="/confirm-email" element={<ConfirmEmail />} />
                      <Route exact path="/events" element={<EventsPage />} />
                    </Routes>
                </div>
                  <Footer />
            </Elements>
            </UserContext.Provider>
            </LangContext.Provider>
            </SuperContext.Provider>
        {/* </Provider> */}
      </Router>
    </div>
  );
}

export default App;