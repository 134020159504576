import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
// import { Popover } from '@varld/popover';
import Popup from 'reactjs-popup';

import EditNews from '../../admincomponents/EditNews';
import PostNews from '../../admincomponents/PostNews';
import './news.css'

import axios from 'axios'
import UserContext from "../../context/UserContext";
import LangContext from '../../context/LangContext';

const Feed = () => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [newsList, setNewsList] = useState([]);
    const [jobsList, setJobsList] = useState([]);
    const [adminEmail, setAdminEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuper, setIsSuper] = useState(false)
    const [userEmail, setUserEmail] = useState("")
    const [userPhone, setUserPhone] = useState("")

    const onNewsDelete = async (id) => {
        // console.log(id)
        await axios.delete(`${process.env.REACT_APP_API_URL}/news/${id}`)
        const news_data = await axios.get(`${process.env.REACT_APP_API_URL}/news/`)
        setNewsList(news_data.data);
    }

    const onJobDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/job/jobs/${id}`);
        } catch (err) {
            return toast.error(err);
        }

        toast.success(langData.toast.job_listing_deleted);
    }

    const onApplyClick = async (id) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/job/apply`, { job_id: id, account_id: userData.user.userId });
        } catch (err) {
            return toast.error(err);
        }

        toast.success(langData.toast.applied_success);

    }

    const jobFilter = (jobList) => {
        return null;
    }

    const newsDateFormatter = (posted_date) => {
        console.log(posted_date)
        const date = new Date(parseInt(posted_date));
        console.log(date);
        return `${date.getMonth() + 1}/${date.getFullYear()}`
    }

    useEffect(() => {
        if (!userData.user) return navigate("/");
        (async () => {
            try {
                const user_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`)
                // console.log(user_data)
                setAdminEmail(user_data.data.admin_email)

                setUserEmail(user_data.data.email);
                // console.log(userEmail)

                setUserPhone(user_data.data.phone);
                // console.log(userEmail)

                if (user_data.data.admin_email) {
                    const news_data = await axios.get(`${process.env.REACT_APP_API_URL}/news/admin/${user_data.data.admin_email}`);
                    // console.log(news_data);
                    setNewsList(news_data.data);
                } else {
                    const news_data = await axios.get(`${process.env.REACT_APP_API_URL}/news/`);
                    // console.log(news_data);
                    setNewsList(news_data.data.reverse());
                }

                setIsAdmin(userData.user.isAdmin);
                setIsSuper(userData.user.isSuper);

            }
            catch (err) {
                toast.error(err.message)
            }
        })()
    }, []);

    return (
        <div className='news-feed mt-5'>
            <div className='d-flex justify-content-between align-items-center'>
                <h1 className='h1'>
                    {langData.feed.header}
                </h1>
                {isSuper ? (
                    <div>
                        <Popup
                            modal
                            trigger={<button className="btn btn-outline-primary mr-2 mt-3">{langData.feed.post_news}</button>}
                        >
                            <PostNews />
                        </Popup>
                    </div>
                )
                    : (
                        null
                    )
                }
            </div>
            {newsList && newsList.map((news, i) => {
                return (
                    <div key={i} className="card-container news my-5">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h1 className='h1 fw-normal mt-1'>
                                    {news.title}
                                </h1>
                                {isSuper && 
                                    <div className='d-flex'>
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-outline-success mr-2">{langData.feed.edit}</button>}
                                        >
                                            <EditNews id={news.id} title={news.title} content={news.content} />
                                        </Popup>
                                        <button onClick={() => onNewsDelete(news.id)} className="btn btn-outline-danger">{langData.feed.delete}</button>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                <div className="lead">
                                    {news.img_url == '' ? (
                                        null
                                    ) : (
                                        <img className={`news-image ${i % 2 == 0 && 'alt-float'}`} src={news.img_url} />
                                    )}
                                    <p className="news-content" dangerouslySetInnerHTML={{__html: news.content}} />
                                </div>
                                <div className='text-center d-grid mt-2'>
                                    <span>
                                        ©
                                        <i className='ml-1'>
                                            Acteducate - {newsDateFormatter(news.date_posted)}
                                        </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className='text-center mb-5'>
                <i>
                    {langData.feed.news_footer}
                </i>
            </div>
        </div>
    )
}

export default Feed;

