import React, { useState, useEffect, useContext } from 'react';
import ACTImage from "../images/act.png";
import { useLocation } from 'react-router-dom';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import FunctionalButtons from "./FunctionalButtons"
import { useStripe } from '@stripe/react-stripe-js';
import './styles.css';

import { toast } from 'react-toastify';
import LangContext from '../context/LangContext';
import loadingGIF from '../images/loadinggif.gif';

const ChangeApproval = () => {
    const url_params = new URLSearchParams(useLocation().search.slice(1));
    const [classInfo, setClassInfo] = useState({})
    const [denialReason, setDenialReason] = useState(false)
    const [form, setForm] = useState()
    const navigate = useNavigate();
    const stripe = useStripe();
    const { langData } = useContext(LangContext);

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    
    const acceptClassChange = async () => {
        let urlAccept = url_params.getAll('class_change')
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/accept/change/${urlAccept}`, { 
                new_classification: classInfo.new_classification,
                old_classification: classInfo.old_classification,
                price: classInfo.new_price,
                subscription: classInfo.subscription,
                admin_email: classInfo.admin_email
            });

            navigate("/")
            toast.success("Classification Changed!")
        } catch (error) {
            toast.error(error.message)
        }

    }

    const denyClassChange = async () => {
        let urlAccept = url_params.getAll('class_change')
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/reason/denial/${urlAccept}`, 
                { reason: form.denial, description: "Deny Classification Change" })

            navigate("/")
            toast.error("Classification Not Changed!")
        } catch (error) {
            toast.error(error.message)
        }

    }

    const showDenial = () => {
        setDenialReason(!denialReason)
    }

    const convertPrices = (number) => {
        return number / 100
    }

    async function asyncSetupChangeApproval() {
        let changeInfo = await axios.get(`${process.env.REACT_APP_API_URL}/account/class/change/${url_params.getAll('class_change')}`)

        setClassInfo(changeInfo.data)
    }

    useEffect(() => {
        asyncSetupChangeApproval()
    }, []);

    return (
        <div>
            <FunctionalButtons />
            <div className="d-block">
                <div className="logo-header">
                    <Link className="link-placement" to="/">
                        <img className="mt-3 d-block profile-logo" src={ACTImage} />
                        <p className="acteducate-link bold">{langData.onboard.act_header}</p>
                    </Link>
                </div>
                <div className="change-account">
                <div className="card mt-5 p-5 m-5">
                        <strong>Account Classification Changes</strong>
                        <p style={{ justifyContent: "initial" }}>
                            You've been taken to this page so that you can accept a classification change which will 
                            change the pricing of your account or your employees accounts. Below is the description of the change 
                            and you can either choose to accept or deny these changes to your account!
                        </p>
                    </div>
                {!classInfo.old_classification ? (
                    <div>
                        <img 
                            src={loadingGIF}
                        />
                    </div>
                ) : (
                    <div>
                        {!denialReason ? (
                        <div className="card mt-5 p-5 m-5">
                            <strong>Description Of Change:</strong>
                            <p>Approve your change from {classInfo.old_classification} ${convertPrices(classInfo.old_cost)} to {classInfo.new_classification} ${convertPrices(classInfo.new_cost)}</p>
                            <div className="w-50 m-auto">
                                <button className="btn btn-primary mt-5" onClick={acceptClassChange}>Accept</button>
                                <button className="btn btn-danger mt-5 ml-2" onClick={showDenial}>Deny</button>
                            </div>
                        </div>
                    ): (
                        <div className="card mt-5 p-5 m-5">
                            <strong>Reason For Denial:</strong>
                            <div className="form-group m-auto">
                                <textarea 
                                    className="form-control"
                                    id="exampleFormControlTextarea1" 
                                    rows="3"
                                    name="denial"
                                    onChange={onChange} 
                                />
                                
                            </div>
                            <div className="w-50 m-auto">
                                <button className="btn btn-primary mt-5" onClick={denyClassChange}>Submit Denial</button>
                                <button className="btn btn-danger mt-5 ml-2" onClick={showDenial}>Back</button>
                            </div>
                        </div>
                    )}
                    </div>
                )}
                </div>
            </div>
        </div>

    );
}

export default ChangeApproval;