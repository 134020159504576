import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useStripe } from '@stripe/react-stripe-js';
import axios from "axios"
import { toast } from 'react-toastify'
import "./auth.css"
import { Validator } from '../../exportcomponents/ValidatorExports';
import PrivacyPolicy from '../../exportcomponents/LegalExports/LegalExports';
import TermsAndConditions from '../../exportcomponents/TermsAndConditions';
import LangContext from '../../context/LangContext';
import STATES from './states.json'

import PrivacyPolicyPDF from '../../assets/ACT Privacy Policy.pdf'
import TermsAndConditionsPDF from '../../assets/ACT Terms of Use.pdf'

const SignUp = ({ non_sub, openLogin }) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({ language: "English", classification: "fitter", billing_frequency: 'monthly', has_renewed: false });
    const [subscriptionCost, setSubscriptionCost] = useState(45);
    const [buttonDisable, setButtonDisable] = useState({});
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [termsAndConds, setTermsAndConds] = useState(false);
    const [agreed, setAgreed] = useState(false)
    const [understood, setUnderstood] = useState(false)
    const [nonSub, setNonSub] = useState(false);
    const [yesNo, setYesNo] = useState({ yes: false, no: true });
    const [signUpPage, setSignUpPage] = useState(0)
    const SUB_PRICE = { fitter: 45, apprentice: 35, trainee: 35, other: 25 };
    const ITV_PRICE = { monthly: 1, quarterly: 3, biannually: 6, annually: 12 };

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        
        if (e.target.name == 'billing_frequency') {
            setSubscriptionCost(SUB_PRICE[form.classification] * ITV_PRICE[e.target.value]);
        } else if (e.target.name == 'classification') {
            setSubscriptionCost(SUB_PRICE[e.target.value] * ITV_PRICE[form.billing_frequency]);
        } else if (e.target.name == 'initials') {
            setUnderstood(!!(form.sign_up_date && e.target.value))
        } else if (e.target.name == 'sign_up_date') {
            setUnderstood(!!(form.initials && e.target.value))
        } else {
            setSubscriptionCost(SUB_PRICE[form.classification] * ITV_PRICE[form.billing_frequency]);
        }
    };

    // Controlled Yes No checkboxes for "have you renewed your Cal Fire License"
    const hasRenewedChange = (e) => {
        // console.log(e.target.name);
        // yes yes
        if (e.target.name == 'yes' && form.has_renewed == true) {
            return;
        }
        // yes no
        if (e.target.name == 'no' && form.has_renewed == true) {
            setForm({ ...form, has_renewed: false });
            setYesNo({ yes: false, no: true });
        }
        // no yes
        if (e.target.name =='yes' && form.has_renewed == false) {
            setForm({ ...form, has_renewed: true });
            setYesNo({ yes: true, no: false });
        }
        // no no
        if (e.target.name == 'no' && form.has_renewed == false) {
            return;
        }
    }

    function submitInfo() {
        setSignUpPage(1)
        setForm({ ...form, city: form.city, initials: '', sign_up_date: '' })
    }

    const submit = async (e) => {
        // console.log(process.env.REACT_APP_API_URL)
        e.preventDefault();
        setButtonDisable({ ...buttonDisable, submit: true });
        setTimeout(() => setButtonDisable({ ...buttonDisable, submit: false }), 6000);
        try {
            const valid = Validator(form, ['email', 'first_name', 'last_name', 'phone', 'address_1', 'city', 'state', 'zip', 'language', 'password', 'passwordCheck'])
            if(form.password == form.passwordCheck) {
                if (valid === false) {
                    return toast.error(langData.toast.invalid_email);
                }
                if (valid.length == 0) {
                    const newIndividual = await axios.post(`${process.env.REACT_APP_API_URL}/account/individual`, { ...form, non_sub: nonSub });
                    // console.log(newIndividual)
                    toast.success(langData.toast.account_created);
                    await stripe.redirectToCheckout({
                        sessionId: newIndividual.data.sessionId
                    });
                } else {
                    
                    toast.error(langData.toast.please_enter_all_required);
                }
            } else {
                toast.error("Passwords Don't Match!");
            }
        }
        catch (err) {
            toast.error("Email Already Registered");
        }
    };

    useEffect(() => {
        if (non_sub !== undefined) {
            setNonSub(non_sub);
        }
    }, []);

    return (
        <div className='auth-modal card'>
            <div className='card-body'>
                {signUpPage == 0 
                ? (
                    <>
                    <h3 className='h3'>
                        {langData.sign_up.sign_up}
                    </h3> 
                    <div className='d-flex mb-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.first}
                            </span>
                            <input
                                value={form.first_name}
                                name="first_name"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.last}
                            </span>
                            <input 
                                value={form.last_name}
                                name="last_name"
                                onChange={onChange}
                                className='sign-up-input' />
                        </div>
                    </div>
                    <div className='d-flex my-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.address_1}
                            </span>
                            <input 
                                value={form.address_1}
                                name="address_1"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.address_2}
                            </span>
                            <input 
                                value={form.address_2}
                                name="address_2"
                                onChange={onChange}
                                className='sign-up-input' />
                        </div>
                    </div>
                    <div className='d-flex my-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.city}
                            </span>
                            <input 
                                value={form.city}
                                name="city"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.state}
                            </span>
                            <select 
                                value={form.state}
                                name="state"
                                onChange={onChange}
                                className='sign-up-input'>
                                <option></option>
                                {STATES.map((state, i) =>
                                    <option key={i} value={state.abbreviation}>{state.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className='d-flex my-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.zip}
                            </span>
                            <input 
                                value={form.zip}
                                name="zip"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.phone}
                            </span>
                            <input 
                                value={form.phone}
                                name="phone"
                                onChange={onChange}
                                className='sign-up-input' />
                        </div>
                    </div>
                    <h5 className='h5 mt-2'>
                        {langData.sign_up.login_setup}
                    </h5> 
                    <div className='d-flex mb-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.email}
                            </span>
                            <input 
                                value={form.email}
                                name="email"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.language}
                            </span>
                            <select 
                                value={form.language}
                                name="language"
                                onChange={onChange}
                                className='sign-up-input'>
                                <option key="en" value="English">English</option>
                                <option key="sp" value="Spanish">Español</option>
                            </select>
                        </div>
                    </div>
                    <div className='d-flex my-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.password}
                            </span>
                            <input 
                                type="password"
                                    value={form.password}
                                name="password"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.confirm_password}
                            </span>
                            <input 
                                type="password"
                                    value={form.passwordCheck}
                                name="passwordCheck"
                                onChange={onChange}
                                className='sign-up-input' />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-2 ml-1'>
                        {/* <div className='d-flex align-items-center'> */}
                        <div className='d-flex align-items-center'>
                            <input 
                                onChange={(e) => setAgreed(e.target.checked)}
                                className='sign-up-check' type="checkbox" />
                            <p className='sign-up-check-label'>
                                {langData.sign_up.i_agree}
                                <a target="_blank" href={TermsAndConditionsPDF}>
                                    {langData.sign_up.terms}
                                </a>
                                {langData.sign_up.and}
                                <a target="_blank" href={PrivacyPolicyPDF}>
                                    {langData.sign_up.privacy_policy}
                                </a>
                            </p>
                        </div>
                        {/* <a className='mr-5'> */}
                        {/* <a onClick={openLogin}>
                            {langData.sign_up.already_have_account}
                        </a> */}
                    </div>
                    <div className='d-flex justify-content-between mt-2'>
                        <button onClick={submitInfo} disabled={!agreed} className='sign-up-button'>
                            {langData.sign_up.create_account_button}
                        </button>
                        {/* <a className='mr-5'>Already Have an Account?</a> */}
                    </div>
                    </>
            ) : (
                <>
                    <h1 className='h1'>
                        {langData.sign_up.welcome_header} {form.first_name}!
                    </h1> 
                    <h3 className='h3'>
                        {langData.sign_up.billing_setup}
                    </h3> 
                    <div className='sign-up-paragraph'>
                        <p className='text-wrap mb-3'>
                            {non_sub
                                ? langData.sign_up.non_sub_save_card
                                : langData.sign_up.before_approved
                            }
                        </p>
                        <b className='text-wrap'>
                            {non_sub
                                ? langData.sign_up.no_charge_till_purchase
                                : langData.sign_up.once_approved
                            }
                        </b>
                        <p className='mt-3'>
                            <i>
                                {langData.sign_up.please_sign}
                            </i>
                        </p>
                    </div>
                    <div className='d-flex mb-1 input-group'>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.initials}
                            </span>
                            <input 
                                value={form.initials}
                                name="initials"
                                onChange={onChange}
                                className='mr-5 sign-up-input' />
                        </div>
                        <div className='d-grid text-left'>
                            <span className="sign-up-label">
                                {langData.sign_up.today_date}
                            </span>
                            <input 
                                value={form.sign_up_date}
                                type="date"
                                name="sign_up_date"
                                onChange={onChange}
                                className='sign-up-input' />
                        </div>
                    </div>
                    {!non_sub && (
                        <div className='d-flex mb-1 input-group'>
                            <div className='d-grid text-left'>
                                <span className="sign-up-label">
                                    {langData.sign_up.billing_frequency}
                                </span>
                                <select 
                                    value={form.billing_frequency}
                                    name="billing_frequency"
                                    onChange={onChange}
                                    className='mr-5 sign-up-input'>
                                        <option key="monthly" value="monthly">
                                            {langData.sign_up.monthly}
                                        </option>
                                        <option key="quarterly" value="quarterly">
                                            {langData.sign_up.quarterly}
                                        </option>
                                        <option key="biannually" value="biannually">
                                            {langData.sign_up.bi_annually}
                                        </option>
                                        <option key="annually" value="annually">
                                            {langData.sign_up.annually}
                                        </option>
                                </select>
                            </div>
                            <div className='d-grid text-left'>
                                <span className="sign-up-label was-renewed">
                                    {langData.sign_up.was_card_renewed}
                                </span>
                                <div className='ml-1 d-flex justify-content-start align-items-center'>
                                    <input 
                                        name="yes"
                                        type="checkbox"
                                        checked={yesNo.yes}
                                        onClick={hasRenewedChange}
                                        className='mr-5 sign-up-check tight' />
                                    <p className='sign-up-check-label mr-5'>
                                        {langData.sign_up.yes}
                                    </p>
                                    <input 
                                        name="no"
                                        type="checkbox"
                                        checked={yesNo.no}
                                        onClick={hasRenewedChange}
                                        className='mr-5 sign-up-check tight' />
                                    <p className='sign-up-check-label'>
                                        {langData.sign_up.no}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='d-flex justify-content-between mt-5 sign-up-button-cluster'>
                        <button disabled={!understood} onClick={submit} className='sign-up-button'>
                            {non_sub ? langData.sign_up.submit_non_sub : langData.sign_up.submit_approval}
                        </button>
                        <button className='go-back-button' onClick={() => {setSignUpPage(0); setAgreed(false); setUnderstood(false)}}>
                            {langData.sign_up.go_back}
                        </button>
                        {/* <a className='mr-5'>Already Have an Account?</a> */}
                    </div>
                </>
            )}
            </div>
        
            {/* <form className="form-background" onSubmit={submit} style={{ overflowY: 'scroll', width: (privacyPolicy || termsAndConds) ? '90vw' : null, height: privacyPolicy ? '90vh' : '500px' }}>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.your_email}</label>
                        {!form.email ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_email}
                    />
                </div>
                <div className="form-group">
                <div className="d-flex justify-content-between">
                        <label htmlFor="first_name">{langData.sign_up.first_name}</label>
                        {!form.first_name ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }          
                    </div>
                    <input
                        type="first_name"
                        className="form-control"
                        name="first_name"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_first_name}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="last_name">{langData.sign_up.last_name}</label>
                        {!form.last_name ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="last_name"
                        className="form-control"
                        name="last_name"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_last_name}
                    />
                </div>
                <div className="form-group">
                <div className="d-flex justify-content-between">
                        <label htmlFor="phone]">{langData.sign_up.phone}</label>
                        {!form.phone ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="phone"
                        className="form-control"
                        name="phone"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_phone_number}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="address_1">{langData.sign_up.address_1}</label>
                        {!form.address_1 ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="address_1"
                        className="form-control"
                        name="address_1"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_address}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address_2">{langData.sign_up.address_2}</label>
                    <input
                        type="address_2"
                        className="form-control"
                        name="address_2"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_address_2}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="city">{langData.sign_up.city}</label>
                        {!form.city ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="city"
                        className="form-control"
                        name="city"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_city}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="state">{langData.sign_up.state}</label>
                        {!form.state ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="state"
                        className="form-control"
                        name="state"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_state}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="zip">{langData.sign_up.zip}</label>
                        {!form.zip ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="zip"
                        className="form-control"
                        name="zip"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_zip}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="language">{langData.sign_up.language}</label>
                    <select
                        className="form-select"
                        name="language"
                        aria-label="Default select example"
                        onChange={onChange}
                    >
                        <option value="English">{langData.sign_up.english}</option>
                        <option value="Spanish">{langData.sign_up.spanish}</option>
                    </select>
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="password">{langData.sign_up.password}</label>
                        {!form.password ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.placeholders.password}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="confirm_password">{langData.sign_up.password_2}</label>
                        {!form.passwordCheck ? 
                            <p className="float-right text-danger">{langData.sign_up.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="password"
                        className="form-control"
                        name="passwordCheck"
                        onChange={onChange}
                        placeholder={langData.placeholders.password_2}
                    />
                </div>
                {!nonSub && (
                <>
                    {/* <div className="form-group">
                        <div className="d-flex justify-content-between">
                            <label htmlFor="classification">{langData.sign_up.classification}</label>
                            {!form.classification ? 
                                <p className="float-right text-danger">{langData.sign_up.required}</p>
                                : 
                                null
                            }
                        </div>
                        <select
                            onChange={onChange}
                            name="classification"
                            className="form-select"
                            aria-label=".form-select-sm example">
                            <option selected>{langData.sign_up.select_account_type}</option>
                            <option value="fitter">CA Fire Sprinkler Fitter - 45$</option>
                            <option value="apprentice">CA Fire Sprinkler Apprentice - 35$</option>
                            <option value="trainee">CA Fire Sprinkler Trainee 35$</option>
                            <option value="other">Other - 25$</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="billing_frequency">{langData.sign_up.billing_frequency}</label>
                        <select
                            onChange={onChange}
                            name="billing_frequency"
                            className="form-select"
                            aria-label=".form-select-sm example">
                            <option value="monthly" selected>{langData.sign_up.billing_frequency_select}</option>
                            <option value="monthly">{langData.sign_up.monthly}</option>
                            <option value="quarterly">{langData.sign_up.quarterly}</option>
                            <option value="biannually">{langData.sign_up.bi_annually}</option>
                            <option value="annually">{langData.sign_up.annually}</option>
                        </select>
                    </div>
                    <div className="mt-2 d-flex">
                        <label style={{ fontSize: '13px' }}>{langData.sign_up.have_you_renewed}</label>
                        <div className="d-flex">
                            <div className="d-block mr-2">
                                <div className="d-flex">
                                    <input
                                        type="checkbox"
                                        name="yes"
                                        checked={yesNo.yes}
                                        onClick={hasRenewedChange}
                                        className="mr-1 mt-1" 
                                    />
                                    <label style={{ fontSize: '13px' }}>{langData.sign_up.yes}</label>
                                </div>
                                <div className="d-flex">
                                    <input
                                        type="checkbox"
                                        name="no"
                                        checked={yesNo.no}
                                        onClick={hasRenewedChange}
                                        className="mr-1 mt-1" 
                                    />
                                    <label style={{ fontSize: '13px' }}>{langData.sign_up.no}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2" style={{fontSize: '20px'}}>
                        <strong>{langData.sign_up.selected_subs}</strong>
                        {/* <p>{form.classification.charAt(0).toUpperCase()}{form.classification.slice(1)}</p>
                        <p>${subscriptionCost}.00 / {form.billing_frequency}</p>
                        <p>{langData.sign_up.additional_deposits_required}</p>
                    </div>
                </>
                )}
                <div>
                    <strong style={{fontSize: '20px', marginTop: '3vh'}}>{langData.sign_up.what_to_expect}</strong>
                    <p>{nonSub ? langData.sign_up.excited_no_sub : langData.sign_up.excited}&nbsp;
                        <strong>{nonSub ? langData.sign_up.no_charge_till_purchase : langData.sign_up.no_charge_till_approved}</strong>
                    </p>
                </div>
                <div>
                    {privacyPolicy ? (
                        <div>
                            <label 
                                onClick={() => setPrivacyPolicy(!privacyPolicy)}
                                style={{ color: 'purple' }}
                                >Hide Privacy Policy
                            </label>
                            <PrivacyPolicy />
                        </div>
                    ) : (
                        null
                    )}
                    <div>
                        <label 
                            onClick={() => setPrivacyPolicy(!privacyPolicy)}
                            style={privacyPolicy ? { color: 'purple' } : { color: 'blue' }}
                            >{privacyPolicy ? "Hide" : "View"}&nbsp;Privacy Policy
                        </label>
                    </div>
                    {termsAndConds ? (
                        <div>
                            <label 
                                onClick={() => setTermsAndConds(!termsAndConds)}
                                style={{ color: 'purple' }}
                                >Hide Terms & Conditions
                            </label>
                            <TermsAndConditions />
                        </div>
                    ) : (
                        null
                    )}
                    <div>
                        <label 
                            onClick={() => setTermsAndConds(!termsAndConds)}
                            style={termsAndConds ? { color: 'purple' } : { color: 'blue' }}
                            >{termsAndConds ? "Hide" : "View"}&nbsp;Terms & Conditions
                        </label>
                    </div>
                    <div>
                        <div className="form-check mt-2">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" for="flexCheckDefault">
                            Click to Agree
                        </label>
                        
                        </div>
                        <p style={{fontStyle: 'italic'}}>By clicking submit, you agree to the above Privacy Policy and Terms and Conditions</p>
                    </div>
                </div>
                <div>
                    <button
                        type="submit" 
                        className="btn btn-primary submit mt-2"
                        disabled={buttonDisable.submit}>
                        {langData.sign_up.submit}
                    </button>
                </div>
            </form> */}
            </div>
    )
}

export default SignUp
