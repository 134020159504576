import React, { useContext, useState } from 'react'
import UserContext from '../context/UserContext';
import langContext from '../context/LangContext';
import { Validator } from '../exportcomponents/ValidatorExports';
import axios from "axios"
import { toast } from 'react-toastify'

const ChangeSuperPass = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(langContext);
    const [form, setForm] = useState({});

    const submit = async (e) => {
        e.preventDefault()

        try {
            const valid = Validator(form, ['password']);
            if (valid.length == 0) {
                await axios.put(`${process.env.REACT_APP_API_URL}/account/super/resetpassword/${userData.user.userId}`,
                {
                    password: form.password,
                })
                toast.success(langData.toast.pass_changed);
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch {
            toast.error(langData.toast.invalid_credentials);
        }
    }

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form);
    };

    return (
        <div>
            <form className="form-background" onSubmit={submit}>
            <h2 className="h2 mb-4">Tokens</h2>
                <div className="form-group">
                    <strong className='correction header'>{langData.change_super_pass.change_pass}</strong>
                </div>
                <div className="form-group">
                    <label htmlFor="password">{langData.change_super_pass.enter_new_pass}</label>
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_new_password}
                    />
                </div>
                <div>
                    <button type="submit" className="btn btn-primary submit mt-2">
                        {langData.change_super_pass.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ChangeSuperPass
