import { ReactComponent as CircleCheck } from './circle-check.svg'
import { ReactComponent as CircleX } from './circle-x.svg'
import { toast } from 'react-toastify'
import { useState } from 'react'
import EventsApi from './api'

const EventAttendees = ({ event, onSuccess }) => {
    const eventsApi = new EventsApi()
    const [attendance, setAttendance] = useState(new Set(event.registrations?.map(({ id, attended }) => ({ id, checked: attended }))))
    const [deleted, setDeleted] = useState([])
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [registrations, setRegistrations] = useState(event.registrations)

    function attendanceChange(e) {
        const { value: id, checked } = e.target
        // Update Checkboxes
        let index = e.target.getAttribute('data-index')
        let new_registrations = [...registrations]
        new_registrations[index] =  { ...new_registrations[index], attended: !new_registrations[index]?.attended }
        setRegistrations(new_registrations)

        // Update Attendance
        let new_attendance = attendance
        const exists = [...new_attendance].find(({ id: sel_id }) => sel_id == id)
        if (exists) new_attendance.delete(exists)
        
        // Upsert new Attendance
        new_attendance.add({ id, checked })
        setAttendance(new_attendance)

        // Enable / Disable save button
        const new_disabled = !new_attendance.size
        setSaveDisabled(new_disabled)
    }

    async function cancelAttendeeClick(e) {
        try {
            console.log("disabling: ", e.target.value)
            await eventsApi.deleteAttendee(e.target.value)
            toast.success('Attendee removed successfully')
            setDeleted([...deleted, e.target.value])
        } catch (err) {
            toast.error("Something went wrong, please try again later")
        }
    }

    async function submit(e) {
        try {
            await eventsApi.updateAttendance(event.id, [...attendance])
            await onSuccess()
            toast.success('Attendance Updated Successfully')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again later...")
        }
    }

    return (
        <div className="form-background popup-scrollable wide-popup event-popup">
            <p className="text-sm mb-1">Event Attendees</p>
            <h2 className="h2 mb-3">{event.title}</h2>

            <button className="w-100 mb-5" disabled={saveDisabled} onClick={submit}>Save Attendance</button>

            <table className="w-100 attendee-table">
                <tr>
                    <th className='py-2 pl-2'>
                        Attendees
                    </th>
                    <th className='py-2'>
                        Email
                    </th>
                    <th className='py-2'>
                        Phone
                    </th>
                    <th className='py-2'>
                        Cal Card Number
                    </th>
                    <th className='text-center py-2'>
                        <CircleCheck className="attendee-header-icon" />
                    </th>
                    <th className='text-center py-2'>
                        <CircleX className="attendee-header-icon" />
                    </th>
                </tr>
                {registrations?.length > 0 ? registrations?.map((registration, i) => (
                    <tr className={deleted.includes(registration.id) ? 'canceled-attendee' : ''}>
                        <td className='pl-2'>
                            {registration.name}
                        </td>
                        <td>
                            {registration.email}
                        </td>
                        <td>
                            {registration.phone}
                        </td>
                        <td>
                            {registration.cal_fire_card}
                        </td>
                        <td className="text-center">
                            <div className="ml-1 pt-1">
                                <input disabled={deleted.includes(registration.id)} data-index={i} checked={registration.attended} value={registration.id} onChange={attendanceChange} type="checkbox" />
                            </div>
                        </td>
                        <td className="text-center">
                            <button disabled={deleted.includes(registration.id)} onClick={cancelAttendeeClick} value={registration.id} className="btn btn-sm btn-danger">X</button>
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td colspan="5">No Registrations To Display...</td>
                    </tr>
                )}
            </table>
        </div>
    )
}

export default EventAttendees