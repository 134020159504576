import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import axios from 'axios';
import './admin.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ImagePopup = ({ frontImg, backImg }) => {
    const [frontPdf, setFrontPdf] = useState(false);
    const [backPdf, setBackPdf] = useState(false);
    const [frontData, setFrontData] = useState();
    const [backData, setBackData] = useState();

    useEffect(() => {
        if (frontImg) {
            let frontSplit = frontImg.split("?alt");
            let frontSlice = frontSplit[0].slice(frontSplit[0].length - 4, frontSplit[0].length);
            // // console.log(frontSlice);
            if (frontSlice == '.pdf') {
                // setFrontPdf(true);
                (async () => {
                    const imageData = await axios.get(`${process.env.REACT_APP_API_URL}/firestore/convertPDF`, { url: frontImg });
                    // // console.log(imageData.data);
                    // // console.log('Converting to Blob');
                    const front_file = new Blob([imageData.data], {
                        type: 'application/pdf'
                    });

                    // // console.log(front_file);
                    const front_url = URL.createObjectURL(front_file);
                    
                    // // console.log(front_url)
                    // window.open(front_url);

                    // // console.log('Attempting pdfjs conversion');
                    const doc = await pdfjs.getDocument({ data: imageData.data });
                    // // console.log(doc);

                    setFrontData(doc);
                })();
            }
        }
        if (backImg) {
            (async () => {
                const imageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/convertPDF`, { url: 'http://www.africau.edu/images/default/sample.pdf' });
                // // console.log(imageData.data);
                setBackData(imageData.data);
            })();
            let backSplit = backImg.split("?alt");
            let backSlice = backSplit[0].slice(backSplit[0].length - 4, backSplit[0].length);
            // // console.log(backSlice);
            // backSlice == '.pdf' && setBackPdf(true);
        }
    }, [frontImg, backImg]);

    return (
        <div>
            <div className="view-image">
                {!frontPdf ? (
                    <img className="view-license-image" src={frontImg} />
                ) : (
                    <Document file={{data: frontData}}>
                        <Page pageNumber={1} />
                    </Document>
                )}
            </div>
            <div className="mt-3 view-image">
                {!backPdf ? (
                    <img className="view-license-image" src={backImg} />
                ) : (
                    <Document file={{ data: backData }}>
                        <Page pageNumber={1} />
                    </Document>
                )}
            </div>
        </div>

    )
}

export default ImagePopup
