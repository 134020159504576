import React, { useState, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LangContext from '../context/LangContext';
import { StringifyFields } from '../exportcomponents/ValidatorExports.js';

const EditNews = ({ id, title, image, content }) => {
    const [form, setForm] = useState({});
    const navigate = useNavigate()

    const { langData } = useContext(LangContext);

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        // // console.log(form);
        try {
            const stringy_form = StringifyFields(form);
            // // console.log(stringy_form)
            await axios.put(`${process.env.REACT_APP_API_URL}/news/edit/${id}`, stringy_form);
            toast.success(langData.toast.news_edited_success);
            navigate('/')
            navigate('/news')
        } catch (err) {
            toast.error(err.message.data);
        }
    };
    
    return (
        <div>
            <form className="form-background" onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="title">{langData.edit_news.title}</label>
                    <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={onChange}
                        defaultValue={title}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="content">{langData.edit_news.content}</label>
                    <textarea
                        type="text"
                        className="form-control"
                        name="content"
                        onChange={onChange}
                        defaultValue={content}
                    />
                </div>
                {/* <div className="form-group">
                    <label htmlFor="image">Image</label>
                    <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                </div> */}
                <div>
                    <button type="submit" className="btn btn-primary submit mt-2">
                        {langData.edit_news.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditNews
