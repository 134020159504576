import React, { useContext } from 'react';
import './navigation.css';
import LangContext from '../../context/LangContext';
import actLogo from '../../images/act-logo.png'

const Footer = () => {
    const { langData } = useContext(LangContext)
    return (
        <footer className='d-flex justify-content-between'>
            <div className='footer-body d-flex align-items-center justify-content-between'>
                <div className='footer-group'>
                    <b>
                        {langData.footer.email}
                    </b>
                    <span>
                        info@acteducate.com
                    </span>
                </div>
                <div className='footer-group'>
                    <b>
                        {langData.footer.phone}
                    </b>
                    <span>
                        (951) 221-3405
                    </span>
                </div>
                <div className='footer-group'>
                    <b>
                        {langData.footer.address}
                    </b>
                    <span>
                        P.O. Box 6482 Norco, CA 92860
                    </span>
                </div>
            </div>
            <div className='d-flex h-100! flex-column justify-content-between align-items-end'>
                <img className='footer-logo' src={actLogo}/>
                <span>
                    © 2022 ACT
                </span>
            </div>
        </footer>
        // <footer>
        //     <div className="row">
        //         <div className="col-2" id="logo"><img src={actLogo} /></div>
        //         <div className="col-2">
        //             <strong className="mb-3">{langData.footer.contact}</strong>
        //             <p>{langData.footer.stay_compliant}</p>
        //             <p>{langData.footer.more_info}</p>
        //             <div className="mt-1" id="icons">
        //                 <div className="icon"><i className="fa fa-envelope"></i></div><div>{langData.footer.email}</div>
        //                 <div className="icon"><i className="fa fa-phone"></i></div><div>{langData.footer.number}</div>
        //                 <div className="icon"><i className="fa fa-map-marker"></i></div><div>{langData.footer.addy}</div>
        //             </div>
        //         </div>
        //     </div>
        // </footer>
    )
}

export default Footer