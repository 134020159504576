import React, { useState, useEffect, useContext } from 'react'
import LangContext from '../../context/LangContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import './deep.css'
import { useLocation, useNavigate } from 'react-router-dom'

const ConfirmEmail = () => {
    const { langData } = useContext(LangContext)
    const navigate = useNavigate()
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [isError, setIsError] = useState(false)
    const url_params = new URLSearchParams(useLocation().search.slice(1));

    function getHeaderText() {
        return isError
            ? langData.confirm_email.toast_error
            : isConfirmed
            ? langData.confirm_email.confirmed_header
            : langData.confirm_email.verifying_header
    }

    function getBodyText() {
        return isError
            ? langData.confirm_email.contact_super 
            : isConfirmed
            ? langData.confirm_email.confirmed_body
            : langData.confirm_email.please_wait
    }

    async function attemptVerifyEmail() {
        console.log("Confirming email for account: ", url_params.get('account_id'))
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/confirm-email/${url_params.get('account_id')}`)
            setIsConfirmed(true)
            toast.success(langData.confirm_email.toast_success)

            setTimeout(() => navigate('/'), 2000)
        } catch (err) {
            setIsError(true)
            console.error("Error confirming email: ", err)
            toast.error(langData.confirm_email.toast_error)
        }

    }

    useEffect(() => {
        setTimeout(attemptVerifyEmail, 2000)
    }, [])

    return (
        <div className='d-flex flex-column justify-content-between text-left mb-5 pb-5 mx-5 px-5'>
            <h1 className='h1'>
                {getHeaderText()}
            </h1>
            <div className='sub-control-cluster'>
                <p className='h5 fw-light'>
                    {getBodyText()}
                </p>
            </div>
            <span>
                {langData.confirm_email.for_questions_regarding}&nbsp;
                <a href="mailto:info@acteducate.com?subject=Email Unsubscribe Complaint">
                    info@acteducate
                </a> 
                &nbsp;{langData.confirm_email.and_we_get_back}
            </span>
        </div>
    )
}

export default ConfirmEmail