import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup';
import { DenialMessage } from '../exportcomponents/PopupExports'
import { Validator, StringifyFields, ValidateExtionsions, validateDate } from '../exportcomponents/ValidatorExports';
import { useStripe } from '@stripe/react-stripe-js'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';

export function SubmitHours() {
    return (
        <div className="form-background">
            <div>
                <strong>Submit OJL Hours</strong>
                <p>Current Month:</p>
                <p>Hours Submitted:</p>
                <hr style={{ height: '2px', background: 'black' }}/>
                <div>
                    <p>Hours Submitted:</p>
                    <select className="form-select" aria-label="Default select example">
                        <option selected>Enter Hours Worked This Month</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                    <button className="btn btn-primary mt-3">Submit</button>
                </div>
            </div>
        </div>
    )
}

export function JobLearningHours({ id, cardId }) {
    const [form, setForm] = useState({});
    const [image, setImage] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const navigate = useNavigate();

    const onFrontImage = (e) => {
        setImage(e.target.files[0]);
        // console.log(form)
    };

    const onChange = (e) => {
        setForm({ ...form, account_id: id, card_id: cardId, [e.target.name]: e.target.value });
        // console.log(form)
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setButtonDisable(true);
        setTimeout(() => {
            setButtonDisable(false);
        }, 3000);

        try {
            if (!image) {
                toast.error("No Image Uploaded!");
                navigate('/');
                navigate('/licenses');
                return;
            }


            const frontData = new FormData();
            frontData.append('image', image, `${Date.now()}-${image.name}`);
            frontData.append('account_id', id);

            try {
                let url_string = ''
                if(image) {
                    const imageData = new FormData();
                    imageData.append('file', image, `${Date.now()}-${image.name}`)
                     const newImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                        headers: { 'content-type': 'multipart/form-data' }
                    });
                    url_string = newImageData.data.downloadURL;

                    const hours = await axios.post(`${process.env.REACT_APP_API_URL}/calfire/hours/${id}/${cardId}`, 
                        { hours: form.hours, img_name: image.name, img_url: url_string, approved: 0 })

                    // console.log(hours)
                }

                toast.success("CEU Hours Submitted!");
                navigate('/');
                navigate('/licenses');
                    
            } catch (err) {
                // console.log(err);
                toast.error("Must Upload A File!");
            }      
        } catch(err) {
            // console.log(err)
        }
        
    }

    return (
        <div className="form-background">
            <div>
                <strong>Upload External Course Hours</strong>
                <p className="mt-3">Hours Completed:</p>
                <select 
                    name="hours" 
                    onChange={onChange}
                    className="form-select" 
                    aria-label="Default select example"
                >
                    <option selected>Select Credits/Hours</option>
                    <option value="1">1 Hour - 0.1 Credits</option>
                    <option value="2">2 Hour - 0.2 Credits</option>
                    <option value="3">3 Hour - 0.3 Credits</option>
                    <option value="4">4 Hour - 0.4 Credits</option>
                    <option value="5">5 Hour - 0.5 Credits</option>
                    <option value="6">6 Hour - 0.6 Credits</option>
                    <option value="7">7 Hour - 0.7 Credits</option>
                    <option value="8">8 Hour - 0.8 Credits</option>
                    <option value="9">9 Hour - 0.9 Credits</option>
                    <option value="10">10 Hour - 1 Credit</option>
                </select>
                    {/* <input
                        
                        type="text" 
                        className="form-control" 
                        aria-label="Amount"
                        onChange={onChange} 
                    /> */}
                <div className="mt-3">
                    <p>Upload Certificate of Completion:</p>
                    <div>
                        <div className="d-flex" style={{ alignItems: "center" }}>
                        <input 
                            type="file" 
                            onChange={onFrontImage} 
                        />
                        </div>
                        
                        <button onClick={onSubmit} className="btn btn-primary mt-3" disabled={buttonDisable}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function SuperEnrollUser({ id, classification, is_admin }) {
    const { langData } = useContext(LangContext);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [calFireForm, setCalFireForm] = useState({});
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [dateListed, setDateListed] = useState();
    const [submittalDate, setSubmittalDate] = useState()

    const navigate = useNavigate();

    const onChange = (e) => {
        setCalFireForm({ ...calFireForm, classification: classification, [e.target.name]: e.target.value });
        // console.log(calFireForm)
    };

    const handleDateListed = (e) => {
        const raw_date = e.target.value
        let formatted_date = ''
        // console.log(raw_date);
        if (raw_date !== '') {
            const split_date = String(raw_date).split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }

        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, date_issued: utc_date });
        setDateListed(formatted_date);
    }

    const handleSubmittalDate = (e) => {
        const raw_date = e.target.value;
        let formatted_date = ''
        if (raw_date !== '') {
            const split_date = raw_date.split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }
        
        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, submittal_due: utc_date });
        setSubmittalDate(formatted_date);
    }

    const onFrontImage = (e) => {
        setFrontImage(e.target.files[0]);
        // console.log(calFireForm)
    };

    const onBackImage = (e) => {
        setBackImage(e.target.files[0]);
        setCalFireForm({ ...calFireForm, back_image: e.target.files[0] })
        // console.log(calFireForm)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        
        setButtonDisable(true);
        setTimeout(() => setButtonDisable(false), 5000);

        try {
            if (!frontImage || !backImage) {
                toast.error("Front and Back Picture Required!");
                navigate('/');
                navigate(is_admin ? '/admin' : '/super');
                return;
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/calfire/primary/${id}`, calFireForm );
            }

            const cardId = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${id}`)
            const card = cardId.data.card_id
            // console.log(card)

                const frontData = new FormData();
                frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`);
                frontData.append('account_id', id);

                const backData = new FormData();
                backData.append('image', backImage, `${Date.now()}-${backImage.name}`);
                backData.append('account_id', id);

                try {
                    let front_url_string = ''
                    let back_url_string = ''
                    if(frontImage && backImage) {
                        const frontData = new FormData();
                        frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`)
                        const frontImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                            headers: { 'content-type': 'multipart/form-data' }
                        });
                        front_url_string = frontImageData.data.downloadURL;
                        // console.log({img_name: frontImage.name, img_url: front_url_string })

                        const backData = new FormData();
                        backData.append('image', backImage, `${Date.now()}-${backImage.name}`)
                        const backImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, backData, {
                            headers: { 'content-type': 'multipart/form-data' }
                        });
                        back_url_string = backImageData.data.downloadURL;
                        // console.log({img_name: frontImage.name, img_url: back_url_string })

                        await axios.post(`${process.env.REACT_APP_API_URL}/calfire/images/${id}`, { card_id: card, img_name: frontImage.name, img_url: front_url_string })
                        await axios.post(`${process.env.REACT_APP_API_URL}/calfire/images/${id}`, { card_id: card, img_name: backImage.name, img_url: back_url_string })
                    }

                    toast.success("Certification added to user!");
                    navigate('/');
                    navigate(is_admin ? '/admin' : '/super');
                    
                } catch (err) {
                    // console.log(err);
                    toast.error("Must Upload Two Pictures!");
                }      
        } catch(err) {
            // console.log(err)
        }
    }

    return (
        <div className="form-background" style={{ height: 'auto' }}>
            <h2 className="h2">Cal Fire Card</h2>
            <div className='mt-1'>
                <i>
                    Please upload card details in Absorb.
                </i>
                {/* <div className="classification d-flex">
                    <strong className="mt-3">Classification:</strong>
                    <p className="mt-3 ml-5">{classification}</p>
                </div>
                <p className="mt-3">Cal Fire Card #:</p>
                <div className="input-group mt-2">
                    <input
                        name="card_number" 
                        type="card_number" 
                        className="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Current Sticker #:</p>
                <div className="input-group mt-2">
                    <input
                        name="sticker_number" 
                        type="sticker_number" 
                        className="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Sticker Year:</p>
                <div className="input-group mt-2">
                    <input
                        name="sticker_year"  
                        type="sticker_year" 
                        className="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                <div className="start-date mt-3">
                    <label>Date First Listed:</label>    
                    <input
                        type="date"
                        name="date_issued"
                        className="form-control mb-3"
                        onBlur={handleDateListed}
                    />
                    </div>
                <div className="start-date">
                    <label>Renewal Status:</label>
                    <input
                        name="renewal_status"  
                        type="sticker_year" 
                        className="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                {classification === 'fitter' || classification === 'fitter (Multi-Family)' || classification === 'fitter (Commercial)' ?
                    (
                    <div className="start-date">
                        <label>Next CEU Submittal Date:</label>
                        <div className="input-group mt-2">
                            <input
                                type="date"
                                name="submittal_due"
                                className="form-control"
                                onBlur={handleSubmittalDate}
                            />
                        </div>
                    </div>
                    ) : (
                        <div>
                            <p className="mt-3">Next CEU Submittal Date:</p>
                            <div className="input-group mt-2">
                                <input
                                    name="submittal_due" 
                                    type="text" 
                                    className="form-control" 
                                    aria-label="Amount"
                                    disabled
                                />
                            </div>
                        </div>
                    )   
                }
                <p>Notes:</p>
                <div className="form-group">
                    <textarea
                        name="notes" 
                        className="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="3"
                        onChange={onChange}
                    >
                    
                    </textarea>
                </div> */}
                <div className="my-2">
                    {/* <p className='mb-1'>
                        <strong>Upload a Copy of the Card:</strong>
                    </p> */}
                    <strong>Front Image</strong>
                    <div style={{ display: 'contents'}}>
                        <input 
                            className="my-2"
                            type="file" 
                            onChange={onFrontImage} />
                    </div>
                    <strong>Back Image</strong>
                    <div style={{ display: 'contents'}}>
                        <input 
                            className="my-2"
                            type="file" 
                            onChange={onBackImage} />
                    </div>
                </div>
                <button
                    onClick={onSubmit}
                    disabled={buttonDisable}
                    className="btn btn-primary w-100">
                    Submit
                </button>
            </div>
        </div>
    )
}

export function SuperEnrollApprentice({ id, classification, is_admin }) {
    const { langData } = useContext(LangContext);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [calFireForm, setCalFireForm] = useState({});
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [dateListed, setDateListed] = useState();
    const [submittalDate, setSubmittalDate] = useState()

    const navigate = useNavigate();

    const onChange = (e) => {
        setCalFireForm({ ...calFireForm, classification: classification, [e.target.name]: e.target.value });
        // console.log(calFireForm)
    };

    const handleDateListed = (e) => {
        const raw_date = e.target.value
        let formatted_date = ''
        // console.log(raw_date);
        if (raw_date !== '') {
            const split_date = String(raw_date).split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }

        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, date_issued: utc_date });
        setDateListed(formatted_date);
    }

    const handleSubmittalDate = (e) => {
        const raw_date = e.target.value;
        let formatted_date = ''
        if (raw_date !== '') {
            const split_date = raw_date.split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }
        
        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, submittal_due: utc_date });
        setSubmittalDate(formatted_date);
    }

    const onFrontImage = (e) => {
        setFrontImage(e.target.files[0]);
        setCalFireForm({ ...calFireForm, front_image: e.target.files[0] })
        // console.log(calFireForm)
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        
        setButtonDisable(true);
        setTimeout(() => setButtonDisable(false), 5000);

        try {
            if (!frontImage) {
                toast.error("Front Picture Required!");
                // navigate('/apprentice');
                return;
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/calfire/primary/${id}`, calFireForm );
            }

            const cardId = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${id}`)
            const card = cardId.data.card_id
            // console.log(card)

                const frontData = new FormData();
                frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`);
                frontData.append('account_id', id);

                try {
                    let front_url_string = ''
                    if(frontImage) {
                        const frontData = new FormData();
                        frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`)
                        const frontImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                            headers: { 'content-type': 'multipart/form-data' }
                        });
                        front_url_string = frontImageData.data.downloadURL;
                        // console.log({img_name: frontImage.name, img_url: front_url_string })

                        await axios.post(`${process.env.REACT_APP_API_URL}/calfire/images/${id}`, { card_id: card, img_name: frontImage.name, img_url: front_url_string })
                    }

                    toast.success("Certification added to user!");
                    // navigate('/apprentice');
                    
                } catch (err) {
                    // console.log(err);
                    toast.error("Something went wrong, please check your entries and try again");
                }      
        } catch(err) {
            // console.log(err)
        }
        
    }

    return (
        <div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
            <div>
                <strong>Add Cal Fire Card</strong>
                <div className="classification d-flex">
                    <strong className="mt-3">Classification:</strong>
                    <p className="mt-3 ml-5">{classification}</p>
                </div>
                <p className="mt-3">Cal Fire Card #:</p>
                <div class="input-group mt-2">
                    <input
                        name="card_number" 
                        type="card_number" 
                        class="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Current Sticker #:</p>
                <div class="input-group mt-2">
                    <input
                        name="sticker_number" 
                        type="sticker_number" 
                        class="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Sticker Year:</p>
                <div class="input-group mt-2">
                    <input
                        name="sticker_year"  
                        type="sticker_year" 
                        class="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                <div className="start-date mt-3">
                    <label>Date First Listed:</label>    
                    <input
                        type="date"
                        name="date_issued"
                        className="form-control mb-3"
                        onBlur={handleDateListed}
                    />
                    </div>
                <div className="start-date">
                    <label>Renewal Status:</label>
                    <input
                        name="renewal_status"  
                        type="sticker_year" 
                        class="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                {classification === 'fitter' || classification === 'fitter (Multi-Family)' || classification === 'fitter (Commercial)' ?
                    (
                    <div className="start-date">
                        <label>Next CEU Submittal Date:</label>
                        <div class="input-group mt-2">
                            <input
                                type="date"
                                name="submittal_due"
                                className="form-control"
                                onBlur={handleSubmittalDate}
                            />
                        </div>
                    </div>
                    ) : (
                        <div>
                            <p className="mt-3">Next CEU Submittal Date:</p>
                            <div class="input-group mt-2">
                                <input
                                    name="submittal_due" 
                                    type="text" 
                                    className="form-control" 
                                    aria-label="Amount"
                                    disabled
                                />
                            </div>
                        </div>
                    )   
                }
                <p>Notes:</p>
                <div class="form-group">
                    <textarea
                        name="notes" 
                        class="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="3"
                        onChange={onChange}
                    >
                    </textarea>
                </div>
                <div className="mt-3">
                    <strong>Upload a Copy of the Card:</strong>
                    <div className="card p-3" style={{ display: 'grid' }}>
                        <strong>Choose Front Image</strong>
                        <div style={{ display: 'contents'}}>
                        <input 
                            type="file" 
                            onChange={onFrontImage} />
                        </div>
                    </div>
                    <button
                        onClick={onSubmit}
                        disabled={buttonDisable}
                        className="btn btn-primary mt-3">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export function SuperEditApprentice({ id, classification, email, is_admin }) {
    const { langData } = useContext(LangContext);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [cardInfo, setCardInfo] = useState([]);
    const [imagesInfo, setImagesInfo] = useState([]);
    const [calFireForm, setCalFireForm] = useState({});
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [dateListed, setDateListed] = useState('');
    const [submittalDate, setSubmittalDate] = useState('');

    const navigate = useNavigate();

    const timestampConverter = (timestamp) => {
        let a = new Date(timestamp)
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();

        let time = date + ' ' + month + ' ' + year;

        return time
    }

    const onChange = (e) => {
        setCalFireForm({ ...calFireForm, classification: classification, [e.target.name]: e.target.value });
        // console.log(calFireForm)
    };

    const handleDateListed = (e) => {
        const raw_date = e.target.value
        let formatted_date = ''
        // console.log(raw_date);
        if (raw_date !== '') {
            const split_date = String(raw_date).split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }

        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, date_issued: utc_date });
        setDateListed(formatted_date);
    }

    const handleSubmittalDate = (e) => {
        const raw_date = e.target.value;
        let formatted_date = ''
        if (raw_date !== '') {
            const split_date = raw_date.split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }
        
        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, submittal_due: utc_date });
        setSubmittalDate(formatted_date);
    }

    const onFrontImage = (e) => {
        setFrontImage(e.target.files[0]);
        setCalFireForm({ ...calFireForm, front_image: e.target.files[0] })
        // console.log(frontImage)
    };
    
    const downloadPDF = (image) => {
        window.open(image)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        
        setButtonDisable(true);
        setTimeout(() => setButtonDisable(false), 5000);

        try {
            try {
                if(!is_admin) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/primary/${id}`, calFireForm );
                }
                // navigate('/');
                toast.success("Cal Fire Card Edited!");
                
                // navigate('/apprentice');
            } catch(e) {
                // console.log(e)
            }

            const cardId = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${id}`)
            const card = cardId.data.card_id

                try {
                    let front_url_string = ''
                    console.log(imagesInfo[0])
                    if(frontImage) {    
                            const frontData = new FormData();
                            frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`)
                            frontData.append('account_id', id);
                            const frontImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                                headers: { 'content-type': 'multipart/form-data' }
                            });
                            
                            front_url_string = frontImageData.data.downloadURL;
                            // console.log({img_name: frontImage.name, img_url: front_url_string })

                            await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/images/${id}/${imagesInfo.card_id}`, { img_name: frontImage.name, img_url: front_url_string })
                        }
                } catch (err) {
                    // console.log(err);
                    toast.error("Error!");
                }      
        } catch(err) {
            // console.log(err)
        }
    }

    const onImageSubmit = async (e) => {
        e.preventDefault();
        
        setButtonDisable(true);
        setTimeout(() => setButtonDisable(false), 5000);

        try {
            if (!frontImage) {
                toast.error("Front Picture Required!");
                // navigate('/apprentice');
                return;
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/calfire/primary/${id}`, calFireForm );
            }

            const cardId = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${id}`)
            const card = cardId.data.card_id
            // console.log(card)

                const frontData = new FormData();
                frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`);
                frontData.append('account_id', id);

                try {
                    let front_url_string = ''
                    if(frontImage) {
                        const frontData = new FormData();
                        frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`)
                        const frontImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                            headers: { 'content-type': 'multipart/form-data' }
                        });
                        front_url_string = frontImageData.data.downloadURL;
                        // console.log({img_name: frontImage.name, img_url: front_url_string })

                        await axios.post(`${process.env.REACT_APP_API_URL}/calfire/images/${id}`, { card_id: card, img_name: frontImage.name, img_url: front_url_string })
                    }

                    toast.success("Certification added to user!");
                    // navigate('/apprentice');
                    
                } catch (err) {
                    // console.log(err);
                    toast.error("Must Upload Two Pictures!");
                }      
        } catch(err) {
            // console.log(err)
        }
    }

    async function setupSuperEditApprentice() {
        const existingInfo = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/cardID/${id}`);
        setCardInfo(existingInfo.data)

        try {
            const split_date = existingInfo.data.date_issued.split('T')[0];
            setDateListed(split_date);
        } catch (err) { }

        try {
            const split_date = existingInfo.data.submittal_due.split('T')[0];
            setSubmittalDate(split_date);
        } catch (err) { }
        
        const existingImages = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/images/${id}`)
        setImagesInfo(existingImages.data[0])
        console.log(existingImages)
    }

    useEffect(() => {
        setupSuperEditApprentice()
    }, [])

    return (
        <div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
            <div>
                <strong>Cal Fire Card Edit</strong>
                <div className="classification d-flex">
                    <strong className="mt-3">Editing:</strong>
                    <p className="mt-3 ml-5">{classification}</p>
                </div>
                <div className="classification d-flex">
                    <strong className="mt-3">Editing:</strong>
                    <p className="mt-3 ml-5">{email}</p>
                </div>
                <p className="mt-3">Cal Fire Card #:</p>
                <div class="input-group mt-2">
                    <input
                        placeholder={cardInfo.card_number}
                        name="card_number" 
                        type="card_number" 
                        class="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Current Sticker #:</p>
                <div class="input-group mt-2">
                    <input
                        placeholder={cardInfo.sticker_number}
                        name="sticker_number" 
                        type="sticker_number" 
                        class="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Sticker Year:</p>
                <div class="input-group mt-2">
                    <input
                        placeholder={cardInfo.sticker_year}
                        name="sticker_year"  
                        type="sticker_year" 
                        class="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                <div className="start-date mt-3">
                    <label>Date First Listed:</label>
                    <input
                        type="date"
                        name="date_issued"
                        className="form-control mb-3"
                        defaultValue={dateListed}
                        onBlur={handleDateListed}
                    />
                </div>
                <div className="start-date">
                    <label>Renewal Status:</label>
                    <input
                        placeholder={cardInfo.renewal_status}
                        name="renewal_status"  
                        type="sticker_year" 
                        class="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                {classification === 'fitter' || classification === 'fitter (Multi-Family)' || classification === 'fitter (Commercial)' ?
                    (
                    <div className="start-date">
                        <label>Next CEU Submittal Date:</label>
                        <div class="input-group mt-2">
                            <input
                                type="date"
                                name="submittal_due"
                                className="form-control"
                                defaultValue={submittalDate}
                                onBlur={handleSubmittalDate}
                            />
                        </div>
                    </div>
                    ) : (
                        <div>
                            <p className="mt-3">Next CEU Submittal Date:</p>
                            <div class="input-group mt-2">
                                <input
                                    placeholder={timestampConverter(cardInfo.submittal_due)}
                                    name="submittal_due" 
                                    type="text" 
                                    className="form-control" 
                                    aria-label="Amount"
                                    disabled
                                />
                            </div>
                        </div>
                    )   
                }
                <p>Notes:</p>
                <div class="form-group">
                    <textarea
                        placeholder={cardInfo.notes}
                        name="notes" 
                        class="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="3"
                        onChange={onChange}
                    >
                    
                    </textarea>
                </div>
                <div className="mt-3">
                    <strong>Upload a Copy of the Card:</strong>
                    <div className="card p-3" style={{ display: 'grid' }}>
                        {imagesInfo ? (
                        <div>
                            <strong>Change Front Image</strong>
                            <div style={{ display: 'contents'}}>
                                <input 
                                    type="file" 
                                    onChange={onFrontImage} 
                                />
                                    <div>
                                        <button className="btn btn-primary mt-2" onClick={() => downloadPDF(imagesInfo.img_url)}>
                                            View Current Image
                                        </button>
                                    </div>
                                    <button 
                                        onClick={onSubmit}
                                        disabled={buttonDisable}
                                        className="btn btn-primary mt-3">
                                        Submit
                                    </button>
                            </div>
                        </div> ) : (
                            <div>
                                <strong>Add Front Image</strong>
                                <div style={{ display: 'contents'}}>
                                    <input 
                                        type="file" 
                                        onChange={onFrontImage} 
                                    />
                                </div>
                                <button 
                                    onClick={onImageSubmit}
                                    disabled={buttonDisable}
                                    className="btn btn-primary mt-3">
                                    Submit
                                </button>
                            </div>
                        )}
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}

export function SuperEditUser({ id, classification, email, is_admin }) {
    const { langData } = useContext(LangContext);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [cardInfo, setCardInfo] = useState([]);
    const [imagesInfo, setImagesInfo] = useState([]);
    const [calFireForm, setCalFireForm] = useState({});
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [dateListed, setDateListed] = useState('');
    const [submittalDate, setSubmittalDate] = useState('');

    const navigate = useNavigate();

    const timestampConverter = (timestamp) => {
        let a = new Date(timestamp)
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();

        let time = date + ' ' + month + ' ' + year;

        return time
    }

    const onChange = (e) => {
        setCalFireForm({ ...calFireForm, classification: classification, [e.target.name]: e.target.value });
        // console.log(calFireForm)
    }; 

    const handleDateListed = (e) => {
        const raw_date = e.target.value
        let formatted_date = ''
        // console.log(raw_date);
        if (raw_date !== '') {
            const split_date = String(raw_date).split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }

        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, date_issued: utc_date });
        setDateListed(formatted_date);
    }

    const handleSubmittalDate = (e) => {
        const raw_date = e.target.value;
        let formatted_date = ''
        if (raw_date !== '') {
            const split_date = raw_date.split('-')
            formatted_date = split_date[1] + '/' + split_date[2] + '/' + split_date[0]
        }
        
        let utc_date;
        try {
            utc_date = new Date(raw_date).toISOString();
        } catch (_) {}

        setCalFireForm({ ...calFireForm, submittal_due: utc_date });
        setSubmittalDate(formatted_date);
    }

    const onFrontImage = (e) => {
        setFrontImage(e.target.files[0]);
        // console.log(frontImage)
    };

    const onBackImage = (e) => {
        setBackImage(e.target.files[0]);
        // console.log(backImage)
    }
    
    const downloadPDF = (image) => {
        window.open(image)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        
        setButtonDisable(true);
        setTimeout(() => setButtonDisable(false), 5000);

        try {
            try {
                await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/primary/${id}`, calFireForm );
    
                toast.success("Cal Fire Card Edited!");
                navigate('/');
                navigate(is_admin ? '/admin' : '/super');
            } catch(e) {
                // console.log(e)
            }

            const cardId = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${id}`)
            const card = cardId.data.card_id

                try {
                    let front_url_string = ''
                    let back_url_string = ''
                    if(frontImage || backImage) {
                        if(frontImage) {
    
                            const frontData = new FormData();
                            frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`)
                            frontData.append('account_id', id);
                            const frontImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                                headers: { 'content-type': 'multipart/form-data' }
                            });
                            
                            front_url_string = frontImageData.data.downloadURL;
                            // console.log({img_name: frontImage.name, img_url: front_url_string })

                            await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/images/${id}/${imagesInfo[0].image_id}`, { img_name: frontImage.name, img_url: front_url_string })
                        }
                        
                        if(backImage) {
                                
                            const backData = new FormData();
                            backData.append('image', backImage, `${Date.now()}-${backImage.name}`)
                            backData.append('account_id', id);
                            const backImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, backData, {
                                headers: { 'content-type': 'multipart/form-data' }
                            });

                            back_url_string = backImageData.data.downloadURL;
                            // console.log({img_name: backImage.name, img_url: back_url_string })
                            
                            await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/images/${id}/${imagesInfo[1].image_id}`, { img_name: backImage.name, img_url: back_url_string })
                        }
                    }
                } catch (err) {
                    // console.log(err);
                    toast.error("Must Upload Two Pictures!");
                }      
        } catch(err) {
            // console.log(err)
        }
    }

    async function asyncSetupSuperEditUser() {
        const existingInfo = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/cardID/${id}`);
        setCardInfo(existingInfo.data)
        // console.log(existingInfo.data)

        try {
            const split_date = existingInfo.data.date_issued.split('T')[0];
            setDateListed(split_date);
        } catch (err) { }

        try {
            const split_date = existingInfo.data.submittal_due.split('T')[0];
            setSubmittalDate(split_date);
        } catch (err) { }
        
        const existingImages = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/images/${id}`)
        setImagesInfo(existingImages.data)
    }

    useEffect(() => {
        asyncSetupSuperEditUser()
    }, [])

    return (
        <div className="form-background" >
            <h2 className="h2">Cal Fire Card</h2>
            <div className='mt-1'>
                <i>
                    Please upload card details in Absorb.
                </i>
                {/* <strong>Cal Fire Card Edit</strong>
                <div className="classification d-flex">
                    <strong className="mt-3">Editing:</strong>
                    <p className="mt-3 ml-5">{classification}</p>
                </div>
                <div className="classification d-flex">
                    <strong className="mt-3">Editing:</strong>
                    <p className="mt-3 ml-5">{email}</p>
                </div>
                <p className="mt-3">Cal Fire Card #:</p>
                <div className="input-group mt-2">
                    <input
                        disabled={is_admin}
                        placeholder={cardInfo.card_number}
                        name="card_number" 
                        type="card_number" 
                        className="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Current Sticker #:</p>
                <div className="input-group mt-2">
                    <input
                        disabled={is_admin}
                        placeholder={cardInfo.sticker_number}
                        name="sticker_number" 
                        type="sticker_number" 
                        className="form-control" 
                        aria-label="Amount"
                        onChange={onChange}
                    />
                </div>
                <p className="mt-3">Sticker Year:</p>
                <div className="input-group mt-2">
                    <input
                        disabled={is_admin}
                        placeholder={cardInfo.sticker_year}
                        name="sticker_year"  
                        type="sticker_year" 
                        className="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                <div className="start-date mt-3">
                    <label>Date First Listed:</label>
                    <input
                        disabled={is_admin}
                        type="date"
                        name="date_issued"
                        className="form-control mb-3"
                        defaultValue={dateListed}
                        onBlur={handleDateListed}
                    />
                </div>
                <div className="start-date">
                    <label>Renewal Status:</label>
                    <input
                        disabled={is_admin}
                        placeholder={cardInfo.renewal_status}
                        name="renewal_status"  
                        type="sticker_year" 
                        className="form-control" 
                        aria-label="Amount" 
                        onChange={onChange}
                    />
                </div>
                {classification === 'fitter' || classification === 'fitter (Multi-Family)' || classification === 'fitter (Commercial)' ?
                    (
                    <div className="start-date">
                        <label>Next CEU Submittal Date:</label>
                        <div className="input-group mt-2">
                            <input
                        disabled={is_admin}
                                type="date"
                                name="submittal_due"
                                className="form-control"
                                defaultValue={submittalDate}
                                onBlur={handleSubmittalDate}
                            />
                        </div>
                    </div>
                    ) : (
                        <div>
                            <p className="mt-3">Next CEU Submittal Date:</p>
                            <div className="input-group mt-2">
                                <input
                                    placeholder={timestampConverter(cardInfo.submittal_due)}
                                    name="submittal_due" 
                                    type="text" 
                                    className="form-control" 
                                    aria-label="Amount"
                                    disabled
                                />
                            </div>
                        </div>
                    )   
                }
                <p>Notes:</p>
                <div className="form-group">
                    <textarea
                        disabled={is_admin}
                        placeholder={cardInfo.notes}
                        name="notes" 
                        className="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="3"
                        onChange={onChange}
                    >
                    
                    </textarea>
                </div> */}
                {is_admin ? (
                    <div>
                        <p>TODO: View images of uploaded cal card (for apprentice view)</p>
                    </div>
                ) : (
                <>
                    <div className="mt-3">
                        {/* <strong>Upload a Copy of the Card:</strong>
                        <div className="card p-3" style={{ display: 'grid' }}>
                            <strong>Change Front Image</strong>
                            <div style={{ display: 'contents'}}>
                                <input 
                                    type="file" 
                                    onChange={onFrontImage} 
                                />
                                    <div>
                                        <button className="btn btn-primary mt-2" onClick={() => downloadPDF(imagesInfo[0].img_url)}>
                                            View Current Image
                                        </button>
                                    </div>
                            </div>
                            <strong className="mt-3">Change Back Image</strong>
                            <div style={{ display: 'contents'}}>
                                <input 
                                    type="file" 
                                    onChange={onBackImage} 
                                />
                                <div>
                                    <button className="btn btn-primary mt-2" onClick={() => downloadPDF(imagesInfo[1].img_url)}>
                                        View Current Image
                                    </button>
                                </div>
                            </div> */}
                            
                        <strong>Front Image</strong>
                        <div style={{ display: 'contents'}}>
                            <input 
                                className="my-2"
                                type="file" 
                                onChange={onFrontImage} />
                        </div>
                        <strong>Back Image</strong>
                        <div style={{ display: 'contents'}}>
                            <input 
                                className="my-2"
                                type="file" 
                                onChange={onBackImage} />
                        </div>
                    </div>
                    <button 
                        onClick={onSubmit}
                        disabled={buttonDisable}
                        className="btn btn-primary mt-3 w-100">
                        Submit
                    </button>
                </>
                )}
            </div>
        </div>
    )
}

export function ViewRequest({ account, id, image, hours }) {
    const [request, setRequest] = useState({})
    const [toggle, setToggle] = useState(false)
    const [toggleDenial, setToggleDenial] = useState(false)
    const [extraHours, setExtraHours] = useState()
    const [denialMessage, setDenialMessage] = useState()
    const navigate = useNavigate();

    const onChange = (e) => {
        setExtraHours({ ...extraHours, [e.target.name]: e.target.value });
        // console.log(extraHours)
    };

    const onChangeMessage = (e) => {
        setDenialMessage({ ...denialMessage, [e.target.name]: e.target.value });
        // console.log(denialMessage)
    };

    const finalizeEditHours = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/calfire/request/${id}`, extraHours)
        const requestData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/request/${id}`)
        // console.log(requestData.data[0])
        setRequest(requestData.data[0])
        setToggle(!toggle)
    }

    const denialApprove= async () => {
        const submittalDenied = `Submitted Hours: ${request.hours} Denied for the Following Reasons: ${denialMessage.denialMessage}`

        await axios.post(`${process.env.REACT_APP_API_URL}/notification/`, { account_id: account, color: 'NONE', message: submittalDenied,  })

        toast.success("Denial Reason Sent!")
    }

    const approveHours = async () => {
        let amount

        const currentHours = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/current/hours/${account}`)
        if(currentHours.data[0].current_hours) {
            amount = parseInt(currentHours.data[0].current_hours) + parseInt(request.hours)
        } else {
            amount = request.hours
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/calfire/hours/${account}`, { hours: amount, id: id })
        // const requestData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/request/${id}`)
        // setRequest(requestData.data[0])

        // navigate("/")
        // navigate("/super")
        toast.success("Hours Approved")

    }

    const editHours = () => {
        setToggle(!toggle)
    }

    const denyHours = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/calfire/request/${id}`);

        setToggleDenial(!toggleDenial)
    
        // navigate("/")
        // navigate("/super")
        toast.error("Hours Denied");
    }

    
    const downloadPDF = (image) => {
        window.open(image)
    }

    async function asyncSetupViewRequest() {
        const requestData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/request/${id}`)

        setRequest(requestData.data[0])
    }

    useEffect(() => {
        asyncSetupViewRequest()
    }, [])

    return (
        <div className="form-background" style={{ overflowY: 'scroll', height: 'auto' }}>
            {toggle ? (
                <div style={{ display: 'contents' }}>
                    <strong>Edit CEU's Requested:</strong>
                    <input
                        name="hours"
                        className="form-control"
                        onChange={onChange} 
                        placeholder={hours}
                    />
                </div>
            ) : (
                <div>
                    <div><strong>CEU's Requested: </strong><p>{hours}</p></div>
                </div>
            )}
            <div className="d-flex">
                {image.includes('.pdf') ? (
					<button className="btn btn-primary" onClick={() => downloadPDF(image)}>
						Download PDF
				    </button>
                ) : (
                    <img src={image} />
                )} 
            </div>
                
            <div className="d-flex">
                <button onClick={approveHours} className="btn btn-primary mt-3">Approve</button>
                {!toggle ? (
                    <div>
                        <button onClick={editHours} className="btn btn-warning mt-3 ml-3">Edit</button>
                    </div>
                ): (
                    <div>
                        <button onClick={finalizeEditHours} className="btn btn-warning mt-3 ml-3">Finalize Edit</button>
                    </div>
                )}
                {!toggleDenial ?
                <div>
                    <button onClick={denyHours} className="btn btn-danger mt-3 ml-3">Deny</button>
                </div> : (null)
                }
            </div>
            
            {!toggleDenial ?
                    (null)
                     : (
                        <div className="form-group mt-3">
                            <strong for="exampleFormControlTextarea1">Reason For Denial</strong>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                onChange={onChangeMessage}
                                name="denialMessage"
                            >

                            </textarea>
                            <div className="denial-buttons">
                                <button onClick={denialApprove} className="btn btn-primary m-1">Submit Denial</button>
                                <button onClick={denyHours} className="btn btn-danger m-1">Cancel Denial</button>
                            </div>
                        </div>   
                    )
                }
        </div>
    )
}

export function RequestInfo({ id }) {
    const [info, setInfo] = useState()

    const submitEmail = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/calfire/request/${id}`, { body: info.info })
    }

    const onChange = (e) => {
        setInfo({ ...info, [e.target.name]: e.target.value });
        // console.log(info)
        // console.log(id)
    };

    return (
        <div className="form-background">
            <div className="form-group">
                <strong for="exampleFormControlTextarea1">Send Request</strong>
                <textarea className="form-control mt-3" id="exampleFormControlTextarea1" rows="3"
                    name="info"
                    onChange={onChange}
                ></textarea>
                <button onClick={submitEmail} className="btn btn-primary mt-3">Submit</button>
            </div>
        </div>
    )
}

export function NoPrimaryData() {
    const { userData } = useContext(UserContext);

    return (
        <div className="card my-5 p-3">
            <p>Primary Certification not yet uploaded!</p>
            {/* <Popup
                modal
                trigger={<button className="btn btn-primary mt-3">Request Info</button>}
            >
                <RequestInfo 
                    id={userData.userId}
                />
            </Popup> */}
            
        </div>
    )
}

export function ElevateAdmin({ id }) {
    const [elevatedStatus, setElevatedStatus] = useState()
    const navigate = useNavigate();

    const setTrue = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/account/admin/level/${id}`, { level: true })
        toast.success("Admin Elevated")
    }

    const setFalse = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/account/admin/level/${id}`, { level: false })
        toast.success("Admin Un-Elevated")
    }

    async function asyncSetupElevateAdmin() {
        const statusData = await axios.get(`${process.env.REACT_APP_API_URL}/account/admin/level/${id}`)
        
        if(statusData.data.elevated == true) {
            setElevatedStatus("Compliance Training Partner") 
        } else {
            setElevatedStatus("Not a Compliance Training Partner") 
        }
    }

    useEffect(() => {
        asyncSetupElevateAdmin()            
    }, [])

    return (
        <div className="form-background">
            <div>
                <h2 className="h2 mb-3">CTP Status</h2>
                    {elevatedStatus == "Not a Compliance Training Partner" ? (
                        <div className='mt-3'>
                            <p>{elevatedStatus}</p>
                            <button onClick={setTrue} className="btn btn-primary mt-5">Make Compliance Training Partner</button>
                        </div>
                    ): (
                        <div className='mt-3'>
                            <p>{elevatedStatus}</p>
                            <button onClick={setFalse} className="btn btn-danger mt-5">Un-Make Compliance Training Partner</button>
                        </div> 
                    )}
            </div>
        </div>
    )
}

export function UploadEmployeePDF() {
    const { langData } = useContext(LangContext);
    const { userData } = useContext(UserContext);
    const [formPDF, setFormPDF] = useState(null);
    const [employees, setEmployees] = useState();
    const [form, setForm] = useState({});
    const navigate = useNavigate();

    const onEmployeeChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(e.target.value)
    }

    const submit = async (e) => {
        e.preventDefault();
        try {
            if (formPDF) {
                let url_string = '';
                // console.log(formPDF);
                if (formPDF) {
                    const newsData = new FormData();
                    newsData.append('file', formPDF, `${formPDF.name}`);
                    const imageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/pdf`, newsData);
                    url_string = imageData.data.downloadURL;

                    // console.log(url_string)
                }

                await axios.post(`${process.env.REACT_APP_API_URL}/calfire/post/${userData.userId}`,
                    { account_id: form.employee, img_name: formPDF.name, img_url: url_string });
                
                toast.success("Apprentice Form Sent for Approval!");
                // navigate("/")
                // navigate("/apprentice");
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    async function asyncSetupUploadEmployeePdf() {
        const adminEmailData = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`);

        const employeeData = await axios.get(`${process.env.REACT_APP_API_URL}/account/employees/${adminEmailData.data.admin_email}`);

        setEmployees(employeeData.data)
    }

    useEffect(() => {
        asyncSetupUploadEmployeePdf()
    }, [])

    return (
        <div className="form-background">
            <strong>Select Employee</strong>
            <select onChange={onEmployeeChange} className="form-select" aria-label="Default select example" name="employee">
                <option key={"default_option"} value={"default_option"}>Choose Employee</option>
                    {employees && employees.map((emp, i) => {
                        return (
                            <option key={i} value={emp.id}>{emp.first_name + " " + emp.last_name}</option>
                        );
                })}
            </select>
            <input type="file" className="form-control-file mt-5" onChange={(e) => setFormPDF(e.target.files[0])} /> 
            {formPDF ? (
                <div>
                    <button onClick={submit} className="btn btn-primary mt-3">
                        Upload Form
                    </button>  
                </div>
            ) : (null)}
        </div>
    )
}

export function SubmitOJL({ id, email, ojlName }) {
    const { langData } = useContext(LangContext);
    const { userData } = useContext(UserContext);
    const [buttonDisable, setButtonDisable] = useState(false)
    const [formPDF, setFormPDF] = useState(null);
    const [form, setForm] = useState();
    const navigate = useNavigate();

    const onHoursChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form)
    }

    const submit = async (e) => {
        e.preventDefault();
        setButtonDisable(true);
        setTimeout(() => {setButtonDisable(false)}, 3000);
        try {
            if (formPDF) {
                let url_string = '';
                // console.log(formPDF);
                if (formPDF) {
                    const newsData = new FormData();
                    newsData.append('file', formPDF, `${formPDF.name}`);
                    const imageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/pdf`, newsData);
                    url_string = imageData.data.downloadURL;

                    // console.log(url_string)
                }

                await axios.post(`${process.env.REACT_APP_API_URL}/calfire/ojl/post/${userData.userId}`,
                    { account_id: id, name: ojlName, email: email, hours: form.hours, img_name: formPDF.name, img_url: url_string });
                
                toast.success("Apprentice Form Sent for Approval!");
                // navigate("/")
                // navigate("/apprentice");
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch (err) {
            toast.error(err.message);
        }
    };
    
    return (
        <div className="form-background">
            <strong>Amount of Hours</strong>
            <div className="input-group mb-3">
                <input 
                    name="hours"
                    type="text" 
                    className="form-control" 
                    placeholder="Hours" 
                    aria-label="Hours" 
                    aria-describedby="basic-addon1" 
                    onChange={onHoursChange}
                />
            </div>
            <input type="file" className="form-control-file mt-5" onChange={(e) => setFormPDF(e.target.files[0])} /> 
            {formPDF ? (
                <div>
                    <button 
                        onClick={submit} 
                        className="btn btn-primary mt-3"
                        disabled={buttonDisable}
                    >
                        Upload Hour Proof
                    </button>  
                </div>
            ) : (null)}
        </div>
    )
}

export function AddOJL({ id, email, ojlName }) {
    const { langData } = useContext(LangContext);
    const { userData } = useContext(UserContext);
    const [buttonDisable, setButtonDisable] = useState(false)
    const [formPDF, setFormPDF] = useState(null);
    const [form, setForm] = useState();
    const navigate = useNavigate();

    const onHoursChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form)
    }

    const submit = async (e) => {
        e.preventDefault();
        setButtonDisable(true);
        setTimeout(() => {setButtonDisable(false)}, 3000);
        try {
            if (formPDF) {
                let url_string = '';
                // console.log(formPDF);
                if (formPDF) {
                    const newsData = new FormData();
                    newsData.append('file', formPDF, `${formPDF.name}`);
                    const imageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/pdf`, newsData);
                    url_string = imageData.data.downloadURL;

                    // console.log(url_string)
                }

                await axios.post(`${process.env.REACT_APP_API_URL}/calfire/ojl/post/${userData.userId}`,
                    { account_id: id, name: ojlName, email: email, hours: form.hours, img_name: formPDF.name, img_url: url_string });
                
                toast.success("Apprentice Form Sent for Approval!");
                // navigate("/")
                // navigate("/apprentice");
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <div className="form-background">
            <strong>Amount of Hours</strong>
            <div className="input-group mb-3">
                <input 
                    name="hours"
                    type="text" 
                    className="form-control" 
                    placeholder="Hours" 
                    aria-label="Hours" 
                    aria-describedby="basic-addon1" 
                    onChange={onHoursChange}
                />
            </div>
            <input type="file" className="form-control-file mt-5" onChange={(e) => setFormPDF(e.target.files[0])} /> 
            {formPDF ? (
                <div>
                    <button 
                        onClick={submit} 
                        className="btn btn-primary mt-3"
                        disabled={buttonDisable}
                    >
                        Upload Hour Proof
                    </button>  
                </div>
            ) : (null)}
        </div>
    )
}

export function SuperAddCEUHours({ id }) {
    const { langData } = useContext(LangContext);
    const { userData } = useContext(UserContext);
    const [buttonDisable, setButtonDisable] = useState(false)
    const [form, setForm] = useState();
    const navigate = useNavigate();

    const onHoursChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form)
    }

    const submit = async (e) => {
        e.preventDefault();
        setButtonDisable(true);
        setTimeout(() => {setButtonDisable(false)}, 3000);
        try {

            await axios.post(`${process.env.REACT_APP_API_URL}/calfire/ojl/post/${userData.userId}`,
                { account_id: id, hours: form.hours });
                
            toast.success("Apprentice Form Sent for Approval!");
            // navigate("/")
            // navigate("/apprentice");

        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <div className="form-background">
            <strong>Amount of Hours</strong>
            <div className="input-group mb-3">
                <input 
                    name="hours"
                    type="text" 
                    className="form-control" 
                    placeholder="Hours" 
                    aria-label="Hours" 
                    aria-describedby="basic-addon1" 
                    onChange={onHoursChange}
                />
            </div>
                <div>
                    <button 
                        onClick={submit} 
                        className="btn btn-primary mt-3"
                        disabled={buttonDisable}
                    >
                        Submit Hours
                    </button>  
                </div>
        </div>
    )
}

export function SuperManageTokens({ id, tokens, learner_id, liveRefresh=undefined }) {
    const { langData } = useContext(LangContext);
    const [tokenAmount, setTokenAmount] = useState(0)
    const { userData } = useContext(UserContext);
    const [buttonDisable, setButtonDisable] = useState(false)
    const [form, setForm] = useState();
    const navigate = useNavigate();

    const onHoursChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form)
    }

    const incrementToken = () => {
        setTokenAmount(tokenAmount + 1)
    }

    const decrementToken = () => {
        tokenAmount > 0 && setTokenAmount(tokenAmount - 1)
    }

    const submit = async (e) => {
        e.preventDefault();
        setButtonDisable(true);
        setTimeout(() => {setButtonDisable(false)}, 3000);
        try {

            await axios.post(`${process.env.REACT_APP_API_URL}/account/token/amount/${id}`, { 
                learner_id,
                tokens: tokenAmount,
            });
                
            toast.success("Token Amount Changed!");

            if (liveRefresh) await liveRefresh()
            // navigate("/")
            // navigate("/super");

        } catch (err) {
            toast.error(err.message);
        }
    };

    useEffect(() => {
        console.log("TOKENS: ", tokens)
        if (tokens) {
            setTokenAmount(tokens);
        }
    }, [tokens])

    return (
        <div className="form-background">
            <h2 className="h2 mb-4">Tokens</h2>
            {/* <div className='text-center'>
            </div> */}
            <div className="w-100 d-flex justify-content-between align-items-center mt-3 mb-4">
                <div className='d-grid'>
                    <span className='fw-bold'>Original Tokens: <span className='fw-normal'>{tokens}</span></span>
                    <span className='fw-bold'>Updated Tokens: <span className='fw-normal'>{tokenAmount}</span></span>
                </div>
                <div className="btn-group w-25 h-75">
                    <button onClick={decrementToken} className="btn btn-info mr-1">-</button>
                    <button onClick={incrementToken} className="btn btn-info">+</button>
                </div>
            </div>
            {/* <div className="d-flex mt-3">
                <div onClick={incrementToken} style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-plus" viewBox="0 0 16 16">
                        <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z"/>
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                </div>
                <div onClick={decrementToken} className="ml-3" style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={tokenAmount == 0 ? "red" : "currentColor"} className="bi bi-file-minus" viewBox="0 0 16 16">
                        <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"/>
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                </div>
            </div> */}
            <div>
                <button 
                    onClick={submit} 
                    className="btn btn-primary mt-3 w-100"
                    disabled={buttonDisable}
                >
                    Finalize Token Change
                </button>  
            </div>
        </div>
    )
}

export function SuperCreateCustomTokens({ id, tokens }) {
    const { langData } = useContext(LangContext);
    const stripe = useStripe();
    const [buttonDisable, setButtonDisable] = useState(false)
    const [customAmount, setCustomAmount] = useState(0);
    const [costPerToken, setCostPerToken] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [form, setForm] = useState();
    const navigate = useNavigate();

    const incrementToken = () => {
        setCustomAmount(customAmount + 1)
    }

    const decrementToken = () => {
        if(customAmount > 0) {
            setCustomAmount(customAmount - 1)
        } else {
            return
        }
        
    }

    const incrementPrice = () => {
        setCostPerToken(costPerToken + 1)
    }

    const decrementPrice = () => {
        if(!costPerToken <= 0) {
            setCostPerToken(costPerToken - 1)
        } else {
            return
        }
        
    }

    const onPurchaseClick = async (quantity, costPer) => {
        const sessionData = await axios.post(`${process.env.REACT_APP_API_URL}/absorb/super-custom-tokens/${id}`,{
            token_quantity: quantity,
            total_cost: costPer
        });

        await stripe.redirectToCheckout({
            sessionId: sessionData.data.sessionId
        });
    }

    async function asyncSetupSuperCreateCustomTokens() {
        const userInfo = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${id}`)

        setTotalCost(costPerToken * customAmount);
    }

    useEffect(() => {
        asyncSetupSuperCreateCustomTokens()
    }, [customAmount, costPerToken])

    return (
        <div className="form-background">
            <h2 className="h2 mb-4">Tokens</h2>
            {/* <div className='text-center'>
            </div> */}
            <div className='d-flex justify-content-around align-items-center'>
                <div className='d-flex flex-column align-items-center'>
                    <h3 className='h3 fw-normal'>${costPerToken}</h3>
                    <b>Cost Per</b>
                    <div className="btn-group h-75 mt-2">
                        <button onClick={decrementPrice} className="btn btn-info mr-1">-</button>
                        <button onClick={incrementPrice} className="btn btn-info">+</button>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <h3 className='h3 fw-normal'>{customAmount}</h3>
                    <b>Tokens</b>
                    <div className="btn-group h-75 mt-2">
                        <button onClick={decrementToken} className="btn btn-info mr-1">-</button>
                        <button onClick={incrementToken} className="btn btn-info">+</button>
                    </div>
                </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                <span className='fw-bold'>Cost Per: <span className='fw-normal'>${costPerToken}</span></span>
                <div className="btn-group w-25 h-75">
                    <button onClick={decrementPrice} className="btn btn-info mr-1">-</button>
                    <button onClick={incrementPrice} className="btn btn-info">+</button>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-5">
                <span className='fw-bold'>Tokens: <span className='fw-normal'>{customAmount}</span></span>
                <div className="btn-group w-25 h-75">
                    <button onClick={decrementToken} className="btn btn-info mr-1">-</button>
                    <button onClick={incrementToken} className="btn btn-info">+</button>
                </div>
            </div> */}
            {/* <strong>Amount of Tokens: {costPerToken}</strong>
            <div className="d-flex mt-3">
                <div onClick={incrementPrice} style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-plus" viewBox="0 0 16 16">
                        <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z"/>
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                </div>
                <div onClick={decrementPrice} className="ml-3" style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-minus" viewBox="0 0 16 16">
                        <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"/>
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                </div>
            </div>
            <strong className="mt-3">Custom Pricing: ${customAmount}</strong>
            <div className="d-flex mt-3">
                <div onClick={incrementToken} style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-plus" viewBox="0 0 16 16">
                        <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z"/>
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                </div>
                <div onClick={decrementToken} className="ml-3" style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-minus" viewBox="0 0 16 16">
                        <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"/>
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                </div>
            </div> */}
            <div className='my-5 text-center'>
                <span className="fw-bold">Total: <span className="fw-normal">${totalCost}</span></span>
            </div>
            <div>
                <button 
                    onClick={() => onPurchaseClick(costPerToken, customAmount)} 
                    className="btn btn-primary w-100"
                >
                    Finalize Token Purchase
                </button>  
            </div>
        </div>
    )
}

export default { SubmitHours, JobLearningHours, NoPrimaryData, SuperEnrollUser, SuperEditUser, ViewRequest, ElevateAdmin, UploadEmployeePDF, SubmitOJL, SuperAddCEUHours, SuperCreateCustomTokens }
