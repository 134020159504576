
export default ({
    positionX,
    positionY,
    isToggled,
    onChange,
    contextMenuRef
}) => {

    const buttons = [{
        color: 'yellow',
        text: 'Assigned, Not Complete',
        hex: '#FFFF5B',
        status: 1
    }, {
        color: 'orange',
        text: 'Assigned, Complete',
        hex: '#FCBD46',
        status: 3
    }, {
        color: 'green',
        text: 'Previous Experience',
        hex: '#49DA49',
        status: 4
    }, {
        color: 'blue',
        text: 'Previous Level',
        hex: '#6f6fe8',
        status: 5
    }]

    function emitColorChange(color, status) {
        if (typeof onChange == 'function') onChange(color, status)
    }

    return (
        <menu
            className={`rti-context-menu ${isToggled ? 'active' : 'inactive'}`}
            style={{ top: `${positionY}px`, left: `${positionX}px` }}
            ref={contextMenuRef}>
            <div>
                <b className="text-center my-2">Change Credit Type</b>
                {buttons.map(({ color, text, hex, status }) => (
                    <button
                        onClick={() => emitColorChange(hex, status)}
                        className={`rti-enrollment-button ${color}`}>
                        {text}
                    </button>
                ))}
            </div>
        </menu>
    )
}