import React, { useContext } from 'react'
import LangContext from '../context/LangContext'
import "./styles.css"

const Licenses = ({ 
    name, 
    reference_number,
    classification,
    status,
    rating,
    issued_date,
    state,
    region,
    notes,
    trainer_name,
    re_certification_date,
    employee_id,
    eye_color,
    hair_color,
    weight,
    height,
    birth_date,
    license_number,
    type,
    expiration_date, 
    credits_required, 
    renewal_frequency, 
    front_image, 
    back_image,
    credits_earned 
}) => {
    const { langData } = useContext(LangContext);

    return (
        <div className="container mb-3" style={{ overflowY: 'scroll', height: '500px' }}>
            <div className="profile-information">
                <div className="row mt-3">
                    <div className="col-sm custom">
                        {name && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.lice_and_cert}
                                </p>
                                <p className="sub-info">
                                    {name}
                                </p>
                            </div>
                        }
                        {reference_number &&
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.ref_num}
                                </p>
                                <p className="sub-info">
                                    {reference_number}
                                </p>
                            </div>  
                        }
                        {issued_date && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.issued_date}
                                </p>
                                <p className="sub-info">
                                    {issued_date}
                                </p>
                            </div>
                        }
                        {rating && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.rating}
                                </p>
                                <p className="sub-info">
                                    {rating}
                                </p>
                            </div>
                        }
                        {state && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.state}
                                </p>
                                <p className="sub-info">
                                    {state}
                                </p>
                            </div>
                        }
                        {region && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.region}
                                </p>
                                <p className="sub-info">
                                    {region}
                                </p>
                            </div>
                        }
                        {hair_color && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.hair_color}
                                </p>
                                <p className="sub-info">
                                    {hair_color}
                                </p>
                            </div>
                        }
                    </div>
                    <div className="col-sm custom">
                        {status && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.status}
                                </p>
                                <p className="sub-info">
                                    {status ? 'Active' : 'Inactive' }
                                </p>
                            </div>
                        }
                        {credits_required && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.credits_required}
                                </p>
                                <p className="sub-info">
                                    {credits_required}
                                </p>
                            </div>
                        }
                        {notes && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.notes}
                                </p>
                                <p className="sub-info">
                                    {notes}
                                </p>
                            </div>
                        }
                        {trainer_name && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.trainer_name}
                                </p>
                                <p className="sub-info">
                                    {trainer_name}
                                </p>
                            </div>
                        }
                        {re_certification_date && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                {langData.licenses.re_certification_date}
                                </p>
                                <p className="sub-info">
                                    {re_certification_date}
                                </p>
                            </div>
                        }
                         {employee_id && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.employee_id}
                                </p>
                                <p className="sub-info">
                                    {employee_id}
                                </p>
                            </div>
                        }
                        {eye_color && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.eye_color}
                                </p>
                                <p className="sub-info">
                                    {eye_color}
                                </p>
                            </div>
                        }
                    </div>
                    <div className="col-sm custom">
                        {classification && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.classification}
                                </p>
                                <p className="sub-info">
                                    {classification}
                                </p>
                            </div>
                        }
                        {renewal_frequency &&
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.renewal_frequency}
                                </p>
                                <p className="sub-info">
                                    {renewal_frequency}
                                </p>
                            </div>
                        }
                        {credits_earned &&
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.credits_earned}
                                </p>
                                <p className="sub-info">
                                    {credits_earned}
                                </p>
                            </div>
                        }
                        {type && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    Type:
                                </p>
                                <p className="sub-info">
                                    {type}
                                </p>
                            </div>
                        }
                        {expiration_date && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.expiration_date}
                                </p>
                                <p className="sub-info">
                                    {expiration_date}
                                </p>
                            </div>
                        }
                        {weight && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.weight}
                                </p>
                                <p className="sub-info">
                                    {weight}
                                </p>
                            </div>
                        }
                        {height && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.height}
                                </p>
                                <p className="sub-info">
                                    {height}
                                </p>
                            </div>
                        }
                        {birth_date && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.birth_date}
                                </p>
                                <p className="sub-info">
                                    {birth_date}
                                </p>
                            </div>
                        }
                        
                        {license_number && 
                            <div className="profile-info-field">
                                <p className="bold-header">
                                    {langData.licenses.license_number}
                                </p>
                                <p className="sub-info">
                                    {license_number}
                                </p>
                            </div>
                        }
                    </div>
                    {front_image &&
                        <div >
                            <img className="license-image" src={front_image} />
                        </div>
                    }
                    {back_image &&
                        <div >
                            <img className="license-image" src={back_image} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Licenses
