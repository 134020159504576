import React, { useContext } from 'react'
import LangContext from '../../context/LangContext'
import newsImage from '../../images/news.jpg'
import jobsImage from  '../../images/jobs.jpg'
import Popup from 'reactjs-popup'
import SignUp from '../Auth/SignUp'
import calFireCirtLogo from '../../images/cal-fire-cert-mgmt-2.png'
import './home.css'

const LearnMore = () => {
    const { langData } = useContext(LangContext)
    
    return (
        <div className='w-75 mx-auto pb-5'>
            <div className='home-cluster mt-5 d-flex align-items-center justify-content-between'>
                <div className='d-grid text-left'>
                    <b className='h1 mb-5 w-50'>
                        {langData.home.cert_management}
                    </b>
                    <p className='w-75 mb-5'>
                        {langData.home.cert_management_desc_1}
                    </p>
                    <img className='home-image mobile' src={calFireCirtLogo} />
                    <p className='w-75 mb-5'>
                        {langData.home.cert_management_desc_2}
                    </p>
                    <Popup
                        modal
                        trigger={
                        <button className='home-button'>
                            {langData.home.cert_management_button}
                        </button>}>
                        <SignUp />
                    </Popup>
                </div>
                <img className='home-image' src={calFireCirtLogo} />
            </div>
            <div className='home-cluster mt-5 d-flex align-items-center justify-content-between'>
                <img className='home-image' src={jobsImage} />
                <div className='d-grid text-left home-cluster flipped'>
                    <b className='h1 mb-5 w-50'>
                        {langData.learn_more.exclusive_jobs}
                    </b>
                    <p className='mb-5'>
                        {langData.learn_more.exclusive_jobs_desc_1}
                    </p>
                    <img className='home-image mobile' src={jobsImage} />
                    <p className='mb-5'>
                        {langData.learn_more.exclusive_jobs_desc_2}
                    </p>
                    {/* <button className='home-button'>
                        {langData.learn_more.exclusive_jobs_button}
                    </button> */}
                </div>
            </div>
            <div className='mb-5 d-flex align-items-center justify-content-between'>
                <div className='d-grid text-left home-cluster'>
                    <b className='h1 mb-5 w-50'>
                        {langData.learn_more.news_updates}
                    </b>
                    <p className='w-75 mb-5'>
                        {langData.learn_more.news_updates_desc_1}
                    </p>
                    <img className='home-image mobile' src={newsImage} />
                    <p className='w-75 mb-5'>
                        {langData.learn_more.news_updates_desc_2}
                    </p>
                    <a href='mailto:info@acteducate.com?subject=News Inquiry'>
                        <button className='home-button'>
                            {langData.learn_more.news_updates_button}
                        </button>
                    </a>
                </div>
                <img className='home-image' src={newsImage} />
            </div>
            {/* <div className="card jumbotron m-5 p-3 rounded">
                <div className="px-0">
                    <strong className="display-3">{langData.getting_started.cert_management_header}</strong>
                    <hr style={{ height: '2px', background: 'black' }} />
                    <p className="cert-manage lead my-3 p-3">{langData.getting_started.cert_management_desc}</p>
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">
                            <strong className="d-inline-block mb-2 text-primary">{langData.getting_started.ca_sprinkler_fitter}</strong>
                            <ul style={{ display: 'contents' }}>
                                <strong>{langData.getting_started.fitter_management_service}</strong>
                                <li className="cert-manage">{langData.getting_started.fitter_desc_0}</li>
                                <strong className="mt-3">{langData.getting_started.fitter_desc_1}</strong>
                                <li className="cert-manage">{langData.getting_started.fitter_desc_2}</li>
                                <strong className="mt-3">{langData.getting_started.license_and_cert}</strong>
                                <li className="cert-manage">{langData.getting_started.license_and_cert_desc}</li>
                                <strong className="mt-3">{langData.getting_started.news_and_updates}</strong>
                                <li className="cert-manage">{langData.getting_started.news_and_updates_desc}</li>
                                <strong className="mt-3">{langData.getting_started.job_opportunities}</strong>
                                <li className="cert-manage">{langData.getting_started.job_opportunities_desc}</li>
                                <strong className="mt-3">{langData.getting_started.help}</strong>
                                <li className="cert-manage">{langData.getting_started.help_desc}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">
                            <strong className="d-inline-block mb-2 text-primary">{langData.getting_started.ca_apprentice}</strong>
                            <ul style={{ display: 'contents' }}>
                                <strong>{langData.getting_started.fitter_management_service}</strong>
                                <li className="cert-manage">{langData.getting_started.fitter_desc_0}</li>
                                <strong className="mt-3">{langData.getting_started.license_and_cert}</strong>
                                <li className="cert-manage">{langData.getting_started.license_and_cert_desc_1}</li>
                                <strong className="mt-3">{langData.getting_started.news_and_updates}</strong>
                                <li className="cert-manage">{langData.getting_started.news_and_updates_desc}</li>
                                <strong className="mt-3">{langData.getting_started.job_opportunities}</strong>
                                <li className="cert-manage">{langData.getting_started.job_opportunities_desc}</li>
                                <strong className="mt-3">{langData.getting_started.help}</strong>
                                <li className="cert-manage">{langData.getting_started.help_desc}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">
                            <strong className="d-inline-block mb-2 text-primary">{langData.getting_started.ca_trainee}</strong>
                            <ul style={{ display: 'contents' }}>
                                <strong>{langData.getting_started.fitter_management_service}</strong>
                                <li className="cert-manage">{langData.getting_started.fitter_desc_0}</li>
                                <strong className="mt-3">{langData.getting_started.license_and_cert}</strong>
                                <li className="cert-manage">{langData.getting_started.license_and_cert_desc_1}</li>
                                <strong className="mt-3">{langData.getting_started.news_and_updates}</strong>
                                <li className="cert-manage">{langData.getting_started.news_and_updates_desc}</li>
                                <strong className="mt-3">{langData.getting_started.job_opportunities}</strong>
                                <li className="cert-manage">{langData.getting_started.job_opportunities_desc}</li>
                                <strong className="mt-3">{langData.getting_started.help}</strong>
                                <li className="cert-manage">{langData.getting_started.questions}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">
                            <strong className="d-inline-block mb-2 text-primary">{langData.getting_started.ca_other}</strong>
                            <p className="cert-manage lead my-3">{langData.getting_started.ca_other_desc}</p>
                            <ul style={{ display: 'contents' }}>
                                <strong className="mt-3">{langData.getting_started.license_and_cert}</strong>
                                <li className="cert-manage">{langData.getting_started.license_and_cert_desc_1}</li>
                                <strong className="mt-3">{langData.getting_started.news_and_updates}</strong>
                                <li className="cert-manage">{langData.getting_started.news_and_updates_desc}</li>
                                <strong className="mt-3">{langData.getting_started.job_opportunities}</strong>
                                <li className="cert-manage">{langData.getting_started.job_opportunities_desc}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card jumbotron m-5 p-3 rounded">
                <div className="px-0">
                    <strong className="display-3">Courses</strong>
                    <hr style={{ height: '2px', background: 'black' }} />
                    <ul style={{ listStyle: 'circle', marginLeft: "20px" }}>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.approved_provider}</li>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.course_multi_lingual}</li>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.subscriber_courses}</li>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.purchasing_courses}</li>                    
                    </ul>
                </div>
            </div>
            <div className="card jumbotron m-5 p-3 rounded">
                <div className="px-0">
                    <strong className="display-3">{langData.getting_started.news_and_updates}</strong>
                    <hr style={{ height: '2px', background: 'black' }} />
                    <ul style={{ listStyle: 'circle', marginLeft: "20px" }}>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.exclusive_news_and_updates}</li>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.contribute_to_news}</li>                    
                    </ul>
                </div>
            </div>
            <div className="card jumbotron m-5 p-3 rounded">
                <div className="px-0">
                    <strong className="display-3">{langData.getting_started.admin_controls}</strong>
                    <hr style={{ height: '2px', background: 'black' }} />
                    <ul style={{ listStyle: 'circle', marginLeft: "20px" }}>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.manage_employees}</li>
                    </ul>
                </div>
            </div>
            <div className="card jumbotron m-5 p-3 rounded">
                <div className="px-0">
                    <strong className="display-3">{langData.getting_started.safety_program}</strong>
                    <hr style={{ height: '2px', background: 'black' }} />
                    <ul style={{ listStyle: 'circle', marginLeft: "20px" }}>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.update_safety_program}</li>
                    </ul>
                </div>
            </div>
            <div className="card jumbotron m-5 p-3 rounded">
                <div className="px-0">
                    <strong className="display-3">{langData.getting_started.job_opportunity_header}</strong>
                    <hr style={{ height: '2px', background: 'black' }} />
                    <ul style={{ listStyle: 'circle', marginLeft: "20px" }}>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.exclusive_news_and_updates}</li>
                        <li className="cert-manage my-3 p-3">{langData.getting_started.interested_in_posting_jobs}</li>                    
                    </ul>
                </div>
            </div> */}
            
        </div>
    )
}

export default LearnMore