import React, { useContext, useEffect, useState } from 'react'
import LangContext from '../../context/LangContext';
import Popup from 'reactjs-popup';
import axios from 'axios';
import './fitters.css'

import { NoPrimaryData, JobLearningHours } from '../../exportcomponents/ApprenticeExports';

const CalFireLicense = ({id, loaded_ceus}) => {
    const { langData } = useContext(LangContext)
    const [hours, setHours] = useState(0)
    const [cardId, setCardId] = useState()
    const [calFireCard, setCalFireCard] = useState()
    const [calImageOne, setCalImageOne] = useState()
    const [calImageTwo, setCalImageTwo] = useState()
    const [absorbHours, setAbsorbHours] = useState(0)
    const [progressHours, setProgressHours] = useState(0.0)
    const [progressWidth, setProgressWidth] = useState(0.0)
    const [expectedHours, setExpectedHours] = useState(0.0)
    const [expectedWidth, setExpectedWidth] = useState(0.0)

    const downloadPDF = (image) => {
        window.open(image)
    }

    const currentToPercent = () => {
        const progress = hours + absorbHours

        let greenProgress = (progress / 3)

        let percent = Math.floor(greenProgress * 100)

        return percent + "%"
    }

    // Get the width of the red expected progress bar as a percentage
    // width = time elapsed since virtual start date / 3 years total + "%"
    // Hours = width * 510
    const expectedToPercent = (calFireData) => {
        if (calFireData) {
            // // Convert submittal_due to MS
            // const submittal_ms = new Date(calFireData.submittal_due);

            // // Get virtual start date by subtracting 3 years (in Milliseconds) from submittal_date
            // const virtual_start = submittal_ms - 94610000000

            // // Calculate ms elapsed since "virtual" start date
            // const elapsed_ms = Date.now() - virtual_start;

            // // Calculate width as elapsed ms divided by 3 years in ms
            // const width = elapsed_ms / 94610000000

            // // Get the expected and current hours as #.# single decimal values
            // let expected_val = (Math.round(width * 3)).toFixed(1);
            // let current_val = (hours / 10) + absorbHours

            // // Set the expected witdth to: (1 - (3-expected)/(3-current)) * 100 as a string with a percent sign
            // setExpectedWidth(String(Math.max(0, (1 - ((3 - expected_val) / (3 - current_val))) * 100)) + "%");

            // // Set expectedHours to width * 3 in #.# format
            // setExpectedHours((Math.round(width * 3)).toFixed(1))
        }
    }

    const timestampConverter = (timestamp) => {
        let a = new Date(timestamp)
        // let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        // let year = a.getFullYear();
        // let month = months[a.getMonth()];
        // let date = a.getDate();

        // let time = months.indexOf(months[a.getMonth()].toString()) + '-' + date + ' ' + year;

        const month_map = {
            "Jan": "01",
            "Feb": "02",
            "Mar": "03",
            "Apr": "04",
            "May": "05",
            "Jun": "06",
            "Jul": "07",
            "Aug": "08",
            "Sep": "09",
            "Oct": "10",
            "Nov": "11",
            "Dec": "12"
        }

        let time = a.toDateString().split(' ');
        return month_map[time[1]] + "/" + time [2] + "/" + time[3];
    }

    async function asyncSetupCalFireLicense() {
        const calFireData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${id}`)
        setCalFireCard(calFireData.data)
        setCardId(calFireData.data.card_id);
        console.log("My head hurrrrrts",calFireData.data.submittal_due)

        const calFireImageOne = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/image/card/${id}`)
        if(calFireImageOne.data[0]) {
            setCalImageOne(calFireImageOne.data[0].img_url)
            setCalImageTwo(calFireImageOne.data[1].img_url)
        }

        const current_hours = (loaded_ceus || []).reduce((total_hours, loaded_ceu) => total_hours + parseInt(loaded_ceu.hours), 0)
        const current_percent = current_hours / 30

        const expected_percent = ((94610000000 
            - (new Date(calFireData.data.submittal_due).getTime() - Date.now()))
            / 94610000000)
        const expected_hours = parseInt(30 * expected_percent)

        console.log(current_hours)
        console.log(typeof current_percent)
        console.log(expected_hours)
        console.log(typeof expected_percent)

        setProgressHours(current_hours)
        setProgressWidth(current_percent)
        setExpectedHours(expected_hours)
        setExpectedWidth(expected_percent)

        
        // const currentHours = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/current/hours/${id}`)
        // if(currentHours.data[0]) {
        //     setHours(currentHours.data[0].current_hours)
        // }

        // const courseAbsorbHours = await axios.get(`${process.env.REACT_APP_API_URL}/absorb/completed/${id}`)
        // if(courseAbsorbHours.data) {
        //     setAbsorbHours(courseAbsorbHours.data)
        // }

        // setProgressHours(((hours / 10) + courseAbsorbHours.data).toFixed(1))
        // expectedToPercent(calFireData.data)
    }

    useEffect(() => {
        asyncSetupCalFireLicense()
    }, [loaded_ceus]);

    return (
        <div>
            {!calFireCard ? (<NoPrimaryData />) : (
                <div className="licenses-card card p-3 my-5">
                    <div className="row license-buttons">
                        <h3 className="h3 mt-3 mb-5">
                            {langData.cal_card.cal_card_header}
                        </h3>
                        <div className="cal-fire-info">
                            <div className="row">
                                <div className="col-sm ml-3 text-nowrap">
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.cal_card_label}
                                        </strong>
                                        {calFireCard.card_number}
                                    </h2>
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.date_first_listed}
                                        </strong>
                                        {calFireCard.date_issued_string || timestampConverter(calFireCard.date_issued)}
                                    </h2>
                                    {(calFireCard.submittal_due_string || calFireCard.submittal_due) ? (<h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.next_submittal}
                                        </strong>
                                        {(calFireCard.submittal_due_string || '').split(' ')[0] || timestampConverter(calFireCard.submittal_due)}
                                    </h2>) : (null)}
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.completed_label}
                                        </strong>
                                        {calFireCard.ceus_completed}
                                    </h2>
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.required_label}
                                        </strong>
                                        {(3.0 - parseFloat(calFireCard.ceus_completed || '0.0')).toFixed(1)}
                                    </h2>
                                </div>
                                <div className="col-sm ml-3 text-nowrap">
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.classification}
                                        </strong>
                                        {calFireCard.classification}
                                    </h2>
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.current_sticker}
                                        </strong>
                                        {calFireCard.current_sticker || (calFireCard.sticker_year + " - " + calFireCard.sticker_number)}
                                    </h2>
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.submittal_period}
                                        </strong>
                                        {calFireCard.submittal_period_string}
                                    </h2>
                                    <h2 className="mb-4 px-0">
                                        <strong>
                                            {langData.cal_card.renewal_status}
                                        </strong>
                                        {calFireCard.renewal_status}
                                    </h2>
                                </div>
                                <h2 className="my-3 ml-3">
                                    <strong className=''>
                                        {langData.cal_card.notes}
                                    </strong>
                                    {calFireCard.notes}
                                </h2>
                            </div>
                            <hr />
                        </div> 
                        {calFireCard.classification === 'fitter' ||calFireCard.classification === 'fitter (Multi-Family)' || calFireCard.classification === 'fitter (Commercial)' ? (
                            <>
                                <h3 className="h3 mb-5">
                                    {langData.cal_card.ceu_progress}
                                </h3>   
                                <div className="text-center">
                                    <div className="progress mb-0">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: (progressWidth * 100) + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="30"></div>
                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: (expectedWidth * 100) + '%' }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="30"></div>
                                    </div>
                                    <div className="current-expected p-3">
                                        <h2 className="lead d-flex align-items-center mt-2 mr-2">
                                            <p className="mr-3">
                                                {langData.cal_card.ceus_completed}
                                            </p>
                                            {progressHours && <p style={{ background: '#198754', color: '#fff', padding: '5px', borderRadius: '10px' }}>{progressHours}</p>}
                                        </h2>
                                        <h2 className="lead d-flex align-items-center mt-2">
                                            <p className="mr-3">
                                                {langData.cal_card.ceus_expected}
                                            </p>
                                            <p style={{ background: '#dc3545', color: '#fff', padding: '5px', borderRadius: '10px' }}>{expectedHours}</p>
                                        </h2>
                                    </div>
                                    {/* <div>
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-success">CEU Course Hours Submittal</button>}
                                        >
                                            <JobLearningHours 
                                                id={id}
                                                cardId={cardId}
                                            />
                                        </Popup> 
                                    </div> */}
                                </div>
                            </> ) : (null)
                        }
                        <div className="cal-fire-images">
                            {!calImageOne ? (
                                <div className='display-6 w-100 text-center mt-2 mb-4'>
                                    <p>No License Image Uploaded</p>
                                </div>
                            ) : (
                                <>
                                {calImageOne && calImageOne.includes('.pdf') ? (
                                    <div className="image-cal w-50 card p-3 m-3">
                                        <div className='p-2'>
                                            <b>Front Image</b>
                                        </div>
                                        <button className="btn btn-primary ml-1" onClick={() => downloadPDF(calImageOne)}>
                                            {langData.cal_card.view_pdf}
                                        </button>
                                    </div>
                                    ) : (
                                    <div>
                                        <div className="image-cal card p-3 m-3">
                                            <img className="cal-fire-img" src={calImageOne} />
                                        </div>
                                    </div>
                                )}
                                {calImageTwo && calImageTwo.includes('.pdf') ? (
                                    <div className="image-cal w-50 card p-3 m-3">
                                        <div className='p-2'>
                                            <b>Back Image</b>
                                        </div>
                                        <button className="btn btn-primary ml-1" onClick={() => downloadPDF(calImageTwo)}>
                                            {langData.cal_card.view_pdf}
                                        </button>
                                    </div>
                                    
                                    ) : (
                                    <div>
                                        <div className="image-cal card p-3 m-3">
                                            <img className="cal-fire-img" src={calImageTwo} />
                                        </div>
                                    </div>
                                )}
                                </>
                            )} 
                        </div>
                    </div>
                </div>
            )} 
        </div>
    )
}

export default CalFireLicense
