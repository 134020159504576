// Imports
import axios from 'axios';
import { toast } from 'react-toastify';

// Method to send notification with optional color
export const sendNotification = async (arg_account_id, arg_message, arg_color="NONE") => {
	// Input Validation
	try {
		await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${arg_account_id}`);
	} catch (err) {
		// console.log(err);
		return toast.error("Invalid Account ID");
	}
	if (!arg_message) {
		return toast.error("Notification Message Required");
	}

	// Send the notification through the route
	try {
		await axios.post(`${process.env.REACT_APP_API_URL}/notification/`, { account_id: arg_account_id, message: arg_message, color: arg_color })
	} catch (err) {
		// console.log(err);
		return toast.error(err);
	}

	toast.success("Notification Sent");
}

export default sendNotification;