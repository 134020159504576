import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import PlaceholderImg from '../images/imageFour.jpg'
import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import ID from '../images/calfiresampletwo.jpg'

import "./apprentice.css"

const Dashboard = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [calFireImage, setCalFireImage] = useState();
    const [data, setData] = useState({
        first_name: "",
        employer: "",
        date_began: "",
        expected_completion: "",
        dol__registration: "",
    })
    const [courseList, setCourseList] = useState([{
        Name: "Loading...",
        Description: "Loading Courses..."
    }]);

    const redirectToAbsorb = (e) => {
        window.location.href = 'https://acteducate.myabsorb.com/#/courses';
    }

    const formatDescription = (description) => {
        let split_string = description.split(/[<>]/)[2]
        
        if (split_string) {
            split_string = split_string.split('&nbsp;')[0]
        } else {
            return 'Loading Courses...'
        }

        return split_string
    }

    const formatDate = (date) => {
        if (!date) return langData.apprentice.not_specified
        let dateTime = new Date(date)
        return parseInt(dateTime.getMonth() + 1) + "/" + dateTime.getDate() + "/" + dateTime.getFullYear()
    }

    const getEvaluationPeriod = (date_start, date_end) => {
        if (!(date_start && date_end)) return langData.apprentice.not_specified
        const start = formatDate(date_start)
        const end = formatDate(date_end)
        return `${start} - ${end}`
    }

    async function asyncSetupDashboard() {
        const statusData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/apprentice/info/${userData.userId}`)
        const courseData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/apprentice/courses/${userData.userId}`);
        const imageData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/image/card/${userData.userId}`);


        console.log("Balsa Weight:\n", statusData?.data);
        console.log("He was challenged by a rooster:\n", courseData?.data);
        // console.log(imageData.data[0].img_url)
        setCalFireImage((imageData?.data[0] || {}).img_url)
        setData({
            ...(statusData?.data || {}),
            ojl_required: null,
            rti_required: null,
            total_ojl_required: null,
            total_rti_required: null,
        })
        setCourseList(courseData?.data || [])
    }

    useEffect(() => {
        asyncSetupDashboard()
    }, [])

  return (
    <div className='my-5'>
        <div className='card'>
            <div className='card-body'>
                <div className='dashboard-table'>
                    <h3 className='h3 mb-0'>
                        {langData.apprentice.apprentice_info}
                    </h3>
                    <hr className='mb-4 mt-2'/>
                    <div className='dashboard-progress-row'>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.name}
                            </b>
                            {data.first_name && data.last_name 
                                ? <p className="dashboard-info">
                                    {data.first_name} {data.last_name}
                                </p> 
                                : <p className="dashboard-info">
                                    N/A
                                </p>
                            }
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.employer}
                            </b>
                            <p className="dashboard-info">
                                {data.business_name || 'N/A'}
                            </p> 
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.current_level}
                            </b>
                            <p className="dashboard-info">
                                {data.level || 'N/A'}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.status}
                            </b>
                            <p className="dashboard-info">
                                {data.apprenticeship_status == true ? 'ACTIVE' : data.status == false ? 'INACTIVE' : 'N/A'}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.cal_card_number}
                            </b>
                            <p className="dashboard-info">
                                {data.cal_card_number || 'N/A'}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.next_eval_date}
                            </b>
                            <p className="dashboard-info">
                                {formatDate(data.next_eval)}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.start_date}
                            </b>
                            <p className="dashboard-info">
                                {formatDate(data.date_began)}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.last_login_date}
                            </b>
                            <p className="dashboard-info">
                                {formatDate(data.last_login_date)}
                            </p>
                        </div>
                    </div>
                    <h3 className='h3 mb-0'>
                        {langData.apprentice.level}
                        {data.level}
                        {langData.apprentice.progress}
                    </h3>
                    <hr className='mb-4 mt-2'/>
                    <div className='dashboard-progress-row'>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.eval_period}
                            </b>
                            <p className="dashboard-info">
                                {getEvaluationPeriod(data.eval_period_start, data.eval_period_end)}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.courses_assigned}
                            </b>
                            <p className="dashboard-info">
                                {data.courses_assigned || 'N/A'}
                            </p>
                        </div>
                        <div className='dashboard-table-item'>
                            <b>
                                {langData.apprentice.courses_completed}
                            </b>
                            <p className="dashboard-info">
                                {data.courses_completed || 'N/A'}
                            </p>
                        </div>
                        <hr className='apprentice-break mx-auto my-4' />
                        <div className='dashboard-progress-row embedded'>
                            <div className='dashboard-table-item w-48'>
                                <b>
                                    {langData.apprentice.ojl_completed}
                                </b>
                                <p className="dashboard-info">
                                    {data.ojl_current || 'N/A'}
                                </p>
                            </div>
                            <div className='dashboard-table-item w-48'>
                                <b>
                                    {langData.apprentice.ojl_required}
                                </b>
                                <p className="dashboard-info">
                                    1000
                                </p>
                            </div>
                            <div className='dashboard-table-item w-48'>
                                <b>
                                    {langData.apprentice.rti_completed}
                                </b>
                                <p className="dashboard-info">
                                    {data.rti_current || 'N/A'}
                                </p>
                            </div>
                            <div className='dashboard-table-item w-48'>
                                <b>
                                    {langData.apprentice.rti_required}
                                </b>
                                <p className="dashboard-info">
                                    52.5
                                </p>
                            </div>
                        </div>
                    </div>
                    <h3 className='h3 mb-0'>
                        {langData.apprentice.overall_progress}
                    </h3>
                    <hr className='mb-4 mt-2'/>
                    <div className='dashboard-progress-row'>
                        <div className='dashboard-table-item w-48'>
                            <b>
                                {langData.apprentice.total_ojl_completed}
                            </b>
                            <p className="dashboard-info">
                                {data.ojl_overall || 'N/A'}
                            </p>
                        </div>
                        <div className='dashboard-table-item w-48'>
                            <b>
                                {langData.apprentice.total_ojl_required}
                            </b>
                            <p className="dashboard-info">
                                7000
                            </p>
                        </div>
                        <div className='dashboard-table-item w-48'>
                            <b>
                                {langData.apprentice.total_rti_completed}
                            </b>
                            <p className="dashboard-info">
                                {data.rti_overall || 'N/A'}
                            </p>
                        </div>
                        <div className='dashboard-table-item w-48'>
                            <b>
                                {langData.apprentice.total_rti_required}
                            </b>
                            <p className="dashboard-info">
                                510
                            </p>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
        {/* <div className="card m-5">
            <div className="bg-dark card-header d-flex justify-content-between align-items-center" style={{ color: "#fff" }}>
                <h1>{langData.apprentice.courses}</h1>
                <button 
                    className="btn btn-success"
                    onClick={redirectToAbsorb}>
                    Go to Courses
                </button>
            </div>
            <div className="form-group w-50">
                <select
                    className="form-select courses-filter"
                    name="language"
                    aria-label="Default select example"
                >
                    <option value="English">{langData.apprentice.all}</option>
                    <option value="English">{langData.apprentice.complete}</option>
                    <option value="English">{langData.apprentice.incomplete}</option>
                    <option value="English">{langData.apprentice.current}</option>
                </select>
            </div>
            <div className="courses-list">
                {courseList.map((course, i) => {
                    return (
                        <div className="module-name m-3">
                            <div className="card" style={{ width: '18rem' }}>
                            <img className="card-img-top" src={PlaceholderImg} alt="Card image cap" />
                                <div className="card-body">
                                    <b className="my-2">{course.Name}</b>
                                    <p className="mt-2 card-text">{course.Description}</p>
                                </div>
                            </div> 
                        </div> 
                    );
                })}
            </div>    
        </div>
        <div className="card m-5">
            <h1 className="bg-dark card-header" style={{ color: "#fff" }}>{langData.apprentice.app_dashboard}</h1>
            <div className="apprentice-program">
                <div className="current-module m-3 d-flex align-content-center">
                    {calFireImage ? (
                        <img 
                            src={calFireImage}
                            className="id-position"
                        />
                    ) : (null)}
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start">
                        <b style={{ fontSize: '26px', textAlign: 'left' }}>{langData.apprentice.student_info}</b>
                    </div>
                    <div className="student-info mx-2 d-flex justify-content-between">
                        <div className="dashboard-info-flex-wrapper">
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.student_name}</strong>
                                {data.first_name && data.last_name ? <p className="dashboard-info">{data.first_name} {data.last_name}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.employer}</strong>
                                {data.employer ? <p className="dashboard-info">{data.employer}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.dol}</strong>
                                {data.dol__registration ? <p className="dashboard-info">{data.dol__registration}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.status}</strong>
                                {data.status ? <p className="dashboard-info">ACTIVE</p> : <p className="dashboard-info">INACTIVE</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.date_began}</strong>
                                {data.date_began ? <p className="dashboard-info">{data.date_began}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.expected_completion}</strong> 
                                {data.expected_completion ? <p className="dashboard-info">{formatDate(data.expected_completion)}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                        </div>  
                    </div>
                    <div className="d-flex justify-content-start">
                        <b style={{ fontSize: '26px' }} className="my-3">{langData.apprentice.app_progress}</b>
                    </div>
                    <div className="student-info mx-2">
                        <div className="dashboard-info-flex-wrapper">
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.eval_date}</strong>
                                {data.next_eval ? <p className="dashboard-info">{data.next_eval}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.current_level}</strong>
                                {data.level ? <p className="dashboard-info">{data.level}</p> : <p className="dashboard-info">Loading...</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.current_reported_pay}</strong>
                                {data.current_level ? <p className="dashboard-info">{data.current_level}</p> : <p className="dashboard-info">N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.ojl}</strong>
                            </div>
                            <div className="d-flex ml-2 justify-content-between">
                                <label>Current Level:</label>
                                {data.rti_current ? <p className='dashboard-sub-info'>{data.ojl_overall}</p> : <p className='dashboard-sub-info'>N/A</p>}
                            </div>
                            <div className="d-flex ml-2 justify-content-between">
                                <label>Overall Progress:</label>
                                {data.rti_overall ? <p className='dashboard-sub-info'>{data.ojl_overall}</p> : <p className='dashboard-sub-info'>N/A</p>}
                            </div>
                            <div className="dashboard-info-container">
                                <strong className="dashboard-info">{langData.apprentice.training_instruction}</strong>
                            </div>
                            <div className="d-flex ml-2 justify-content-between">
                                <label>Current Level:</label>
                                {data.ojl_current ? <p className='dashboard-sub-info'>{data.ojl_overall}</p> : <p className='dashboard-sub-info'>N/A</p>}
                            </div>
                            <div className="d-flex ml-2 justify-content-between">
                                <label>Overall Progress:</label>
                                {data.ojl_overall ? <p className='dashboard-sub-info'>{data.ojl_overall}</p> : <p className='dashboard-sub-info'>N/A</p>}
                            </div>
                            {/* <div className="dashboard-info-container">
                                <strong className="dashboard-info">Course Progress</strong>
                            </div>
                            <div className="d-flex ml-2 justify-content-between">
                                <label>Courses in Current Level:</label>
                                {courseList ? <p className='dashboard-sub-info'>{courseList.length}</p> : <p className='dashboard-sub-info'>Loading Courses...</p>}
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>
        </div> */}
    </div>
  )
};

export default Dashboard;
