import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { CreateJobForm, InviteEmployees } from '../../exportcomponents/FormExports';
import { ApplicationQuestions } from '../../exportcomponents/PopupExports';
import EditJobs from '../../admincomponents/EditJobs';
import Popup from 'reactjs-popup';
import UserContext from '../../context/UserContext';
import LangContext from '../../context/LangContext';
import saveAdminJobs from '../../exportcomponents/ExcelExports';
import sendNotification from '../../exportcomponents/NotificationExports';

import './jobs.css'
import JobHistory from "../../supercomponents/JobHistory";
import { toast } from 'react-toastify';

const Jobs = () => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [jobs, setJobs] = useState([]);
    const [emptyFlag, setEmptyFlag] = useState(false);
    const [appliedJobs, setAppliedJobs] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [currentApp, setCurrentApp] = useState({});
    const [currentJob, setCurrentJob] = useState({});
    const [currentApplicants, setCurrentApplicants] = useState([]);
    const [newJobForm, setNewJobForm] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuper, setIsSuper] = useState(false);
    const [adminJobs, setAdminJobs] = useState([]);
    const [referenceId, setReferenceId] = useState();
    const [filterByCredentials, setFilterByCredentials] = useState(false);
    const [credFilters, setCredFilters] = useState([]);
    const [jobRenderRow, setJobRenderRow] = useState(null);
    const [appliedRenderRow, setAppliedRenderRow] = useState(null);
    const [activeRenderRow, setActiveRenderRow] = useState(null);
    const [adminRenderRow, setAdminRenderRow] = useState(null);
    const [jobSearch, setJobSearch] = useState("")
    const [businessName, setBusinessName] = useState('')

    let jobResults = !jobSearch
        ? jobs
        : jobs.filter(job =>
            job.title.toString().toLowerCase().includes(jobSearch.toLocaleLowerCase())
        );


    const renderAppliedRows = (i) => {
        setJobRenderRow(null);
        setActiveRenderRow(null);
        setAdminRenderRow(null);
        setAppliedRenderRow(i);
    }

    const renderActiveRows = (i) => {
        setAppliedRenderRow(null);
        setJobRenderRow(null);
        setAdminRenderRow(null);
        setActiveRenderRow(i);
    }

    const renderAdminRows = (job, i) => {
        onCurrentJobClick(job);
        setAppliedRenderRow(null);
        setActiveRenderRow(null);
        setJobRenderRow(null);
        setAdminRenderRow(i);
    }

    const renderJobRows = (i) => {
        // console.log(i);
        setAppliedRenderRow(null);
        setActiveRenderRow(null);
        setAdminRenderRow(null);
        setJobRenderRow(i);
    }

    const getJobsData = async () => {
        const jobData = await axios.get(`${process.env.REACT_APP_API_URL}/job/jobsearch?account_id=${userData.userId}`);
        console.log(jobData.data)
        return jobData.data;
    }

    const getAdminJobs = async (admin_email) => {
        const adminJobData = await axios.get(`${process.env.REACT_APP_API_URL}/job/jobs/admin/${admin_email}?account_id=${userData.userId}`);
        console.log(adminJobData.data)
        return adminJobData.data;
    }

    const getApplicationData = async () => {
        const applicationData = await axios.get(`${process.env.REACT_APP_API_URL}/job/applicants/user/${userData.user.userId}`);

        return applicationData.data;
    }

    const getActiveJobs = async () => {
        const combinedJobData = [];
        const activeJobData = await axios.get(`${process.env.REACT_APP_API_URL}/job/active/account/${userData.user.userId}`);
        for (const job of activeJobData.data) {
            const jobData = await axios.get(`${process.env.REACT_APP_API_URL}/job/jobs/${job.job_id}`);
            combinedJobData.push({ ...job, title: jobData.data.title, description: jobData.data.description, industry: jobData.data.industry });
        }

        // console.log(combinedJobData);

        return combinedJobData;
    }

    const getRefId = async () => {
        const user_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`);

        return user_data.data;
    }

    const onApply = async (job_id, answers) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/job/apply`, {
            account_id: userData.user.userId,
            job_id: job_id,
            responses: answers
        });
        const jobs = userData.user.isIndividual
            ? await getJobsData()
            : await getAdminJobs(referenceId)
        setJobs(jobs)
    }

    const onHireClick = async (account_id, job_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/job/applicants/${account_id}/${job_id}`);
            const onboardData = await axios.get(`${process.env.REACT_APP_API_URL}/job/newhire/${job_id}`);
            await axios.post(`${process.env.REACT_APP_API_URL}/job/hire/`, { job_id: job_id, account_id: account_id, start_date: onboardData.data.start_date, end_date: onboardData.data.end_date });

            getAdminJobs(referenceId)
                .then((jobs) => {
                    setJobs(jobs);

                    // if (jobs.length > 0) {
                    //     setCurrentApplicants(jobs[i].applicants);
                    // }
                });

            toast.success('Contractor Hired Successfully')
            // if (userData.user.isAdmin || userData.user.isIndividual || userData.user.isSuper) {
            //     getJobsData()
            //         .then((jobs) => {
            //             setJobs(jobs);
            //         });
            // }
        } catch (err) {
            // console.log(err);
        }
    }

    const onRejectClick = async (account_id, job_id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/job/email/applicants/${account_id}/${job_id}`);
            getAdminJobs(referenceId)
                .then((jobs) => {
                    setJobs(jobs);

                    // if (jobs.length > 0) {
                    //     setCurrentApplicants(jobs[i].applicants);
                    // }
                });

            toast.success('Application Removed Successfully')
            // sendNotification(e.target.value, `Sorry, your application for ${currentJob.title} was removed from consideration.`);

            // let updatedApplicants = currentApplicants;
            // updatedApplicants.splice(i, 1);
            // setCurrentApplicants([...updatedApplicants]);
        } catch (err) {
            // console.log(err);
        }
    }

    const onCurrentJobClick = (job) => {
        // console.log(job.applicants);
        setCurrentJob(job);
        setCurrentApplicants(job.applicants);
        setCredFilters(job.requirements.map(req => { return { name: req, included: true } }));
    }

    const removeContractor = async (e, contractor) => {
        e.preventDefault()

        try {
            // console.log(e.target.value);
            await axios.delete(`${process.env.REACT_APP_API_URL}/job/contractor/${contractor}/${e.target.value}`);

            getAdminJobs(referenceId)
                .then(jobs => {
                    setJobs(jobs)
                })

            toast.success("Contractor Removed Successfully")
        } catch (err) {
            // console.log(err);
        }
    }

    const onDelete = async (e, i) => {
        e.preventDefault();

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/job/jobs/${e.target.value}`);

            getApplicationData()
                .then((applications) => {
                    setAppliedJobs(applications);
                });
            getAdminJobs(referenceId)
                .then((jobs) => {
                    setJobs(jobs);
                    // if (!userData.user.isAdmin && !userData.user.isIndividual && !userData.user.isSuper) {
                    //     setJobs(jobs);
                    // }

                    // if (jobs.length > 0) {
                    //     setCurrentApplicants(jobs[i].applicants);
                    // }
                });

            // if (userData.user.isAdmin || userData.user.isIndividual || userData.user.isSuper) {
            //     getJobsData()
            //         .then((jobs) => {
            //             setJobs(jobs);
            //         });
            // }


        } catch (err) {
            // console.log(err);
        }
    }

    const onUnapply = async (e) => {
        e.preventDefault();


        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/job/applicants/${userData.user.userId}/${e.target.value}`);

            const jobs = userData.user.isIndividual
                ? await getJobsData()
                : await getAdminJobs(referenceId)
            setJobs(jobs)
        } catch (err) {
            // console.log(err);
        }
    }

    const applicantFilter = (applicants) => {
        let filteredApplicants = [];

        if (filterByCredentials == true) {
            for (const applicant of applicants) {
                var push = true;
                if (applicant.progress == 0) {
                    for (const requirement of currentJob.requirements) {
                        if ((credFilters[currentJob.requirements.indexOf(requirement)] || {}).included) {
                            const reqArray = applicant.credentials.map(cred => cred.name);

                            if (reqArray.indexOf(requirement) == -1) {
                                push = false;
                            }
                        }
                    }
                } else {
                    push = false;
                }
                push && filteredApplicants.push(applicant);
            }
        } else {
            for (const applicant of applicants) {
                if (applicant.progress == 0) {
                    filteredApplicants.push(applicant);
                }
            }
        }
        return filteredApplicants;
    }

    const onCredFilterChange = (e) => {
        let updatedFilters = credFilters;
        updatedFilters[e.target.value] = { ...updatedFilters[e.target.value], included: !updatedFilters[e.target.value].included };
        setCredFilters([...updatedFilters]);
    }

    useEffect(() => {
        if (!userData.user) return navigate("/");

        // This code is overly complex, and needs help

        // If an individual logs in, they should get all jobs posted by everyone
        if (userData.user.isIndividual) {
            // console.log("Look at me I'm an individual!");
            getJobsData()
                .then((jobs) => {
                    // console.log(jobs)
                    setJobs(jobs);
                });
        }
        // if an employee logs in, they should only see their admin's job listings
        else if (!userData.user.isAdmin && !userData.user.isIndividual && !userData.user.isSuper) {
            getRefId()
                .then((user) => {
                    // console.log(user.admin_email);
                    setReferenceId(user.admin_email);
                    setBusinessName(user.business_name)
                    getAdminJobs(user.admin_email)
                        .then((jobs) => {
                            // console.log(jobs);
                            setJobs(jobs);
                            if (jobs.length > 0) {
                                setCredFilters(jobs[0].requirements.map(req => { return { name: req, included: true } }));
                            }
                        }
                        );
                }
                );
        }
        // If an admin logs in, they should only see internal company job listings and their job controls.
        else if (userData.user.isAdmin) {
            getRefId()
                .then((user) => {
                    // console.log(user.admin_email);
                    setReferenceId(user.admin_email);
                    setBusinessName(user.business_name)
                    getAdminJobs(user.admin_email)
                        .then((jobs) => {
                            console.log(jobs);
                            setJobs(jobs);
                            if (jobs.length > 0) {
                                setCurrentJob(jobs[0]);
                                setCurrentApplicants(jobs[0].applicants);
                                setCredFilters(jobs[0].requirements.map(req => { return { name: req, included: true } }));
                            }
                        }
                        );
                }
                );
        }
        // If a super logs in they should see all job postings by everyone, and job controls for "SUPER" jobs
        else if (userData.user.isSuper) {
            // getJobsData()
            //     .then((jobs) => {
            //         setJobs(jobs);
            setReferenceId("SUPER");
            getAdminJobs('SUPER')
                .then((admin_jobs) => {
                    setJobs(admin_jobs);
                    if (admin_jobs.length > 0) {
                        setCurrentJob(admin_jobs[0]);
                        setCurrentApplicants(admin_jobs[0].applicants);
                        setCredFilters(admin_jobs[0].requirements.map(req => { return { name: req, included: true } }));
                    }
                });
            // });
        }

        getActiveJobs()
            .then((jobs) => {
                setActiveJobs(jobs);
            });

        getApplicationData()
            .then((applications) => {
                setAppliedJobs(applications);
                if (applications.length > 0) { setCurrentApp(applications[0]); }
            });

        setIsAdmin(userData.user.isAdmin);
        setIsSuper(userData.user.isSuper);
    }, []);

    useEffect(() => {
        if (appliedJobs.length == 0) {
            setEmptyFlag(true);
        } else if (emptyFlag == true) {
            setEmptyFlag(false);
            setCurrentApp(appliedJobs[0]);
        } else {
            setCurrentApp(appliedJobs[0]);
        }
    }, [appliedJobs]);


    const formatDate = (date) => {
        if (!date) return 'Not Specified'
        let dateTime = new Date(date)
        return parseInt(dateTime.getMonth() + 1) + "/" + dateTime.getDate() + "/" + dateTime.getFullYear()
    }

    return (
        <div className='mt-5 jobs-feed'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                    <h1 className='h1'>
                        {langData.jobs.header}
                    </h1>
                    <i className={jobRenderRow != null ? 'd-none' : 'ml-3'}>
                        {langData.jobs.select_instructions}
                    </i>
                </div>
                <div className='d-flex'>
                    <div className="all-job-search">
                        <input
                            type="text"
                            placeholder={langData.jobs.search_placeholder}
                            aria-label={langData.jobs.search_placeholder}
                            aria-describedby="basic-addon2"
                            onChange={(e) => setJobSearch(e.target.value)}
                            value={jobSearch}
                        />
                        <div>
                            <span className="input-group-text h-100" id="basic-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    {(isAdmin || isSuper) &&
                        <Popup
                            modal
                            trigger={<button className="btn btn-outline-primary ml-3">{langData.jobs.create_job}</button>}
                        >
                            <CreateJobForm admin_email={isAdmin ? referenceId : 'SUPER'} />
                        </Popup>
                    }
                </div>
            </div>
            <div>
                <div className="my-5">
                    <div>
                        <div className='mt-3'>
                            {jobs.length == 0 ? (
                                <div className="card job-row-extended p-3">
                                    <div>
                                        <p>{langData.jobs.no_jobs_available}</p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {jobResults.map((job, i) => {
                                        return (
                                            <>
                                                {i == jobRenderRow ?
                                                    <div style={{ background: job.is_hired ? 'darkSeaGreen' : 'white' }} className="card job-row expanded p-3" onClick={() => renderJobRows(i)}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex job-title align-items-center'>
                                                                <h4 className="h4 ml-5 mt-3 fw-bold">
                                                                    {job.title}
                                                                </h4>
                                                                {job.is_hired
                                                                    ? <span>
                                                                        ({langData.jobs.hired_label})</span>
                                                                    : job.is_applied && <span>
                                                                        ({langData.jobs.applied_label})
                                                                    </span>
                                                                }
                                                            </div>

                                                        </div>

                                                        <div className="d-flex flex-column px-5 mt-2" id={`row-target-${i}`}>
                                                            <div className="d-flex flex-column">
                                                                <div className="d-flex flex-column">
                                                                    <strong>{langData.jobs.description_header}</strong>
                                                                    <span className='p-2' dangerouslySetInnerHTML={{ __html: job.description?.replaceAll('&nbsp;', '<br/>') }}></span>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex jobs-flex-row mt-2'>
                                                                <div className="d-flex flex-column job-flex-3 pr-1">
                                                                    <strong>{langData.jobs.instructions_header}</strong>
                                                                    <span className='p-2' dangerouslySetInnerHTML={{ __html: job.instructions?.replaceAll('&nbsp;', '<br/>') }}></span>
                                                                </div>
                                                                <div className="d-flex flex-column job-flex-1 mt-2 mb-4">
                                                                    <div className="d-flex flex-row justify-content-between">
                                                                        <strong>
                                                                            {langData.jobs.business_label}
                                                                        </strong>
                                                                        <span>{job.alt_business_name || job.business_name}</span>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-between mt-2">
                                                                        <strong>
                                                                            {langData.jobs.start_date}
                                                                        </strong>
                                                                        <span>{formatDate(job.start_date)}</span>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-between mt-2">
                                                                        <strong>
                                                                            {langData.jobs.end_date}
                                                                        </strong>
                                                                        <span>{formatDate(job.end_date)}</span>
                                                                    </div>
                                                                    {/* <div className="job-table-item mt-2">
                                                                    <strong>
                                                                        {langData.jobs.required_certs}
                                                                    </strong>
                                                                    <p>
                                                                        {job.requirements.length > 0
                                                                            ? job.requirements.length == 1
                                                                                ? job.requirements
                                                                                : job.requirements.map((requirement, req_i) => (
                                                                                    <>
                                                                                        {req_i > 0 && ', '}{requirement}
                                                                                    </>
                                                                                ))
                                                                            : 'Not Specified'

                                                                        }
                                                                    </p>
                                                                </div> */}
                                                                    {!isAdmin && !isSuper ? (
                                                                        <>
                                                                            {job.is_applied ? (
                                                                                <button className="btn btn-outline-warning mt-3" onClick={onUnapply} value={job.id}>{langData.jobs.un_apply}</button>
                                                                            ) : !job.is_hired && (job.questions.length == 1 && job.questions[0].question == null) ? (
                                                                                <button className="btn btn-outline-primary mt-3" onClick={() => onApply(job.id, null)}>{langData.jobs.apply}</button>
                                                                            ) : !job.is_hired && (
                                                                                <Popup
                                                                                    modal
                                                                                    trigger={<button className="btn btn-outline-primary mt-3">{langData.jobs.apply}</button>}
                                                                                >
                                                                                    <ApplicationQuestions job_id={job.id} questionList={job.questions} onFinishCallback={onApply} />
                                                                                </Popup>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <div className='d-flex flex-row mt-3'>
                                                                            <Popup
                                                                                modal
                                                                                trigger={<button className={`btn btn-outline-success ${(job.business_name != businessName && (isSuper && job.business_name != 'Acteducate.com')) ? 'd-none' : 'w-100'}`}>
                                                                                    {langData.jobs.edit_header}
                                                                                </button>}
                                                                            >
                                                                                <EditJobs id={job.id} industry={job.industry} title={job.title} description={job.description} />
                                                                            </Popup>
                                                                            <button className={`btn btn-outline-danger ml-2 ${(job.business_name != businessName && (isSuper && job.business_name != 'Acteducate.com')) ? 'd-none' : 'w-100'}`} onClick={(e) => onDelete(e, i)} value={job.id}>
                                                                                {langData.jobs.delete}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {(isAdmin || (isSuper && job.business_name == 'Acteducate.com')) && (
                                                            <>
                                                                <div className="float-left mx-5">
                                                                    <tr className="d-block mt-5">
                                                                        <h4 className='h4'>{langData.jobs.current_applicants}</h4>
                                                                        {job.applicants && job.applicants.length == 0 ? (
                                                                            <div className='d-flex justify-content-center'>
                                                                                <i>
                                                                                    {langData.jobs.no_applicants_available}
                                                                                </i>
                                                                            </div>
                                                                        ) : applicantFilter(job.applicants || []).map((applicant, i) => (
                                                                            <div className="card p-2 px-3 mb-2">
                                                                                <div className="applicant-acceptance d-flex justify-content-between align-items-center">
                                                                                    <div className='w-25 text-nowrap'>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <strong>
                                                                                                {langData.jobs.name_label}
                                                                                            </strong>
                                                                                            <p>{applicant.first_name} {applicant.last_name}</p>
                                                                                        </div>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <strong>
                                                                                                {langData.jobs.employment_label}
                                                                                            </strong>
                                                                                            <p>{applicant.admin_email ? `Employee of ${businessName}` : 'Contractor'}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <Popup
                                                                                            modal
                                                                                            trigger={<button className="btn btn-outline-warning">{langData.jobs.info}</button>}
                                                                                        >
                                                                                            <div className="form-background">
                                                                                                <h2 className="h2 mb-2">{langData.jobs.applicant_info_header}</h2>
                                                                                                <div className='mb-2'>
                                                                                                    <strong className='h5'>{langData.jobs.applicant_details}</strong>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div>
                                                                                                        <strong>{langData.jobs.name}</strong>
                                                                                                    </div>
                                                                                                    <div className='mb-1'>
                                                                                                        <p>{applicant.first_name}&nbsp;{applicant.last_name}</p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <strong>{langData.jobs.phone}</strong>
                                                                                                    </div>
                                                                                                    <div className='mb-1'>
                                                                                                        <p>{applicant.phone}</p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <strong>
                                                                                                            {langData.jobs.address_label}
                                                                                                        </strong>
                                                                                                        <p>{applicant.address_1}&nbsp;{applicant.address_2}</p>
                                                                                                        <p>{applicant.city}, {applicant.state}</p>
                                                                                                        <p className='mb-1'>{applicant.zip}</p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <strong>
                                                                                                            {langData.jobs.classification}
                                                                                                        </strong>
                                                                                                        <p>{applicant.classification}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <div>
                                                                                                    <strong>{langData.jobs.credential_header}</strong>
                                                                                                </div> */}
                                                                                                {/* <div>
                                                                                                    {applicant.credentials.map((credential, i) => {
                                                                                                        return (<p>{credential.name}</p>)
                                                                                                    })}
                                                                                                </div> */}
                                                                                                <div className='mt-3 mb-2'>
                                                                                                    <strong className='h5 mb-3'>{langData.jobs.applicant_questions}</strong>
                                                                                                </div>
                                                                                                <div>
                                                                                                    {(applicant.responses || []).map((response, i) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <strong>{response.question}</strong>
                                                                                                                <p>{response.response}</p>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Popup>
                                                                                        <button className="btn btn-outline-primary ml-2" onClick={(e) => onHireClick(applicant.id, job.id)}>{langData.jobs.accept}</button>
                                                                                        <button className="btn btn-outline-danger ml-2" onClick={(e) => onRejectClick(applicant.id, job.id)}>{langData.jobs.delete}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </tr>
                                                                </div>
                                                                <div className="float-left mx-5">
                                                                    <tr className="d-block mt-5">
                                                                        <h4 className='h4'>{langData.jobs.current_contractors}</h4>
                                                                        {job.contractors && job.contractors.length == 0 ? (
                                                                            <div className='d-flex justify-content-center mb-2'>
                                                                                <i>
                                                                                    {langData.jobs.no_contractors_available}
                                                                                </i>
                                                                            </div>
                                                                        ) : job.contractors.map((contractor, i) => (
                                                                            <div key={i} className="card p-2 px-3 mb-2">
                                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                                    <div className='w-25 text-nowrap'>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <strong>
                                                                                                {langData.jobs.name_label}
                                                                                            </strong>
                                                                                            <p>{contractor.first_name} {contractor.last_name}</p>
                                                                                        </div>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <strong>
                                                                                                {langData.jobs.employment_label}
                                                                                            </strong>
                                                                                            <p>{contractor.admin_email ? `Employee of ${businessName}` : 'Contractor'}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <button className="btn btn-outline-danger" onClick={(e) => removeContractor(e, contractor.id)} value={job.id}>
                                                                                        {langData.jobs.un_hire}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </tr>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    :
                                                    <div style={{ background: job.is_hired ? 'darkSeaGreen' : 'white' }} className="card job-row p-3" onClick={() => renderJobRows(i)}>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className='d-flex job-title align-items-center'>
                                                                <h4 className="h4 ml-5">
                                                                    {job.title}
                                                                </h4>
                                                                {job.is_hired
                                                                    ? <span>
                                                                        ({langData.jobs.hired_label})
                                                                    </span>
                                                                    : job.is_applied &&
                                                                    <span>
                                                                        ({langData.jobs.applied_label})
                                                                    </span>
                                                                }
                                                            </div>
                                                            <p className='job-description-cropped mr-5'>
                                                                {job.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                {!isAdmin && !isSuper && (
                    <div className="jobs-break">
                        <div className="applied-jobs-container m-3">
                            <div>
                                <div>
                                    <div>
                                        <strong className="card p-2 mt-5">{langData.jobs.applied_header}</strong>
                                    </div>
                                </div>
                                {appliedJobs.length == 0 ? (
                                    <div className="card job-row-extended p-3">
                                        <div>
                                            <p>{langData.jobs.no_applications}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {appliedJobs.map((application, i) => {
                                            return (
                                                <div onClick={() => setCurrentApp(application)}>
                                                    {i == appliedRenderRow ?
                                                        <div className="card p-2 active-row-expanded" onClick={() => renderAppliedRows(i)}>
                                                            <div>
                                                                <strong>{langData.jobs.title_header}</strong>
                                                                <p>{application.title}</p>
                                                            </div>
                                                            <div>
                                                                <strong>{langData.jobs.industry_header}</strong>
                                                                <p>{application.industry}</p>
                                                            </div>
                                                            <div>
                                                                <strong>{langData.jobs.description_header}</strong>
                                                                <p>{application.description}</p>
                                                            </div>
                                                            <button className="btn btn-warning" onClick={onUnapply} value={currentApp.id}>{langData.jobs.un_apply}</button>
                                                        </div>
                                                        :
                                                        <div className="card p-2 active-row" onClick={() => renderAppliedRows(i)}>
                                                            <strong>{langData.jobs.title_header}</strong>
                                                            <p className="active-jobs-title">{application.title}</p>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="active-jobs m-3">
                            <div>
                                <div>
                                    <div>
                                        <strong className="card p-2 mt-5">{langData.jobs.active_jobs}</strong>
                                    </div>
                                </div>
                                {activeJobs.length == 0 ? (
                                    <div className="card job-row-extended p-3">
                                        <div>
                                            <p>{langData.jobs.no_active_jobs}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {activeJobs.map((job, i) => {
                                            return (
                                                <>
                                                    {i == activeRenderRow ?
                                                        <div className="d-flex card active-row-expanded text-left p-3" onClick={() => renderActiveRows(i)}>
                                                            <strong>{langData.jobs.title_header}</strong>
                                                            <p>
                                                                {job.title}
                                                            </p>
                                                            <strong>{langData.jobs.address_header}</strong>
                                                            <p>
                                                                {job.address_1}
                                                            </p>
                                                            <p>
                                                                {job.address_2}
                                                            </p>
                                                            <p>
                                                                {job.city}
                                                            </p>
                                                            <p>
                                                                {job.state}
                                                            </p>
                                                            <p>
                                                                {job.zip}
                                                            </p>
                                                            <strong>{langData.jobs.email}</strong>
                                                            <p>
                                                                {job.email}
                                                            </p>
                                                            <strong>{langData.jobs.contact}</strong>
                                                            <p>
                                                                {job.phone}
                                                            </p>
                                                            <strong>{langData.jobs.start_date}</strong>
                                                            <p>
                                                                {job.start_date}
                                                            </p>
                                                            <strong>{langData.jobs.end_date}</strong>
                                                            <p>
                                                                {job.end_date}
                                                            </p>
                                                            <strong>{langData.jobs.company_name}</strong>
                                                            <p>
                                                                {job.industry}
                                                            </p>
                                                            <strong>{langData.jobs.description_header}</strong>
                                                            <p>
                                                                {job.description}
                                                            </p>
                                                        </div>
                                                        :
                                                        <div className="card active-row p-2" onClick={() => renderActiveRows(i)}>
                                                            <strong>{langData.jobs.title_header}</strong>
                                                            <p className="active-jobs-title">
                                                                {job.title}
                                                            </p>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {(isAdmin || isSuper) && (
                    <>
                        <div className="mb-5">
                            <div>
                                {(adminJobs.length | jobResults.length) == 0 ? ( 
                                    <div className='text-center mt-3'>
                                        <i>
                                            Click Create Job to Post a New Job Listing
                                        </i>
                                    </div>
                                ) : adminJobs.map((job, i) => {
                                    return (
                                        <>
                                            {i == adminRenderRow ?
                                                <div className="card p-3 job-row expanded">
                                                    <div>
                                                        {/* <Popup
                                                            modal
                                                            trigger={<button className="btn btn-outline-success">{langData.jobs.invite_employees}</button>}
                                                        >
                                                            <InviteEmployees admin_email={referenceId} admin_id={userData.userId} job_id={job.id} job_title={job.title} />
                                                        </Popup>
                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <p className='job-title h4 my-auto'>{job.title}</p>
                                                            <div className="d-flex">
                                                                <Popup
                                                                    modal
                                                                    trigger={<button className="btn btn-outline-success">
                                                                        Edit
                                                                    </button>}
                                                                >
                                                                    <EditJobs id={job.id} industry={job.industry} title={job.title} description={job.description} />
                                                                </Popup>
                                                                <button className="btn btn-outline-danger ml-2" onClick={(e) => onDelete(e, i)} value={job.id}>{langData.jobs.delete}</button>
                                                            </div>
                                                        </div>
                                                        <div className='job-table-row'>
                                                            <div className="job-table-item mt-2">
                                                                <strong>
                                                                    Business:
                                                                </strong>
                                                                <p>{job.business_name}</p>
                                                            </div>
                                                            <div className="job-table-item mt-2">
                                                                <strong>
                                                                    Start Date:
                                                                </strong>
                                                                <p>{formatDate(job.start_date)}</p>
                                                            </div>
                                                            <div className="job-table-item mt-2">
                                                                <strong>
                                                                    End Date:
                                                                </strong>
                                                                <p>{formatDate(job.end_date)}</p>
                                                            </div>
                                                            <div className="job-table-item mt-2">
                                                                <strong>
                                                                    Required Certs:
                                                                </strong>
                                                                <p>
                                                                    {job.requirements.length > 0
                                                                        ? job.requirements.length == 1
                                                                            ? job.requirements
                                                                            : job.requirements.map((requirement, req_i) => (
                                                                                <>
                                                                                    {req_i > 0 && ', '}{requirement}
                                                                                </>
                                                                            ))
                                                                        : 'Not Specified'

                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="job-table-item description mt-2">
                                                                <strong>{langData.jobs.description_header}</strong>
                                                                <p>{job.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="float-left">
                                                        <tr className="d-block mt-5">
                                                            <p className="bold">{langData.jobs.current_applicants}</p>
                                                            {applicantFilter(currentApplicants).map((applicant, i) => {
                                                                return (
                                                                    <div className="applicant-acceptance card mb-1">
                                                                        <div className="d-flex justify-content-between align-items-center p-1 pl-2">
                                                                            <p>{applicant.first_name}</p>
                                                                            <div className="d-flex">
                                                                                <Popup
                                                                                    modal
                                                                                    trigger={<button className="btn btn-outline-warning py-0 px-1">{langData.jobs.info}</button>}
                                                                                >
                                                                                    <div className="form-background">
                                                                                        <div className="card p-5">
                                                                                            <div>
                                                                                                <strong>{langData.jobs.applicant_details}</strong>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <strong>{langData.jobs.name}</strong>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p>{applicant.first_name}&nbsp;{applicant.last_name}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <strong>{langData.jobs.phone}</strong>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p>{applicant.phone}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <strong>Address</strong>
                                                                                                    <p>{applicant.address_1}&nbsp;{applicant.address_2}</p>
                                                                                                    <p>{applicant.city}, {applicant.state}</p>
                                                                                                    <p>{applicant.zip}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <strong>Classification</strong>
                                                                                                    <p>{applicant.classification}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card p-5">
                                                                                            <div>
                                                                                                <strong>{langData.jobs.credential_header}</strong>
                                                                                            </div>
                                                                                            <div>
                                                                                                {applicant.credentials.map((credential, i) => {
                                                                                                    return (<p>{credential.name}</p>)
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card p-5">
                                                                                            <div>
                                                                                                <strong>{langData.jobs.applicant_questions}</strong>
                                                                                            </div>
                                                                                            <div>
                                                                                                {applicant.responses.map((response, i) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <strong>{response.question}</strong>
                                                                                                            <p>{response.response}</p>
                                                                                                        </>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Popup>
                                                                                <button className="btn btn-outline-primary py-0 px-1 ml-1" onClick={(e) => onHireClick(e, i)} value={applicant.id}>{langData.jobs.accept}</button>
                                                                                <button className="btn btn-outline-danger py-0 px-1 ml-1" onClick={(e) => onRejectClick(e, i)} value={applicant.id}>{langData.jobs.delete}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>  
                                                                )
                                                            })}
                                                        </tr>
                                                    </div>
                                                    <div className="float-left">
                                                        <tr className="d-block mt-5">
                                                            <p className="bold">{langData.jobs.current_contractors}</p>
                                                            {job.contractors && job.contractors.map((contractor, i) => {
                                                                return (
                                                                    <div key={i} className="card p-1 pl-2 mb-1">
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <p>{contractor.first_name}&nbsp;{contractor.last_name}</p>
                                                                            <button className="btn btn-outline-danger py-0 px-2" onClick={(e) => removeContractor(e, contractor.id)} value={job.id}>X</button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </tr>
                                                    </div>
                                                </div>
                                                :
                                                <div className="card p-3 job-row" onClick={() => renderAdminRows(job, i)}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="job-title cropped h4">{job.title}</p>
                                                        <p className='mb-2 job-description-cropped'>
                                                            {job.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        {/* {adminJobs.length > 0 && (
                            <div className="card current-applicants p-5 mb-5">
                                <div>
                                    <div className="">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h3 className="h3">{langData.jobs.current_applicants}</h3>
                                            <div className='d-flex'>
                                                <div>
                                                    {/* <div className="mb-2 mt-1 d-flex align-items-center justify-content-end">
                                                        <span className="mr-3">{langData.jobs.filter_by_cred}</span>
                                                        <input className="filter-check" type="checkbox" onChange={(e) => setFilterByCredentials(!filterByCredentials)} />
                                                    </div> */}
            {/* <select className="requirement-filter form-select" aria-label="Default select example" value="default" onChange={onCredFilterChange}>
                                                        <option key="default">{langData.jobs.include_exclude_filt}</option>
                                                        {credFilters.map((cred, i) => {
                                                            return (
                                                                <option key={i} value={i} style={{ color: cred.included ? 'black' : 'gray' }}>{cred.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="d-flex">
                                                    <input
                                                        // onChange={(e) => setSearch(e.target.value)}
                                                        type="search"
                                                        placeholder={langData.placeholders.search}
                                                        aria-label="Search"
                                                        aria-describedby="search-addon"
                                                        // value={search}
                                                        />
                                                    <div>
                                                        <span className="input-group-text h-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    {/* <button onClick={() => saveAdminJobs(adminJobs)} className="export-excel btn btn-outline-primary mt-3">{langData.jobs.export_excel}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card p-3 mt-5">
                                        <div>
                                            <span className="job-title h4">{currentJob.title}</span>
                                            {applicantFilter(currentApplicants).map((applicant, i) => {
                                                return (
                                                    <div className="applicant-acceptance card mt-5">
                                                        <div className="d-flex justify-content-between align-items-center p-3">
                                                            <span className="h4 mb-0">{applicant.first_name}</span>
                                                            <div className="d-flex">
                                                                <Popup
                                                                    modal
                                                                    trigger={<button className="btn btn-warning job-button">{langData.jobs.info}</button>}
                                                                >
                                                                    <div className="form-background">
                                                                        <div className="card p-5">
                                                                            <div>
                                                                                <strong>{langData.jobs.applicant_details}</strong>
                                                                            </div>
                                                                            <div>
                                                                                <div>
                                                                                    <strong>{langData.jobs.name}</strong>
                                                                                </div>
                                                                                <div>
                                                                                    <p>{applicant.first_name}&nbsp;{applicant.last_name}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <strong>{langData.jobs.phone}</strong>
                                                                                </div>
                                                                                <div>
                                                                                    <p>{applicant.phone}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <strong>Address</strong>
                                                                                    <p>{applicant.address_1}&nbsp;{applicant.address_2}</p>
                                                                                    <p>{applicant.city}, {applicant.state}</p>
                                                                                    <p>{applicant.zip}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <strong>Classification</strong>
                                                                                    <p>{applicant.classification}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card p-5">
                                                                            <div>
                                                                                <strong>{langData.jobs.credential_header}</strong>
                                                                            </div>
                                                                            <div>
                                                                                {applicant.credentials.map((credential, i) => {
                                                                                    return (<p>{credential.name}</p>)
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className="card p-5">
                                                                            <div>
                                                                                <strong>{langData.jobs.applicant_questions}</strong>
                                                                            </div>
                                                                            <div>
                                                                                {applicant.responses.map((response, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            <strong>{response.question}</strong>
                                                                                            <p>{response.response}</p>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Popup>
                                                                <button className="btn btn-primary job-button" onClick={(e) => onHireClick(e, i)} value={applicant.id}>{langData.jobs.accept}</button>
                                                                <button className="btn btn-danger job-button" onClick={(e) => onRejectClick(e, i)} value={applicant.id}>{langData.jobs.delete}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {/* <div>
                    {isAdmin &&
                        <JobHistory />
                    }
                </div>
            </div> */}
        </div >
    )
}

export default Jobs;

