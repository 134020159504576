import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import LangContext from "../../context/LangContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "./profile.css";
import axios from "axios";

import Popup from "reactjs-popup";
import EditProfile from "./EditProfile";

const ProfileCard = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { langData } = useContext(LangContext);
  const [userInfo, setUserInfo] = useState({ name: "", list: [] });
  const [customerID, setCustomerID] = useState("");
  const [certList, setCertList] = useState([
    {
      name: "",
      reference_number: "",
      renewal_frequency: "",
      credits_required: "",
    },
  ]);
  const [adminEmail, setAdminEmail] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [adminPaymentInfo, setAdminPaymentInfo] = useState([]);
  const [paymentUsers, setPaymentUsers] = useState({});
  const [editingProfile, setEditingProfile] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);
  const [isNonPaid, setIsNonPaid] = useState(true);
  const [isNonSub, setIsNonSub] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { id } = useParams();
  
  const asyncSetupUserControls = async () => {
    try {
      // Get User Data
      const userInfo = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`);
      
      // Get Payment Information
      const upcommingInvoices = await axios.get(`${process.env.REACT_APP_API_URL}/payment/upcoming-invoice/${userData.userId}/?isAdmin=${userData.user.isAdmin}&isIndividual=${userData.user.isIndividual}`);
      console.log(upcommingInvoices.data)
      formatPaymentInfo(upcommingInvoices.data.invoices, upcommingInvoices.data.individual_data);
      const classPaymentData = await axios.get(`${process.env.REACT_APP_API_URL}/payment/admin/${userInfo.data.admin_email}`);
      classPaymentData.data && setPaymentUsers(classPaymentData.data); 
    } catch (error) {
      console.error(error)
    }
  }

  const customerPortal = async (e) => {
    e.preventDefault();
    try {
        const portalSession = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-customer-portal-session`, { customer_id: customerID });
        // console.log(portalSession.data.redirectURL);
        window.location.href = portalSession.data.redirectURL;
    } catch (err) {
        // console.log(err);
    }
  }

  const loadCustomerID = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/id/${userData.userId}`);
      setCustomerID(data.customerId);
  }
  
  const formatPaymentInfo = (invoices, individual_data) => {
    const dateString = String(new Date(individual_data.next_billing_date * 1000)).slice(4, 15);
    if (userData.user.isIndividual) {
        let raw_desc = individual_data.description;
        let amount_string = raw_desc.split(" (at ")[1];
        amount_string = amount_string.substring(0, amount_string.length - 1);

        // console.log(invoices);
        // console.log(amount_string);

        // const cents = String(invoices[0].plan.amount).slice(-2);
        // const dollars = String(invoices[0].plan.amount).slice(0, -2);
        // const dueString = `$${dollars}.${cents}`;


        setPaymentInfo({ plan: individual_data.plan, amount: amount_string, next_date: dateString, last4: individual_data.last4, brand: individual_data.brand });
      } else if (userData.user.isAdmin) {
        
        setPaymentInfo({ plan: individual_data.plan, amount: individual_data.amount, next_date: dateString, last4: individual_data.last4, brand: individual_data.brand });
        // console.log(invoices);
        // let charges = [];
        // const invoice = invoices.filter(inv => inv.plan)[0]
        // // for (const invoice of invoices) {
        //   const dateString = String(Date(invoice.current_period_end)).slice(4, 15);
          
        //   let plan = invoice.items.data[0].price.nickname ? invoice.items.data[0].price.nickname : "Admin Plan";
        //   let checkUnique = charges.filter((charge) => charge.plan == plan);

        //   console.log('invoice', invoice)

        //   if (checkUnique.length == 0) {
        //       const cents = String(invoice.plan.amount).slice(-2);
        //       const dollars = String(invoice.plan.amount).slice(0, -2);
        //       const dueString = `$${dollars}.${cents}`;

        //       // console.log(dueString);

        //       charges.push({
        //           plan: plan,
        //           amount: dueString,
        //           next_date: dateString
        //       });
        //   } else {
        //       let i = charges.map((charge) => { return charge.plan }).indexOf(plan);
        //       // console.log(i);
        //       // console.log(invoice.plan.amount, parseInt(charges[i].amount.slice(1, -3) * 100));
        //       const cents = String(invoice.plan.amount + parseInt(charges[i].amount.slice(-2) * 100)).slice(-2);
        //       const dollars = String(invoice.plan.amount + parseInt(charges[i].amount.slice(1, -3) * 100)).slice(0, -2);
        //       const dueString = `$${dollars}.${cents}`;
              
        //       // console.log(dueString);
              
        //       charges[i] = {...charges[i], amount: dueString};
        //   }
        // }

        // setAdminPaymentInfo(charges);
    }
  }

  useEffect(() => {
    console.log("the project to ", userData.user.isAdmin)
    console.log("shame and humiliate ", userData.user.isIndividual)
    setIsAdmin(userData.user.isAdmin)
    setIsNonSub(userData.user.isNonSub)
    setIsNonPaid(!(userData.user.isIndividual || userData.user.isAdmin))
    if (!userData.user.isNonSub) asyncSetupUserControls();
    // if it's an admin, nonsub  or super set to true
    // console.log("This fucking kid at waffle house is eying me hard lol\n", userData.user)
    setEnableEditing(userData.user.isAdmin || userData.user.isSuper || userData.user.isNonSub)
    loadCustomerID();
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/account/user/${id}`,
          {
            headers: { "x-auth-token": localStorage.getItem("auth-token") },
          }
        );

        setUserInfo((info) => ({ ...userInfo, list: data }));
        

        const accountInfo = await axios.get(
          `${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`
        );
        // console.log(accountInfo)
        setAdminEmail(accountInfo.data.admin_email);

        // console.log(accountInfo.data.admin_email)

        if (userData.user.isIndividual == true) {
          const cert_data = await axios.get(
            `${process.env.REACT_APP_API_URL}/certification/holder/${userData.user.userId}`,
            {
              headers: { "x-auth-token": localStorage.getItem("auth-token") },
            }
          );
          setCertList(cert_data.data);
          // console.log(certList)
        }

        if (userData.user.isAdmin == true) {
          const cert_data = await axios.get(
            `${process.env.REACT_APP_API_URL}/certification/holder/${userData.user.userId}`,
            {
              headers: { "x-auth-token": localStorage.getItem("auth-token") },
            }
          );
          setCertList(cert_data.data);
        }
      } catch (err) {
        toast.error(err);
      }
    })();
  }, []);

  const EmailDisplay = () => (
    <div>
      <b>
        Email
      </b>
      <p>
        {userInfo.list.email}
      </p>
    </div>
  )

  const NameDisplay = () => (
    <div>
      <b>
        {langData.profile.name}
      </b>
      <p>
        {userInfo.list.first_name} {userInfo.list.last_name}
      </p>
    </div>
  )

  const PhoneDisplay = () => (
    <div>
      <b>
        {langData.profile.phone}
      </b>
      <p>
      {userInfo.list.phone}
      </p>
    </div>
  )

  return (
    <div className="profile mt-5">
      <h1 className="h1">
        {langData.profile.header}
      </h1>
      {/* Account Info */}
      <table className="profile-table">
        <tbody>
          {editingProfile
            ? (
              <EditProfile user={userInfo.list} cancelCallback={() => setEditingProfile(false)}/>
            ) : (
            <>
              <tr className="profile-inline-row">
                <td className="table-item">
                  <div className="d-grid">
                    <b>
                      {langData.profile.id}
                    </b>
                    <p>
                      {userInfo.list.shorthand || userInfo.list.id}
                    </p>
                  </div>
                </td>
                <td className="table-item desktop-only">
                  <EmailDisplay />
                </td>
                <td className="table-item">
                  <div className="d-grid">
                    <b>
                      {langData.profile.account_type}
                    </b>
                    <p>
                      {userInfo.list.admin_email ? 'Employee' : 'Individual'} {userInfo.list.classification}
                    </p>
                  </div>
                </td>
              </tr>
              <tr className='profile-inline-row'>
                <td className="table-item mobile-only">
                  <EmailDisplay />
                </td>
                <td className="table-item desktop-only">
                  <NameDisplay />
                </td>
                <td className="table-item desktop-only">
                  <PhoneDisplay />
                </td>
                <td className="table-item">
                  <div className="d-grid">
                    <b>
                      {langData.profile.joined}
                    </b>
                    <p>
                      {userInfo.list.date_joined}
                    </p>
                  </div>
                </td>
              </tr>
              <tr className="profile-mobile-row">
                <td className="table-item">
                  <NameDisplay />
                </td>
                <td className="table-item">
                  <PhoneDisplay />
                </td>
              </tr>
              <tr className="profile-inline-row">
                <td className="table-item">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.address}
                    </b>
                    <p>
                      {userInfo.list.address_1}{userInfo.list.address_2 && ', ' + userInfo.list.address_2}
                    </p>
                    <p>
                      {userInfo.list.city}, {userInfo.list.state} {userInfo.list.zip}
                    </p>
                  </div>
                </td>
                <td className="table-item">
                  <div>
                    <b>
                      {langData.profile.language}
                    </b>
                    <p>
                      {userInfo.list.language}
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="table-item" colSpan="3">
                  <button
                    onClick={() => setEditingProfile(true)}
                    className="profile-button mb-5"
                    disabled={!enableEditing}>
                    {langData.profile.edit_profile}
                  </button>
                  {!enableEditing && <i>{langData.profile.editing_disabled}</i>}
                  {/* {Object.keys(userInfo.list).map(key => <p>{key}</p>)} */}
                </td>
              </tr>
            </>
            )
          }
          <tr className="payment-header-row">
            <td className="table-item" colSpan="3">
              <h1 className="h1">
                {langData.profile.payment_details}
              </h1>
            </td>
          </tr>
            {isNonPaid
            ? (
              <tr className='profile-inline-row'>
                <td colSpan='3'>
                  <i>
                    {langData.profile.no_payment_info_saved}
                  </i>
                </td>
              </tr>
            ) : isNonSub
            ? (
              <tr>
                <td className="table-item" colSpan="3">
                  {/* <div className="d-grid"> */}
                    <i className="mb-5">No subscription info on file</i>
                    <br/>
                    <br/>
                    <button className="profile-button mt-5" onClick={customerPortal}>
                      {langData.profile.stripe_button}
                    </button>
                  {/* </div> */}
                  {/* <button className="profile-button danger">Deactivate Subscription</button> */}
                </td>
              </tr>
            ) : !paymentInfo.plan ? (
              <i>
                {langData.profile.loading_payment_info}
              </i>
            ) : (
              <>
                <tr className='profile-inline-row'>
                  <td className="table-item">
                    <div>
                      <b>
                        {langData.profile.plan}
                      </b>
                      <p>
                        {paymentInfo.plan}
                      </p>
                    </div>
                  </td>
                  <td className="table-item">
                    <div>
                      <b>
                        {langData.profile.next_billing_date}
                      </b>
                      <p>
                        {paymentInfo.next_date}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className='profile-inline-row'>
                  <td className="table-item">
                    <div>
                      <b>
                        {isAdmin ? langData.profile.annual_cost : langData.profile.monthly_cost}
                      </b>
                      <p>
                        {paymentInfo.amount}
                      </p>
                    </div>
                  </td>
                  <td className="table-item">
                    <div>
                      <b>
                        {langData.profile.payment_method}
                      </b>
                      <p>
                        {paymentInfo.brand} 
                        {paymentInfo.brand != 'Link' && langData.profile.ending_in}
                        {paymentInfo.last4}
                      </p>
                    </div>
                  </td>
                </tr> 
                <tr>
                  <td className="table-item" colSpan="3">
                    <button className="profile-button" onClick={customerPortal}>
                      {langData.profile.stripe_button}
                    </button>
                    {/* <button className="profile-button danger">Deactivate Subscription</button> */}
                  </td>
                </tr>
                <tr>
                  <td className="table-item" colSpan="3">
                    <i>
                      {langData.profile.deactivate_instructions}
                    </i>
                  </td>
                </tr>
              </>
            )
          }
        </tbody>
      </table>



      {/* <div className="container mb-3 mt-5">
        <div className="profile-information">
          <h2
            className="card-header header-color display-5 mb-3"
            style={{ color: "white" }}
          >
            {langData.profile.header}
          </h2>
          <div className="row mt-2">
            <div className="col-sm custom">
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.id}</p>
                {userInfo.list.shorthand ? (
                  <p className="sub-info">{userInfo.list.shorthand}</p>
                ) : (
                  <p className="sub-info">{userInfo.list.id}</p>
                )}
                <p className="bold-header">{langData.profile.name}</p>
                <p className="sub-info">
                  {userInfo.list.first_name} {userInfo.list.last_name}
                </p>
              </div>
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.address}</p>
                <p className="sub-info">
                  {userInfo.list.address_1 + " " + userInfo.list.address_2}
                </p>
                <p className="sub-info">
                  {userInfo.list.city +
                    ", " +
                    userInfo.list.state +
                    " " +
                    userInfo.list.zip}
                </p>

                {userInfo.list.classification && (
                  <div>
                    <p className="bold-header">{langData.profile.level}</p>
                    <p className="sub-info">{userInfo.list.classification}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm custom">
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.status}</p>
                <p className="sub-info">
                  {userInfo.list.status ? "ACTIVE" : "PENDING"}
                </p>
              </div>
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.status}</p>
                <p className="sub-info">{userInfo.list.phone}</p>
              </div>
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.email}</p>
                <p className="sub-info">{userInfo.list.email}</p>
              </div>
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.joined}</p>
                <p className="sub-info">{userInfo.list.date_joined}</p>
              </div>
              <div className="profile-info-field">
                <p className="bold-header">{langData.profile.language}</p>
                <p className="sub-info">{userInfo.list.language}</p>
              </div>
            </div>
          </div>
          <Popup
            modal
            trigger={
              <button className="btn btn-warning w-100 edit-profile">
                {langData.profile.edit_profile}
              </button>
            }
          >
            <EditProfile
              firstName={userInfo.list.first_name}
              lastName={userInfo.list.last_name}
              address_1={userInfo.list.address_1}
              address_2={userInfo.list.address_2}
              city={userInfo.list.city}
              state={userInfo.list.state}
              zip={userInfo.list.zip}
              language={userInfo.list.language}
              phone={userInfo.list.phone}
              email={userInfo.list.email}
            />
          </Popup>
        </div>
      </div> */}
    </div>
  );
};

export default ProfileCard;
