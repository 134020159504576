import React, { useState, useContext, useEffect } from 'react'
import UserContext from "../../context/UserContext";
import LangContext from '../../context/LangContext';
import { useNavigate } from "react-router-dom"
import "./profile.css"

import ProfileCard from "./ProfileCard"

const Profile = () => {
    const { userData, setUserData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const navigate = useNavigate();

    const logout = () => {
        setUserData({
            token: undefined,
            user: undefined,
        });

        localStorage.setItem("auth-token", "");
        navigate("/")
    }

    useEffect(() => {
        if (!userData.user) navigate("/");
    }, [userData.user]);

    return (
        <div>
            <ProfileCard />
        </div>
    )
}

export default Profile
