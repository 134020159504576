import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './admin.css'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import { Validator } from '../exportcomponents/ValidatorExports';


const EditJobs = ({ admin_email, applicants, business, description, end_date, id, industry, requirements, start_date, title }) => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [inviteForm, setInviteForm] = useState({});
    const [newHireForm, setNewHireForm] = useState({});
    const [certList, setCertList] = useState([]);
    const [requiredList, setRequiredList] = useState([]);
    const [customInvite, setCustomInvite] = useState(false);
    const [newHireInit, setNewHireInit] = useState({});
    const [inviteInit, setInviteInit] = useState({});

    const handleStartDate = (value, e) => {
        setNewHireForm({ ...newHireForm, start_date: value });
        const dateFormat = parseInt(value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
        setStartDate(dateFormat);
    }

    const handleEndDate = (value, e) => {
        setNewHireForm({ ...newHireForm, end_date: value });
        setEndDate(value);
    }

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const onNewHireChange = (e) => {
        setNewHireForm({ ...newHireForm, [e.target.name]: e.target.value });
    }

    const onInviteChange = (e) => {
        setInviteForm({ ...inviteForm, [e.target.name]: e.target.value });
    }

    const onCertChange = (e) => {
        let newCerts = certList;
        // // console.log(requiredList);
        // Move the data
        setRequiredList([...requiredList, certList[e.target.value]]);
        // Delete from dropdown list
        newCerts.splice(e.target.value, 1);
        newCerts.sort();
        setCertList(newCerts);
    }

    const onRequiredDelete = (e) => {
        e.preventDefault();
        let newRequired = requiredList;

        setCertList([...certList, requiredList[e.target.value]]);
        newRequired.splice(e.target.value, 1);
        newRequired.sort();
        setRequiredList(newRequired);
    }

    const onListingSubmit = async (e) => {
        e.preventDefault();
        // // console.log("listing");
        // // console.log(form);
        const valid = Validator(form, ['industry']);
        // // console.log(valid);

        await axios.put(`${process.env.REACT_APP_API_URL}/job/jobs/${id}`, form);
        toast.success("Job Edited Successfully")
        navigate('/')
        navigate('/jobs')
    }

    const onNewHireSubmit = async (e) => {
        e.preventDefault();
        // // console.log("New Hire");

        // // console.log(newHireForm);
        await axios.put(`${process.env.REACT_APP_API_URL}/job/newhire/${id}`, newHireForm);
        toast.success("Job Edited Successfully")
        navigate('/')
        navigate('/jobs')
    }

    const onInviteSubmit = async (e) => {
        e.preventDefault();
        await axios.put(`${process.env.REACT_APP_API_URL}/invite/${inviteInit.id}`, { sender_id: userData.userId, event_id: id, link: inviteForm.link, message: inviteForm.message });
        toast.success("Invite Edited Successfully")
        navigate('/')
        navigate('/jobs')
    }

    const onCertSubmit = async (e) => {
        e.preventDefault();
        // // console.log("Certification");

        // // console.log(requiredList);
        // // console.log(id);
        await axios.put(`${process.env.REACT_APP_API_URL}/job/requirements/${id}`, { certifications: requiredList });
        toast.success("Certifications Edited Successfully")
        navigate('/')
        navigate('/jobs')
    }

    useEffect(() => {
        if (!userData) navigate('/');
        // // console.log(userData);
            (async () => {

                await axios.get(`${process.env.REACT_APP_API_URL}/certification/`)
                    .then((certs) => {
                        let newCerts = certs.data;
                        newCerts.sort();
                        setCertList(newCerts);
                    });
                await axios.get(`${process.env.REACT_APP_API_URL}/job/newhire/${id}`)
                    .then(({ data }) => {
                        // // console.log(data);
                        setNewHireInit(data);
                        setStartDate(new Date(data.start_date));
                        setEndDate(new Date(data.end_date));
                    });
                await axios.get(`${process.env.REACT_APP_API_URL}/invite/custom/${userData.userId}/${id}`)
                    .then(({ data }) => {
                        // // console.log(data);
                        setInviteInit(data);
                    });
                await axios.get(`${process.env.REACT_APP_API_URL}/job/requirements/${id}`)
                    .then(({ data }) => {
                        // // console.log(data);
                        setRequiredList(data);
                    })
            })()
    }, []);

    return (
        <div>
            <form className="form-background popup-scrollable">
            <h2 className="h2 mb-2">{langData.edit_jobs.edit_listing}</h2>
            <strong></strong>
                <div>
                    {/* <div className="form-group mb-5">
                        <label>{langData.edit_jobs.industry}</label>
                        <input
                            className="form-control"
                            onChange={onChange}
                            name="industry"
                            placeholder={`${industry}`}
                        /> 
                    </div>*/}
                    <div>
                        <label>{langData.edit_jobs.title}</label>
                        <input
                            className="form-control mb-5"
                            onChange={onChange}
                            name="title"
                            placeholder={`${title}`}
                        />
                    </div>
                    <div>
                        <label>{langData.edit_jobs.description}</label>
                        <input
                            className="form-control mb-5"
                            onChange={onChange}
                            name="description"
                            placeholder={`${description}`}
                        />
                    </div>
                    <button className="mb-5 btn btn-outline-primary" onClick={(e) => onListingSubmit(e)}>{langData.edit_jobs.edit_listing}</button>
                </div>
                <div>
                    <strong className="mb-2 w-100">{langData.edit_jobs.company_header}</strong>
                    <div>
                        <label>{langData.edit_jobs.address}</label>
                        <input
                            className="form-control mb-5"
                            onChange={onNewHireChange}
                            name="address_1"
                            placeholder={`${newHireInit.address_1}`}
                        />
                    </div><div>
                        <label>{langData.edit_jobs.email}</label>
                        <input
                            className="form-control mb-5"
                            onChange={onNewHireChange}
                            name="email"
                            placeholder={`${newHireInit.email}`}
                        />
                    </div>
                    <div>
                        <label>{langData.edit_jobs.phone}</label>
                        <input
                            className="form-control mb-5"
                            onChange={onNewHireChange}
                            name="phone"
                            placeholder={`${newHireInit.phone}`}
                        />
                    </div>
                    <div>
                        <label>{langData.edit_jobs.instructions}</label>
                        <input
                            className="form-control mb-5"
                            onChange={onNewHireChange}
                            name="instructions"
                            placeholder={`${newHireInit.instructions}`}
                        />
                    </div>
                    <div className="start-date">
                        <label>{langData.edit_jobs.start_date}</label>
                        <DatePicker
                            name="start_date"
                            className="form-control mb-5"
                            defaultValue={startDate}
                            onChange={(value, e) => handleStartDate(value, e)}
                            closeCalendar={true} 
                        />
                    </div>
                    <div className="end-date">
                        <label>{langData.edit_jobs.end_date}</label>
                        <DatePicker
                            name="end_date"
                            className="form-control mb-5"
                            defaultValue={endDate}
                            onChange={(value, e) => handleEndDate(value, e)} />
                    </div>
                    <button className="mb-5 btn btn-outline-primary" onClick={(e) => onNewHireSubmit(e)}>{langData.edit_jobs.edit_listing}</button>
                </div>
                <form>
                    {/* <strong className="mb-2">{langData.edit_jobs.required_certs}</strong>
                    {requiredList.map((cert, i) => {
                        return (
                            <>
                                <p className="m-2">
                                    {cert.name}
                                </p>
                                <button className="d-flex btn btn-danger" onClick={onRequiredDelete} value={i}>X</button>
                            </>
                        );
                    })} */}
                    {/* <select className="form-select mt-2" aria-label="Default select example" onChange={onCertChange} value={"default_option"}>
                        <option key={"default_option"} value={"default_option"}>{langData.edit_jobs.choose_certs}</option>
                        {certList.map((cert, i) => {
                            return (
                                <option key={i} value={i}>{cert.name}</option>
                            );
                        })}
                    </select>
                    <button className="mb-5 btn btn-outline-primary mt-2" onClick={(e) => onCertSubmit(e)}>Edit Certifications</button> */}
                </form>
                <div className="mt-2">
					<strong className="mb-2">Custom Options</strong>
                    <div className="d-flex mb-2">
                        <input className="mt-1 mr-3" type="checkbox" onChange={() => setCustomInvite(!customInvite)} />
                        <p style={{ fontSize: '12px', paddingTop: '2px', marginLeft: '-7px' }}>Customize Applicants Inviting Instructions</p>
                    </div>
                    {customInvite &&
                        <div>
                            <div>
                                <label>Link to Apply / Interview</label>
                                <input
                                    className="form-control mb-5"
                                    onChange={onInviteChange}
                                    name="link"
                                    placeholder={inviteInit.link}
                                />
                            </div>
                            <div>
                                <label>Instructions for Applicant</label>
                                <input
                                    className="form-control mb-5"
                                    onChange={onInviteChange}
                                    name="message"
                                    placeholder={inviteInit.message}
                                />
                            </div>
                            <button className="mb-5 btn btn-outline-primary" onClick={(e) => onInviteSubmit(e)}>Edit Invite</button>
                        </div>
                    }
                </div>
            </form>
        </div >
    );
}

export default EditJobs
