import React, { useContext, useState, useEffect, useCallback } from 'react'
import axios from "axios";
import { Validator } from '../exportcomponents/ValidatorExports';
import { toast } from 'react-toastify';

import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';

const ResetAdminPassword = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({});

    const submit = async (e) => {
        e.preventDefault()

        try {
            const valid = Validator(form, ['password']);
            if (valid.length == 0) {
                await axios.put(`${process.env.REACT_APP_API_URL}/account/admin/reset/${userData.userId}`,
                {
                    password: form.password,
                })
                toast.success(langData.toast.employee_pass_changed);
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch(err) {
            // console.log(err)
            toast.error(langData.toast.invalid_credentials);
        }
    }

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form);
    };

    useEffect(() => {
        (async () => {
            const userEmail = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`)
            // console.log(userEmail.data.status)

        })()
    }, []);

    return (
        <div>
            <form className="form-background" onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="password">{langData.reset_password.new_pass_header}</label>
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.reset_password.new_pass_placeholder}
                    />
                </div>
                <div>
                    <button type="submit" className="btn btn-primary submit mt-2">
                        {langData.reset_password.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ResetAdminPassword
