import { useState, useEffect } from 'react'
import EventsApi from './api'
import { toast } from 'react-toastify'
import axios from 'axios'

const EventSuperEnroller = ({ event, onSuccess }) => {
    const eventsApi = new EventsApi()
    const [ctpList, setCtpList] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [individualList, setIndividualList] = useState([])
    const [selected, setSelected] = useState(null)
    const [isIndividual, setIsIndividual] = useState(null)

    function ctpChange(e) {
        const ctp_id = e.target.value
        const employees = ctpList.find(({id}) => id == ctp_id)?.employees || []
        setEmployeeList(employees)
    }

    function selectedChange(e) {
        setIsIndividual(e.target.name == 'individual')
        setSelected(e.target.value)
    }

    async function submit() {
        try {
            await eventsApi.addSuperEnroller(selected, event.id, isIndividual)
            await onSuccess()
            toast.success('Attendee Added Successfully')
        } catch (err) {
            console.log("error?", err?.response?.status)
            console.error(err)
            if (err?.response?.status == '402') return toast.error("Failed to process payment.")
            toast.error("Something went wrong, please try again later...")
        }
    }

    async function asyncSetupEventSuperEnroller() {
        console.log("setting up enroller?")
        const { data: { ctp_list, individual_list } } = await eventsApi.getSuperEnroller(event.id)
        console.log("individual_list:", individual_list)
        console.log("resp:", ctp_list)
        setCtpList(ctp_list)
        setIndividualList(individual_list)
    }

    useEffect(() => { asyncSetupEventSuperEnroller() }, [])

    return (
        <div className="form-background popup-scrollable wide-popup">
            <p className="text-sm mb-1">Enroll Event Attendees</p>
            <h2 className="h2 mb-3">{event.title}</h2>

            <b className=''>Select Employee</b>

            <select className='form-control mb-5 mt-3' onChange={ctpChange}>
                <option key="ctp-select-option">Select CTP</option>
                {ctpList.map((option) => (
                    <option key={option.id} value={option.id}>{option.business_name} - {option.admin_email}</option>
                ))}
            </select>

            <select className='form-control mb-3' name='employee' onChange={selectedChange}>
                <option>Select Employee</option>
                {employeeList.map((option) => (
                    <option disabled={option.is_enrolled} key={option.id}  value={option.id}>{option.name} - {option.classification}</option>
                ))}
            </select>

            <div className='d-flex flex-row justify-content-between align-items-center'>
                <hr />
                <p className='mx-2 pb-1'>OR</p>
                <hr />
            </div>


            <b className=''>Select Individual</b>

            <select className='form-control my-3' name='individual' onChange={selectedChange}>
                <option>Select Individual</option>
                {individualList.map((option) => (
                    <option disabled={option.is_enrolled} key={option.id}  value={option.id}>{option.name} - {option.classification}</option>
                ))}
            </select>

            {/* <div className='d-flex flex-row justify-content-start'>
                <input className='mr-2 h-min w-min' type="checkbox" />
                <label className=''>Charge CTP Card on File</label>
            </div> */}

            {/* Button Group */}
            <div className="d-flex flex-column align-items-center gap-3 mt-5 mb-2">
                <button disabled={!selected} onClick={submit} className="btn btn-primary w-100">Add Attendee</button>
            </div>

            <p className='text-center mt-3'>
                <i>Enrolling will automatically charge the Individual or CTP using their card on file.</i>
            </p>
        </div>
    )
}

export default EventSuperEnroller