import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js'
import UserContext from '../context/UserContext';
import './styles.css';

const AbsorbPurchaseTokens = ({ logged_in }) => {
    const stripe = useStripe();
    const { userData } = useContext(UserContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const [nonSub, setNonSub] = useState(false);
    const [customAmount, setCustomAmount] = useState(0);
    const [totalCost, setTotalCost] = useState(13);
    const [perTokenCost, setPerTokenCost] = useState(25);
    const [selectedQuantity, setSelectedQuantity] = useState(4);
    const [decreaseStyle, setDecreaseStyle] = useState('currentColor');

    const incrementToken = () => {
        if(!(totalCost < 13)) {
            setTotalCost(totalCost + 1)
            setCustomAmount(customAmount + perTokenCost)
            setSelectedQuantity(totalCost + 1);
        } else {
            setTotalCost(13);
            setSelectedQuantity(13);
            setCustomAmount(13 * perTokenCost)
        }
    }

    const decrementToken = () => {
        if(!(totalCost <= 13)) {
            setTotalCost(totalCost - 1)
            setCustomAmount(customAmount - perTokenCost)
            setSelectedQuantity(totalCost - 1)
        } else {
            setDecreaseStyle('red');
            setTimeout(() => setDecreaseStyle('currentColor'), 500);

            // setTotalCost(13);
            // setSelectedQuantity(13);
            // setCustomAmount(13 * perTokenCost)
        }
        
    }

    const onSelectClick = (quantity) => {
        setSelectedQuantity(quantity);
        setTotalCost(quantity);
        setCustomAmount(quantity * perTokenCost)
    }

    const onPurchaseClick = async () => {
        // console.log(selectedQuantity);
        
        const sessionData = await axios.post(`${process.env.REACT_APP_API_URL}/absorb/purchase-tokens`,{
            account_id: userData.userId,
            token_quantity: selectedQuantity,
            token_cost: perTokenCost * 100
        });

        // console.log(sessionData.data.sessionId);

        await stripe.redirectToCheckout({
            sessionId: sessionData.data.sessionId
        });
    }

    useEffect(() => {
        setLoggedIn(logged_in);

        if (userData.user !== undefined) {
            setNonSub(!userData.user.isNonSub);
            setCustomAmount(4 * (userData.user.isNonSub ? 25 : 13))
            setPerTokenCost(userData.user.isNonSub ? 25 : 13)
        }

    }, []);

    return (
        <div className="card absorb purchase-tokens p-5">
            <strong className="absorb purchase-header">Tokens</strong>
            {/* <i>{nonSub ? '$13.00' : '$25.00'} per Token</i> */}
            <div className='d-flex flex-column align-items-center'>
                <div className='btn-group w-100 mt-5'>
                    <button className={`btn ${selectedQuantity == 4 ? 'btn-success' : 'btn-outline-success'}`} disabled={!loggedIn} onClick={() => onSelectClick(4)} >4 Tokens</button>
                    <button className={`btn ${selectedQuantity == 8 ? 'btn-success' : 'btn-outline-success'}`} disabled={!loggedIn} onClick={() => onSelectClick(8)} >8 Tokens</button>
                    <button className={`btn ${selectedQuantity == 12 ? 'btn-success' : 'btn-outline-success'}`} disabled={!loggedIn} onClick={() => onSelectClick(12)} >12 Tokens</button>
                </div>
                <div className='text-center my-5'>
                    <h2 className='h2'>
                        ${customAmount}
                    </h2>
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <div>
                        <span className='mr-2'>
                            {selectedQuantity} Tokens
                        </span>
                        <div className='btn-group'>
                            <button className={`btn btn-sm w-100 p-0 px-2 ${selectedQuantity > 13 ? 'btn-outline-secondary' : 'btn-secondary'}`} disabled={selectedQuantity < 13} onClick={decrementToken}>-</button>
                            <button className='btn btn-sm w-100 p-0 px-2 btn-outline-secondary' onClick={incrementToken}>+</button>
                        </div>
                    </div>
                    <button 
                        disabled={!loggedIn} onClick={() => onPurchaseClick()} 
                        className="btn btn-outline-success"
                        >
                        {!loggedIn && 'Sign-Up to '}Checkout
                    </button>
                </div>
            </div>
            {/* <div className="ml-3">
                {/* <div className="d-flex justify-content-between">
                    <div className="absorb token-bundle">
                        <strong>4 Token Bundle</strong>
                        <div>
                            <p>{nonSub ? '$52.00' : '$100.00'}</p>
                        </div>
                    </div>
                    <div>
                        <button disabled={!loggedIn} onClick={() => onSelectClick(4)} className="btn btn-outline-success absorb purchase-button-wrapper">{selectedQuantity == 4 ? `Selected` : !loggedIn ? 'Sign-Up to Purchast Tokens' : 'Select Bundle'}</button>
                    </div>
                </div>
                <hr className="absorb purchase-divider" />
                <div className="d-flex justify-content-between">
                    <div className="absorb token-bundle">
                        <strong>8 Token Bundle</strong>
                        <div>
                            <p>{nonSub ? '$104.00' : '$200.00'}</p>
                        </div>
                    </div>
                    <div>
                        <button disabled={!loggedIn} onClick={() => onSelectClick(8)} className="btn btn-outline-success absorb purchase-button-wrapper">{selectedQuantity == 8 ? `Selected` : !loggedIn ? 'Sign-Up to Purchast Tokens' : 'Select Bundle'}</button>
                    </div>
                </div>
                <hr className="absorb purchase-divider" />
                <div className="d-flex justify-content-between mb-5">
                    <div className="absorb token-bundle">
                        <strong>12 Token Bundle</strong>
                        <div>
                            <p>{nonSub ? '$156.00' : '$300.00'}</p>
                        </div>
                    </div>
                    <div>
                        <button disabled={!loggedIn} onClick={() => onSelectClick(12)} className="btn btn-outline-success absorb purchase-button-wrapper">{selectedQuantity == 12 ? `Selected` : !loggedIn ? 'Sign-Up to Purchast Tokens' : 'Select Bundle'}</button>
                    </div>
                </div>
                <hr className="absorb purchase-divider" />
                <div className="justify-content-between mb-5">
                    <div className="absorb token-bundle">
                        </div>
                        <div className="d-flex mx-2 justify-content-between align-items-between">
                            <div className="d-grid">
                                <p className="h2">Total</p>
                                <p><label className="h3">${customAmount} / </label> {totalCost} <i>tokens</i></p>
                            </div>
                            <div className="text-center">
                                <div className="d-inline">
                                    <strong>Custom</strong>
                                </div>
                                <div>
                                    <i>Min 13 Tokens</i>
                                </div>
                                <div className="d-flex justify-content-center mt-2">
                                    <div onClick={incrementToken} style={{ cursor: 'pointer' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi bi-file-plus" viewBox="0 0 16 16">
                                            <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z"/>
                                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                                        </svg>
                                    </div>
                                    <div onClick={decrementToken} className="ml-3" style={{ cursor: 'pointer' }}>
                                        <svg className="decrease-button" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={decreaseStyle}viewBox="0 0 16 16">
                                            <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"/>
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="mt-2 d-flex justify-content-end">
                    
                        <button 
                            disabled={!loggedIn} onClick={() => onPurchaseClick()} 
                            className="btn btn-outline-success mt-3"
                        >
                            {!loggedIn && 'Sign-Up to '}Checkout
                        </button>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default AbsorbPurchaseTokens;