import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import Popup from "reactjs-popup";
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import CorrectPendingIndv from './SuperCorrectionForms';
import { useStripe } from '@stripe/react-stripe-js'

import { ElevateAdmin, SuperCreateCustomTokens } from '../exportcomponents/ApprenticeExports';
import EditAdminProfile from './EditAdminProfile';
import { 
	UserInfo, 
	EditQuotePricing, 
} from '../exportcomponents/PopupExports';
import { collapsedStyle } from '../exportcomponents/StyleExports'

const PendingIndList = ({ onLastClicked }) => {
    const navigate = useNavigate()
    const stripe = useStripe()
    const { userData } = useContext(UserContext);
	const { langData } = useContext(LangContext);
    const [pendingIndividuals, setPendingIndividuals] = useState([]);
    const [adminSearch, setAdminSearch] = useState("")
    const [downPaymentCost, setDownPaymentCost] = useState(100);
    const [superEmail, setSuperEmail] = useState("");
    const [buttonDisable, setButtonDisable] = useState({});
    const [pendingIndTab, setPendingIndTab] = useState(true)
    const [selectedPend, setSelectedPend] = useState({});

    const onPendIndvApprove = async (account_id, charge_down) => {
        // const confirm_first = !charge_down && window.confirm("Are you sure you'd like to approve this account without a down payment?")
        // if (!confirm_first) return
		try {
			setButtonDisable({ ...buttonDisable, pendIndv: true, pendIndvDown: true });
			setTimeout(() => setButtonDisable({ ...buttonDisable, pendIndv: false, pendIndv: false }), 5000);
			// console.log("Approve Indv: ", account_id);
			const approvedIndv = await axios.put(`${process.env.REACT_APP_API_URL}/account/individual/approve/${account_id}`, { charge_down });
			if (approvedIndv.data.missing_session) {
				toast.error("No Card on File!");
                setTimeout(async () => {
                    if (window.confirm("Would you like to update this account's add this user's payment information manually?")) {
                        return await stripe.redirectToCheckout({
                            sessionId: approvedIndv.data.missing_session
                        });
                    }
                }, 1000)
                return
			}
			// let pendingIndvData;
			// await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/individual`)
			// 	.then(({data}) => {
			// 		pendingIndvData = data;
			// 	}).catch((err) => {
			// 		// console.log(err);
			// 	});
			// setPendingIndividuals(pendingIndvData);
            // navigate('/')
            // navigate('/super')
			toast.success(langData.toast.individual_approved);
		} catch (err) {
			// console.log(err);
			toast.error(langData.toast.individual_approve_fail);
		}
	}

	const onPendIndvDelete = async (e) => {
        const confirmed = window.confirm("Are you sure you'd like to permanently delete this pending fitter?")
        if (!confirmed) return
		try {
			// console.log("Delete Indv: ", e.target.value);
			await axios.delete(`${process.env.REACT_APP_API_URL}/account/user/${e.target.value}`);
			const pendingIndvData = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/individual`);
			setPendingIndividuals(pendingIndvData.data);
			toast.error(langData.toast.individual_deny);
		} catch (err) {
			// console.log(err);
			toast.error(langData.toast.individual_deny_fail);
		}

	}

    async function onBypassEmailConfirm(account_id) {
        try {
            const super_verified = await window.confirm("Are you sure? Bypassing confirm may leave ACT open to payment chargebacks.")
            if (!super_verified) return
            
            await axios.post(`${process.env.REACT_APP_API_URL}/account/confirm-email/${account_id}`)
            
            toast.success('Email Bypassed Successfully')
            setupPendingIndividualList()
        } catch (error) {
            console.error(error)
            toast.error('Something went wrong')
        }
    } 

    async function reSendEmailConfirmation(account_id) {
        try {
            // console.log("EMAILLLLLLLLLLLLL!!!!!!!!!!\n\n", email)
            await axios.put(`${process.env.REACT_APP_API_URL}/account/individual/re-confirm/${account_id}`)
            toast.success("Confirmation email re-sent!")
            setupPendingIndividualList();
        } catch (err) {
            console.error(err);
            toast.error("Something went wrong")
        }
    }

    async function setupPendingIndividualList() {
        try {
            const pend_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/individual`);
            setPendingIndividuals(pend_data.data);
        } catch (err) {
            toast.error(err.message)
        }
    }

    function readableBillingFreq(billing_frequency) {
        return {
            1: 'Monthly',
            3: 'Quarterly',
            6: 'Bi-Annually',
            12: 'Annually',
        }[billing_frequency]
    }

    useEffect(() => {
		if (!userData.user) return navigate("/");
		setSuperEmail(userData.user.login);
		setupPendingIndividualList()
	}, []);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                <h3 className='h3 text-secondary'>{langData.super_functionality.pending_indv}</h3>
                
                <div className='d-flex justify-content-end'>
                    <div className="tab-title apprentice rounded">
                        <h1>{pendingIndividuals.length}</h1>
                    </div>
                    <span onClick={(e) => setupPendingIndividualList()} className="input-group-text rounded border-0 ml-3">
                        <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z"/></g>
                        </svg>
                    </span>
                </div>
            </div>
            <div className='min-vh-50'>
                {pendingIndividuals.map((pend, i) => {
                    return (
                        <div key={i} className={`card card-body mt-3 ${selectedPend.id == pend.id && 'select-highlight'}`} onClick={() => setSelectedPend(pend)}>
                            <div className='row'>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.name}
                                    </strong>
                                    <p>
                                        {pend.first_name} {pend.last_name}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.email}
                                    </strong>
                                    <p>
                                        {pend.email}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.billing_frequency}
                                    </strong>
                                    <p>
                                        {readableBillingFreq(pend.billing_frequency)}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.has_renewed}
                                    </strong>
                                    <p>
                                        {pend.has_renewed || 'Not Specified'}
                                    </p>
                                </div>
                            </div>
                                


                                {/* DON'T FORGET TO BRING BE BACK */}
                                {/* <div className="w-50">
                                    {pend.pending_payment ? (
                                        <div className="form-background justify-content-center">
                                            <p>{langData.super_functionality.no_card_saved}</p>
                                            <br/>
                                            <p>{langData.super_functionality.no_card_instructions}</p>
                                        </div>
                                    ) : (
                                        !pend.email_confirmed && (
                                            <div className='text-center'>
                                                <i>
                                                    Email Not Yet Confirmed
                                                </i>
                                                <button 
                                                    className='mt-2'
                                                    onClick={() => reSendEmailConfirmation(pend.account_id)}>
                                                    Re-Confirm Email
                                                </button>
                                                <button 
                                                    onClick={() => onBypassEmailConfirm(pend.account_id)}
                                                    className='mt-2'>
                                                    Bypass Verification
                                                </button>
                                            </div>
                                        )
                                    )}
                                </div> */}
                        </div>
                        
                    )
                })}
            </div>
            <div className='d-flex justify-content-center position-sticky bottom-0'>
                <div className='opaque p-3 w-75 d-flex flex-wrap justify-content-evenly align-items-center'>
                    {selectedPend.paid === false ? (
                        <button
                            className="m-1 text-nowrap"
                            onClick={() => { onPendIndvApprove(selectedPend.id, null) }}>
                            Add Card Manually
                        </button>
                    ) : !selectedPend.email_confirmed ? (
                        <>
                            <button 
                                className='m-1 text-nowrap'
                                onClick={() => reSendEmailConfirmation(selectedPend.id)}>
                                Re-Confirm Email
                            </button>
                            <button 
                                onClick={() => onBypassEmailConfirm(selectedPend.id)}
                                className='m-1 text-nowrap'>
                                Bypass Verification
                            </button>
                        </>
                    ) : (
                        <>
                            <Popup
                                modal
                                trigger={<button className="text-nowrap m-1" disabled={buttonDisable.pendIndv || !selectedPend.email_confirmed}>{langData.super_functionality.approve}</button>} >
                                <div className="form-background">
                                    <h3 className='h3 mb-3'>{langData.super_functionality.approve_header}</h3>
                                    
                                    <div className='d-flex justify-content-between mb-2'>
                                        <b>{langData.super_functionality.approving}</b>
                                        <span className='fw-normal'>{selectedPend.email}</span>
                                    </div>
                                    <label className='mb-1'>{langData.super_functionality.set_down_price}</label>
                                    <input
                                        className="form-control mb-2" 
                                        type="number"
                                        placeholder={downPaymentCost}
                                        onChange={(e) => setDownPaymentCost(e.target.value)} />
                                    <button
                                        className="btn btn-success w-100 mt-2"
                                        disabled={buttonDisable.pendIndvDown}
                                        onClick={() => onPendIndvApprove(selectedPend.id, downPaymentCost)}>
                                        {langData.super_functionality.approve}
                                    </button>
                                </div>
                            </Popup>
                            <button
                                className="text-nowrap m-1"
                                disabled={buttonDisable.pendIndv || !selectedPend.email_confirmed}
                                onClick={() => onPendIndvApprove(selectedPend.id, null)}>
                                {langData.super_functionality.approve_no_down}
                            </button>
                        </>
                    )}
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.info}</button>}
                    >
                        <UserInfo
                            id={selectedPend.id}
                        />
                    </Popup>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.make_corrections}</button>}
                    >
                        <CorrectPendingIndv
                            id={selectedPend.id}
                        />
                    </Popup>
                    <button
                        className="text-nowrap m-1"
                        disabled={buttonDisable.pendIndv}
                        onClick={onPendIndvDelete} value={selectedPend.id}>
                        {langData.super_functionality.delete}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PendingIndList
