const live_data = {
    "bundled_courses": {
        "1": {
            "1": {
                "Id": "55b63203-f7f2-4dc3-a0b4-2c324382d796",
                "CourseType": "OnlineCourse",
                "Name": "Being an effective and employable fitter (23-01)",
                "Description": "<p> This course covers skills that are necessary for the fitter to be successful and effective on the job. &nbsp;The skills covered are effective problem solving, critical thinking, social skills and organizational skills that all those working in the industry can greatly benefit from advancing.&nbsp;</p><p>&nbsp;</p>",
                "Notes": null,
                "ExternalId": "ACT",
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "2806243b-37f4-42f3-930c-77512f57ad1e"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "24439982-39e5-4a06-aec5-e556185ff24d",
                "CertificateUrl": "Private/Defaults/Absorb Cert (Revised 3).pdf",
                "Audience": "en",
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": 2,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T19:45:28.59",
                "DateAdded": "2023-01-17T22:26:40.203"
            },
            "2": {
                "Id": "41b58696-45c1-4b70-82b4-70baac80632b",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-1  Basic Communication",
                "Description": "<p>This course is the first course in the Apprentice Level 2 curriculum and covers the basics of communication.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "245d90be-683d-4a2e-9ba3-68cb8c0f5b7a"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-04-02T16:08:14.087",
                "DateAdded": "2021-11-10T04:47:25.33"
            },
            "3": {
                "Id": "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-2 - Basic Employability",
                "Description": "<p>This course is the second course in the Apprentice Level 2 curriculum and covers the basics of employability.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "8cc45363-1640-4fa1-8efa-21121ae3f597",
                    "1ed17e70-2a34-418c-bd1c-f9ba3759a0b2",
                    "85d20df0-488a-4064-858a-3df20912d4ff"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:22:10.487",
                "DateAdded": "2021-11-23T05:21:59.717"
            },
            "4": {
                "Id": "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-3 - Basic Safety",
                "Description": "<p>This course is the third course in the Apprentice Level 2 curriculum and covers the basics of Safety.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "110596ad-aa57-4fc7-b319-7468cf612f9e",
                    "b89e2117-19d7-43f5-bc38-45a0545b97e4",
                    "af63d6f2-d5de-4029-81cb-dc0afbc2efd6",
                    "72cec4fa-a9c5-4518-b744-f3001903d591",
                    "b23d99b2-b96e-4d3e-a223-4fa9ef918fd7",
                    "3880b492-652c-45a8-9320-2af2cea03574",
                    "9d1814a4-1c3c-451e-9a90-af5afd0434c8"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-04-05T21:46:26.133",
                "DateAdded": "2021-12-20T05:55:37.727"
            },
            "5": {
                "Id": "bf2ec21d-c833-4938-8f5d-2a5fabc79ce2",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-4 - intro to Hand Tools",
                "Description": "<p>This course is the fourth course in the Apprentice Level 2 curriculum and covers the Introduction to Hand Tools.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "b0a55d6b-7824-4f5d-a34f-a724457e74ed",
                    "a036cf76-aa9b-46cb-96c0-20f8e80025aa",
                    "fed74c5a-7505-43a0-80ca-30a60d8cc1a3",
                    "db2e5438-9e26-45a3-8ace-29015bf95e98",
                    "5ecaf822-dbf7-41e2-b9b0-a4919b8b24c9",
                    "c1d13c65-6340-4041-b2e0-6bf75454e710",
                    "4f15c932-027b-4afe-a485-112dd05ed215",
                    "a67bc116-b41f-446c-aca1-9ea1473eb083"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:22:49.707",
                "DateAdded": "2022-01-03T03:46:51.833"
            }
        },
        "2": {
            "1": {
                "Id": "301a0cd3-002e-4e8e-9479-776a1d65d02c",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-5 - Intro to Power Tools",
                "Description": "<p>This course is the fifth course in the Apprentice Level 2 curriculum and covers the Introduction to Power Tools.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "74246e5a-6e66-4f67-9276-2e62c08f3073",
                    "a05bec17-7bc2-4bb5-95a3-d241a3822391",
                    "b9844e5c-d2b5-434b-8de5-733663bc8093"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "bf2ec21d-c833-4938-8f5d-2a5fabc79ce2",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:23:27.81",
                "DateAdded": "2022-01-14T04:33:30"
            },
            "2": {
                "Id": "ace6003f-e842-4e09-8b95-f00b28ccd43b",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-6 - Intro to Material Handling",
                "Description": "<p>This course is the sixth and final course in the Apprentice Level 2 curriculum and covers the Introduction to Material Handling.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "6a7fdc47-2036-4dea-bd2b-cbea909e7d0b",
                    "29d170fe-f33a-4918-86f5-f650107277e3",
                    "d3107d84-a3ac-4a23-9413-cbd306cfe237",
                    "82d7e8c9-ab13-4b3f-af5f-8c2d144f9364",
                    "ee16f7a6-d0ba-4510-9bfc-5930364e81d1"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "bf2ec21d-c833-4938-8f5d-2a5fabc79ce2",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "301a0cd3-002e-4e8e-9479-776a1d65d02c",
                    "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:24:01.937",
                "DateAdded": "2022-01-28T01:25:23.567"
            },
            "3": {
                "Id": "263ba948-0e65-4432-8857-778bac29a18c",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-1 - Basic Rigging",
                "Description": "<p>This course is the first&nbsp;course in the Apprentice Level 3 curriculum and covers Basic Rigging.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "7558a2e3-ff0f-4a4e-80fe-f682968430af",
                    "7844613e-1797-48e4-bfb6-cecadb400fae",
                    "e3e28ec5-c3c7-43d4-9521-9d3df698c033",
                    "54c54983-1c6d-45c6-8692-7260edfcc1fb",
                    "f7a4274b-a1ec-4053-8c6b-28b60c8eb266",
                    "4aa41c96-b617-4144-be3f-9d396a690922",
                    "18524cd9-d00a-418b-9e92-25ea92f66f82"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-28T21:45:20.607",
                "DateAdded": "2022-02-04T04:06:34.843"
            },
            "4": {
                "Id": "0319d435-8521-41ac-b516-c2d1cf2b862c",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-2 - Intro to Construction Drawings",
                "Description": "<p>This course is the second course in the Apprentice Level 3 curriculum and covers Introduction to Construction Drawings.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c95457cc-6943-4432-b9db-a25bded688d8",
                    "71d99f10-89f2-4516-bbc5-c0db63b65e35",
                    "bccc2380-5a4d-41c9-9623-47af135528e4",
                    "48438f4a-c95b-4a51-9dbf-6b694114dca8",
                    "4db9fe6a-3025-4c2d-ada6-4237b190b2ed",
                    "7c39faae-5deb-4ad5-9387-ee8c90d45298",
                    "929af39d-348f-44f3-a5f0-ca78f86e7c9a",
                    "06be1b39-a6f3-4c88-929e-90b256487f69",
                    "7434eaae-ee4d-4926-b8a4-320564ca7d98"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-28T22:12:39.85",
                "DateAdded": "2022-02-04T04:18:36.163"
            },
            "5": {
                "Id": "996e5b32-c2a4-4778-bc4d-2d7ab26540a7",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-3 - Intro to Construction Math",
                "Description": "<p>This course is the third course in the Apprentice Level 3 curriculum and covers Introduction to Construction Math.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "95894b18-7d27-4c03-9414-98e0c95ea188",
                    "a4e50e34-bc16-454b-96dc-636c93a156ef",
                    "74c42476-358f-4333-8891-fa6465b5daf9",
                    "742cc9c5-6671-4cf9-82c3-60b12e24ff8b",
                    "155d2232-ef0e-4bef-8772-400dc3e2dec1",
                    "0facff61-b612-454d-9b2e-2b6169622882",
                    "4917387c-cbbd-4996-9e97-823498f77a23",
                    "526bec49-8684-49e6-81b5-2c6358a7f307",
                    "a746e03a-a30e-49f1-aa98-33c6c89649b9",
                    "3dbde5c3-51a2-4076-8876-41a7013c60a9",
                    "3f06c3c9-dae4-473b-bccb-a5f7471eb782",
                    "2acf73d7-f9f8-450e-befb-3612c5f355e9",
                    "566da33a-73f3-4ad6-82e9-3a5f3a08631e",
                    "03c742bd-b35f-4c58-b224-70efe8812130",
                    "183e7568-5d1f-4492-ae2f-904ba2e53df0",
                    "893531c9-98cc-422a-a7d0-031132c7ef84"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-09-26T20:53:49.02",
                "DateAdded": "2022-02-04T04:21:07.203"
            }
        },
        "3": {
            "1": {
                "Id": "7a1aaaa4-cdfa-46aa-8aa4-5a2d38cba638",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-4 - Orientation to the trade",
                "Description": "<p>This course is the fourth course in the Apprentice Level 3 curriculum and covers Orientation to the trade.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "9a28b6a6-d094-4dcf-b710-7bbf2e1cf97e",
                    "8aea15b5-6e38-4706-afd6-227ed1899ce1",
                    "dc333365-b87d-44ef-a1cc-f4a3f8fa3456",
                    "e184e683-eabf-4225-84ca-98463e6d526f",
                    "9e975379-7fd6-4234-b57f-c0426756effe",
                    "582ee8d6-823e-44dd-b22b-5cfd98162acf",
                    "8d32f07f-368d-490c-a7fa-19bd0abe82c7"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-27T21:50:25.493",
                "DateAdded": "2022-02-04T04:22:35.77"
            },
            "2": {
                "Id": "d3c32b6a-01e5-4d65-95c8-1f17fb66518c",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-5 - Introduction to Components & Systems",
                "Description": "<p>This course is the fifth course in the Apprentice Level 3 curriculum and covers Introduction to Components &amp; Systems.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "43c8b934-267c-4782-8659-d44a8151aae0",
                    "ee5bc027-9ea3-417d-beb3-92c341c88494",
                    "d155bdf0-c568-493f-bb0f-a08c01344d28",
                    "b6c1b87d-f59c-483c-ab77-6dfc1e712ff4",
                    "86dd5301-8439-4e11-ac7b-26be68b573a1",
                    "7eb355b3-7700-4c9d-b2a8-551543fae4c5",
                    "e181eb55-81b9-4cf9-91e0-57e8a5e37c1f"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-03-25T22:10:46.17",
                "DateAdded": "2022-02-04T04:24:35.737"
            },
            "3": {
                "Id": "686ae996-b139-4863-a78b-05b201977cd4",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-6 - Steel Pipe",
                "Description": "<p>This course is the sixth course in the Apprentice Level 3 curriculum and covers Steel Pipe.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c92d151a-a9c3-483d-b846-6408bba913ba",
                    "adf93ed1-d405-4f26-a712-ddef17c6d4d4",
                    "608ec9d5-d554-4f74-9caa-23a72fed982b",
                    "60060a2c-891b-4d2f-a8ef-9859f9d40b08",
                    "bcbb4da7-b3f2-417c-942a-47e56a98d9ba",
                    "1053db34-77c6-4ae8-bf91-6307130b500e",
                    "8b00e772-5e1d-48d9-a4ec-f3f3ee33abd9"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:14:25.953",
                "DateAdded": "2022-02-04T04:26:35.623"
            },
            "4": {
                "Id": "f2df4768-d69e-426e-b6bb-049b4339aaee",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-7 - CPVC Pipe & Fittings",
                "Description": "<p>This course is the seventh and final course in the Apprentice Level 3 curriculum and covers CPVC Pipe &amp; Fittings.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "8f7b8489-c917-4a1f-8614-d77dfe949f6a",
                    "bd4fcb6f-4a78-4400-8b85-bd096f1e85df",
                    "0b172735-aba8-4191-bdd0-15634a684c59",
                    "e3bbdcae-b9db-4eef-acfb-85e647c832d4",
                    "3e5aa4ff-9c14-4b9c-b5f8-a5d4410b44dc",
                    "96ace90b-c3f5-4705-a674-8abb6b98d834",
                    "c5ecf20d-8254-48b8-9a31-8c71c428fb0f"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-27T21:53:48.653",
                "DateAdded": "2022-02-04T04:28:16.25"
            },
            "5": {
                "Id": "c091907d-b349-420e-82af-8a77685ef52e",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-1 - Copper Tube Systems",
                "Description": "<p>This course is the first course in the Apprentice Level 4 curriculum and covers Copper Tube Systems.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "785870d2-5a9b-491b-9b47-bfca892b34e8",
                    "4f1d2230-0f7c-4533-9608-e6bfde0eb4e6",
                    "b633ff2b-eb5f-491b-9fa7-3667a84d02af"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:15:51.67",
                "DateAdded": "2022-05-16T22:04:29.15"
            }
        },
        "4": {
            "1": {
                "Id": "5e4965da-799f-4e9e-8c78-cc239d5d079c",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-2 - Underground Pipe Systems",
                "Description": "<p>This course is the second course in the Apprentice Level 4 curriculum and covers Underground Pipe Systems.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c051ba42-51f1-4cf4-baa1-4518b936a603",
                    "4f843a85-9931-49b8-aa5c-0a114c1a9f35",
                    "e84c2fb4-1293-46c8-837c-34e5c73551fe"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:16:34.947",
                "DateAdded": "2022-05-16T22:04:28.84"
            },
            "2": {
                "Id": "db7671e3-ed4b-4caf-8839-11821617abb3",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-3 - Hangers, Supports, Restraints and Guides.",
                "Description": "<p>This course is the third course in the Apprentice Level 4 curriculum and covers Hangers, Supports, Restraints and Guides.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "5dc64dee-98ea-4df7-92df-d1712296d82c",
                    "b368c50f-a7fa-4586-8351-fd0f8745f766",
                    "09bca1b4-e58e-4ac3-952f-6aefd611fced"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:17:08.667",
                "DateAdded": "2022-05-16T22:19:00.803"
            },
            "3": {
                "Id": "5a3d549c-0a74-4ed4-bbed-12ca73dee8a4",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-4 - General Purpose Valves",
                "Description": "<p>This course is the fourth course in the Apprentice Level 4 curriculum and covers General Purpose Valves.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "f9456c89-5add-46b4-a51a-34fabd81d3bb",
                    "4f68fab2-50c2-49ff-b4fa-5b19e499eb0d",
                    "28410088-9bd2-4625-a36a-701c8918ed68",
                    "c6d0dab6-57e8-4d63-af5f-792fa3f1c92e",
                    "2d9c91ec-5afa-4bf4-9666-13b917129a47"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:17:57.81",
                "DateAdded": "2022-05-16T22:18:30.5"
            },
            "4": {
                "Id": "5add6a1c-e379-47f7-aec7-6a5caf2a11e5",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-5 - General Trade Math",
                "Description": "<p>This course is the fifth course in the Apprentice Level 4 curriculum and covers General Trade Math.&nbsp;&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "e4809ed0-941a-40b5-a2c5-147053feb3ed",
                    "4fd54015-a367-4ae7-b159-6b909fdf5cec",
                    "ad0363de-0225-4468-b68e-58b3f75fc9e0"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-08-14T22:46:28.96",
                "DateAdded": "2022-05-16T22:19:00.697"
            },
            "5": {
                "Id": "9382f9e3-72e5-4c32-89a0-307a28004766",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-1 - Shop Drawings",
                "Description": "<p>This course is the first course in the Apprentice Level 5 curriculum and covers Shop Drawings.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "dcda53ee-f614-47dd-9552-2f8c681253e0",
                    "2976eeca-05a1-4c69-99df-04392b35606a",
                    "941df005-c2fa-4733-a00c-c755968a0ec8"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:18:58.883",
                "DateAdded": "2022-06-20T23:21:46.653"
            }
        },
        "5": {
            "1": {
                "Id": "6cc4f255-77c1-4997-a26d-3b072c9ad91f",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-2 - Standard Spray Sprinklers",
                "Description": "<p>This course is the second course in the Apprentice Level 5 curriculum and covers Standard Spray Sprinklers.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "ea259a1d-23d0-43d8-ae60-7e4d1efe0001",
                    "5e349d5d-1e41-456a-b6c7-904fe5e684ea",
                    "3088fb1f-f86a-47ee-9726-e8c7894e3db6",
                    "28afa2ae-08a8-4ff0-8ac4-de9893cb77b6",
                    "eb28f82f-9ee5-4655-943b-3a4be0ecaba1",
                    "16bd277e-ce6d-448d-92c6-b2e78a55e369",
                    "568fed27-a27e-4a2b-8763-1a1459eed01b"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:19:32.043",
                "DateAdded": "2022-06-20T23:21:32.503"
            },
            "2": {
                "Id": "03d1d5dc-3a50-463e-b409-423b6b742155",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-3 - Wet Fire Sprinkler Systems",
                "Description": "<p>This course is the third course in the Apprentice Level 5 curriculum and covers Wet Fire Sprinkler Systems.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "320897bf-3b10-436c-9c41-7a42498b11b5",
                    "9577532d-a0c9-4f5e-812a-e32b2f4b2a41",
                    "3d3172d3-c922-4431-ae95-45ed2a4e17e3",
                    "c0d0ee31-918f-4d05-b960-989a5a4cfe3c"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:19:59.647",
                "DateAdded": "2022-06-20T23:21:33.797"
            },
            "3": {
                "Id": "0158687a-aa4a-40e2-af7b-505a4ff3f2c8",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-4 - Dry Pipe Systems",
                "Description": "<p>This course is the fourth course in the Apprentice Level 5 curriculum and covers Dry Pipe Systems.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "516465a9-880a-48b1-b20d-bd305b3b0ba7",
                    "ea58dba8-4926-48d1-9a5a-81ddbc2a077f",
                    "c3d69e57-373f-4d30-9a31-3f8d55e8e787",
                    "d00b09cf-94d9-4016-8db4-796459a38807",
                    "72911f2f-ccd1-49af-9f53-b46acd26cc15",
                    "f79e4cb0-99b8-4965-a4dc-57e92fec8e55"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-05-09T21:45:53.817",
                "DateAdded": "2022-06-20T23:21:45.267"
            },
            "4": {
                "Id": "0f7d88ca-187c-4934-af60-802e93619d15",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-1 - Deluge/Preaction Systems",
                "Description": "<p>This course is the first course in the Apprentice Level 6 curriculum and covers Deluge/Preaction Systems.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c1aeb518-a213-4b5b-bbd4-9d32cd99b7bb",
                    "771cf84f-f4b4-4f88-b2bf-7b267432cdc0",
                    "68ebecf8-27f4-4780-a25f-36b80e150fa0",
                    "3c2a8bcb-aeeb-4c1d-a734-f5b0b0fed500"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "48abe5c7-c012-482a-a7ea-7379176076ec",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-06-12T01:57:26.493",
                "DateAdded": "2022-06-20T23:21:33.883"
            },
            "5": {
                "Id": "d947996e-f13b-407b-a914-f6472dfa2027",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-2 - Standpipes (Tuberias Verticales)",
                "Description": "<p>Este curso es el segundo curso en el plan de estudios del nivel de aprendiz 6 y cubre las fuentes de agua.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "44c7db6e-819a-44fc-8dbd-14ef1718c5ae",
                    "e441dbce-8eb7-4d26-8ddb-28dfb1b856df",
                    "bd901db7-8be8-4c51-8205-6f6e8df0b628",
                    "7dba1c4f-c91f-47d8-9c57-f6885b29bc76",
                    "d501199e-b179-493f-8b12-e944c5578ffb",
                    "85dddb84-0b95-4b55-9c99-1ea00c644dbf"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4df3ce3-519a-4474-a8f1-62aa0a1397a0",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T22:16:05.747",
                "DateAdded": "2022-09-11T21:01:24.047"
            }
        },
        "6": {
            "1": {
                "Id": "aac89b01-5aa8-4dcc-9864-01987753da1a",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-3 - Water Supplies",
                "Description": "<p>This course is the third course in the Apprentice Level 6 curriculum and covers Water Supplies.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "bbd871e5-c663-4463-ae88-633c1826b0d7",
                    "6deff992-bc80-45f9-9b40-4b02a752f882",
                    "27c098bf-1c81-4ef3-ac89-a5b857f8ed49",
                    "fbc8052f-4ae6-4102-881f-7fb759a1b370",
                    "cbadd007-f6c4-46a8-a89c-a2a32e306eae",
                    "4ae98d0a-6e41-4b8a-8dea-b08ba0fa2a24"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "48abe5c7-c012-482a-a7ea-7379176076ec",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:22:20.22",
                "DateAdded": "2022-06-20T23:34:45.79"
            },
            "2": {
                "Id": "a4cda165-4a40-42b9-ba92-5506f596d9f9",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-5 - Application Specific Sprinklers and Nozzles",
                "Description": "<p>This course is the fifth course in the Apprentice Level 6 curriculum and covers Application Specific Sprinklers and Nozzles</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "eae74797-db36-4772-8757-c0ae97915395",
                    "ebf71f37-8b73-4cea-bd4b-00c8629ea23d",
                    "4d70eea1-f3b5-4442-b449-8679f54ff115",
                    "5fd99bb0-d5df-43e4-b1ed-ec8250d711bd",
                    "ce2c2ff7-df97-441d-9dcf-3fc941373d77"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "48abe5c7-c012-482a-a7ea-7379176076ec",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:23:30.607",
                "DateAdded": "2022-06-20T23:34:36.973"
            },
            "3": {
                "Id": "39a69d53-71f7-40ed-b60f-6231d464b195",
                "CourseType": "OnlineCourse",
                "Name": "LVL7-1 - System Layout",
                "Description": "<p>This course is the first course in the Apprentice Level 7 curriculum and covers System Layout.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "aed7d297-acf4-43fc-8811-df667f4e295a",
                    "36931e2e-4ed1-4fdd-8bb5-ae89c861021b",
                    "5ca28ead-3a66-4b76-a128-b1df74d32b4e"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "f094131b-ddb2-4e38-9932-a0d510de066f",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:24:03.803",
                "DateAdded": "2022-06-20T23:34:16.513"
            },
            "4": {
                "Id": "1d1089c9-d9df-41c5-8888-da99e6129676",
                "CourseType": "OnlineCourse",
                "Name": "LVL7-4 - Introductory Skills for the Foreman",
                "Description": "<p>This course is the fourth course in the Apprentice Level 7 curriculum and covers Introductory Skills for the Foreman.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "80075404-107e-4f4a-96ab-03f1beefb471",
                    "48c7b202-7f80-4104-a4b3-b3eef90ebf66",
                    "43463424-2a74-4dfb-b7a8-b1aa8a4c95ee",
                    "43102385-a078-44b5-a7aa-acdd5b5168b7"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "f094131b-ddb2-4e38-9932-a0d510de066f",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:25:58.623",
                "DateAdded": "2022-06-20T23:34:37.197"
            },
            "5": {
                "Id": "62a8e3cd-42c2-4303-a06b-e8e03676141f",
                "CourseType": "OnlineCourse",
                "Name": "LVL7-5 - Procedures & Documentation",
                "Description": "<p>This course is the fifth course in the Apprentice Level 7 curriculum and covers Procedures &amp; Documentation.&nbsp;&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "23cc4374-41a5-421a-bf6d-0bdccf838687",
                    "4be27736-7b70-4a53-9838-52fdb3f907af",
                    "49909557-0375-4470-9bb4-85073ebde8f0"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "f094131b-ddb2-4e38-9932-a0d510de066f",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:26:50.62",
                "DateAdded": "2022-06-20T23:34:18.503"
            }
        }
    },
    "bundled_enrollments": [
        {
            "id": "c373c54e-d08f-4d3b-9840-f35dc5af2eab",
            "name": "Victor  Palacios ",
            "learner_id": "0277b34a-c739-44b8-9be7-6c6cc27d31d2",
            "current_level": 1,
            "rti_current": null,
            "rti_overall": null,
            "eval_period_start": null,
            "eval_period_end": null
        },
        {
            "id": "3cd17759-cf11-4413-9d76-cecad189a347",
            "name": "Pedro Lopez",
            "learner_id": "02f1b9ce-8992-4b9d-90b3-2e61f4b857f9",
            "current_level": 1,
            "rti_current": null,
            "rti_overall": null,
            "eval_period_start": null,
            "eval_period_end": null
        },
        {
            "id": "99cf9140-242d-49ed-87db-aad8d1b5ea37",
            "name": "Elvin Noe Lanza Menocal ",
            "learner_id": "031ddefc-7f41-4d26-8730-e96e9ddf832e",
            "current_level": 1,
            "rti_current": null,
            "rti_overall": null,
            "eval_period_start": null,
            "eval_period_end": null
        },
        {
            "id": "595a0d61-ee8e-4b36-8110-c8dd1aed600a",
            "name": "Oscar Servin",
            "learner_id": "08fa8be0-9ba2-40f1-8817-4358fda59267",
            "current_level": 1,
            "rti_current": null,
            "rti_overall": null,
            "eval_period_start": null,
            "eval_period_end": null
        },
        {
            "id": "03a611d5-3bc1-4118-b4e0-4d07d98c5a3e",
            "name": "Daniel Angel  Villapudua Morales ",
            "learner_id": "093ee2bb-2115-497f-8762-d6aa40615486",
            "rti_current": "9",
            "rti_overall": "9",
            "completed": 1,
            "assigned": 1,
            "levels": {
                "1": {
                    "2": {
                        "color": "#FCBD46",
                        "credits": 7.5,
                        "eval_period_start": "2024-07-01T00:00:00",
                        "eval_period_end": "2024-12-31T00:00:00"
                    },
                    "3": {
                        "color": "#FFFF5B",
                        "credits": null
                    }
                }
            },
            "current_level": 2
        },
        {
            "id": "597f64e2-0467-4d2f-88bc-812fd7173640",
            "name": "Juan C. Salazar",
            "learner_id": "09b9ecce-331f-4a22-b725-f1aa11d3482f",
            "current_level": 1,
            "rti_current": null,
            "rti_overall": null,
            "eval_period_start": null,
            "eval_period_end": null
        },
        {
            "id": "fba6076b-3e1a-4314-a9ca-f028cb214522",
            "name": "Julian Almaraz Olvera ",
            "learner_id": "0b5ab3e7-893d-4559-b29c-cd350600f7ce",
            "current_level": 1,
            "rti_current": "0",
            "rti_overall": "0",
            "eval_period_start": "2024-09-01T00:00:00",
            "eval_period_end": "2025-02-28T00:00:00"
        },
        {
            "id": "2c8966e7-bdf6-4c9d-b53f-547089912388",
            "name": "Nicolas Gallegos Gonzalez ",
            "learner_id": "0ca066a4-c48f-4da1-83ff-c6c3407529ee",
            "rti_current": "132",
            "rti_overall": "438",
            "current_level": 6,
            "completed": 1,
            "assigned": 0,
            "levels": {
                "5": {
                    "5": {
                        "color": "#FCBD46",
                        "credits": 25,
                        "eval_period_start": "2024-04-01T00:00:00",
                        "eval_period_end": "2024-09-30T00:00:00"
                    }
                }
            }
        },
        {
            "id": "532b1dea-e6ae-4f3e-8398-e6e27acbddb2",
            "name": "Luis Fernando   Acero Garcia",
            "learner_id": "0e704a4b-816b-40c0-a3d8-d5e7b9b2d203",
            "current_level": 1,
            "rti_current": "0",
            "rti_overall": "0",
            "eval_period_start": "2024-09-01T00:00:00",
            "eval_period_end": "2025-02-28T00:00:00"
        },
        {
            "id": "ac44e302-240b-4875-b463-917dd320f743",
            "name": "Marvin Wilfredo Medina",
            "learner_id": "0f15c067-030d-461b-bbbf-625309073209",
            "rti_current": "0",
            "rti_overall": "306",
            "completed": 15,
            "assigned": 0,
            "levels": {
                "1": {
                    "4": {
                        "color": "#FCBD46",
                        "credits": 12.5,
                        "eval_period_start": "2024-09-01T00:00:00",
                        "eval_period_end": "2025-02-28T00:00:00"
                    }
                },
                "2": {
                    "4": {
                        "color": "#6f6fe8",
                        "credits": 10
                    },
                    "5": {
                        "color": "#6f6fe8",
                        "credits": 10
                    }
                },
                "3": {
                    "2": {
                        "color": "#6f6fe8",
                        "credits": 7.5
                    },
                    "3": {
                        "color": "#6f6fe8",
                        "credits": 22.5
                    },
                    "4": {
                        "color": "#6f6fe8",
                        "credits": 10
                    },
                    "5": {
                        "color": "#6f6fe8",
                        "credits": 10
                    }
                },
                "4": {
                    "1": {
                        "color": "#6f6fe8",
                        "credits": 17.5
                    },
                    "2": {
                        "color": "#6f6fe8",
                        "credits": 15
                    },
                    "3": {
                        "color": "#6f6fe8",
                        "credits": 15
                    },
                    "4": {
                        "color": "#6f6fe8",
                        "credits": 20
                    },
                    "5": {
                        "color": "#6f6fe8",
                        "credits": 32.5
                    }
                },
                "5": {
                    "1": {
                        "color": "#6f6fe8",
                        "credits": 20
                    },
                    "2": {
                        "color": "#6f6fe8",
                        "credits": 25
                    },
                    "3": {
                        "color": "#6f6fe8",
                        "credits": 25
                    }
                }
            },
            "current_level": 6
        }
    ]
}

const test_data = {
    "bundled_courses": {
        "1": {
            "1": {
                "Id": "55b63203-f7f2-4dc3-a0b4-2c324382d796",
                "CourseType": "OnlineCourse",
                "Name": "Being an effective and employable fitter (23-01)",
                "Description": "<p> This course covers skills that are necessary for the fitter to be successful and effective on the job. &nbsp;The skills covered are effective problem solving, critical thinking, social skills and organizational skills that all those working in the industry can greatly benefit from advancing.&nbsp;</p><p>&nbsp;</p>",
                "Notes": null,
                "ExternalId": "ACT",
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "2806243b-37f4-42f3-930c-77512f57ad1e"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "24439982-39e5-4a06-aec5-e556185ff24d",
                "CertificateUrl": "Private/Defaults/Absorb Cert (Revised 3).pdf",
                "Audience": "en",
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": 2,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T19:45:28.59",
                "DateAdded": "2023-01-17T22:26:40.203"
            },
            "2": {
                "Id": "41b58696-45c1-4b70-82b4-70baac80632b",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-1  Basic Communication",
                "Description": "<p>This course is the first course in the Apprentice Level 2 curriculum and covers the basics of communication.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "245d90be-683d-4a2e-9ba3-68cb8c0f5b7a"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-04-02T16:08:14.087",
                "DateAdded": "2021-11-10T04:47:25.33"
            },
            "3": {
                "Id": "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-2 - Basic Employability",
                "Description": "<p>This course is the second course in the Apprentice Level 2 curriculum and covers the basics of employability.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "8cc45363-1640-4fa1-8efa-21121ae3f597",
                    "1ed17e70-2a34-418c-bd1c-f9ba3759a0b2",
                    "85d20df0-488a-4064-858a-3df20912d4ff"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:22:10.487",
                "DateAdded": "2021-11-23T05:21:59.717"
            },
            "4": {
                "Id": "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-3 - Basic Safety",
                "Description": "<p>This course is the third course in the Apprentice Level 2 curriculum and covers the basics of Safety.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "110596ad-aa57-4fc7-b319-7468cf612f9e",
                    "b89e2117-19d7-43f5-bc38-45a0545b97e4",
                    "af63d6f2-d5de-4029-81cb-dc0afbc2efd6",
                    "72cec4fa-a9c5-4518-b744-f3001903d591",
                    "b23d99b2-b96e-4d3e-a223-4fa9ef918fd7",
                    "3880b492-652c-45a8-9320-2af2cea03574",
                    "9d1814a4-1c3c-451e-9a90-af5afd0434c8"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-04-05T21:46:26.133",
                "DateAdded": "2021-12-20T05:55:37.727"
            },
            "5": {
                "Id": "bf2ec21d-c833-4938-8f5d-2a5fabc79ce2",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-4 - intro to Hand Tools",
                "Description": "<p>This course is the fourth course in the Apprentice Level 2 curriculum and covers the Introduction to Hand Tools.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "b0a55d6b-7824-4f5d-a34f-a724457e74ed",
                    "a036cf76-aa9b-46cb-96c0-20f8e80025aa",
                    "fed74c5a-7505-43a0-80ca-30a60d8cc1a3",
                    "db2e5438-9e26-45a3-8ace-29015bf95e98",
                    "5ecaf822-dbf7-41e2-b9b0-a4919b8b24c9",
                    "c1d13c65-6340-4041-b2e0-6bf75454e710",
                    "4f15c932-027b-4afe-a485-112dd05ed215",
                    "a67bc116-b41f-446c-aca1-9ea1473eb083"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:22:49.707",
                "DateAdded": "2022-01-03T03:46:51.833"
            }
        },
        "2": {
            "1": {
                "Id": "301a0cd3-002e-4e8e-9479-776a1d65d02c",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-5 - Intro to Power Tools",
                "Description": "<p>This course is the fifth course in the Apprentice Level 2 curriculum and covers the Introduction to Power Tools.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "74246e5a-6e66-4f67-9276-2e62c08f3073",
                    "a05bec17-7bc2-4bb5-95a3-d241a3822391",
                    "b9844e5c-d2b5-434b-8de5-733663bc8093"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "bf2ec21d-c833-4938-8f5d-2a5fabc79ce2",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:23:27.81",
                "DateAdded": "2022-01-14T04:33:30"
            },
            "2": {
                "Id": "ace6003f-e842-4e09-8b95-f00b28ccd43b",
                "CourseType": "OnlineCourse",
                "Name": "LVL2-6 - Intro to Material Handling",
                "Description": "<p>This course is the sixth and final course in the Apprentice Level 2 curriculum and covers the Introduction to Material Handling.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "6a7fdc47-2036-4dea-bd2b-cbea909e7d0b",
                    "29d170fe-f33a-4918-86f5-f650107277e3",
                    "d3107d84-a3ac-4a23-9413-cbd306cfe237",
                    "82d7e8c9-ab13-4b3f-af5f-8c2d144f9364",
                    "ee16f7a6-d0ba-4510-9bfc-5930364e81d1"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [
                    "1bcddbb7-f32a-444e-98bf-202ec2197cea",
                    "bf2ec21d-c833-4938-8f5d-2a5fabc79ce2",
                    "b2f41270-1a46-4ef0-b1bb-5fe24d72375f",
                    "41b58696-45c1-4b70-82b4-70baac80632b",
                    "301a0cd3-002e-4e8e-9479-776a1d65d02c",
                    "93b6fcf5-463a-4405-8aa8-7e5919227f9f",
                    "0d7c5d4e-6ce0-4866-aa15-82f4679ae082"
                ],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "cf1cd028-89c3-4225-91d7-8b62db04c691",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-11-26T00:24:01.937",
                "DateAdded": "2022-01-28T01:25:23.567"
            },
            "3": {
                "Id": "263ba948-0e65-4432-8857-778bac29a18c",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-1 - Basic Rigging",
                "Description": "<p>This course is the first&nbsp;course in the Apprentice Level 3 curriculum and covers Basic Rigging.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "7558a2e3-ff0f-4a4e-80fe-f682968430af",
                    "7844613e-1797-48e4-bfb6-cecadb400fae",
                    "e3e28ec5-c3c7-43d4-9521-9d3df698c033",
                    "54c54983-1c6d-45c6-8692-7260edfcc1fb",
                    "f7a4274b-a1ec-4053-8c6b-28b60c8eb266",
                    "4aa41c96-b617-4144-be3f-9d396a690922",
                    "18524cd9-d00a-418b-9e92-25ea92f66f82"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-28T21:45:20.607",
                "DateAdded": "2022-02-04T04:06:34.843"
            },
            "4": {
                "Id": "0319d435-8521-41ac-b516-c2d1cf2b862c",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-2 - Intro to Construction Drawings",
                "Description": "<p>This course is the second course in the Apprentice Level 3 curriculum and covers Introduction to Construction Drawings.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c95457cc-6943-4432-b9db-a25bded688d8",
                    "71d99f10-89f2-4516-bbc5-c0db63b65e35",
                    "bccc2380-5a4d-41c9-9623-47af135528e4",
                    "48438f4a-c95b-4a51-9dbf-6b694114dca8",
                    "4db9fe6a-3025-4c2d-ada6-4237b190b2ed",
                    "7c39faae-5deb-4ad5-9387-ee8c90d45298",
                    "929af39d-348f-44f3-a5f0-ca78f86e7c9a",
                    "06be1b39-a6f3-4c88-929e-90b256487f69",
                    "7434eaae-ee4d-4926-b8a4-320564ca7d98"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-28T22:12:39.85",
                "DateAdded": "2022-02-04T04:18:36.163"
            },
            "5": {
                "Id": "996e5b32-c2a4-4778-bc4d-2d7ab26540a7",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-3 - Intro to Construction Math",
                "Description": "<p>This course is the third course in the Apprentice Level 3 curriculum and covers Introduction to Construction Math.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "95894b18-7d27-4c03-9414-98e0c95ea188",
                    "a4e50e34-bc16-454b-96dc-636c93a156ef",
                    "74c42476-358f-4333-8891-fa6465b5daf9",
                    "742cc9c5-6671-4cf9-82c3-60b12e24ff8b",
                    "155d2232-ef0e-4bef-8772-400dc3e2dec1",
                    "0facff61-b612-454d-9b2e-2b6169622882",
                    "4917387c-cbbd-4996-9e97-823498f77a23",
                    "526bec49-8684-49e6-81b5-2c6358a7f307",
                    "a746e03a-a30e-49f1-aa98-33c6c89649b9",
                    "3dbde5c3-51a2-4076-8876-41a7013c60a9",
                    "3f06c3c9-dae4-473b-bccb-a5f7471eb782",
                    "2acf73d7-f9f8-450e-befb-3612c5f355e9",
                    "566da33a-73f3-4ad6-82e9-3a5f3a08631e",
                    "03c742bd-b35f-4c58-b224-70efe8812130",
                    "183e7568-5d1f-4492-ae2f-904ba2e53df0",
                    "893531c9-98cc-422a-a7d0-031132c7ef84"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-09-26T20:53:49.02",
                "DateAdded": "2022-02-04T04:21:07.203"
            }
        },
        "3": {
            "1": {
                "Id": "7a1aaaa4-cdfa-46aa-8aa4-5a2d38cba638",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-4 - Orientation to the trade",
                "Description": "<p>This course is the fourth course in the Apprentice Level 3 curriculum and covers Orientation to the trade.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "9a28b6a6-d094-4dcf-b710-7bbf2e1cf97e",
                    "8aea15b5-6e38-4706-afd6-227ed1899ce1",
                    "dc333365-b87d-44ef-a1cc-f4a3f8fa3456",
                    "e184e683-eabf-4225-84ca-98463e6d526f",
                    "9e975379-7fd6-4234-b57f-c0426756effe",
                    "582ee8d6-823e-44dd-b22b-5cfd98162acf",
                    "8d32f07f-368d-490c-a7fa-19bd0abe82c7"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-27T21:50:25.493",
                "DateAdded": "2022-02-04T04:22:35.77"
            },
            "2": {
                "Id": "d3c32b6a-01e5-4d65-95c8-1f17fb66518c",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-5 - Introduction to Components & Systems",
                "Description": "<p>This course is the fifth course in the Apprentice Level 3 curriculum and covers Introduction to Components &amp; Systems.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "43c8b934-267c-4782-8659-d44a8151aae0",
                    "ee5bc027-9ea3-417d-beb3-92c341c88494",
                    "d155bdf0-c568-493f-bb0f-a08c01344d28",
                    "b6c1b87d-f59c-483c-ab77-6dfc1e712ff4",
                    "86dd5301-8439-4e11-ac7b-26be68b573a1",
                    "7eb355b3-7700-4c9d-b2a8-551543fae4c5",
                    "e181eb55-81b9-4cf9-91e0-57e8a5e37c1f"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-03-25T22:10:46.17",
                "DateAdded": "2022-02-04T04:24:35.737"
            },
            "3": {
                "Id": "686ae996-b139-4863-a78b-05b201977cd4",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-6 - Steel Pipe",
                "Description": "<p>This course is the sixth course in the Apprentice Level 3 curriculum and covers Steel Pipe.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c92d151a-a9c3-483d-b846-6408bba913ba",
                    "adf93ed1-d405-4f26-a712-ddef17c6d4d4",
                    "608ec9d5-d554-4f74-9caa-23a72fed982b",
                    "60060a2c-891b-4d2f-a8ef-9859f9d40b08",
                    "bcbb4da7-b3f2-417c-942a-47e56a98d9ba",
                    "1053db34-77c6-4ae8-bf91-6307130b500e",
                    "8b00e772-5e1d-48d9-a4ec-f3f3ee33abd9"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:14:25.953",
                "DateAdded": "2022-02-04T04:26:35.623"
            },
            "4": {
                "Id": "f2df4768-d69e-426e-b6bb-049b4339aaee",
                "CourseType": "OnlineCourse",
                "Name": "LVL3-7 - CPVC Pipe & Fittings",
                "Description": "<p>This course is the seventh and final course in the Apprentice Level 3 curriculum and covers CPVC Pipe &amp; Fittings.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "8f7b8489-c917-4a1f-8614-d77dfe949f6a",
                    "bd4fcb6f-4a78-4400-8b85-bd096f1e85df",
                    "0b172735-aba8-4191-bdd0-15634a684c59",
                    "e3bbdcae-b9db-4eef-acfb-85e647c832d4",
                    "3e5aa4ff-9c14-4b9c-b5f8-a5d4410b44dc",
                    "96ace90b-c3f5-4705-a674-8abb6b98d834",
                    "c5ecf20d-8254-48b8-9a31-8c71c428fb0f"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "2863666f-e643-4501-a3b2-ef3d0baec265",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-09-27T21:53:48.653",
                "DateAdded": "2022-02-04T04:28:16.25"
            },
            "5": {
                "Id": "c091907d-b349-420e-82af-8a77685ef52e",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-1 - Copper Tube Systems",
                "Description": "<p>This course is the first course in the Apprentice Level 4 curriculum and covers Copper Tube Systems.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "785870d2-5a9b-491b-9b47-bfca892b34e8",
                    "4f1d2230-0f7c-4533-9608-e6bfde0eb4e6",
                    "b633ff2b-eb5f-491b-9fa7-3667a84d02af"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:15:51.67",
                "DateAdded": "2022-05-16T22:04:29.15"
            }
        },
        "4": {
            "1": {
                "Id": "5e4965da-799f-4e9e-8c78-cc239d5d079c",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-2 - Underground Pipe Systems",
                "Description": "<p>This course is the second course in the Apprentice Level 4 curriculum and covers Underground Pipe Systems.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c051ba42-51f1-4cf4-baa1-4518b936a603",
                    "4f843a85-9931-49b8-aa5c-0a114c1a9f35",
                    "e84c2fb4-1293-46c8-837c-34e5c73551fe"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:16:34.947",
                "DateAdded": "2022-05-16T22:04:28.84"
            },
            "2": {
                "Id": "db7671e3-ed4b-4caf-8839-11821617abb3",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-3 - Hangers, Supports, Restraints and Guides.",
                "Description": "<p>This course is the third course in the Apprentice Level 4 curriculum and covers Hangers, Supports, Restraints and Guides.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "5dc64dee-98ea-4df7-92df-d1712296d82c",
                    "b368c50f-a7fa-4586-8351-fd0f8745f766",
                    "09bca1b4-e58e-4ac3-952f-6aefd611fced"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:17:08.667",
                "DateAdded": "2022-05-16T22:19:00.803"
            },
            "3": {
                "Id": "5a3d549c-0a74-4ed4-bbed-12ca73dee8a4",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-4 - General Purpose Valves",
                "Description": "<p>This course is the fourth course in the Apprentice Level 4 curriculum and covers General Purpose Valves.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "f9456c89-5add-46b4-a51a-34fabd81d3bb",
                    "4f68fab2-50c2-49ff-b4fa-5b19e499eb0d",
                    "28410088-9bd2-4625-a36a-701c8918ed68",
                    "c6d0dab6-57e8-4d63-af5f-792fa3f1c92e",
                    "2d9c91ec-5afa-4bf4-9666-13b917129a47"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:17:57.81",
                "DateAdded": "2022-05-16T22:18:30.5"
            },
            "4": {
                "Id": "5add6a1c-e379-47f7-aec7-6a5caf2a11e5",
                "CourseType": "OnlineCourse",
                "Name": "LVL4-5 - General Trade Math",
                "Description": "<p>This course is the fifth course in the Apprentice Level 4 curriculum and covers General Trade Math.&nbsp;&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "e4809ed0-941a-40b5-a2c5-147053feb3ed",
                    "4fd54015-a367-4ae7-b159-6b909fdf5cec",
                    "ad0363de-0225-4468-b68e-58b3f75fc9e0"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "10739227-696f-43cd-93a0-be2268c0bf0a",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-08-14T22:46:28.96",
                "DateAdded": "2022-05-16T22:19:00.697"
            },
            "5": {
                "Id": "9382f9e3-72e5-4c32-89a0-307a28004766",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-1 - Shop Drawings",
                "Description": "<p>This course is the first course in the Apprentice Level 5 curriculum and covers Shop Drawings.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "dcda53ee-f614-47dd-9552-2f8c681253e0",
                    "2976eeca-05a1-4c69-99df-04392b35606a",
                    "941df005-c2fa-4733-a00c-c755968a0ec8"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:18:58.883",
                "DateAdded": "2022-06-20T23:21:46.653"
            }
        },
        "5": {
            "1": {
                "Id": "6cc4f255-77c1-4997-a26d-3b072c9ad91f",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-2 - Standard Spray Sprinklers",
                "Description": "<p>This course is the second course in the Apprentice Level 5 curriculum and covers Standard Spray Sprinklers.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "ea259a1d-23d0-43d8-ae60-7e4d1efe0001",
                    "5e349d5d-1e41-456a-b6c7-904fe5e684ea",
                    "3088fb1f-f86a-47ee-9726-e8c7894e3db6",
                    "28afa2ae-08a8-4ff0-8ac4-de9893cb77b6",
                    "eb28f82f-9ee5-4655-943b-3a4be0ecaba1",
                    "16bd277e-ce6d-448d-92c6-b2e78a55e369",
                    "568fed27-a27e-4a2b-8763-1a1459eed01b"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:19:32.043",
                "DateAdded": "2022-06-20T23:21:32.503"
            },
            "2": {
                "Id": "03d1d5dc-3a50-463e-b409-423b6b742155",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-3 - Wet Fire Sprinkler Systems",
                "Description": "<p>This course is the third course in the Apprentice Level 5 curriculum and covers Wet Fire Sprinkler Systems.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "320897bf-3b10-436c-9c41-7a42498b11b5",
                    "9577532d-a0c9-4f5e-812a-e32b2f4b2a41",
                    "3d3172d3-c922-4431-ae95-45ed2a4e17e3",
                    "c0d0ee31-918f-4d05-b960-989a5a4cfe3c"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:19:59.647",
                "DateAdded": "2022-06-20T23:21:33.797"
            },
            "3": {
                "Id": "0158687a-aa4a-40e2-af7b-505a4ff3f2c8",
                "CourseType": "OnlineCourse",
                "Name": "LVL5-4 - Dry Pipe Systems",
                "Description": "<p>This course is the fourth course in the Apprentice Level 5 curriculum and covers Dry Pipe Systems.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "516465a9-880a-48b1-b20d-bd305b3b0ba7",
                    "ea58dba8-4926-48d1-9a5a-81ddbc2a077f",
                    "c3d69e57-373f-4d30-9a31-3f8d55e8e787",
                    "d00b09cf-94d9-4016-8db4-796459a38807",
                    "72911f2f-ccd1-49af-9f53-b46acd26cc15",
                    "f79e4cb0-99b8-4965-a4dc-57e92fec8e55"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4ab1701-33e2-4369-a937-74dfd8e2f0cb",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-05-09T21:45:53.817",
                "DateAdded": "2022-06-20T23:21:45.267"
            },
            "4": {
                "Id": "0f7d88ca-187c-4934-af60-802e93619d15",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-1 - Deluge/Preaction Systems",
                "Description": "<p>This course is the first course in the Apprentice Level 6 curriculum and covers Deluge/Preaction Systems.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "c1aeb518-a213-4b5b-bbd4-9d32cd99b7bb",
                    "771cf84f-f4b4-4f88-b2bf-7b267432cdc0",
                    "68ebecf8-27f4-4780-a25f-36b80e150fa0",
                    "3c2a8bcb-aeeb-4c1d-a734-f5b0b0fed500"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "48abe5c7-c012-482a-a7ea-7379176076ec",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2024-06-12T01:57:26.493",
                "DateAdded": "2022-06-20T23:21:33.883"
            },
            "5": {
                "Id": "d947996e-f13b-407b-a914-f6472dfa2027",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-2 - Standpipes (Tuberias Verticales)",
                "Description": "<p>Este curso es el segundo curso en el plan de estudios del nivel de aprendiz 6 y cubre las fuentes de agua.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "44c7db6e-819a-44fc-8dbd-14ef1718c5ae",
                    "e441dbce-8eb7-4d26-8ddb-28dfb1b856df",
                    "bd901db7-8be8-4c51-8205-6f6e8df0b628",
                    "7dba1c4f-c91f-47d8-9c57-f6885b29bc76",
                    "d501199e-b179-493f-8b12-e944c5578ffb",
                    "85dddb84-0b95-4b55-9c99-1ea00c644dbf"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "a4df3ce3-519a-4474-a8f1-62aa0a1397a0",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T22:16:05.747",
                "DateAdded": "2022-09-11T21:01:24.047"
            }
        },
        "6": {
            "1": {
                "Id": "aac89b01-5aa8-4dcc-9864-01987753da1a",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-3 - Water Supplies",
                "Description": "<p>This course is the third course in the Apprentice Level 6 curriculum and covers Water Supplies.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "bbd871e5-c663-4463-ae88-633c1826b0d7",
                    "6deff992-bc80-45f9-9b40-4b02a752f882",
                    "27c098bf-1c81-4ef3-ac89-a5b857f8ed49",
                    "fbc8052f-4ae6-4102-881f-7fb759a1b370",
                    "cbadd007-f6c4-46a8-a89c-a2a32e306eae",
                    "4ae98d0a-6e41-4b8a-8dea-b08ba0fa2a24"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "48abe5c7-c012-482a-a7ea-7379176076ec",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:22:20.22",
                "DateAdded": "2022-06-20T23:34:45.79"
            },
            "2": {
                "Id": "a4cda165-4a40-42b9-ba92-5506f596d9f9",
                "CourseType": "OnlineCourse",
                "Name": "LVL6-5 - Application Specific Sprinklers and Nozzles",
                "Description": "<p>This course is the fifth course in the Apprentice Level 6 curriculum and covers Application Specific Sprinklers and Nozzles</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "eae74797-db36-4772-8757-c0ae97915395",
                    "ebf71f37-8b73-4cea-bd4b-00c8629ea23d",
                    "4d70eea1-f3b5-4442-b449-8679f54ff115",
                    "5fd99bb0-d5df-43e4-b1ed-ec8250d711bd",
                    "ce2c2ff7-df97-441d-9dcf-3fc941373d77"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "48abe5c7-c012-482a-a7ea-7379176076ec",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:23:30.607",
                "DateAdded": "2022-06-20T23:34:36.973"
            },
            "3": {
                "Id": "39a69d53-71f7-40ed-b60f-6231d464b195",
                "CourseType": "OnlineCourse",
                "Name": "LVL7-1 - System Layout",
                "Description": "<p>This course is the first course in the Apprentice Level 7 curriculum and covers System Layout.</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "aed7d297-acf4-43fc-8811-df667f4e295a",
                    "36931e2e-4ed1-4fdd-8bb5-ae89c861021b",
                    "5ca28ead-3a66-4b76-a128-b1df74d32b4e"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "f094131b-ddb2-4e38-9932-a0d510de066f",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:24:03.803",
                "DateAdded": "2022-06-20T23:34:16.513"
            },
            "4": {
                "Id": "1d1089c9-d9df-41c5-8888-da99e6129676",
                "CourseType": "OnlineCourse",
                "Name": "LVL7-4 - Introductory Skills for the Foreman",
                "Description": "<p>This course is the fourth course in the Apprentice Level 7 curriculum and covers Introductory Skills for the Foreman.&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "80075404-107e-4f4a-96ab-03f1beefb471",
                    "48c7b202-7f80-4104-a4b3-b3eef90ebf66",
                    "43463424-2a74-4dfb-b7a8-b1aa8a4c95ee",
                    "43102385-a078-44b5-a7aa-acdd5b5168b7"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "f094131b-ddb2-4e38-9932-a0d510de066f",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:25:58.623",
                "DateAdded": "2022-06-20T23:34:37.197"
            },
            "5": {
                "Id": "62a8e3cd-42c2-4303-a06b-e8e03676141f",
                "CourseType": "OnlineCourse",
                "Name": "LVL7-5 - Procedures & Documentation",
                "Description": "<p>This course is the fifth course in the Apprentice Level 7 curriculum and covers Procedures &amp; Documentation.&nbsp;&nbsp;</p>",
                "Notes": null,
                "ExternalId": null,
                "AccessDate": null,
                "ExpireType": 0,
                "ExpireDuration": {
                    "Years": 0,
                    "Months": 0,
                    "Days": 0,
                    "Hours": 0
                },
                "ExpiryDate": null,
                "ActiveStatus": 0,
                "TagIds": [],
                "ResourceIds": [
                    "23cc4374-41a5-421a-bf6d-0bdccf838687",
                    "4be27736-7b70-4a53-9838-52fdb3f907af",
                    "49909557-0375-4470-9bb4-85073ebde8f0"
                ],
                "EditorIds": [],
                "Prices": [],
                "CompetencyDefinitionIds": [],
                "PrerequisiteCourseIds": [],
                "PostEnrollmentCourseIds": [],
                "AllowCourseEvaluation": false,
                "CategoryId": "f094131b-ddb2-4e38-9932-a0d510de066f",
                "CertificateUrl": null,
                "Audience": null,
                "Goals": null,
                "Vendor": null,
                "CompanyCost": null,
                "LearnerCost": null,
                "CompanyTime": null,
                "LearnerTime": null,
                "DateEdited": "2023-01-30T20:26:50.62",
                "DateAdded": "2022-06-20T23:34:18.503"
            }
        }
    },
    "bundled_enrollments": [
        {
            "id": "e7c21cdc-2675-47c0-8b1f-40bd1e6b08e9",
            "name": "1 decline",
            "learner_id": "55e863ce-504f-4030-9ce4-9d063a6fa81a",
            "rti_current": "1",
            "rti_overall": "2",
            "completed": 1,
            "assigned": 3,
            "levels": {
                "1": {
                    "1": {
                        "color": "#FFFF5B",
                        "credits": null,
                        "eval_period_start": "2023-02-16T00:00:00",
                        "eval_period_end": "2023-02-02T00:00:00"
                    }
                },
                "2": {
                    "3": {
                        "color": "#6f6fe8",
                        "credits": 7.5
                    }
                },
                "5": {
                    "3": {
                        "color": "#FFFF5B",
                        "credits": null
                    }
                },
                "6": {
                    "3": {
                        "color": "#FFFF5B",
                        "credits": null
                    }
                }
            },
            "current_level": 1
        },
        {
            "id": "799eb038-3cac-4dc4-a033-c13aedc8dfc8",
            "name": "thats a wrap",
            "learner_id": "f964f4ed-3338-4aab-9968-e10e3054a114",
            "current_level": 1,
            "rti_current": null,
            "rti_overall": null,
            "eval_period_start": null,
            "eval_period_end": null
        }
    ]
}

export { live_data, test_data }