import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import { toast } from 'react-toastify';
import { Validator, StringifyFields, ValidateExtionsions, validateDate } from '../exportcomponents/ValidatorExports';
import UserContext from "../context/UserContext"
import LangContext from '../context/LangContext';

import {
    IssuedToField,
    RefNumField,
    ClassificationField,
    RatingField,
    DateIssuedField,
    StateField,
    RegionField,
    NotesField,
    TrainerNameField,
    ReCertificationDateField,
    EmployeeIDField,
    EyeColorField,
    HairColorField,
    WeightField,
    HeightField,
    DateOfBirthField,
    LicenseNumberField,
    TypeField,
    ExpirationDateField
} from '../exportcomponents/FieldExports';

// const path = require('path');

const AddCertificate = ({ user, employee }) => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
	const [buttonDisable, setButtonDisable] = useState({});
    const [form, setForm] = useState({});
    const [selectedCert, setSelectedCert] = useState();
    const [currentImages, setCurrentImages] = useState([]);
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [fieldList, setFieldList] = useState([]);
    const [certList, setCertList] = useState([{
        id: "",
        name: "",
        reference_number: "",
        renewal_frequency: 0,
        credits_required: 0
    }]);

    const downloadPDF = (image) => {
        window.open(image)
    }

    const loadCertificates = async () => {
        let loadedCerts;
        await axios.get(`${process.env.REACT_APP_API_URL}/certification`)
            .then(data => {
                loadedCerts = data;
            });

        return loadedCerts.data;
    };

     
    const loadImages = async () => {
        let loadedImages;
        let imageIds = [];
        let user_id = user;
        if (employee) user_id = employee;
        await axios.get(`${process.env.REACT_APP_API_URL}/certification/images/${user_id}`)
            .then(data => {
                loadedImages = data;
            });

        // console.log(loadedImages.data)
        return loadedImages.data;
    }

    const loadFields = async (cert_id, is_issued) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/certification/fields/${cert_id}`);
        // console.log([...data]); 
        // console.log(is_issued);
        setFieldList([...data]);
        if (is_issued == true || is_issued == "true") {
            const certObject = certList.filter(cert_row => cert_row.id == cert_id);
            let newForm = {}
            data.forEach(field => {
                newForm[field] = certObject[0][field]
            })
            setForm({
                ...newForm
            })
        } else {
            setForm({});
        }
    }

    const loadCertsIssued = async (rawCertList) => {
        // console.log("TRANSLATION: THERE'S A MAD MAN IN OUR MIDST\n", user || employee)
        // Get the certifications held for the user being edited by the super
        const certsIssued = await axios.get(`${process.env.REACT_APP_API_URL}/account/individual/cert/${user || employee}?expand=true`);

        const issuedMap = certsIssued.data.map(issuedRow => { return issuedRow.id })

        // For each certification in the array of certs, append a boolean issued attribute
        for (var cert in rawCertList) {
            let isIssued = issuedMap.indexOf(rawCertList[cert].id) > -1 ? true : false
            rawCertList[cert] = {
                ...rawCertList[cert],
                issued: isIssued
            }
            if (isIssued) {
                rawCertList[cert] = {
                    ...rawCertList[cert],
                    ...certsIssued.data[issuedMap.indexOf(rawCertList[cert].id)]
                }
            }
        }
        return rawCertList;
    }

    const onCertChange = (e) => {
        // console.log(e.target.value);
        // console.log(selectedCert);

        const cert_id = e.target.value.split(',')[0];
        const is_selected = e.target.value.split(',')[1];
        const cert_name = e.target.value.split(',')[2];
        // console.log(cert_name)
        setSelectedCert(cert_id);
        loadFields(cert_id, is_selected);
    };

    const onFrontImage = (e) => {
        // console.log(e.target.files[0]);
        setFrontImage(e.target.files[0]);
    };

    const onBackImage = (e) => {
        setBackImage(e.target.files[0]);
    }

    function onFieldChange(e) {
        // console.log(form)
        // console.log(`${e.target.name}: ${e.target.value}`);
        let editForm = form;
        editForm[e.target.name] = e.target.value;
        // console.log(editForm);
        setForm({ ...form, account_id: user || employee });
        // console.log(form)
    }

    function showEdit() {
        let selectedCertData = certList.filter(filter_cert => { return filter_cert.id == selectedCert });
        if (selectedCertData.length > 0) {
            if (selectedCertData[0].issued == true) {
                return true;
            }
        }
        return false;
    }

    function refreshPage() {
        if (user) {
            // navigate('/');
            // navigate('/super');
        } else if (employee) {
            navigate('/');
            navigate('/admin');
        } else {
            navigate('/');
            navigate('/licenses');
        }
    }

    const submit = async (e) => {
        e.preventDefault();

        setButtonDisable({ submit: true });
        setTimeout(() => setButtonDisable({ submit: false }), 2000);
        
        try {
            if (!frontImage) {
                toast.error(langData.toast.one_cert_image_required);
                refreshPage();
                return;
            }

            let front_ext_valid = true;
            let back_ext_valid = true;
            
            const stringy_form = StringifyFields(form);
            // console.log(stringy_form)

            // console.log(frontImage.name)
            front_ext_valid = ValidateExtionsions(frontImage.name, ['jpg', 'jpeg', 'png']);
            if (backImage) {
                back_ext_valid = ValidateExtionsions(backImage.name, ['jpg', 'jpeg', 'png']);
            }
            const valid = Validator(stringy_form, fieldList);

            if (!front_ext_valid || !back_ext_valid) {
                return toast.error(langData.toast.double_check_file_ext);
            }

            if (valid.length == 0) {
                const frontData = new FormData();
                frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`);
                frontData.append('account_id', user || employee);
                frontData.append('certification_id', selectedCert);
                // console.log(frontImage);
                try {
                    let held = null;
                    if(user) { 
                        await axios.post(`${process.env.REACT_APP_API_URL}/certification/super/holder/${user}/${selectedCert}`, stringy_form)
                        .then((res) => {
                            held = res.data.held;
                        }).catch(err => {
                            held = 'Something went wrong, please double check all inputs and try again';
                        });

                        
                        if (held != null) {
                            return toast.error(held);
                        }
                        await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                            headers: { 'content-type': 'multipart/form-data' }
                        });
                        if (backImage != null) {
                            const backData = new FormData();
                            backData.append('image', backImage, `${Date.now()}-${backImage.name}`);
                            backData.append('account_id', user || employee);
                            backData.append('certification_id', selectedCert);
                            await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, backData, {
                                headers: { 'content-type': 'multipart/form-data' }
                            });
                        }
                        toast.success("Certification added to user!");
                        refreshPage();

                    } else {
                        // console.log('IT IS DONE!!!')
                        let user_id = "";
                        if (employee) {
                            user_id = employee;
                        } else {
                            user_id = userData.userId;
                        }
                        await axios.post(`${process.env.REACT_APP_API_URL}/certification/holder/${user_id}/${selectedCert}`, stringy_form)
                        .then((res) => {
                            held = res.data.held;
                        }).catch(err => {
                            held = 'Something went wrong, please double check all inputs and try again';
                        });

                        // console.log(held)
                        
                        if (held != null && held != undefined) {
                            return toast.error(held);
                        }

                        await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
                            headers: { 'content-type': 'multipart/form-data' }
                        });

                        // console.log('we gotta goooo')
                        if (backImage != null) {
                            const backData = new FormData();
                            backData.append('image', backImage, `${Date.now()}-${backImage.name}`);
                            backData.append('account_id', user);
                            backData.append('certification_id', selectedCert);
                            await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, backData, {
                                headers: { 'content-type': 'multipart/form-data' }
                            });
                        }
                        toast.success(langData.toast.cert_request_sent);
                        refreshPage();
                    }
                    
                } catch (err) {
                    // console.log(err);
                    toast.error(langData.toast.double_check_inputs);
                }                
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
        } catch (err) {
            // console.log(err);
            toast.error(err.message);
            refreshPage();
        }
    };

    const onSubmitEdit = async (e) => {
        e.preventDefault();
        
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/certification/edit/cert/${user}`, form );

            toast.success("Certification Edited!");
            refreshPage();
        } catch(e) {
            // console.log(e)
        }

        // try {
            

        //     const cardId = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/card/${user}`)
        //     const card = cardId.data.card_id

        //         try {
        //             let front_url_string = ''
        //             let back_url_string = ''
        //             if(frontImage || backImage) {
        //                 if(frontImage) {
    
        //                     const frontData = new FormData();
        //                     frontData.append('image', frontImage, `${Date.now()}-${frontImage.name}`)
        //                     frontData.append('account_id', user);
        //                     const frontImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, frontData, {
        //                         headers: { 'content-type': 'multipart/form-data' }
        //                     });
                            
        //                     front_url_string = frontImageData.data.downloadURL;
        //                     // console.log({img_name: frontImage.name, img_url: front_url_string })

        //                     await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/images/${user}/${imagesInfo[0].image_id}`, { img_name: frontImage.name, img_url: front_url_string })
        //                 }
                        
        //                 if(backImage) {
                                
        //                     const backData = new FormData();
        //                     backData.append('image', backImage, `${Date.now()}-${backImage.name}`)
        //                     backData.append('account_id', user);
        //                     const backImageData = await axios.post(`${process.env.REACT_APP_API_URL}/firestore/image`, backData, {
        //                         headers: { 'content-type': 'multipart/form-data' }
        //                     });

        //                     back_url_string = backImageData.data.downloadURL;
        //                     // console.log({img_name: backImage.name, img_url: back_url_string })
                            
        //                     await axios.put(`${process.env.REACT_APP_API_URL}/calfire/edit/images/${user}/${imagesInfo[1].image_id}`, { img_name: backImage.name, img_url: back_url_string })
        //                 }
        //             }

        //             refreshPage();
        //             toast.success("Cal Fire Card Edited!");
        //         } catch (err) {
        //             // console.log(err);
        //             toast.error("Must Upload Two Pictures!");
        //         }      
        // } catch(err) {
        //     // console.log(err)
        // }
        
    }

    useEffect(() => {
        if (user || employee) {
            let user_id = user;
            if (employee) {
                user_id = employee;
            }
            // console.log('I AM THE USER HEAR ME RAAAAOOOORRAARR', user_id)
            loadCertificates()
                .then(data => {
                    if (user_id) {
                        let certData = loadCertsIssued(data)
                            .then(appendedData => {
                                // console.log(appendedData)
                                setCertList(appendedData);
                            })
                    } else {
                        setCertList(data)
                        if(data[0].id) {
                            setSelectedCert(data[0].id);
                            loadFields(data[0]);
                        }
                    }
                });
            
            loadImages()
                .then(data => {
                    setCurrentImages(data)
                }
            )
        }
    }, [user, showEdit()]);

    return (
        <div>
            <form className="form-background" onSubmit={showEdit() ? onSubmitEdit : submit} encType="multipart/form-data" style={{ height: 'auto', maxHeight: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                <div className="form-group">
                    <strong htmlFor="certificate">{langData.add_cert.enter_cert_header}</strong>
                    <select onChange={onCertChange} className="form-select mb-2" aria-label="Default select example">
                        <option>{langData.add_cert.select_cert}</option>
                        {certList.map((cert, i) => {
                            return (
                                <option style={ cert.issued ? { background: "#198754", color: "white" } : { color: "black" }} value={[cert.id, cert.issued]} key={i}>{cert.name}</option>
                            )
                        })}
                    </select>
                    <p>{langData.add_cert.cert_not_listed}</p>
                </div>
                <div className="inline-forms">
                    <div className="render-fields">
                        {!fieldList.includes("name") ? null :
                            <div>
                                <h3>{langData.add_cert.name}</h3>
                                <IssuedToField onChange={onFieldChange} preFilled={form.name} />
                            </div>
                        }
                        {!fieldList.includes("reference_number") ? null :
                            <div>
                                <h3>{langData.add_cert.ref_num}</h3>
                                <RefNumField onChange={onFieldChange} preFilled={form.reference_number} />
                            </div>
                        }
                        {!fieldList.includes("classification") ? null :
                            <div>
                                <h3>{langData.add_cert.classification}</h3>
                                <ClassificationField onChange={onFieldChange} preFilled={form.classification} />
                            </div>
                        }
                        {!fieldList.includes("rating") ? null :
                            <div>
                                <h3>{langData.add_cert.rating}</h3>
                                <RatingField onChange={onFieldChange} preFilled={form.rating} />
                            </div>
                        }
                        {!fieldList.includes("issued_date") ? null :
                            <div>
                                <h3>{langData.add_cert.issued_date}</h3>
                                <DateIssuedField onChange={onFieldChange} preFilled={form.issued_date} />
                            </div>
                        }
                        {!fieldList.includes("state") ? null :
                            <div>
                                <h3>{langData.add_cert.state}</h3>
                                <StateField onChange={onFieldChange} preFilled={form.state} />
                            </div>
                        }
                        {!fieldList.includes("region") ? null :
                            <div>
                                <h3>{langData.add_cert.region}</h3>
                                <RegionField onChange={onFieldChange} preFilled={form.region} />
                            </div>
                        }
                        {!fieldList.includes("notes") ? null :
                            <div>
                                <h3>{langData.add_cert.notes}</h3>
                                <NotesField onChange={onFieldChange} preFilled={form.notes} />
                            </div>
                        }
                        {!fieldList.includes("trainer_name") ? null :
                            <div>
                                <h3>{langData.add_cert.trainer_name}</h3>
                                <TrainerNameField onChange={onFieldChange} preFilled={form.trainer_name} />
                            </div>
                        }
                        {!fieldList.includes("re_certification_date") ? null :
                            <div>
                                <h3>{langData.add_cert.re_certification_date}</h3>
                                <ReCertificationDateField onChange={onFieldChange} preFilled={form.re_certification_date} />
                            </div>
                        }
                        {!fieldList.includes("employee_id") ? null :
                            <div>
                                <h3>{langData.add_cert.employee_id}</h3>
                                <EmployeeIDField onChange={onFieldChange} preFilled={form.employee_id} />
                            </div>
                        }
                        {!fieldList.includes("eye_color") ? null :
                            <div>
                                <h3>{langData.add_cert.eye_color}</h3>
                                <EyeColorField onChange={onFieldChange} preFilled={form.eye_color} />
                            </div>
                        }
                        {!fieldList.includes("hair_color") ? null :
                            <div>
                                <h3>{langData.add_cert.hair_color}</h3>
                                <HairColorField onChange={onFieldChange} preFilled={form.hair_color} />
                            </div>
                        }
                        {!fieldList.includes("weight") ? null :
                            <div>
                                <h3>{langData.add_cert.weight}</h3>
                                <WeightField onChange={onFieldChange} preFilled={form.weight} />
                            </div>
                        }
                        {!fieldList.includes("height") ? null :
                            <div>
                                <h3>{langData.add_cert.height}</h3>
                                <HeightField onChange={onFieldChange} preFilled={form.height} />
                            </div>
                        }
                        {!fieldList.includes("birth_date") ? null :
                            <div>
                                <h3>{langData.add_cert.birth_date}</h3>
                                <DateOfBirthField onChange={onFieldChange} preFilled={form.birth_date} />
                            </div>
                        }
                        {!fieldList.includes("license_number") ? null :
                            <div>
                                <h3>{langData.add_cert.license_number}</h3>
                                <LicenseNumberField onChange={onFieldChange} preFilled={form.license_number} />
                            </div>
                        }
                        {!fieldList.includes("type") ? null :
                            <div>
                                <h3>{langData.add_cert.type}</h3>
                                <TypeField onChange={onFieldChange} preFilled={form.type} />
                            </div>
                        }
                        {!fieldList.includes("expiration_date") ? null :
                            <div>
                                <h3>{langData.add_cert.expiration_date}</h3>
                                <ExpirationDateField onChange={onFieldChange} preFilled={form.expiration_date} />
                            </div>
                        }
                    </div>
                </div>
                {showEdit() ? (
                    <div>
                        <strong>{langData.add_cert.image_header}</strong>
                        <div>
                                 
                            <p>{langData.add_cert.image_front}</p>
                            <input 
                                className="btn btn-outline"
                                type="file" 
                                onChange={onFrontImage} />
                            {currentImages[0] && 
                                <button className="btn btn-primary ml-3" onClick={() => downloadPDF(currentImages[0].img_url)}>
                                    View Image
                                </button>
                            }  
                        </div>
                        <div>
                            <p>{langData.add_cert.image_back}</p>
                            <input 
                                className="btn btn-outline"
                                type="file" 
                                onChange={onBackImage} />
                            {currentImages[1] && 
                                <button className="btn btn-primary ml-3" onClick={() => downloadPDF(currentImages[1].img_url)}>
                                    View Image
                                </button>
                            } 
                        </div>
                    </div>
                ) : (
                    <div>
                        <strong>{langData.add_cert.image_header}</strong>
                        <div>
                            <p>{langData.add_cert.image_front}</p>
                            <input 
                                className="btn btn-outline"
                                type="file" 
                                onChange={onFrontImage} />
                        </div>
                        <div>
                            <p>{langData.add_cert.image_back}</p>
                            <input 
                                className="btn btn-outline"
                                type="file" 
                                onChange={onBackImage} />
                        </div>
                    </div>
                )}
                
                <div>
                    {showEdit() ? (
                        <button disabled={buttonDisable.submit} type="submit" className="btn btn-outline-warning submit mt-2">
                            Save Edits
                        </button>
                    ) : (
                        <button disabled={buttonDisable.submit} type="submit" className="btn btn-primary submit mt-2">
                            {langData.add_cert.submit}
                        </button>
                    )}
                </div>
            </form>
        </div>
    )
}

export default AddCertificate
