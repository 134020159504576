import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../../context/UserContext';
import LangContext from '../../context/LangContext';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import states from '../Auth/states.json'
import axios from 'axios';
import "./profile.css";


const EditProfile = ({ user, cancelCallback }) => {
    const [form, setForm] = useState({});
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [adminEmail, setAdminEmail] = useState()
    const navigate = useNavigate()

    const onChange = (e) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            // console.log(form);
            if (form.email) {
                const email_is_unique = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-email-duplicate`, { email: form.email });
                // console.log(email_is_unique);
                if (email_is_unique.data) {
                    return toast.error(langData.toast.email_in_use);
                }
            }
            if (userData.user.isSuper || userData.user.isAdmin) {
                // console.log("super");
                await axios.put(`${process.env.REACT_APP_API_URL}/account/super/${userData.userId}`, form)
                navigate("/");
                navigate(`/profile/${userData.userId}`);
                toast.success(langData.toast.profile_changes_success);
            }
            else if (userData.user.isIndividual) {
                // console.log("individual");
                await axios.put(`${process.env.REACT_APP_API_URL}/account/individual/${userData.userId}`, form)
                navigate("/")
                navigate(`/profile/${userData.userId}`)
                toast.success(langData.toast.profile_changed);
            }
            else {
                // console.log("employee");
                await axios.put(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`, {admin_email: adminEmail,  ...form});
                toast.success(langData.toast.profile_changes_sent);
            }

        } catch (err) {
            toast.error(err.message.data);
        }
    };

    async function asyncSetupEditProfile() {
        const accountInfo = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`)

        setAdminEmail(accountInfo.data.admin_email)
    }
    
    useEffect(() => {
        asyncSetupEditProfile()
    }, [])

    return (
            <>
              <tr className='profile-inline-row'>
                <td className="table-item">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.id}
                    </b>
                    <input
                        className="profile-input"
                        disabled={true}
                        placeholder={user.shorthand || user.id}
                    />
                  </div>
                </td>
                <td className="table-item desktop-only">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.email}
                    </b>
                    <input
                        type="email"
                        name="email"
                        onChange={onChange}
                        className="profile-input"
                        placeholder={user.email || 'email'}
                    />
                  </div>
                </td>
                <td className="table-item">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.account_type}
                    </b>
                    <input
                        disabled={true}
                        className="profile-input"
                        placeholder={`${user.admin_email ? 'Employee' : 'Individual'} ${user.classification}`}
                    />
                  </div>
                </td>
              </tr>
              <tr className='profile-inline-row'>
                <td className='table-item mobile-only'>
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.email}
                    </b>
                    <input
                        type="email"
                        name="email"
                        onChange={onChange}
                        className="profile-input"
                        placeholder={user.email || 'email'}
                    />
                  </div>
                </td>
                <td className="table-item desktop-only">
                  <div className='d-flex'>
                    <div
                      className="d-block double-name">
                      <b>
                        {langData.profile.first_name}
                      </b>
                      <input
                          className="profile-input"
                          name="first_name"
                          onChange={onChange}
                          placeholder={user.first_name || 'first name'}
                      />
                    </div>
                    <div
                      className="d-block double-name">
                      <b>
                        {langData.profile.last_name}
                      </b>
                      <input
                          className="profile-input"
                          name="last_name"
                          onChange={onChange}
                          placeholder={user.last_name || 'last name'}
                      />
                    </div>
                  </div>
                </td>
                <td className="table-item desktop-only">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.phone}
                    </b>
                    <input
                        className="profile-input"
                        name="phone"
                        onChange={onChange}
                        placeholder={user.phone || 'phone'} />
                  </div>
                </td>
                <td className="table-item">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.joined}
                    </b>
                    <input
                        disabled={true}
                        className="profile-input"
                        placeholder={user.date_joined}
                    />
                  </div>
                </td>
              </tr>
              <tr className='profile-mobile-row'>
                <td className='table-item'>
                  <div className='d-flex'>
                    <div
                      className="d-block pr-1">
                      <b>
                        {langData.profile.first_name}
                      </b>
                      <input
                          className="profile-input double-name"
                          name="first_name"
                          onChange={onChange}
                          placeholder={user.first_name || 'first name'}
                      />
                    </div>
                    <div
                      className="d-block">
                      <b>
                        {langData.profile.last_name}
                      </b>
                      <input
                          className="profile-input double-name"
                          name="last_name"
                          onChange={onChange}
                          placeholder={user.last_name || 'last name'}
                      />
                    </div>
                  </div>
                </td>
                <td className='table-item'>
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.phone}
                    </b>
                    <input
                        className="profile-input"
                        name="phone"
                        onChange={onChange}
                        placeholder={user.phone || 'phone'} />
                  </div>
                </td>
              </tr>
              <tr className='profile-inline-row'>
                <td className="table-item">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.address}
                    </b>
                    <table className='profile-input address'>
                      <tbody>
                        <tr>
                          <td colSpan="4">
                            <input
                              name="address_1"
                              onChange={onChange}
                              className='address-input'
                              placeholder={user.address_1 || 'Adr 1'} />
                          </td>
                            <td className="address-mobile-collapse">
                              <input
                                className='address-input'
                                name="address_2"
                                onChange={onChange}
                                placeholder={user.address_2 || 'Adr 2'} />
                            </td>
                        </tr>
                        <tr className='address-mobile-row'>
                          <td colSpan='2'>
                            <input
                              className='address-input narrow'
                              name="address_2"
                              onChange={onChange}
                              placeholder={user.address_2 || 'Adr 2'} />
                          </td>
                          <td colSpan='3'>
                            <input
                              className='address-input'
                              name="city"
                              onChange={onChange}
                              placeholder={user.city || 'city'} />
                          </td>
                        </tr>
                        <tr className='address-mobile-collapse'>
                          <td colSpan='2'>
                            <input
                              className='address-input'
                              name="city"
                              onChange={onChange}
                              placeholder={user.city || 'city'} />
                          </td>
                          <td colSpan="2">
                            <select
                              className='address-input state-select'
                              name="state"
                              onChange={onChange}
                              placeholder={user.state || 'state'}
                              >
                              {states.map(({name, abbreviation}) => (
                                <option
                                value={abbreviation}>
                                  {abbreviation}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              name="zip"
                              onChange={onChange}
                              className='address-input narrow'
                              placeholder={user.zip || 'zipcode'} />
                          </td>
                        </tr>
                        <tr className='address-mobile-row'>
                          <td>
                            <input
                              name="zip"
                              onChange={onChange}
                              className='address-input narrow'
                              placeholder={user.zip || 'zipcode'} />
                          </td>
                          <td colSpan="2">
                            <select
                              className='address-input state-select'
                              name="state"
                              onChange={onChange}
                              placeholder={user.state || 'state'}
                              >
                              {states.map(({name, abbreviation}) => (
                                <option
                                value={abbreviation}>
                                  {abbreviation}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td className="table-item">
                  <div
                    className="d-grid">
                    <b>
                      {langData.profile.language}
                    </b>
                    <select 
                      className='profile-input'
                      name="language" 
                      value={form.language || user.language}
                      onChange={onChange}>
                      <option value="English">
                        {langData.profile.english}
                      </option>
                      <option value="Spanish">
                        {langData.profile.spanish}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="table-item" colSpan="3">
                  <button
                    onClick={submit}
                    className="profile-button">
                    {langData.profile.save}
                  </button>
                  <button
                    onClick={() => cancelCallback()}
                    className="profile-button danger">
                    {langData.profile.cancel}
                  </button>
                </td>
              </tr>
              {/* <form className="form-background" onSubmit={submit} style={{ overflowY: 'scroll', height: '500px' }}>
                  <div className="form-group">
                      <label htmlFor="name">{langData.edit_profile.first_name}</label>
                      <input
                          type="first_name"
                          className="form-control"
                          name="first_name"
                          onChange={onChange}
                          placeholder={user.first_name}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="name">{langData.edit_profile.last_name}</label>
                      <input
                          type="last_name"
                          className="form-control"
                          name="last_name"
                          onChange={onChange}
                          placeholder={user.last_name}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{langData.edit_profile.address_1}</label>
                      <input
                          type="address_1"
                          className="form-control"
                          name="address_1"
                          onChange={onChange}
                          placeholder={user.address_1}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{langData.edit_profile.address_2}</label>
                      <input
                          type="address_2"
                          className="form-control"
                          name="address_2"
                          onChange={onChange}
                          placeholder={user.address_2}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{langData.edit_profile.city}</label>
                      <input
                          type="city"
                          className="form-control"
                          name="city"
                          onChange={onChange}
                          placeholder={user.city}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{langData.edit_profile.state}</label>
                      <input
                          type="state"
                          className="form-control"
                          name="state"
                          onChange={onChange}
                          placeholder={user.state}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{langData.edit_profile.zip}</label>
                      <input
                          type="zip"
                          className="form-control"
                          name="zip"
                          onChange={onChange}
                          placeholder={user.zip}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="displayName">{langData.edit_profile.language}</label>
                      <select
                          className="form-select"
                          name="language"
                          aria-label="Default select example"
                          onChange={onChange}
                      >
                          <option value="English">{langData.edit_profile.english}</option>
                          <option value="Spanish">{langData.edit_profile.spanish}</option>
                      </select>
                  </div>
                  <div className="form-group">
                      <label htmlFor="credits_required">{langData.edit_profile.phone}</label>
                      <input
                          type="phone"
                          className="form-control"
                          name="phone"
                          onChange={onChange}
                          placeholder={user.phone}
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="credits_required">{langData.edit_profile.email}</label>
                      <input
                          type="email"
                          className="form-control"
                          name="email"
                          onChange={onChange}
                          placeholder={user.email}
                      />
                  </div>
                  <div
                    className="d-grid">
                      <button type="submit" className="btn btn-primary submit mt-2">
                          {langData.edit_profile.submit}
                      </button>
                  </div>
              </form> */}
            </>
    )
}

export default EditProfile
