import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import Popup from "reactjs-popup";
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import UnpaidAccount from './UnpaidAccount';
import SuperResetPassword from '../components/SuperResetPassword'

import { ElevateAdmin, SuperCreateCustomTokens } from '../exportcomponents/ApprenticeExports';
import EditAdminProfile from './EditAdminProfile';
import {
    UserInfo,
    EditQuotePricing,
} from '../exportcomponents/PopupExports';
import { collapsedStyle } from '../exportcomponents/StyleExports'
import { writeExcel } from '../services/excel';

const AdminList = ({ onLastClicked }) => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const navigate = useNavigate()
    const [adminListSearch, setAdminListSearch] = useState("")
    const [admins, setAdmins] = useState([])
    const [superEmail, setSuperEmail] = useState("");
    const [adminTab, setAdminTab] = useState()
    const [selectedAdmin, setSelectedAdmin] = useState({})

    const adminList = adminListSearch == ''
        ? admins
        : admins.filter(admin => {
            return admin.email.toString().toLowerCase().includes(adminListSearch.toLowerCase()) ||
                (admin.first_name.toString() + " " + admin.last_name.toString()).toLowerCase().includes(adminListSearch.toLowerCase()) ||
                admin.admin_email.toString().toLowerCase().includes(adminListSearch.toLowerCase()) ||
                admin.business_name.toString().toLowerCase().includes(adminListSearch.toLowerCase())
        })


    const moveAdminToHistory = async (id) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/account/admin/history/${id}`);

        // navigate('/')
        // navigate('/super')
        toast.success("Account Deactivated");
    }

    const downloadCTPs = async () => {
        console.log("downloadCTPs: ", admins)
        const cleaned_admins = admins.map(({ 
            id,
            password,
            classification,
            employee_count,
            lookup_id,
            elevated,
            paid,
            business_name,
            account_id,
            billing_frequency,
            admin_email,
            first_name,
            last_name,
            ...admin 
        }) => ({ business_name, name: `${first_name} ${last_name}`, ...admin, payment_failed: !paid ? 'failed' : '', apprentice_partner: elevated ? 'yes' : 'no' }))

        writeExcel(cleaned_admins, 'CTP-Export')
    }

    async function asyncSetupAdminList() {
        try {
            const admin_list = await axios.get(`${process.env.REACT_APP_API_URL}/account/admins`)
            console.log("This'll be the day that I diiiiiiie: ", admin_list)
            setAdmins(admin_list.data)

        } catch (err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if (!userData.user) return navigate("/");
        setSuperEmail(userData.user.login);
        asyncSetupAdminList()
    }, []);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                <h3 className='h3 text-secondary'>{langData.super_functionality.admins}</h3>
                <div className='d-flex justify-content-end'>
                    <div className="input-group rounded mr-2">
                        <input
                            onChange={(e) => setAdminListSearch(e.target.value)}
                            type="search"
                            className="form-control rounded"
                            placeholder={langData.placeholders.search}
                            aria-label="Search"
                            aria-describedby="search-addon"
                            value={adminListSearch}
                        />
                        <span className="input-group-text border-0" id="search-addon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </span>
                    </div>
                    <div className="tab-title apprentice rounded">
                        <h1>{adminList.length}</h1>
                    </div>

                    <span onClick={(e) => asyncSetupAdminList()} className="input-group-text rounded border-0 ml-3">
                        <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z" /></g>
                        </svg>
                    </span>
                    <span onClick={(e) => downloadCTPs()} className="input-group-text rounded border-0 ml-3">
                        <svg width="1.2rem" height="1.2rem" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <g><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></g>
                        </svg>
                    </span>
                </div>
            </div>
            <div className='min-vh-50'>
                {adminList && adminList.map((admin, i) => {
                    return (
                        <div key={i} className={`card card-body mt-3 ${selectedAdmin.id == admin.id && 'select-highlight'}`} onClick={() => setSelectedAdmin(admin)}>
                            {admin.paid === false && (<UnpaidAccount account_id={admin.id} />)}
                            <div className='row'>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.name}
                                    </strong>
                                    <p>
                                        {admin.first_name + " " + admin.last_name}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.email}
                                    </strong>
                                    <p>
                                        {admin.email}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.billing_frequency}
                                    </strong>
                                    <p>
                                        {!admin.tokens | admin.tokens == 0 ? 'No Tokens' : admin.tokens}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.company}
                                    </strong>
                                    <p>
                                        {admin.admin_email} | {admin.business_name}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.has_apprentice}
                                    </strong>
                                    <p>
                                        {admin.elevated ? 'Yes' : 'No'}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.fitter_count}
                                    </strong>
                                    <p>
                                        {admin.employee_count.fitter}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.apprentice_count}
                                    </strong>
                                    <p>
                                        {admin.employee_count.act_apprentice}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='d-flex justify-content-center position-sticky bottom-0'>
                <div className='opaque p-3 w-75 d-flex flex-wrap justify-content-evenly align-items-center'>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">Change Password</button>}
                    >
                        <SuperResetPassword
                            email={selectedAdmin.email}
                        />
                    </Popup>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.info}</button>}
                    >
                        <UserInfo
                            id={selectedAdmin.id}
                        />
                    </Popup>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.edit_admin}</button>}
                    >
                        <EditAdminProfile
                            id={selectedAdmin.id}
                        />
                    </Popup>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.admin_options}</button>}
                    >
                        <ElevateAdmin
                            id={selectedAdmin.id}
                        />
                    </Popup>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.custom_priced_tokens}</button>}
                    >
                        <SuperCreateCustomTokens
                            id={selectedAdmin.id}
                        />
                    </Popup>
                    {/* <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.edit_pricing_structure}</button>}
                    >
                        <EditQuotePricing 
                            id={selectedAdmin.id}
                        />
                    </Popup> */}
                    <button onClick={() => moveAdminToHistory(selectedAdmin.id)} className="text-nowrap m-1">{langData.super_functionality.move_all_history}</button>
                </div>
            </div>
        </div>
    )
}

export default AdminList
