import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import './apprenticeReport.css'

const SELECT_LIMIT = 10

const ApprenticeReports = () => {
    const [disable, setDisable] = useState(false)
    const [employers, setEmployers] = useState([])
    const [search, setSearch] = useState('')
    const [effectiveDate, setEffectiveDate] = useState('')
    const [selectedFilter, setSelectedFilter] = useState(null)

    async function sendReports() {
        if (!effectiveDate) return toast.warning("Please enter an 'Effective Date' before sending.")
        try {
            toast.success("Emailing apprentice reports, may take some time...")
            setDisable(true)
            await axios.post(`${process.env.REACT_APP_API_URL}/automation/send-apprentice-reports`, { combined: employers, effective_date: effectiveDate })
            toast.success("Apprentice reports sent successfully!")
            await asyncApprenticeReportSetup()
            setDisable(false)
        } catch (err) {
            console.error(err)
            setDisable(false)
            toast.error("Something went wrong, please try again later...")
        }
    }

    function onCtpCheck(e, ctp) {
        console.log("onCtpCheck: ", e.target.checked, ctp)
        if (ctp.all_reports_sent) {
            toast.warning('All monthly reports have already been sent for this CTP.')
            return e.target.checked = false
        }
        if (selectedLimitReached(e)) {
            toast.error(`Limit of ${SELECT_LIMIT} selections reached.`)
            return e.target.checked = false
        }
        
        const employer_index = employers.findIndex(({ business_number }) => ctp.business_number == business_number)
        let new_employers = [...employers]
        
        console.log(employer_index)
        // console.log(new_employers[employer_index])
        new_employers[employer_index].selected = e.target.checked
        new_employers[employer_index].apprentices
            .filter(({ report_sent }) => !report_sent)
            .slice(0, e.target.checked ? SELECT_LIMIT - selectedCount() : SELECT_LIMIT)
            .forEach(apprentice => apprentice.selected = e.target.checked)
        setEmployers(new_employers)
    }

    function onApprenticeCheck(e, apprentice) {
        console.log("onApprenticeCheck: ", e.target.checked, apprentice)
        if (apprentice.report_sent) {
            toast.warning("This apprentice's monthly report has already been sent.")
            return e.target.checked = false
        }
        if (selectedLimitReached(e)) {
            toast.error(`Limit of ${SELECT_LIMIT} selections reached.`)
            return e.target.checked = false
        }

        const employer_index = employers.findIndex(({ business_number }) => apprentice.admin_email == business_number)
        const apprentice_index = employers[employer_index].apprentices.findIndex(({ id }) => apprentice.id == id)
        let new_employers = [...employers]

        new_employers[employer_index].apprentices[apprentice_index].selected = e.target.checked
        if (new_employers[employer_index].apprentices.some(({ selected }) => selected)) new_employers[employer_index].selected = true
        else new_employers[employer_index].selected = false
        setEmployers(new_employers)
    }

    function selectedLimitReached(e) {
        return e.target.checked && selectedCount() >= SELECT_LIMIT
    }

    function selectedCount() {
        return employers.flatMap(({ apprentices }) => apprentices.filter(app => app.selected)).length
    }

    function onEmployerFilterSelect(e) {
        setSelectedFilter(e.target.value)
    }

    function onSearchChange(e) {
        setSearch(e.target.value)
    }

    function onEffectiveDateChange(e) {
        setEffectiveDate(e.target.value)
    }

    async function asyncApprenticeReportSetup() {
        const { data: employer_data } = await axios.get(`${process.env.REACT_APP_API_URL}/automation/report-apprentices`)
        setEmployers(employer_data.sort((a, b) => b.apprentices.length - a.apprentices.length))
        console.log("EMPLOYERS: ", employer_data)
    }

    useEffect(() => { asyncApprenticeReportSetup() }, [])
    return (
        <div className='form-background extra-wide-popup h-75 popup-scrollable'>
            <div className='d-flex justify-content-between align-items-center'>
                <h2 className="h2 mb-3">Progress Reports</h2>
                <button disabled={disable} onClick={sendReports} className='w-min'>Send</button>
            </div>
            <div className='d-flex mt-5 mb-3'>
                <input onChange={onEffectiveDateChange} className='w-100 form-control rounded' placeholder="Enter an Effective Date... (required)"/>
                <span className="input-group-text rounded border-0" id="search-addon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="16" height="16" fill="#000000"><g id="calendar_add" data-name="calendar add"><path class="cls-1" d="M22.5 3H21V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1h-4V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1H7V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1H2.5A1.5 1.5 0 0 0 1 4.5v18A1.5 1.5 0 0 0 2.5 24h20a1.5 1.5 0 0 0 1.5-1.5v-18A1.5 1.5 0 0 0 22.5 3zM19 2h1v3h-1zm-7 0h1v3h-1zM5 2h1v3H5zM2.5 4H4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h1.5a.5.5 0 0 1 .5.5V8H2V4.5a.5.5 0 0 1 .5-.5zm20 19h-20a.5.5 0 0 1-.5-.5V9h21v13.5a.5.5 0 0 1-.5.5z"/><path class="cls-1" d="M16 15.5h-3v-3a.5.5 0 0 0-1 0v3H9a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1z"/></g></svg>
                </span>
            </div>
            <div className='d-flex mt-3 mb-3'>
                <input onChange={onSearchChange} className='w-100 form-control rounded' placeholder='Search by CTP or Apprentice...'/>
                <span className="input-group-text rounded border-0" id="search-addon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </span>
            </div>

            <select onChange={onEmployerFilterSelect} className='w-100 mb-5 form-control form-select'>
                <option value='' disabled={true} selected>Select a CTP...</option>
                <option value='' >All CTP's</option>
                {employers
                    .sort(({ business_name: a_bn }, { business_name: b_bn }) => {
                        const a = a_bn.toLowerCase() 
                        const b = b_bn.toLowerCase()
                        return a > b ? 1 : a < b ? -1 : 0
                        })
                    .map(({ business_name, id }) => (
                    <option value={id}>{business_name}</option>
                ))}
            </select>
            <table className='table w-100'>
                {/* MAP THE CTP */}
                {employers
                    .sort(({ business_name: a_bn }, { business_name: b_bn }) => {
                        const a = a_bn.toLowerCase() 
                        const b = b_bn.toLowerCase()
                        return a > b ? 1 : a < b ? -1 : 0
                        })
                    .filter(({ id }) => selectedFilter ? selectedFilter == id : true)
                    .filter(({ business_name, apprentices }) => search 
                        ? [business_name, ...apprentices.map(({ first_name, last_name }) => `${first_name} ${last_name}`)]
                            .some(name => name?.toLowerCase()?.includes(search?.toLowerCase())) 
                        : true)
                    .map(({ apprentices, ...employer }, i) => {
                    return (
                        <>
                            <tr>
                                <td className='py-0'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex'>
                                            <input className='checkbox-constrained' checked={employer.selected} onChange={(e) => onCtpCheck(e, employer)} type="checkbox"></input>
                                            <span className='ml-2 pt-1 h4'>{employer.business_name}</span>
                                        </div>
                                        <div className='d-flex'>
                                            <p>{employer.all_reports_sent}</p>
                                            {/* <p className='mr-2'>{apprentices.length == 0 ? 'No Apprentices' : employer.all_reports_sent ? 'All Reports Sent' : 'Pending Reports'}</p> */}
                                            {apprentices.length == 0 ? (
                                                <></>
                                            ) : (employer.all_reports_sent ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 48 48">
                                                    <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                                                </svg>
                                            ) : (
                                                <svg className='circle-x' xmlns="http://www.w3.org/2000/svg" height="22px" width="22px" viewBox="0 0 530 530">
                                                    <path fill="#ff4444cc" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                                </svg>
                                            ))}
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            {/* MAP THE APPRENTICES FOR EACH CTP */}
                            {apprentices
                                .sort(({ first_name: a_f, last_name: a_l }, { first_name: b_f, last_name: b_l }) => {
                                    const a = `${a_f} ${a_l}`.toLowerCase() 
                                    const b = `${b_f} ${b_l}`.toLowerCase()
                                    return a > b ? 1 : a < b ? -1 : 0
                                    })
                                .map((apprentice, j) => {
                                return (
                                    <tr>
                                        <td className='py-0'>
                                            <div className='d-flex align-items-center justify-content-between ml-5'>
                                                <div className='d-flex'>
                                                    <input className='checkbox-constrained' checked={apprentice.selected} onChange={(e) => onApprenticeCheck(e, apprentice)} type="checkbox"></input>
                                                    <span className='ml-2 pb-1'>{apprentice.first_name} {apprentice.last_name}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    {apprentice.report_sent ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 48 48">
                                                            <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg className='circle-x' xmlns="http://www.w3.org/2000/svg" height="22px" width="22px" viewBox="0 0 530 530">
                                                            <path fill="#ff4444cc" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )
                })}
            </table>
        </div>
    )
}

export default ApprenticeReports