export const collapsedStyle = (state) => {
    let borders = []
    if(state) {
        borders.push("card-header header-color")
    } else {
        borders.push("card-header header-color")
    }

    return borders
}

export const collapsedApprenticeStyle = (state) => {
    let borders = []
    if(state) {
        borders.push("card-header header-color tab")
    } else {
        borders.push("card-header header-color")
    }

    return borders
}

export const isEmployee = (state) => {
    let background = []
    if(state) {
        background.push('white')
    } else {
        background.push('darkSeaGreen')
    }

    return background
}