import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import axios from 'axios';
import './admin.css';

import { ChangeClassification } from '../exportcomponents/FormExports';
import { UserInfo, EditProfileByAdmin, Enrollments } from '../exportcomponents/PopupExports';
import { SuperEnrollUser, SuperEditUser } from '../exportcomponents/ApprenticeExports';
import { EnrollSingle } from './EnrollComponents.js';
import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import { collapsedStyle } from '../exportcomponents/StyleExports';
import ViewCEUSubmits from '../supercomponents/ViewCEUSubmits';
import AddCertificates from '../components/AddCertificate';

const EmployeeList = ({ admin_email }) => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [employeeList, setEmployeeList] = useState([]);
    const [activeUsersSearch, setActiveUsersSearch] = useState("")
    const [empTab, setEmpTab] = useState(false);
    const [validIds, setValidIds] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState('')

    
    const moveToHistory = async (e) => {
        setDisableButton(true);
        setTimeout(() => setDisableButton(false), 5000);
		await axios.post(`${process.env.REACT_APP_API_URL}/account/history/${e.target.value}`, {
            admin_id: userData.userId
        });

        navigate('/');
        navigate('/admin');
	}

    const activeUsersList = !activeUsersSearch
		? employeeList
		: employeeList.filter(ind =>
			ind.email.toString().toLowerCase().includes(activeUsersSearch.toLocaleLowerCase()) ||
			(ind.first_name.toString() + " " + ind.last_name.toString()).toLowerCase().includes(activeUsersSearch.toLocaleLowerCase())
		);

    function classFormatter(raw_class) {
        try {
            const split_class = raw_class.split('act_')
            if (split_class.length > 1) {
                return 'ACT Apprentice'
            }
            return raw_class.charAt(0).toUpperCase() + raw_class.slice(1);
        } catch (err) {
            // // console.log('Error in class formatter\n', err);
            return 'No Classification'
        }
    }

    async function asyncSetupEmployeeList() {
        if (!userData.user) return navigate('/');
        
        const adminData = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`)
        const employeeData = await axios.get(`${process.env.REACT_APP_API_URL}/account/employees/${adminData.data.admin_email}`);
        setEmployeeList(employeeData.data || []);

        let allAccounts = []
		const all_card_ids = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/all/cards`)
		for(let i = 0; i < all_card_ids.data.length; i++) {
			allAccounts.push(all_card_ids.data[i].account_id)
		}
        // // console.log(allAccounts)
		setValidIds(allAccounts)
    }

    useEffect(() => {
        asyncSetupEmployeeList()
    }, []);

    return (
        <div>

            <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                <h3 className='h3 text-secondary'>Fitters</h3>
                <div className='d-flex justify-content-end'>
                    <div className="input-group rounded mr-2">
                        <input
                            onChange={(e) => setActiveUsersSearch(e.target.value)}
                            type="search"
                            className="form-control rounded"
                            placeholder={langData.placeholders.search}
                            aria-label="Search"
                            aria-describedby="search-addon"
                            value={activeUsersSearch}
                        />
                            <span className="input-group-text border-0" id="search-addon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                    </div>
                    <div className="tab-title apprentice rounded">
                        <h1>{activeUsersList.length}</h1>
                    </div>
                </div>
            </div>

            {/* <div 
                className={collapsedStyle(!empTab)}
                type="button" 
                data-toggle="collapse" 
                data-target="#empCollapse" 
                aria-expanded="false" 
                aria-controls="empCollapse"
                onClick={() => setEmpTab(!empTab)}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div>
                    <strong className="pending-text">{langData.employee_list.employees}</strong>
                </div>
                {activeUsersList &&
                    <div className="tab-title">
                        <h1>{activeUsersList.length}</h1>
                    </div>
                }
            </div>
            <div className="card collapse" id="empCollapse"> */}
            {/* <div style={{ backgroundColor: '#1b1b1b'}}>
                {empTab ? (
                    <div className="input-group rounded w-50 m-3">
                        <input
                            onChange={(e) => setActiveUsersSearch(e.target.value)}
                            type="search"
                            className="form-control rounded"
                            placeholder={langData.placeholders.search}
                            aria-label="Search"
                            aria-describedby="search-addon"
                            value={activeUsersSearch}
                        />
                            <span className="input-group-text border-0" id="search-addon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                    </div>
                ) : (null)}
            </div> */}
            {/* <div className="color-code-table mt-2 rounded">
                <strong className="color-coded-text">Color Codes: </strong>
                <div className="d-flex">
                    <div className="text-center mr-2">
                        <div style={{ backgroundColor: 'cadetBlue', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                            <strong style={{ color: '#363636' }}>Apprentice</strong>
                        </div>
                    </div>
                    <div className="text-center">
                        <div style={{ backgroundColor: 'darkSeaGreen', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                            <strong style={{ color: '#363636' }}>Fitter</strong>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="mydivider"></div>
            <div className="row certifications"> */}
                {(!employeeList || employeeList.length == 0) ? (
                    <p>{langData.employee_list.no_employees}</p>
                ) : (
                    <div className='min-vh-50'>
                        {activeUsersList.map((employee, i) => {
                            return (
                                <div key={i} className={`card card-body mt-1 pt-3 expandable ${selectedEmployee.id == employee.id && 'select-highlight'}`} onClick={() => setSelectedEmployee(employee)} >
                                {/* style={{ backgroundColor: (employee.classification || '').includes('fitter') ? 'darkSeaGreen' : 'cadetBlue' }} > */}
                                    {/* <div className='row'>
                                        <span>{JSON.stringify(Object.keys(employee))}</span>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>{langData.super_functionality.name}</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.first_name + " " + employee.last_name}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>{langData.super_functionality.email}</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.email}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Tokens:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.tokens ? employee.tokens : <i>No Tokens</i>}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Classification:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.classification}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Admin:</strong>
                                            <p style={{ color: 'black' }}>
                                                {!employee.admin_email ? 'Individual' : `${employee.admin_email} | ${employee.business_name}`}
                                            </p>
                                        </div>
                                        {/* <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>CEU Hours:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.ceus_completed || <i>No CEU's Earned</i>}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Next CEU Submit Date:</strong>
                                            <p style={{ color: 'black' }}>
                                                {timestampConverter(employee.submittal_due)}
                                            </p>
                                        </div> */}
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Card #:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.card_number || 'No Card #'}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Date First Listed:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.date_issued_string || 'No Issued Date'}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>Current Sticker #:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.current_sticker || 'No Sticker #'}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>CEU Submittal Period:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.submittal_period_string || 'No Submittal Period'}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong style={{ color: 'black' }}>CEU's towards Next Submittal:</strong>
                                            <p style={{ color: 'black' }}>
                                                {employee.ceus_completed || "No CEU's"}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                {langData.super_functionality.name}
                                            </strong>
                                            <p>
                                                {employee.first_name} {employee.last_name}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                Email
                                            </strong>
                                            <p>
                                                {employee.email}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                Tokens:
                                            </strong>
                                            <p>
                                                {employee.tokens ? employee.tokens : <i>No Tokens</i>}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                {employee.apprentice_level ? 'RTI' : 'CEU'} Hours:
                                            </strong>
                                            <p>
                                                {employee.current_hours ? (employee.current_hours / 10).toFixed(1) : <i>No {employee.apprentice_level ? 'RTI' : 'CEU'}'s</i>}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                Classification:
                                            </strong>
                                            <p>
                                                {employee.classification ? classFormatter(employee.classification) : <i>No Classification</i>}
                                            </p>
                                        </div> */}
                                {/* <div key={i} style={{ backgroundColor: employee.classification.includes('fitter') ? 'darkSeaGreen' : 'cadetBlue' }} className={`card d-flex justify-content-between p-3 ${i == 0 ? "mb-3 mx-3" : "m-3"}`}>
                                    <div>
                                        <div className="d-flex">
                                            <strong>Name:</strong>
                                            <p className="ml-2">{employee.first_name} {employee.last_name}</p>
                                        </div>
                                        <div className="d-flex">
                                            <strong>Email:</strong>
                                            <p className="ml-2">{employee.email}</p>
                                        </div>
                                        {!employee.apprentice_level &&(
                                            <div className="d-flex">
                                                <strong>Tokens:</strong>
                                                <p className="ml-2">{employee.tokens ? employee.tokens : <i>No Tokens</i>}</p>
                                            </div>
                                        )}
                                        <div className="d-flex">
                                            <strong>{employee.apprentice_level ? 'RTI' : 'CEU'} Hours:</strong>
                                            <p className="ml-2">{employee.current_hours ? (employee.current_hours / 10).toFixed(1) : <i>No {employee.apprentice_level ? 'RTI' : 'CEU'}'s</i>}</p>
                                        </div>
                                        <div className="d-flex">
                                            <strong>Classification:</strong>
                                            <p className="ml-2">{employee.classification ? classFormatter(employee.classification) : <i>No Classification</i>}</p>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'initial' }}>
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-warning m-2">{langData.employee_list.info}</button>}
                                        >
                                            <UserInfo
                                                id={employee.id}
                                            />
                                        </Popup>
                                        {/* {!validIds.includes(employee.id) ? (
                                                <button className="btn btn-primary m-2" disabled>Cal Fire Card in Review</button>
												// <Popup
												// 	modal
												// 	trigger={<button className="btn btn-primary m-2">Cal Fire Card</button>}
												// >
												// 	<SuperEnrollUser
												// 		id={employee.id}
												// 		classification={employee.classification}
                                                //         is_admin
												// 	/>
												// </Popup>
											) : (
												<Popup
													modal
													trigger={<button className="btn btn-warning m-2">View Cal Fire Card</button>}
												>
													<SuperEditUser
														id={employee.id}
														classification={employee.classification}
                                                        is_admin
													/>
												</Popup>
											)}
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-primary m-2">Change Classification</button>}
                                        >
                                            <ChangeClassification
                                                id={employee.id}
                                                old_class={employee.classification}
                                                email={employee.email}
                                                level={"admin"}
                                            />
                                        </Popup>
                                        <Popup
                                        trigger={<button className="btn btn-primary m-2">{langData.employee_list.certs}</button>}>
                                            <div className="form-background">
                                                <strong>{langData.employee_list.certs}</strong>
                                                {employee.certifications.length == 0 ? (
                                                    <p>{langData.employee_list.no_certs}</p>
                                                ): (
                                                    <>
                                                    {employee.certifications.map((certification, i) => {
                                                        return (
                                                            <div>
                                                                <p>{certification.name}</p>
                                                            </div>
                                                        )
                                                    })}
                                                    </>
                                                )}
                                            </div>
                                        </Popup>
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-outline-warning m-2" value={employee.id}>Edit</button>}
                                        >
                                            <EditProfileByAdmin 
                                                userId={employee.id}
                                                editorId={admin_email}
                                            />
                                        </Popup>
                                        {employee.classification.includes('fitter') && (
                                            <>
                                                <Popup
                                                    modal
                                                    trigger={<button className="btn btn-primary m-2">Enroll</button>}
                                                >
                                                    <EnrollSingle employee={employee} userData={userData} />
                                                </Popup>
                                                <Popup
                                                    modal
                                                    trigger={<button className="btn btn-outline-primary">View Enrollments</button>}>
                                                        <Enrollments
                                                            account_id={employee.id}
                                                            account={employee}
                                                        />
                                                </Popup>
                                            </>
                                        )}
                                        {/* <button className="btn btn-danger m-2" disabled={true} onClick={moveToHistory} value={employee.id}>Deactivate</button> */}
                                        {/* <button className="btn btn-outline-danger m-2" disabled={disableButton} onClick={moveToHistory} value={employee.id}>Deactivate</button> */}
                                        {/* <Popup
                                            modal
                                            trigger={<button className="m-2 btn btn-outline-success">Certifications</button>}>
                                            <AddCertificates
                                                employee={employee.id}
                                            />
                                        </Popup>
                                        <Popup
                                            modal
                                            trigger={<button className="m-2 btn btn-outline-warning">CEU's</button>}>
                                            <   
                                                apprentice_view
                                                id={employee.id}
                                                hasCalCard={false}
                                            />
                                        </Popup>
                                    </div>
                                </div> */}
                                    </div>
                                // </div>
                            )
                        })}
                        <div className='d-flex justify-content-center position-sticky bottom-0'>
                            <div className='opaque p-3 w-75 d-flex justify-content-evenly align-items-center'>
                                <Popup
                                    modal
                                    trigger={<button className="m-1">{langData.employee_list.info}</button>}
                                >
                                    <UserInfo
                                        id={selectedEmployee.id}
                                    />
                                </Popup>
                                {(selectedEmployee.classification || '').includes('fitter') && (
                                    <>
                                        <Popup
                                            modal
                                            trigger={<button className="m-1">Enroll</button>}
                                        >
                                            <EnrollSingle employee={selectedEmployee} userData={userData} />
                                        </Popup>
                                        <Popup
                                            modal
                                            trigger={<button className="m-1">View Enrollments</button>}>
                                                <Enrollments
                                                    account_id={selectedEmployee.id}
                                                    account={selectedEmployee}
                                                />
                                        </Popup>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                </div>
        //     </div>
        // </div>
    )
}

export default EmployeeList
