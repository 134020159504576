import React, { useState, useContext } from 'react'

import Popup from "reactjs-popup";
import UserContext from "../context/UserContext"
import LangContext from '../context/LangContext';
import { useNavigate } from 'react-router-dom';
import { en, sp } from '../context/Translations';

// import Login from "./LogIn"

import './styles.css'

const FunctionalButtons = () => {
    const lang_dict = { English: en, Spanish: sp };
    const { userData, setUserData } = useContext(UserContext);
    const { langData, setLangData } = useContext(LangContext);
    const navigate = useNavigate();

    const logout = () => {
        setUserData({
            token: undefined,
            user: undefined,
        });

        
        setLangData(en);

        localStorage.setItem("auth-token", "");
        navigate("/")
    }

    const langChange = () => {
        setLangData(langData == en ? sp : en);
    }

    return (
        <div style={{ float: "right", display: "contents" }}>
            <div className="homepage-buttons mt-2 mb-2">
                {userData.user ? (
                    <div className='button-display'>
                        <button onClick={logout} className="btn btn-danger margin-right mt-3 corner-button">{langData.functional_buttons.log_out}</button>
                        <button onClick={langChange} className="btn btn-success margin-right mt-3 corner-button">{langData.functional_buttons.change_language}</button>
                    </div>
                ) : (
                    <div className="button-display">
                        <Popup
                            modal
                            trigger={<button className="btn btn-danger margin-right mb-3">{langData.functional_buttons.indiv_sign_up}</button>}
                        >
                            {/* <SignUp /> */}
                        </Popup>
                        <Popup
                            modal
                            trigger={<button className="btn btn-primary margin-right mb-3">{langData.functional_buttons.log_in}</button>}
                        >
                            {/* <Login /> */}
                        </Popup>
                        <button onClick={langChange} className="btn btn-success margin-right mb-3">{langData.functional_buttons.change_language}</button>
                    </div>
                )}

            </div>
        </div>
    )
}

export default FunctionalButtons
