import React, { useState, useEffect, useContext } from 'react';
import UserContext from "../../context/UserContext";
import axios from 'axios';

const AddCalendlySchedule = () => {
    const { userData } = useContext(UserContext);
    const [prefill, setPrefill] = useState({ loaded: false, email: null, name: null })

    async function asyncSetupAddCalendlySchedule() {
        console.log(userData.user.userId)
        const user_info = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`)
        console.log("MY BACK HURTS! ", user_info.data)
        if (user_info.data) {
            const { email, first_name, last_name } = user_info.data
            setPrefill({ loaded: true, email: encodeURIComponent(email), name: encodeURIComponent(`${first_name} ${last_name}`) })
        }


    }

    useEffect(() => { asyncSetupAddCalendlySchedule() }, [])
    
    return (
        <div className='form-background p-0' style={{ height: '88vh', width: '88vw', overflow: 'hidden' }}>
            <p>{prefill.loaded}</p>
            {!prefill.loaded ? (
                <div className='d-flex justify-content-center align-items-center'>
                    {/* <p>LOADING ...</p> */}
                </div>
            ) : (
                <iframe
                    id="calendly-iframe"
                    style={{ height: '88vh', width: '88vw' }}
                    // style={{ height }}
                    src={`https://calendly.com/acteducate/acteducate-site-training?email=${prefill.email}&name=${prefill.name}`}>
                </iframe>
            )}
            {/* <InlineWidget 
                // styles={{overflowY: 'show' }}
                url="https://calendly.com/nickstine1450/30min"
            /> */}
        </div>
    )
}

export default AddCalendlySchedule