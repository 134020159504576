import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ACTImage from "../images/act.png";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import UnpaidAccount from '../supercomponents/UnpaidAccount'
import Popup from "reactjs-popup";
import './apprentice.css';
import downChevron from '../images/down_chevron.png';
import upChevron from '../images/up_chevron.png';

import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import { SuperEnrollApprentice, SuperEditApprentice } from '../exportcomponents/ApprenticeExports';
import SuperResetPassword from '../components/SuperResetPassword';
import { UploadEmployeePDF } from '../exportcomponents/ApprenticeExports';
import { UserInfo } from '../exportcomponents/PopupExports';
import { timestampConverter } from '../exportcomponents/TimeStampConverter';
import CorrectPendingIndv from "../supercomponents/SuperCorrectionForms";
import ApprenticeEdit from "./ApprenticeEdit";
import Dashboard from "./Dashboard";
import ApprenticeCourses from './ApprenticeCourses';
import AddCalendlySchedule from '../components/Calendly/AddCalendlySchedule'

import ApprenticeApplication from '../images/ApprenticeApplication.pdf'
import ApprenticeReports from './ApprenticeReports';
import { writeExcel } from '../services/excel';

const Apprentice = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const navigate = useNavigate();
    const [isSuper, setIsSuper] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [apprenticeSearch, setApprenticeSearch] = useState("")
	const [adminSelected, setAdminSelected] = useState("All Accounts");
	const [admins, setAdmins] = useState([])
    const [apprentices, setApprentices] = useState([]);
    const [apprenticeLevel, setApprenticeLevel] = useState(0);
    const [hours, setHours] = useState(0);
    const [expanded, setExpanded] = useState(null);
    const [selected, setSelected] = useState({})

    const currentToPercent = () => {
        const progress = hours

        let greenProgress = (progress / 30)

        let percent = Math.floor(greenProgress * 100)

        return percent + "%"
    }

    const activeApprenticeSearch = !apprenticeSearch
		? apprentices
		: apprentices.filter(app => 
			app.email.toString().toLowerCase().includes(apprenticeSearch.toLocaleLowerCase()) ||
			(app.first_name.toString() + " " + app.last_name.toString()).toLowerCase().includes(apprenticeSearch.toLocaleLowerCase()) ||
            app.address_1.toString().toLowerCase().includes(apprenticeSearch.toLocaleLowerCase()) ||
			app.shorthand.toString().toLowerCase().includes(apprenticeSearch.toLocaleLowerCase()) ||
            app.city.toString().toLowerCase().includes(apprenticeSearch.toLocaleLowerCase()) ||
            app.phone.toString().toLowerCase().includes(apprenticeSearch.toLocaleLowerCase()) || 
            app.zip.toString().toLowerCase().includes(apprenticeSearch.toLocaleLowerCase())
		);

    const moveToHistory = async (account_id, admin_email) => {
        const confirmed = window.confirm("Are you sure you'd like to deactivate this apprentice?")
        if (!confirmed) return
        // setButtonDisable(true);
        // setTimeout(() => {setButtonDisable(false)}, 1500);
        
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/history/${account_id}`, {
                admin_email
            });
        } catch (err) {
            console.log(err);
        }

        // navigate('/');
        // return navigate('/apprentice');
    }

    const onUpdateLevel = async (account_id) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/calfire/apprentice/level/${account_id}/${apprenticeLevel}`);

            toast.success('Apprentice Level Updated')
        } catch (err) {
            console.log(err)
            toast.error('Failed to Update Level')
        }
    }

    const onCompleteApprenticeship = async (account_id) => {
        try {
            const confirmed = await window.confirm("Are you sure you'd like to graduate this apprentice to a fitter?")
            if (!confirmed) return toast.error("Graduation Canceled")
            await axios.put(`${process.env.REACT_APP_API_URL}/calfire/apprentice/complete/${account_id}`)
            toast.success('Apprenticeship Completed!')
        } catch (err) {
            console.log(err)
            toast.error('Failed to Complete Apprenticeship')
        }
    }

    const redirectToAbsorb = (e) => {
        window.location.href = 'https://acteducate.myabsorb.com/#/courses';
    }

    const formatDate = (date) => {
        if (!date) return langData.apprentice.not_specified
        let dateTime = new Date(date)
        return parseInt(dateTime.getMonth() + 1) + "/" + dateTime.getDate() + "/" + dateTime.getFullYear()
    }

    function apprenticeRowClick(apprentice, i) {
        setExpanded(expanded == i ? null : i)
        setSelected(apprentice)
    }

    async function downloadApprentices() {
        console.log("downloadApprentices: ", apprentices)
        const cleaned_apprentices = apprentices.map(({
            id,
            account_id,
            learner_id,
            admin_id,
            password,
            first_name,
            last_name,
            ...apprentice
        }) => ({ name: `${first_name} ${last_name}`, ...apprentice }))
        writeExcel(cleaned_apprentices, 'Apprentices')
    }

    async function asyncSetupApprentice() {
        const statusData = await axios.get(`${process.env.REACT_APP_API_URL}/account/admin/level/${userData.userId}`) // userData.userId is the current logged in user's uuid 
        // TODO:    Apprentice RTI from absorb
        //          load those bad bois before the setApprentice call(s) and standardize those into less if statements
        let apprenticeData
        if(statusData.data.elevated === true) {
            apprenticeData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/apprentice/admin/${statusData.data.account_id}`)
            setApprentices(apprenticeData.data)
        }
        
        if (userData.user.isIndividual) return

        // Setup Admins and Supers
        if(!statusData.data) {
            apprenticeData = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/apprentice/super`)
            console.log("THERE'S A PYRAMID SCHEMER ON THE LOOSE IN THIS STARBOOKS :)", apprenticeData.data)
            setApprentices(apprenticeData.data)
        }

        let allAccounts = []
		const all_card_ids = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/all/cards`)
		for(let i = 0; i < all_card_ids.data.length; i++) {
			allAccounts.push(all_card_ids.data[i].account_id)
		}
            
        const admin_list = await axios.get(`${process.env.REACT_APP_API_URL}/account/admins`)
        setAdmins(admin_list.data)
    }

    useEffect(() => {
        if (!userData.user) { return navigate('/') }
        setIsSuper(userData.user.isSuper);
        setIsAdmin(userData.user.isAdmin);

        asyncSetupApprentice()
    }, []);
    

    return (
        <div className='apprentice-page mt-5'>
            <div className='d-flex align-items-center justify-content-between mb-5'>
                <h1 className='h1'>
                    {(isSuper || isAdmin) 
                        ? langData.apprentice.controls_header
                        : langData.apprentice.dashboard_header
                    }
                </h1>
                {(isSuper || isAdmin) ? (
                    <div className="input-group w-50 apprentice">
                        {isSuper && 
                            <Popup
                                modal
                                trigger={<button className='btn rounded btn-outline-primary mr-2'>
                                    Progress Reports
                                </button>}>
                                <ApprenticeReports />
                            </Popup>
                        }
                        <select 
                            className={`form-select admin-select rounded mr-3 ${isAdmin && 'd-none'}`}
                            aria-label="Select Admin" 
                            name="admin" 
                            onChange={(e) => setAdminSelected(e.target.value)}
                        >
                            <option>
                                {langData.apprentice.all_accounts}
                            </option>
                                {admins && admins.map((admin, i) => {
                                    return (
                                        <option key={i} value={admin.admin_email}>{admin.admin_email + " - " + admin.business_name}</option>
                                    );
                                })}
                        </select>
                        <div className='d-flex'>
                            <input
                                onChange={(e) => setApprenticeSearch(e.target.value)}
                                className="form-control rounded"
                                placeholder={langData.placeholders.search}
                                aria-label="Search"
                                aria-describedby="search-addon"
                                value={apprenticeSearch}
                            />
                            <span className="input-group-text rounded border-0" id="search-addon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                            <div className="tab-title apprentice rounded ml-3">
                                <h1>{apprentices.length}</h1>
                            </div>
                            <span onClick={(e) => asyncSetupApprentice()} className="input-group-text rounded border-0 ml-3">
                                <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z"/></g>
                                </svg>
                            </span>
                            <span onClick={(e) => downloadApprentices()} className="input-group-text rounded border-0 ml-3">
                                <svg width="1.2rem" height="1.2rem" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <g><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></g>
                                </svg>
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex justify-content-end'>    
                        <Popup
                            modal
                            trigger={<button className="btn btn-outline-primary mr-3" onClick={redirectToAbsorb}>
                                {langData.apprentice.schedule_open_hours}
                            </button>}>
                            <AddCalendlySchedule />
                        </Popup>
                        <button className="btn btn-outline-primary" onClick={redirectToAbsorb}>
                            {langData.apprentice.go_to_apprentice_courses}
                        </button>
                    </div>
                )}
            </div>
            {/* {elevatedStatus == "Elevated" ? (
                <div className="card p-3 w-50 mt-3" style={{ margin: 'auto' }}>
                    <label>Download Apprentice Form</label>
                        <a href={ApprenticeApplication} download>
                            <button className="btn btn-primary mt-3">
                                Download Form
                            </button>
                        </a>
                    <label className="mt-3">Add Apprentice</label>
                    <Popup
                        modal
                        trigger={<button className="btn btn-primary mt-3">Submit a new apprentice for approval</button>}
                    >
                        <UploadEmployeePDF />
                    </Popup>
                </div>
            ) : (null)} */}
            {isSuper || isAdmin ? (
                <div className="tall">
                    {activeApprenticeSearch.map((apprentice, i) => {
                        return [apprentice.admin_email, 'All Accounts'].includes(adminSelected) && (
                            <div key={i} className={`card apprentice-card p-3 my-3 ${expanded == i && 'expanded'}`}>
                                <div onClick={() => apprenticeRowClick(apprentice, i) }>
                                    {apprentice.paid === false && (<UnpaidAccount account_id={apprentice.account_id} is_employee />)}
                                    <div className={`d-flex justify-content-between align-items-center ${expanded == i && 'flex-column-reverse'}`}>
                                        {expanded !== i ? (
                                            <div className='ml-2 my-1 collapsed-apprentice-row'>
                                                <div className='d-flex'>
                                                    <strong>
                                                        {langData.apprentice.name}
                                                    &nbsp;</strong>
                                                    <p>{apprentice.first_name + " " + apprentice.last_name}</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <strong>
                                                        {langData.apprentice.employer}
                                                    &nbsp;</strong>
                                                    <p>{apprentice.admin_email} | {apprentice.business_name}</p>
                                                </div>
                                                {/* <div className='d-flex'>
                                                    <strong>Current RTI:&nbsp;</strong>
                                                    <p>{apprentice.rti_current || '0.00'} | Level {apprentice.level}</p>
                                                </div> */}
                                            </div>
                                        ) : (
                                            <div className="table mt-3">
                                                <div className="d-flex apprentice-table-wrapper">
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.name}
                                                        </strong>
                                                        <p>{apprentice.first_name + " " + apprentice.last_name}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.current_level}
                                                        </strong>
                                                        <p>{apprentice.current_level}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong scope="row">
                                                            {langData.apprentice.ojl_current}
                                                        </strong>
                                                        <p>{apprentice.ojl_current}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex apprentice-table-wrapper">
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.language}
                                                        </strong>
                                                        <p>{{ 'en': 'English', 'sp': 'Spanish' }[langData.lang]}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong scope="row">
                                                            {langData.apprentice.apprenticeship_status}
                                                        </strong>
                                                        <p>{apprentice.apprenticeship_status}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong scope="row">
                                                            {langData.apprentice.ojl_total}
                                                        </strong>
                                                        <p>{apprentice.ojl_overall} / 7000</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex apprentice-table-wrapper">
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.dol_registration}
                                                        </strong>
                                                        <p>{apprentice.dol_registration}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.next_eval}
                                                        </strong>
                                                        <p>{formatDate(apprentice.next_eval)}</p>
                                                        {/* <p>{timestampConverter(apprentice.next_eval)}</p> */}
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong scope="row">
                                                            {langData.apprentice.rti_current}
                                                        </strong>
                                                        <p>{apprentice.rti_current}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex apprentice-table-wrapper">
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong scope="row">
                                                            {langData.apprentice.cal_card_number}
                                                        </strong>
                                                        <p>{apprentice.cal_card_number}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.apprenticeship_began}
                                                        </strong>
                                                        <p>{formatDate(apprentice.date_began)}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong scope="row">
                                                            {langData.apprentice.rti_total}
                                                        </strong>
                                                        <p>{apprentice.rti_overall} / 510</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex apprentice-table-wrapper">
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.employer}
                                                        </strong>
                                                        <p>{apprentice.business_name} | {apprentice.admin_email}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.expected_completion}
                                                        </strong>
                                                        <p>{apprentice.expected_completion}</p>
                                                    </div>
                                                    <div className="w-50 px-2 table-apprentice">
                                                        <strong>
                                                            {langData.apprentice.notes}
                                                        </strong>
                                                        <p>{apprentice.notes}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        
                                        {/* <div className={`${expanded == i && 'd-flex w-100 justify-content-end'}`}>
                                            <button className="btn btn-outline-info" onClick={() => expanded == i ? setExpanded(null) : setExpanded(i) }>
                                                <span>
                                                    {langData.apprentice.expand}
                                                    <img src={expanded == i ? upChevron : downChevron} className="chevron" />
                                                </span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between" style={{ alignItems: 'center' }}>
                                    <div>
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-warning m-2">
                                                {langData.apprentice.account_info}
                                            </button>}
                                        >
                                            <UserInfo 
                                                id={apprentice.account_id}
                                            />
                                        </Popup>
                                        {isSuper && <>
                                        <Popup
                                            modal
                                            trigger={<button class="btn btn-outline-warning m-2">
                                                {langData.apprentice.edit_user}
                                            </button>}
                                        >
                                            <CorrectPendingIndv
                                                id={apprentice.account_id}
                                                show_billing={false}
                                            />
                                        </Popup>
                                        <Popup
                                            modal
                                            trigger={<button class="btn btn-outline-warning m-2">
                                                {langData.apprentice.edit_apprentice}
                                            </button>}
                                        >
                                            <ApprenticeEdit 
                                                id={apprentice.account_id}
                                                apprentice={apprentice}
                                            />
                                        </Popup>
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-primary m-1">
                                                {langData.apprentice.change_password}
                                            </button>}
                                        >
                                            <SuperResetPassword 
                                                email={apprentice.email}
                                                apprentice={true}
                                            />
                                        </Popup>
                                        
                                        <Popup
                                            trigger={<button className="btn btn-info m-2">
                                                {langData.apprentice.update_progress}
                                            </button>}
                                            onOpen={() => setApprenticeLevel(apprentice.level)}
                                            onClose={() => setApprenticeLevel(0)}
                                            >
                                            <div className="card p-2">
                                                <b>
                                                    {langData.apprentice.update_apprentice_level}
                                                </b>
                                                <input
                                                    className='my-3'
                                                    value={apprenticeLevel}
                                                    type="number"
                                                    onChange={(e) => setApprenticeLevel(e.target.value)}
                                                />
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => onUpdateLevel(apprentice.account_id)}>
                                                    {langData.apprentice.update_level}
                                                </button>
                                                <div className="d-flex justify-content-center my-2 w-100">
                                                    <i>
                                                        ...  
                                                        {langData.apprentice.or}
                                                        ...
                                                    </i>
                                                </div>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => onCompleteApprenticeship(apprentice.account_id)}>
                                                    {langData.apprentice.complete_apprenticeship}
                                                </button>
                                            </div>
                                        </Popup>
                                        {!validIds.includes(apprentice.account_id) ? (
                                                    <Popup
                                                        modal
                                                        trigger={<button className="btn btn-primary m-2">
                                                            {langData.apprentice.cal_fire_card}
                                                        </button>}
                                                    >
                                                        <SuperEnrollApprentice
                                                            id={apprentice.account_id}
                                                            classification={apprentice.classification}
                                                            is_admin
                                                        />
                                                    </Popup>
                                                ) : (
                                                    <Popup
                                                        modal
                                                        trigger={<button className="btn btn-warning m-2">
                                                            {langData.apprentice.edit_cal_card}
                                                        </button>}
                                                    >
                                                        <SuperEditApprentice
                                                            id={apprentice.account_id}
                                                            classification={apprentice.classification}
                                                            is_admin
                                                        />
                                                    </Popup>
                                                )}
                                        </>}
                                        <button className="btn btn-outline-danger m-2" disabled={isAdmin} onClick={() => moveToHistory(apprentice.account_id, apprentice.admin_email)}>
                                            {langData.apprentice.deactivate}
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        )
                    })}
                    <div className='d-flex justify-content-center position-sticky bottom-0'>
                        <div className='opaque p-3 w-75 d-flex flex-wrap justify-content-evenly align-items-center'>
                            <Popup
                                modal
                                trigger={<button className='text-nowrap'>
                                    {langData.apprentice.account_info}
                                </button>}
                            >
                                <UserInfo 
                                    id={selected.account_id}
                                />
                            </Popup>
                            
                            <Popup
                                modal
                                trigger={<button className='text-nowrap'>
                                    {langData.apprentice.edit_user}
                                </button>}
                            >
                                <CorrectPendingIndv
                                    id={selected.account_id}
                                    show_billing={false}
                                />
                            </Popup>
                            {/* <button>Edit Apprentice</button> */}
                            
                            <Popup
                                modal
                                trigger={<button className='text-nowrap'>
                                    {langData.apprentice.change_password}
                                </button>}
                            >
                                <SuperResetPassword 
                                    email={selected.email}
                                    apprentice={true}
                                />
                            </Popup>
                                    <button
                                        className='text-nowrap'
                                        onClick={() => onCompleteApprenticeship(selected.account_id)}>
                                        {langData.apprentice.complete_apprenticeship}
                                    </button>
                            {/* <Popup
                                trigger={<button className="">
                                    {langData.apprentice.update_progress}
                                </button>}
                                onOpen={() => setApprenticeLevel(selected.level)}
                                onClose={() => setApprenticeLevel(0)}
                                >
                                <div className="card p-2">
                                    <b>
                                        {langData.apprentice.update_apprentice_level}
                                    </b>
                                    <input
                                        className='my-3'
                                        value={apprenticeLevel}
                                        type="number"
                                        onChange={(e) => setApprenticeLevel(e.target.value)}
                                    />
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => onUpdateLevel(selected.account_id)}>
                                        {langData.apprentice.update_level}
                                    </button>
                                    <div className="d-flex justify-content-center my-2 w-100">
                                        <i>
                                            ...  
                                            {langData.apprentice.or}
                                            ...
                                        </i>
                                    </div>
                                    <button
                                        className="btn btn-success"
                                        onClick={() => onCompleteApprenticeship(selected.account_id)}>
                                        {langData.apprentice.complete_apprenticeship}
                                    </button>
                                </div>
                            </Popup> */}
                            {/* <button>Update Progress</button> */}
                            {!selected.has_cal_card ? (
                                <Popup
                                    modal
                                    trigger={<button className='text-nowrap'>
                                        {langData.apprentice.cal_fire_card}
                                    </button>}
                                >
                                    <SuperEnrollApprentice
                                        id={selected.account_id}
                                        classification={selected.classification}
                                        is_admin
                                    />
                                </Popup>
                            ) : (
                                <Popup
                                    modal
                                    trigger={<button className='text-nowrap'>
                                        {langData.apprentice.edit_cal_card}
                                    </button>}
                                >
                                    <SuperEditApprentice
                                        id={selected.account_id}
                                        classification={selected.classification}
                                        is_admin
                                    />
                                </Popup>
                            )}
                            <button className='text-nowrap' disabled={isAdmin} onClick={() => moveToHistory(selected.account_id, selected.admin_email)}>
                                {langData.apprentice.deactivate}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <Dashboard />
                    <ApprenticeCourses />
                </div>
            )} 
        </div>
    )
}

export default Apprentice
