import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import axios from "axios"
import { toast } from 'react-toastify'
import "../components/styles.css"
import { useNavigate } from 'react-router-dom';
import { Validator } from '../exportcomponents/ValidatorExports';


const SignUpApprentice = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [admins, setAdmins] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [adminChange, setAdminChange] = useState("");
    const [form, setForm] = useState({ language: "English", classification: 'fitter', billing_frequency: 'month' });
    const [empForm, setEmpForm] = useState({ language: 'English', billing_frequency: 'month' })
    const [buttonDisable, setButtonDisable] = useState({
        submit: false
    });
    const [formState, setFormState] = useState({
        show_submit: false,
        show_option: true,
        show_form: true,
        admin_chosen: false,
        show_new: false
    });

    function clearFormState() {
        let newState = formState;
        Object.keys(newState).forEach(key => newState = { ...newState, [key]: false })
        setFormState(newState)
    }

    // function updateFormState(newForm) {
    //     clearFormState();
    //     setFormState({
    //         ...formState,
    //         ...newForm
    //     })
    //     // console.log({
    //         ...formState,
    //         ...newForm
    //     })
    // }

    const navigate = useNavigate()

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setFormState({
            ...formState,
            show_submit: true
        })
        // console.log(form);
    };

    const onEmpChange = (e) => {
        setEmpForm({ ...empForm, [e.target.name]: e.target.value })
    }

    const onAdminChange = async (e) => {
        const [valueOne, valueTwo] = String(e.target.value).split(',');
        loadEmployees(valueOne)
        setAdminChange({...adminChange, admin_email: valueOne, admin_id: valueTwo });
        if (valueOne === 'null') {
            clearFormState()            
        } else {
            setFormState({ ...formState, show_new: true })
        }
        
        // console.log("admin_change", valueOne)
    };

    const submitOld = async (e) => {
        e.preventDefault();
        // console.log("Submit Old")
        setButtonDisable({ submit: true });
        setTimeout(() => (setButtonDisable({ submit: false })), 5000);
        try {
            // console.log(form)
            await axios.post(`${process.env.REACT_APP_API_URL}/calfire/apprentice/request/${form.emp_id}`, { admin_id: adminChange.admin_id })
            toast.success("Employee updated to apprentice!")
        } catch (err) {
            toast.error(err.message);
        }
    };

    const submitNew = async (e) => {
        e.preventDefault()
        // console.log("submit new")
        setButtonDisable({submit: true });
        setTimeout(() => (setButtonDisable({ submit: false })), 5000);
        try {
            // console.log(empForm)
            // email, password, first_name, last_name, phone, address_1, address_2,
            // city, state, zip, language, classification, admin_email, admin_id, new_apprentice
            axios.post(`${process.env.REACT_APP_API_URL}/account/admin/individual`, {
                ...empForm,
                billing_frequency: form.billing_frequency,
                new_apprentice: true,
                admin_email: adminChange.admin_email,
                admin_id: adminChange.admin_id,
                classification: 'act_apprentice'
            }).then(res => {
                if (res.data.card_declined) return toast.error("Card Declined")
                console.log("New Account ID: ", res.data.account_id)
                axios.post(`${process.env.REACT_APP_API_URL}/calfire/apprentice/request/${res.data.account_id}`, { 
                    admin_id: adminChange.admin_id
                }).then(res => {
                    toast.success('New Apprentice Created!')
                }) 
            }).catch((err) => {
                // console.log('please lord just kill me now :)', err.response.status)
                if (err.response.status === 409) toast.error('An account with that email already exists')
            })
        } catch (err) {
            // console.log(err);
        }
    }

    const loadEmployees = async (admin_email) => {
        // console.log('loading employees')
        const employee_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/employees/${admin_email}`)
        // console.log(employee_data.data)
        setEmployees(employee_data.data)
    }

    async function asyncSetupSignUpApprentice() {
        const admin_list = await axios.get(`${process.env.REACT_APP_API_URL}/account/elevated/admins`)

        setAdmins(admin_list.data)
    }

    useEffect(() => {
        asyncSetupSignUpApprentice()
    }, [])

    return (
        <div>
            <form className="form-background popup-scrollable" onSubmit={!formState.show_form ? submitOld : submitNew}>
                <h2 className="h2 mb-2">Add Apprentice</h2>
                <div className="mb-2">
                    <label htmlFor="email">Choose CTP:</label>
                    <select 
                        className="" 
                        name="admin" 
                        aria-label="Default select example"
                        onChange={onAdminChange}
                    >
                        <option value="null">Choose CTP</option>
                            {admins && admins.map((admin, i) => {
								return (
                                    <option key={i} value={[admin.admin_email, admin.id]}>{admin.business_name}</option>
                                );
							})}
                    </select>	
				</div>
                {/* {adminChange ? (<div className="mb-2">
                    <label htmlFor="email">Choose Employee:</label>
                    <select 
                        className="form-select mt-2" 
                        name="emp_id" 
                        aria-label="Default select example"
                        onChange={onChange}
                        disabled={formState.show_form}
                    >
                        <option key={"default_option"} value={null}>Choose Employee</option>
                            {employees && employees.map((emp, i) => {
								return (
                                    <option key={i} value={emp.id}>{emp.admin_email + " - " + emp.email}</option>
                                );
							})}
                    </select>	
				</div> ) : (
                    <div className="mb-2">
                        <select 
                        className="form-select mt-2" 
                        name="email" 
                        aria-label="Default select example"
                        disabled = {true}
                    >
                            <option key={"default_option"}>No Employees</option>
                        </select>
                    </div>
                )} */}
                <div className="d-flex btn-group">
                    {/* <div className="or-dot">
                        or...
                    </div>
                    <button
                        type="button"
                        className={`btn btn-${!formState.show_form ? 'outline-' : ''}success mt-2 right`}
                        onClick={() => setFormState({ ...formState, show_form: !formState.show_form })}
                        disabled={buttonDisable.submit || !formState.show_new }
                    >
                        {!formState.show_form ? 'Create New Account' : 'Use Existing Account'}
                    </button> */}
                </div>
                <div>
                    {formState.show_form === true && (
                        <div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">Employee Email</label>
                                    {!form.email ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    } 
                                </div>
                                <input
                                    type="email"
                                    className=""
                                    name="email"
                                    onChange={onEmpChange}
                                    placeholder="Enter Employee Email"
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.first_name}</label>
                                    {!form.first_name ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="first_name"
                                    className=""
                                    name="first_name"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_first_name}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.last_name}</label>
                                    {!form.last_name ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="last_name"
                                    className=""
                                    name="last_name"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_last_name}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.phone}</label>
                                    {!form.phone ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="phone"
                                    className=""
                                    name="phone"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_phone_number}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.address_1}</label>
                                    {!form.address_1 ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="address_1"
                                    className=""
                                    name="address_1"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_address}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="displayName">{langData.sign_up_employee.address_2}</label>
                                <input
                                    type="address_2"
                                    className=""
                                    name="address_2"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_address_2}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.city}</label>
                                    {!form.city ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="city"
                                    className=""
                                    name="city"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_city}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.state}</label>
                                    {!form.state ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="state"
                                    className=""
                                    name="state"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_state}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.zip}</label>
                                    {!form.zip ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="zip"
                                    className=""
                                    name="zip"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.enter_zip}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="displayName">{langData.sign_up_employee.language}</label>
                                <select
                                    className="form-select"
                                    name="language"
                                    aria-label="Default select example"
                                    onChange={onEmpChange}
                                >
                                    <option value="English">{langData.sign_up_employee.english}</option>
                                    <option value="Spanish">{langData.sign_up_employee.spanish}</option>
                                </select>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="Billing Frequency">Billing Frequency</label>
                                <select
                                    className="form-select"
                                    name="billing_frequency"
                                    aria-label="Default select example"
                                    onChange={onChange}
                                >
                                    <option value="month">Monthly</option>
                                    <option value="year">Annually</option>
                                </select>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.password}</label>
                                    {!form.password ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="password"
                                    className=""
                                    name="password"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.password}
                                />
                            </div>
                            <div className=" mb-5">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="email">{langData.sign_up_employee.password_2}</label>
                                    {!form.passwordCheck ? 
                                        <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <input
                                    type="password"
                                    className=""
                                    name="passwordCheck"
                                    onChange={onEmpChange}
                                    placeholder={langData.placeholders.password_2}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary submit w-100"
                                disabled={buttonDisable.submit || !formState.show_new}
                                >
                                Submit
                            </button>
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default SignUpApprentice