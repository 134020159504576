import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify'
import Popup from "reactjs-popup";
import ACTImage from "../images/act.png";

import "./admin.css";
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';

import FunctionalButtons from "../components/FunctionalButtons"
import Navbar from '../components/Navbar';
import SignUpAdminEmployee from './SignUpAdminEmployee';

import { Link, useNavigate } from 'react-router-dom';
import EmployeeList from './EmployeeList';
// import PendingEmployees from './PendingEmployees';
import AdminPendCert from './AdminPendCert';
import EmployeeChanges from './EmployeeChanges';
import AbsorbPurchaseTokens from '../components/AbsorbPurchaseTokens';

const AdminFunctionality = () => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [referenceId, setReferenceId] = useState('')
    const [search, setSearch] = useState("")
    const [adminTokens, setAdminTokens] = useState('Loading...')
    const [activeDisplay, setActiveDisplay] = useState('employees')

    function onDisplayTabChange(display_name) {
		setActiveDisplay(display_name)
	}

	function isActiveDisplay(display_name, is_display) {
		return is_display
			? activeDisplay != display_name && 'd-none'
			: activeDisplay == display_name && 'active'
	}

    useEffect(() => {
        (async () => {
            try {
                const user_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.userId}`)
                setReferenceId(user_data.data.admin_email)

                const admin_tokens = await axios.get(`${process.env.REACT_APP_API_URL}/absorb/admin/tokens/${userData.userId}`)
                setAdminTokens(admin_tokens.data)
                // // console.log(user_data.data.admin_email)

                // if (user_data) {
                //     // console.log(user_data.data.admin_email)
                //     const pendArray = []
                //     const pend_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/user/${user_data.data.admin_email}`);
                //     // console.log(pend_data.data)
                //     for (let i = 0; i < pend_data.data.length; i++) {
                //         if (userData.user.login !== pend_data.data[i].email) {
                //             pendArray.push(pend_data.data[i])
                //         } else {
                //             // console.log(pend_data.data[i])
                //         }
                //     }
                //     setPendList(pendArray);
                // }

            } catch (err) {
                toast.error(err);
            }
        })()
    }, [userData, referenceId, search]);

    return (
        <div className='my-5 admin-functionality'>
            <div className='mb-5 d-flex align-items-center justify-content-between'>               
                <h1 className='h1'>
                    Admin Controls
                </h1>
                <div className='d-flex align-items-center justify-content-around'>               
                    <h5 className="h5 mr-3 mt-2">
                        Tokens:&nbsp;
                        <span className='fw-light'>
                            {adminTokens}
                        </span>
                    </h5>
                    <h5 className="h5 mr-3 mt-2">
                        Business #:&nbsp;
                        <span className='fw-light'>
                            {referenceId}
                        </span>
                    </h5>
                    <Popup
                        trigger={<button className='btn btn-outline-primary'>More Tokens</button>}
                        modal>
                        <AbsorbPurchaseTokens logged_in={true} />
                    </Popup>
                </div>
            </div>
            {/* <ul className='nav nav-tabs nav-fill'>
                <li className='nav-item' onClick={() => onDisplayTabChange('active_subs')}>
                    <a className={'nav-link ' + isActiveDisplay('active_subs')}>
                        Employees
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('account_history')}>
                    <a className={'nav-link ' + isActiveDisplay('account_history')}>
                        Account History
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('non_subs')}>
                    <a className={'nav-link ' + isActiveDisplay('non_subs')}>
                        Non Subscribers
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('pending_indiv')}>
                    <a className={'nav-link ' + isActiveDisplay('pending_indiv')}>
                        Pending Individuals
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('admin_list')}>
                    <a className={'nav-link ' + isActiveDisplay('admin_list')}>
                        CTP's
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('pending_admins')}>
                    <a className={'nav-link ' + isActiveDisplay('pending_admins')}>
                        Pending CTP's
                    </a>
                </li>
                {/* <li className='nav-item' onClick={() => onDisplayTabChange('admin_change')}>
                    <a className={'nav-link ' + isActiveDisplay('admin_change')}>
                        Pending CTP Changes
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('pending_jobs')}>
                    <a className={'nav-link ' + isActiveDisplay('pending_jobs')}>
                        Jobs Pending
                    </a>
                </li>
                {/* <li className='nav-item' onClick={() => onDisplayTabChange('cert_list')}>
                    <a className={'nav-link ' + isActiveDisplay('cert_list')}>
                        Certifications
                    </a>
                </li>
                <li className='nav-item' onClick={() => onDisplayTabChange('pending_certs')}>
                    <a className={'nav-link ' + isActiveDisplay('pending_certs')}>
                        Certifications Pending
                    </a>
                </li>
            </ul> */}
            <EmployeeList 
                    admin_email={referenceId}
                />
            {/* <div>
                <AdminPendCert />
            </div>
            <div>
                <EmployeeChanges />
            </div> */}
            {/* <div className="admin-buttons">
                <Popup
                    modal
                    trigger={<button className="btn btn-warning">{langData.admin_controls.add_emp}</button>}
                >
                    <SignUpAdminEmployee />
                </Popup>
                <button
                    className="btn btn-warning"
                    onClick={onBulkUpload}
                    disabled>
                    {langData.admin_controls.bulk_add}
                </button>
            </div> */}
            {/* <div>
                <PendingEmployees />
            </div> */}
        </div >


    )
}

export default AdminFunctionality;
