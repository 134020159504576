import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import axios from 'axios';
import './deep.css'
import LangContext from '../../context/LangContext';
import loadingGif from '../../images/loadinggif.gif'

const Unsubscribe = () => {
    const [params] = useSearchParams()
    const email = params.get('email')
    const { langData } = useContext(LangContext)
    const [subState, setSubState] = useState('loading')

    async function attemptUnsubscribe() {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/ses/unsubscribe/${email}`,
            )
            toast.success("Unsubscribed Successfully")
            setSubState('unsubscribed')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong")
            setSubState('error')
        }
    }

    async function attemptResubscribe() {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/ses/resubscribe/${email}`)
            toast.success("Re-Subscribed Successfully")
            setSubState('resubscribed')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong")
            setSubState('error')
        }
    }

    function getHeaderText() {
        switch (subState) {
            case 'loading':
                return langData.unsubscribe.header_loading
            case 'unsubscribed':
                return langData.unsubscribe.header_unsub
            case 'resubscribed':
                return langData.unsubscribe.header_resub
        }
    }

    function getButtonText() {
        switch (subState) {
            case 'loading':
                return langData.unsubscribe.button_loading
            case 'unsubscribed':
                return langData.unsubscribe.button_unsub
            case 'resubscribed':
                return langData.unsubscribe.button_resub
        }
    }

    function getBodyText() {
        switch (subState) {
            case 'loading':
                return langData.unsubscribe.body_loading
            case 'unsubscribed':
                return langData.unsubscribe.body_unsub
            case 'resubscribed':
                return langData.unsubscribe.body_resub
        }
    }

    useEffect(() => {
        console.log("Attempting to unsubscribe: ", email)
        attemptUnsubscribe()
    }, [])

    return (
        <div className='d-flex flex-column justify-content-between text-left mb-5 pb-5 mx-5 px-5'>
            <h1 className='h1'>
                {getHeaderText()}
            </h1>
            <div className='sub-control-cluster'>
                <button onClick={
                    subState == 'unsubscribed'
                        ? attemptResubscribe
                        : attemptUnsubscribe}
                    className='px-5! mb-5'>
                    {getButtonText()}
                </button>
                <p className='h5 fw-light'>
                    {getBodyText()}
                </p>
            </div>
            <span>
                {langData.unsubscribe.footer_at_act}&nbsp;
                <a href="mailto:info@acteducate.com?subject=Email Unsubscribe Complaint">
                    info@acteducate
                </a> 
                &nbsp;{langData.unsubscribe.footer_let_us_know}
            </span>
        </div>
    )
}

export default Unsubscribe