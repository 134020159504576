// Form Validation Methods

// StrinigyQuotes puts a backslash in front of any quotes within a user entered string
// and returns a "stringy" form with the processed data.
export const StringifyFields = (form) => {
    let stringy_form = {};
    // console.log(form)
    for (const [key, field] of Object.entries(form)) {
        let combined_string = '';
        // console.log(field);
        let sliced_input = field.split("'");

        for (const i in sliced_input) {
            combined_string = combined_string + sliced_input[i];
            if (i < sliced_input.length - 1) { 
                combined_string = combined_string + "\\'";
            }
        }

        // sliced_input = combined_string.split('"');
        // combined_string = '';
        // for (const i in sliced_input) {
        //     combined_string = combined_string + sliced_input[i];
        //     if (i < sliced_input.length - 1) { 
        //         combined_string = combined_string + '\\"';
        //     }
        // }

        stringy_form = { ...stringy_form, [key]: combined_string }
    }

    // console.log(stringy_form);

    return stringy_form;
}

// Validate Extension
// Accepts an <input type=file /> 's e.file.name, and an array 
// of acceptable .extensions like .png or .pdf or .jpg and returns
// true if the name's extension is included in the extensions array.
export const ValidateExtionsions = (name, extensions) => {
    let acceptable = false;
    const ext_list = name.split('.')
    const ext = ext_list[ext_list.length - 1]

    if (extensions.includes(ext.toLowerCase())) {
        acceptable = true;
    }
    
    // console.log(acceptable ? 'Extension Accepted' : 'Extension Rejected');
    return acceptable;
}

export const Validator = (form, fields) => {
    // console.log(form, "\n", fields);
    let unFilled = [];
    if (fields.length > 0) {
        for (const field of fields) {
            if (form[field] == 'email'){
                // if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(form['email']) === false){
                //     return false;
                // }
            }
            if (!form[field] || form[field] == "" || form[field] == null) {
                unFilled.push(field);
            }
        }
    }

    // console.log(unFilled);
    return unFilled;
}

export const validateDate = (input) => {
    const intCheck = input.split(/(?!^\d+$)^.+$/);
    // console.log("input: ", input, "\nintCheck: ", intCheck);
    return false;
}

export default { Validator, StringifyFields, ValidateExtionsions };