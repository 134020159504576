import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import UserContext from '../../context/UserContext';
import LangContext from '../../context/LangContext';

import Licenses from '../Licenses';
import Popup from 'reactjs-popup';
import AddCertificate from "../AddCertificate";

import './fitters.css'
import CalFireLicense from './CalFireLicense';

const LicensesPage = () => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [ceuSubmissions, setCeuSubmissions] = useState([])
    const [certList, setCertList] = useState([]);
    const [currentImage, setCurrentImage] = useState({});

    const logValues = async (id, object) => {
        // console.log(object)
        const imageData = await axios.get(`${process.env.REACT_APP_API_URL}/firestore/image/${userData.user.userId}/${id}`);
        // console.log(imageData)
        if (imageData.data.length == 1) {
            setCurrentImage({ front: imageData.data[0].img_url });
        } else if (imageData.data.length > 1) {
            setCurrentImage({ front: imageData.data[0].img_url, back: imageData.data[1].img_url });
        }
        
        const ceuData = await axios.get(`${process.env.REACT_APP_API_URL}/firestore/image/${userData.user.userId}/${id}`);
    }

    const getBackground = (number) => {
        let colorReturn
        if(number == 1) {
            colorReturn = '#dc3545'
        } else if(number == 2) {
            colorReturn = '#198754'
        }

        return colorReturn;
    }

    const validateDate = (date) => {
        const isValid = /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date);
    
        return isValid
    }
    
    const calculateRemaining = (date) => {
        let buttonStyle;
        const split_date = String(date).split("-");
        const year = split_date[0];
        const month = split_date[1];
        const day = split_date[2];
        let ms_expire = Date.UTC(year, month-1, day);
        let ms_uncorrected = Date.UTC(year, month, day);
        const days_left = Math.round((ms_expire - Date.now()) / (8.64 * Math.pow(10, 7)));

        if (days_left < 0) {
            buttonStyle = "btn btn-secondary mt-3"
        } else if (days_left < 14) {
            buttonStyle = "btn btn-danger mt-3"
        } else if (days_left < 60) {
            buttonStyle = "btn btn-warning mt-3"
        } else {
            buttonStyle = "btn btn-primary mt-3"
        }

        return buttonStyle;
    }

    const calculateCertColor = (exp_date) => {
        if (validateDate(exp_date)) {
            return calculateRemaining(exp_date);
        } else {
            return "btn btn-primary mt-3"
        }
    }

    const resetValues = () => {
        setCurrentImage("")
    }

    const downloadPDF = (image) => {
        window.open(image)
    }

    useEffect(() => {
        (async () => {
            try {
                // console.log(userData);
                const cert_data = await axios.get(`${process.env.REACT_APP_API_URL}/certification/holder/${userData.user.userId}`, {
                    headers: { "x-auth-token": localStorage.getItem("auth-token") }
                });
                setCertList(cert_data.data);

                const priorCEUSubmissions = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/hours/ceu/${userData.user.userId}`)
                const absorbCEUs = await axios.get(`${process.env.REACT_APP_API_URL}/absorb/enrollments/${userData.user.userId}`)
                const absorbFormattedCEUs = absorbCEUs.data.map((ceu) => ({
                    reason: '',
                    approved: 2,
                    hours: ceu.Credits * 10,
                    description: `${ceu.CourseName} - ${Date(ceu.DateCompleted).toString()}`,
                    img_url: ceu.CertificateId
                        ? `https://acteducate.myabsorb.com/admin/certificates/download/${ceu.CertificateId}?pdf=true`
                        : 'null',
                }))

                const overall_ceus = [ ...priorCEUSubmissions.data, ...absorbFormattedCEUs]
                setCeuSubmissions(overall_ceus)

            } catch (err) {
                toast.error(err.message.data)
            }
        })()
    }, [userData]);

    return (
        <div className='fitter-profile'>
            <div className='d-flex align-items-center'>
                <h1 className='h1 mt-5'>
                    Fitter Profile
                </h1>
            </div>
            <CalFireLicense 
                id={userData.userId}
                loaded_ceus={ceuSubmissions}
            />
            <div className="licenses-card card p-3 my-5">
                <div className="row license-buttons w-100">
                <h3 className="h3 mt-3">
                    {langData.license_page.lice_and_cert}
                </h3>
                    <div className="row mt-2">
                        <div className="profile-field">
                            <Popup
                                modal
                                trigger={<button className="btn btn-outline-primary">{langData.license_page.submit_cert_button}</button>}
                            >
                                <AddCertificate 
                                    user={userData.userId}
                                />
                            </Popup>
                        </div>
                    </div>
                <div>
                </div>
                    {certList.length == 0 ? (
                        <i className='mb-3 mt-4'>{langData.license_page.no_certs_to_display}</i>
                    ) : (
                        <div>
                        {certList.map((cert) => {
                            return (
                                <div>
                                    <Popup
                                        onOpen={() => logValues(cert.id, cert)}
                                        modal
                                        onClose={resetValues}
                                        trigger={
                                            <button
                                                value={[
                                                    cert.id,
                                                    cert.name,
                                                    cert.reference_number,
                                                    cert.renewal_frequency,
                                                ]}

                                                className={calculateCertColor(cert.expiration_date)}
                                            >
                                                {cert.name}
                                            </button>
                                        }
                                    >
                                        <Licenses
                                            name={cert.name}
                                            reference={cert.reference_number}
                                            credits_required={cert.credits_required}
                                            renewal_frequency={cert.renewal_frequency}
                                            classification={cert.classification}
                                            status={cert.status}
                                            rating={cert.rating}
                                            issued_date={cert.issued_date}
                                            state={cert.state}
                                            region={cert.region}
                                            notes={cert.notes}
                                            trainer_name={cert.trainer_name}
                                            re_certification_date={cert.re_certification_date}
                                            employee_id={cert.employee_id}
                                            eye_color={cert.eye_color}
                                            hair_color={cert.hair_color}
                                            weight={cert.weight}
                                            height={cert.height}
                                            birth_date={cert.birth_date}
                                            license_number={cert.license_number}
                                            type={cert.type}
                                            expiration_date={cert.expiration_date} 
                                            credits_earned={cert.credits_required}
                                            front_image={currentImage.front}
                                            back_image={currentImage.back}
                                        />
                                    </Popup>
                                </div>
                            )
                        })}
                        </div>
                    )}
                </div>
            </div>
            {/* <div className="licenses-card card p-3 my-5">
                <div className="row license-buttons w-100">
                    <h3 className='h3 mt-3'>
                        {langData.license_page.current_ceu_header}
                    </h3>
                    {!ceuSubmissions.length ? (
                        <strong>
                            {langData.license_page.no_ceus_to_display}
                        </strong>
                    ) : (
                        <div>
                            {ceuSubmissions.map((ceu) => {
                                return (
                                    <div className="card text-left p-1 my-3"  style={{ outline: `1px solid ${getBackground(ceu.approved)}` }}>
                                        <div className='card-body'>
                                            <div className="flexy-ceu-card d-flex justify-content-between align-items-center">
                                                <div className='ceu-description'>
                                                    <h5 className='h5'><span>{ceu.hours}</span> CEUs</h5>
                                                    <p>{(ceu.description || '').split(' - ')[0]}</p>
                                                </div>
                                                {ceu.reason && 
                                                    <div className="d-flex">
                                                        <strong>
                                                            {langData.license_page.reason_for_change}
                                                        </strong>
                                                        <p className="ml-2">
                                                            {ceu.reason}
                                                        </p>
                                                    </div>
                                                } 
                                                <div className="d-flex">
                                                    {ceu.img_url.includes('pdf') ? (
                                                        <button className="btn btn-outline-primary" onClick={() => downloadPDF(ceu.img_url)}>
                                                            {langData.license_page.view_pdf_button}
                                                        </button>
                                                    ) : (
                                                        <div className='ceu-image-wrapper'>
                                                            <img src={ceu.img_url} className="ceu-image" onClick={() => downloadPDF(ceu.img_url)} />
                                                            <i>
                                                                {langData.license_page.click_to_expand}
                                                            </i>
                                                        </div>
                                                    )} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div> */}
        </div>
    )
}

export default LicensesPage
