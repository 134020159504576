import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";

import ACTImage from "../images/act.png";
import UserContext from '../context/UserContext';
import LangContext from "../context/LangContext";
import FunctionalButtons from "./FunctionalButtons";
import sendNotification from '../exportcomponents/NotificationExports';
import { JobInvite } from '../exportcomponents/FormExports';
import Navbar from '../components/Navbar'

const NotificationsPage = () => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const [notifications, setNotifications] = useState([]);
    const [invites, setInvites] = useState([]);

    const loadNotifications = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/notification/${userData.userId}`);
            // console.log(data);
            setNotifications(data);
        } catch (err) {
            toast.error(err.data.message);
        }
        
    }

    const loadInvites = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/invite/${userData.userId}`);
            // console.log(data);
            setInvites(data);
        } catch (err) {
            toast.error(err.message);
        }
    }

    const deleteNotification = async (e) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/notification/${e.target.value}`);
            loadNotifications();
        } catch (err) {
            toast.error(err.message);
        }
    }

    const deleteInvite = async (e) => {
        try {
            // console.log(e.target.value);
            await axios.delete(`${process.env.REACT_APP_API_URL}/invite/${e.target.value}`);
            loadInvites();
        } catch (err) {
            toast.error(err.message);
        }
    }

    useEffect(() => {
        if (!userData.user) return navigate("/");
        loadNotifications();
        loadInvites();
    }, []);

    return (
        <div>
            <div className="container notifications">

                <div className="row one mt-5">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="card">
                            <div className="card-header header-color">
                                <strong style={{color: "white"}}>{langData.notifications.notification}</strong>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    {notifications.map((notify, index) => (
                                        <li
                                            key={index}
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            {notify.message}
                                            <button
                                                onClick={deleteNotification} value={notify.id}
                                                className="btn btn-danger text-light"
                                            >
                                                X
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row two mt-5">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="card">
                            <div className="card-header header-color">
                            <strong style={{color: "white"}}>{langData.notifications.invite}</strong>
                            </div>
                            <div className="card-body">
                                {invites.map((invite, index) => (
                                <ul className="d-flex mb-2" style={{height: '60px'}}>
                                    <Popup
                                        modal
                                        trigger={
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                {invite.message}
                                            </li>
                                        }
                                    >
                                        <JobInvite invite={invite} deleteCallback={deleteInvite}/>
                                    </Popup>
                                    <button
                                        onClick={deleteInvite} value={invite.id}
                                        className="btn btn-danger text-light ml-1"
                                    >X</button>
                                </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default NotificationsPage;
