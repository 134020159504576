import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import ACTImage from "../images/act.png";
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';
import { Link, useNavigate } from "react-router-dom";
import { en, sp } from '../context/Translations';
import './styles.css';

import BrowseCourses from "./BrowseCourses";
import AbsorbCourses from './Courses/AbsorbCourses';
import Navbar from './Navbar';

const CoursePage = () => {
	const { userData, setUserData } = useContext(UserContext);
    const { langData, setLangData } = useContext(LangContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
        setUserData({
            token: undefined,
            user: undefined,
        });

        localStorage.setItem("auth-token", "");
        navigate("/")
    }

    const langChange = () => {
        setLangData(langData == en ? sp : en);
    }

    useEffect(() => {
        const checkToken = localStorage.getItem('auth-token');
		
        if (checkToken != null && checkToken != "" && userData.user == undefined) {
            navigate('/');
        }
        if (userData.user !== undefined) {
            setLoggedIn(true);
        }
    }, [userData.user]);
    
	return (
		<div>
            <AbsorbCourses />
            {/* <div className="card absorb disclaim-footer m-3 p-3 mt-5">
                <p>{langData.absorb_courses.disclaimer}</p>
            </div> */}
        </div>
	);
}

export default CoursePage;