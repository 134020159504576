import React, { useState, useContext } from 'react';
import LangContext from '../context/LangContext';

export const NameField = ({ onChange = null, grayover = false, preFilled = "" }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="name"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.name : preFilled}
			readOnly={grayover}
		/>
	);
}

export const RefNumField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="reference_number"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.ref_num : preFilled}
			readOnly={grayover}
		/>
	);
}

export const ClassificationField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="classification"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.classification : preFilled}
			readOnly={grayover}
		/>
	);
}

export const RatingField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="rating"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.rating : preFilled}
			readOnly={grayover}
		/>
	);
}

export const DateIssuedField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="date"
			name="issued_date"
			onChange={onChange}
			defaultValue={preFilled === null || preFilled === "" ? null : preFilled}
			readOnly={grayover}
		/>
	);
}

export const StateField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="state"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.state : preFilled}
			readOnly={grayover}
		/>
	);
}

export const RegionField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="region"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.region : preFilled}
			readOnly={grayover}
		/>
	);
}

export const NotesField = ({ onChange = null, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="notes"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.notes : preFilled}
			readOnly={grayover}
		/>
	);
}


export const IssuedToField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="name"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.name_on_cert : preFilled}
			readOnly={grayover}
		/>
	);
}

export const TrainerNameField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="trainer_name"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.trainer_name : preFilled}
			readOnly={grayover}
		/>
	);
}

export const ReCertificationDateField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="re_certification_date"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.re_certification_date : preFilled}
			readOnly={grayover}
		/>
	);
}

export const EmployeeIDField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="employee_id"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.employee_id : preFilled}
			readOnly={grayover}
		/>
	);
}

export const EyeColorField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="eye_color"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.eye_color : preFilled}
			readOnly={grayover}
		/>
	);
}

export const HairColorField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="hair_color"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.hair_color : preFilled}
			readOnly={grayover}
		/>
	);
}

export const WeightField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="weight"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.weight : preFilled}
			readOnly={grayover}
		/>
	);
}

export const HeightField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="height"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.height : preFilled}
			readOnly={grayover}
		/>
	);
}

export const DateOfBirthField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="birth_date"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.birth_date : preFilled}
			readOnly={grayover}
		/>
	);
}

export const LicenseNumberField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="license_number"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.license_number : preFilled}
			readOnly={grayover}
		/>
	);
}

export const TypeField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="text"
			name="type"
			onChange={onChange}
			placeholder={preFilled === null || preFilled === "" ? langData.field_exports.type : preFilled}
			readOnly={grayover}
		/>
	);
}

export const ExpirationDateField = ({ onChange, grayover = false, preFilled = null }) => {
	const { langData } = useContext(LangContext);
	return (
		<input
			className="form-control"
			type="date"
			name="expiration_date"
			onChange={onChange}
			defaultValue={preFilled === null || preFilled === "" ? null : preFilled}
			readOnly={grayover}
		/>
	);
}

export const ButtonForFields = ({ onFieldRemove, value }) => {
	return (
		<button className="btn btn-primary" value="ButtonForFields" onClick={() => onFieldRemove(value)}>
			<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
				<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
			</svg>
		</button>
	);
}

export default { 
	NameField, 
	IssuedToField, 
    RefNumField, 
    ClassificationField, 
    RatingField, 
    DateIssuedField, 
    StateField, 
    RegionField, 
    NotesField, 
    TrainerNameField, 
    ReCertificationDateField, 
    EmployeeIDField, 
    EyeColorField, 
    HairColorField, 
    WeightField, 
    HeightField, 
    DateOfBirthField, 
    LicenseNumberField, 
    TypeField, 
    ExpirationDateField,
    ButtonForFields
};