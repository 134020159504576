import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import Popup from "reactjs-popup";
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ImagePopup from '../admincomponents/ImagePopup';
import sendNotification from '../exportcomponents/NotificationExports';
import { 
    DenialMessage,
    JobInfo 
} from '../exportcomponents/PopupExports';
import { collapsedStyle } from '../exportcomponents/StyleExports'

const JobsPending = ({ onLastClicked }) => {
    const { userData } = useContext(UserContext);
	const { langData } = useContext(LangContext);
    const navigate = useNavigate()
    const [certPendingSearch, setCertPendingSearch] = useState("")
    const [superEmail, setSuperEmail] = useState("");
    const [jobsPending, setJobsPending] = useState([]);
    const [jobsTab, setJobsTab] = useState()
    const [selectedJob, setSelectedJob] = useState({})

    const onPendingJobApprove = async (value) => {
		// console.log('approve that job!');
		// console.log(value.admin_email);
		try {
			if (value.business_email !== 'Internal') {
				let admin_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/admin/${value.admin_email}`)
				// console.log(admin_data.data);
				sendNotification(admin_data.data.id, `Your job posting for ${value.title} has been approved!`);
			}
			await axios.put(`${process.env.REACT_APP_API_URL}/job/approve/${value.id}`);
			const job_data = await axios.get(`${process.env.REACT_APP_API_URL}/job/pending`);
			setJobsPending(job_data.data);
            toast.success('Job approved successfully')
		} catch (err) {
            toast.error('Something went wrong...')
			console.error(err)
		}
	}

	const onPendingJobDelete = async (value, message) => {
		// console.log("Forget that silly cert!");
		// console.log(message);
		// console.log(value);
		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}/job/jobs/${value.id}`);
			let admin_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/admin/${value.admin_email}`)
			// console.log(admin_data.data);
			sendNotification(admin_data.data.id, `Sorry, Your job listing was denied for the following reason(s): ${message}`);
			const job_data = await axios.get(`${process.env.REACT_APP_API_URL}/job/pending`);
			setJobsPending(job_data.data);
            toast.warning('Job deleted successfully')
		} catch (err) {
            toast.error('Something went wrong...')
			console.error(err)
		}
	}

    async function asyncSetupJobsPending() {
        try {
            const job_data = await axios.get(`${process.env.REACT_APP_API_URL}/job/pending`);
            setJobsPending(job_data.data);
        } catch (err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
		if (!userData.user) return navigate("/");
		setSuperEmail(userData.user.login);
		asyncSetupJobsPending()
	}, []);

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                <h3 className='h3 text-secondary'>{langData.super_functionality.jobs_pending}</h3>
                <div className='d-flex justify-content-end'>
                    <div className="input-group rounded mr-2">
                        <input
                            onChange={(e) => setCertPendingSearch(e.target.value)}
                            type="search"
                            className="form-control rounded"
                            placeholder={langData.placeholders.search}
                            aria-label="Search"
                            aria-describedby="search-addon"
                            value={certPendingSearch}
                        />
                        <span className="input-group-text border-0" id="search-addon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </span>
                    </div>
                    <div className="tab-title apprentice rounded">
                        <h1>{jobsPending.length}</h1>
                    </div>
                    <span onClick={(e) => asyncSetupJobsPending()} className="input-group-text rounded border-0 ml-3">
                        <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z"/></g>
                        </svg>
                    </span>
                </div>
            </div>
            <div className='min-vh-50'>
                {jobsPending.map((job, i) => {
                    return (
                        <div key={i} className={`card card-body mt-3 ${selectedJob.id == job.id && 'select-highlight'}`} onClick={() => setSelectedJob(job)}>
                            <div className='row'>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.job}
                                    </strong>
                                    <p>
                                        {job.title}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.description}
                                    </strong>
                                    <p>
                                        {job.description}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.company}
                                    </strong>
                                    <p>
                                        {job.business_name}
                                    </p>
                                </div>
                                <div className='col-sm'>
                                    <strong className="mt-2">
                                        {langData.super_functionality.author}
                                    </strong>
                                    <p>
                                        {job.business_email}
                                    </p>
                                </div>
                            </div>
                        </div>
                        // <div key={i} className="mt-3 d-flex justify-content-center card">
                        //     <p className="employee mt-2">
                        //         {langData.super_functionality.job}
                        //     </p>
                        //     <p className="sub-info licenses-link">
                        //         {job.title}
                        //     </p>
                        //     <p className="employee mt-2">
                        //         {langData.super_functionality.description}
                        //     </p>
                        //     <p className="sub-info licenses-link">
                        //         {job.description}
                        //     </p>
                        //     <p className="employee mt-2">
                        //         {langData.super_functionality.company}
                        //     </p>
                        //     <p className="sub-info licenses-link">
                        //         {job.business_name}
                        //     </p>
                        //     <p className="employee mt-2">
                        //         {langData.super_functionality.author}
                        //     </p>
                        //     <p className="sub-info licenses-link">
                        //         {job.business_email}
                        //     </p>
                        //     <div className="button-placement mt-3 mb-3">
                        //         {/* <button className="btn btn-primary" onClick={() => onPendingJobApprove(job)}>{langData.super_functionality.approve}</button>
                        //         <Popup
                        //             modal
                        //             trigger={<button className="btn btn-danger space">{langData.super_functionality.deny}</button>}
                        //         >
                        //             <DenialMessage value={job} onFinishCallback={onPendingJobDelete} />
                        //         </Popup>
                        //         <Popup
                        //             modal
                        //             trigger={<button className="btn btn-warning space">{langData.super_functionality.info}</button>}
                        //         >
                        //             <JobInfo jobId={job.id} />
                        //         </Popup> */}
                        //     </div>
                        // </div>
                    )
                })}
            </div>
            <div className='d-flex justify-content-center position-sticky bottom-0'>
                <div className='opaque p-3 w-75 d-flex justify-content-evenly align-items-center'>
                    <button className="text-nowrap m-1" onClick={() => onPendingJobApprove(selectedJob)}>{langData.super_functionality.approve}</button>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.deny}</button>}
                    >
                        <DenialMessage value={selectedJob} onFinishCallback={onPendingJobDelete} />
                    </Popup>
                    <Popup
                        modal
                        trigger={<button className="text-nowrap m-1">{langData.super_functionality.info}</button>}
                    >
                        <JobInfo jobId={selectedJob.id} />
                    </Popup>
                </div>
            </div>
        </div>
    )
}

export default JobsPending
