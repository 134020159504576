import { useState, useContext } from "react"
import { EventInfo, EventRegistration } from './index'
import LangContext from "../../context/LangContext"

const EventUserPopup = ({ event, open_display='info', onRegistered=() => {} }) => {
    const [display, setDisplay] = useState(open_display)
    const { langData: { events: eventLang }} = useContext(LangContext)

    function onDisplayChange(new_display) {
        setDisplay(new_display)
    }
    
    return (
        <div className={`form-background popup-scrollable wide-popup ${display == 'info' ? 'event-popup' : ''}`}>
            <p className="text-sm mb-1">{display == 'info' ? eventLang.info_sub_header : eventLang.registration_sub_header}</p>
            {display == 'info'
             ? (<EventInfo event={event} onReserveClick={() => onDisplayChange('register')} />)
             : (<EventRegistration event={event} onGoBackClick={() => onDisplayChange('info')} onRegistered={onRegistered}/>)
            }
        </div>
    )
}

export default EventUserPopup