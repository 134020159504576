import React, { useState, useEffect, useContext } from 'react';
import ACTImage from "../../images/act.png";
import { useLocation } from 'react-router-dom';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import Popup from 'reactjs-popup';
import FunctionalButtons from "../FunctionalButtons"
import { useStripe } from '@stripe/react-stripe-js';
import '../styles.css';

import SignUpAdmin from '../../admincomponents/SignUpAdmin';
import { PaymentRedirect } from '../../exportcomponents/PopupExports';
import { toast } from 'react-toastify';
import LangContext from '../../context/LangContext';
import loadingGIF from '../../images/loadinggif.gif';

const Onboard = () => {
    // VARIABLES
    // Configs
    const url_params = new URLSearchParams(useLocation().search.slice(1));
    const navigate = useNavigate();
    const stripe = useStripe();
    const { langData } = useContext(LangContext);
    // Payment Redirect
    const [redirect, setRedirect] = useState(false);
    const [redirectSession, setRedirectSession] = useState(null);
    const [redirectURL, setRedirectURL] = useState(null);
    const [redirectMessage, setRedirectMessage] = useState("");
    // Conditional Render
    const [renderForm, setRenderForm] = useState(false);
    const [renderReset, setRenderReset] = useState(false);
    const [newPasswords, setNewPasswords] = useState({ pass: "", confirm: "" });
    const [quoteData, setQuoteData] = useState({});

    const onPaymentRedirect = (session_id, redirect_msg) => {
        setRedirectMessage(redirect_msg);
        setRedirectSession(session_id);
        // setRedirectURL()
        setRedirect(true);
    }

    const loadSession = async (quote) => {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, {
            mode: 'setup',
            payment_method_types: ['card', 'sepa_debit'],
            email: quote.email
        });

        // console.log(data);

        onPaymentRedirect(data.id, "Last step is to fill out your paytment information for recurring payments");
    }

    const loadQuotes = async () => {
        if (url_params.getAll('quote_id')[0]) {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/quote/id/${url_params.getAll('quote_id')[0]}`);
            // console.log(data);
            if (data != "") {
                // console.log(data)
                setQuoteData(data);
                setRenderForm(true);
                return data;
            }
            return null;
        } else if (url_params.getAll('auto_approve')[0]) {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/quote/account/${url_params.getAll('auto_approve')[0]}`);
            // console.log(data);
            if (data != "") {
                setQuoteData(data);
                return data;
            }
            return null;
        }
        return null;
    }

    const autoApprove = async (quote) => {
        const approve_response = await axios.put(`${process.env.REACT_APP_API_URL}/account/user/approve/${quote.account_id}`, { 
            subscription_price: quote.subscription_price,
            down_price: quote.down_price,
            fitter_price: quote.fitter_price,
            apprentice_price: quote.apprentice_price,
            act_apprentice_price: quote.act_apprentice_price,
            trainee_price: quote.trainee_price,
            other_price: quote.other_price
        });

        if (approve_response.data.card_declined) toast.error('Card Declined')
    }

    const onPassResetClick = async (e) => {
        e.preventDefault();
        // console.log(newPasswords);
        if (newPasswords.pass != newPasswords.confirm) {
            toast.error('Please make sure your passwords match')
        } else {
            await axios.put(`${process.env.REACT_APP_API_URL}/account/resetpassword`,
            {
                employee: url_params.getAll('reset_pass')[0],
                password: newPasswords.pass,
                isEmail: true
            });
            toast.success('Password Reset Successful!')
            navigate('/')
        }
    }

    async function tryNonSubPaid() {
        const nonSubStatus = await axios.put(`${process.env.REACT_APP_API_URL}/payment/set-nonsub-paid/${url_params.getAll('non_sub_paid')[0]}`)
        const { success } = nonSubStatus.data
        if (success) {
            setTimeout(navigate('/'), 3000)
            return toast.success('Account Setup Verified')
        }
        toast.error("Something went wrong")
    }

    async function asyncOnboardSetup() {
        if (stripe != undefined) {
            // console.log(url_params.getAll('quote_id'));
            // console.log(url_params.getAll('auto_approve'));
            // console.log(url_params.getAll('done'));
            // console.log(url_params.getAll('pending_payment'));
            // console.log(url_params.getAll('cancel'));
            
            if (url_params.getAll('done')[0]) {
                try{
                    const sessionData = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-admin-session`, { account_id: url_params.getAll('done')[0] });
                    const { session_id } = sessionData.data;

                    setTimeout(async () => (
                        await stripe.redirectToCheckout({
                            sessionId: session_id
                        })
                    ), 10000);
                } catch (err) {
                    toast.error(err.message);
                }            
            } else if (url_params.getAll('pending_payment')[0]) {
                try {
                    setTimeout(() => navigate("/"), 10000);
                    await axios.delete(`${process.env.REACT_APP_API_URL}/payment/pending-payment/delete/${url_params.getAll('pending_payment')[0]}`)
                        .then(() => {
                            toast.success(langData.toast.card_saved);
                        });
                } catch (err) {
                    toast.error(err.message);
                }
            } else if (url_params.getAll('cancel')[0]) {
                try {
                    setTimeout(() => navigate("/"), 10000);
                    await axios.delete(`${process.env.REACT_APP_API_URL}/account/user/${url_params.getAll('cancel')[0]}`)
                        .then(() => {
                            toast.error(langData.toast.sign_up_canceled_card_required);
                        });
                } catch (err) {
                    toast.error(err.message);
                }
            } else if (url_params.getAll('tokens')[0] && url_params.getAll('account_id')[0]) {
                try{
                    setTimeout(() => navigate('/'), 2000);
                    const purchase_success = await axios.put(`${process.env.REACT_APP_API_URL}/absorb/update-tokens`, {
                        account_id: url_params.getAll('account_id')[0],
                        token_quantity: parseInt(url_params.getAll('tokens')[0]),
                        purchase_id: url_params.getAll('purchase_id')[0]
                    });

                    if (purchase_success.data.invalid_purchase_id) return toast.error('Invalid Token Purchase')
                    toast.success('Token Added to Your Account!')
                } catch (err) {
                    toast.error(err.message);
                }
            } else if (url_params.getAll('reset_pass')[0]) {
                try{
                    setRenderReset(true);
                } catch (err) {
                    toast.err(err.message);
                }
            } else if (url_params.getAll('non_sub_paid')[0]) {
                try {
                    tryNonSubPaid()
                } catch (err) {
                    toast.err(err.message)
                }
            }


            loadQuotes()
                .then((data) => {
                    // setTimeout(() => navigate("/"), 10000);
                    if (url_params.getAll('auto_approve')[0]) {
                        setTimeout(() => navigate("/"), 10000);
                        autoApprove(data);
                    } else if (url_params.getAll('quote_id')[0]) {
                        
                    }
                }
            );
        }
    }

    useEffect(() => {
        asyncOnboardSetup()
    }, [stripe]);

    return (
        <>
            {!renderReset ? (
                <div>
                    <div>
                        <strong>Please wait while we update your account!</strong>
                    </div>
                    <img alt=" " src={loadingGIF} />
                    <div className="card m-3 mt-3 p-2">
                        <p>{langData.onboard.please_wait_message}</p>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column justify-content-between text-left mb-5 pb-5 mx-5 px-5'>
                    <h1 className='h1'>
                        {langData.onboard.pass_reset}
                    </h1>
                    <div className='sub-control-cluster left'>
                        {/* <p className='h5 fw-light'>
                            {langData.onboard.pass_reset_body}
                        </p> */}
                        <div className="d-grid">
                            <label className='fw-light h5'>
                                {langData.onboard.new_pass}
                            </label>
                            <input
                                type="password"
                                name="pass"
                                onChange={(e) => setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value})}
                            />
                        </div>
                        <div className="d-grid">
                            <label className='fw-light h5'>
                                {langData.onboard.confirm_pass}
                            </label>
                            <input
                                type="password"
                                name="confirm"
                                onChange={(e) => setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value})}
                            />
                        </div>
                        <button onClick={onPassResetClick}>
                            {langData.onboard.pass_reset_button}
                        </button>
                    </div>
                    <span>
                        {langData.onboard.footer_1}&nbsp;
                        <a href="mailto:info@acteducate.com?subject=Emailed Link Complaint">
                            info@acteducate
                        </a> 
                        &nbsp;{langData.onboard.footer_2}
                    </span>
                </div>
            )}
        </>
        // <div>
        //     <div className='h-100!'>
        //         {renderReset ? (
                    
        //             // <div className="form-background">
        //             //     <div className="form-group">
        //             //         <strong>New Password</strong>
        //             //         <input
        //             //             type="password"
        //             //             name="pass"
        //             //             onChange={(e) => setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value})}
        //             //             className="form-control"
        //             //         />
        //             //     </div>
        //             //     <div className="form-group">
        //             //         <strong>Confirm Password</strong>
        //             //         <input
        //             //             type="password"
        //             //             name="confirm"
        //             //             onChange={(e) => setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value})}
        //             //             className="form-control"
        //             //         />
        //             //     </div>
        //             //     <div>
        //             //         <button
        //             //             className="btn btn-primary mt-3"
        //             //             onClick={(e) => onPassResetClick(e)}
        //             //         >
        //             //             Reset Password
        //             //         </button>
        //             //     </div>
        //             // </div>
        //         ) : (
        //             <div>
        //                 {renderForm == false ? (
        //                     <div>
        //                         <div>
        //                             <strong>Please wait while we update your account!</strong>
        //                         </div>
        //                         <img alt=" " src={loadingGIF} />
        //                         <div className="card m-3 mt-3 p-2">
        //                             <p>{langData.onboard.please_wait_message}</p>
        //                         </div>
        //                     </div>
        //                 ) : (
        //                     <div>
        //                         <div className="onboard quote-card card p-3">
        //                             <header className="onboard quote-header">Quoted Prices:</header>
        //                             <hr style={{ height: '2px', background: 'black' }} />
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Subscription Price:</strong>&nbsp;
        //                                 ${quoteData.subscription_price}.00
        //                             </p>
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Down Payment:</strong>&nbsp;
        //                                 ${quoteData.down_price}.00
        //                             </p>
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Price Per/Fitter:</strong>&nbsp;
        //                                 ${quoteData.fitter_price}.00
        //                             </p>
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Price Per/ACT-Apprentice:</strong>&nbsp;
        //                                 ${quoteData.apprentice_price}.00
        //                             </p>
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Price Per/Non ACT-Apprentice:</strong>&nbsp;
        //                                 ${quoteData.act_apprentice_price}.00
        //                             </p>
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Price Per/Trainee:</strong>&nbsp;
        //                                 ${quoteData.trainee_price}.00
        //                             </p>
        //                             <p className="onboard quoted-price d-flex w-100 justify-content-between">
        //                                 <strong>Price Per/Other Employee:</strong>&nbsp;
        //                                 ${quoteData.other_price}.00
        //                             </p>
        //                         </div>
        //                         <div className="mb-5">
        //                         <SignUpAdmin card={true} quote={quoteData} redirectCallback={onPaymentRedirect} />
                                
        //                         <Popup
        //                             modal
        //                             onClose={() => setRedirect(false)}
        //                             open={redirect}
        //                             closeOnDocumentClick={false}
        //                         >
        //                             <PaymentRedirect
        //                                 message={redirectMessage}
        //                                 session_id={redirectSession}
        //                                 redirect_url={redirectURL}
        //                             />
        //                         </Popup>
        //                         </div>
        //                     </div>
        //                 )}
        //             </div>
        //         )}
        //     </div>
        // </div>

    );
}

export default Onboard;