import { useState, useEffect } from 'react'
import EventsApi from './api'
import { toast } from 'react-toastify'

import EventForm from './Form'
import './events.css'

const EventEdit = ({ event, onSuccess }) => {
    const eventsApi = new EventsApi()
    const formState = useState({})
    const benefitsState = useState([])
    const [form, setForm] = formState
    const [benefits, setBenefits] = benefitsState

    async function submit() {
        try {
            await eventsApi.editEvent(form, benefits)
            await onSuccess()
            toast.success('Event Updated Successfully')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again later...")
        }
    }

    async function asyncSetupEventEdit() {
        const { benefits, ...details } = event
        console.log(details)
        setForm(details)
        setBenefits(benefits)
    }

    useEffect(() => { asyncSetupEventEdit() }, [])

    return (
        <div className="form-background popup-scrollable wide-popup event-popup">
            <p className="text-sm mb-1">Event Edit</p>
            <h2 className="h2 mb-3">{event.title}</h2>

            <EventForm formState={formState} benefitsState={benefitsState} />

            {/* Button Group */}
            <div className="d-flex flex-column align-items-center gap-3 mt-5 mb-2">
                <button onClick={submit} className="btn btn-primary w-75">Save Event Changes</button>
                <button className="btn btn-outline-secondary w-50">Discard Changes</button>
            </div>
        </div>
    )
}

export default EventEdit