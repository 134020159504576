import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../admincomponents/admin.css'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import sendNotification from './NotificationExports';
import { Validator } from './ValidatorExports';

export function CreateJobForm({ admin_email, email, phone }) {
	const currentDate = new Date()
	const formattedDate = parseInt(currentDate.getMonth() + 1) + "/" + currentDate.getDate() + "/" + currentDate.getFullYear()
	const navigate = useNavigate();
	const userData = useContext(UserContext);
	const { langData } = useContext(LangContext);
	const [userInfo, setUserInfo] = useState({})
	const [form, setForm] = useState({});
	const [startDate, setStartDate] = useState(formattedDate);
	const [endDate, setEndDate] = useState(formattedDate)
	const [inviteForm, setInviteForm] = useState({});
	const [newHireForm, setNewHireForm] = useState({});
	const [certList, setCertList] = useState([]);
	const [requiredList, setRequiredList] = useState([]);
	const [customInvite, setCustomInvite] = useState(false);
	const [customQuestions, setCustomQuestions] = useState(false);
	const [questionList, setQuestionList] = useState([{ quest: "", len: 0 }]);

	const handleStartDate = (value, e) => {
		setNewHireForm({ ...newHireForm, start_date: value });
		const dateFormat = parseInt(value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
		setStartDate(dateFormat);
		// console.log(startDate);
	}

	const handleEndDate = (value, e) => {
		setNewHireForm({ ...newHireForm, end_date: value });
		const dateFormat = parseInt(value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
		setEndDate(dateFormat);
	}

	const onChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
		// console.log(startDate);
	};

	const onNewHireChange = (e) => {
		setNewHireForm({ ...newHireForm, [e.target.name]: e.target.value });
	}

	const onInviteChange = (e) => {
		setInviteForm({ ...inviteForm, [e.target.name]: e.target.value });
	}

	const onCertChange = (e) => {
		let newCerts = certList;
		// Move the data
		setRequiredList([...requiredList, certList[e.target.value]]);
		// Delete from dropdown list
		newCerts.splice(e.target.value, 1);
		newCerts.sort();
		setCertList(newCerts);
	}

	const onQuestionChange = (e, i) => {
		// console.log(e.target.value, e.target.value.length, i);
		// console.log(questionList[i]);
		if (questionList[questionList.length - 1].len > 0) {
			let newQuestions = questionList;
			newQuestions[i] = { ques: e.target.value, len: e.target.value.length }
			setQuestionList([...newQuestions, { ques: "", len: 0 }]);
		} else {
			let newQuestions = questionList;
			newQuestions[i] = { ques: e.target.value, len: e.target.value.length }
			setQuestionList([...newQuestions]);
		}
	}

	const onRequiredDelete = (e) => {
		e.preventDefault();
		let newRequired = requiredList;

		// console.log(e.target.value);
		// console.log(newRequired);

		setCertList([...certList, requiredList[e.target.value]]);
		newRequired.splice(e.target.value, 1);
		newRequired.sort();
		setRequiredList([...newRequired]);
	}

	const onSubmit = async (e) => {
		e.preventDefault();

		// console.log(form);

		try {
			const valid = Validator(form, ['title']);
			
			if (valid.length == 0) {
				const job_data = await axios.post(`${process.env.REACT_APP_API_URL}/job/`, { 
					...form,
					admin_email: admin_email,
					start_date: newHireForm.start_date,
					end_date: newHireForm.end_date,
					alt_business_name: newHireForm.alt_business_name,
					questions: questionList 
				});
				// return toast.success("TEST TEST TEST")
				await axios.post(`${process.env.REACT_APP_API_URL}/job/requirements/${job_data.data.id}`, { requirements: requiredList });
				await axios.post(`${process.env.REACT_APP_API_URL}/invite/custom`, { ...inviteForm, sender_id: userData.userData.userId, event_id: job_data.data.id });
				await axios.post(`${process.env.REACT_APP_API_URL}/job/newhire`, { ...newHireForm, job_id: job_data.data.id });
				toast.success(langData.toast.job_submitted_for_review);
				navigate('/')
				navigate('/jobs');
			} else {
				toast.error(langData.toast.please_enter_all_required);
			}
			

		} catch (err) {
			toast.error('Something went wrong with posting this job');
			// console.log(err);
		}
	}

	useEffect(() => {
		if (!userData) navigate('/');
		(async () => {
			await axios.get(`${process.env.REACT_APP_API_URL}/certification/`)
				.then((certs) => {
					let newCerts = certs.data;
					newCerts.sort();
					setCertList(newCerts);
				});


		})()
	}, []);

	return (
		<div>
			<form className="form-background popup-scrollable wide-popup" onSubmit={onSubmit} style={{ overflowY: 'scroll', height: '500px', width: '400px' }}>
				<h2 className="h2 mb-3">{langData.form_exports.create_job_header}</h2>
				<strong className="mb-2">{langData.form_exports.job_listing_header}</strong>
				<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>These are the main details of the job listing, and will be visible in the job search results</p>
				{/* <div className="form-group mb-5">
					<label>{langData.form_exports.industry}</label>
					<input
						className="form-control"
						onChange={onChange}
						name="industry"
						placeholder={langData.placeholders.enter_industry}
					/>
				</div> */}
				<div>
					<label>{langData.form_exports.title}</label>
					<input
						className="form-control mb-5"
						onChange={onChange}
						name="title"
						placeholder={langData.placeholders.enter_title}
					/>
				</div>
				<div>
					<label>{langData.form_exports.description}</label>
					<textarea
						className="form-control mb-5 min-textarea-height"
						onChange={onChange}
						name="description"
						placeholder={langData.placeholders.enter_desc}
					/>
				</div>
				<strong className="mb-2">{langData.form_exports.company_header}</strong>
				<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>Please provide all information that a new hire will need to start their new assignment</p>
				<div>
					<label>{langData.form_exports.address}</label>
					<input
						className="form-control mb-5"
						onChange={onNewHireChange}
						name="address"
						placeholder={langData.placeholders.enter_address}
					/>
				</div>
				<div>
					<label>{langData.form_exports.email}</label>
					<input
						className="form-control mb-5"
						onChange={onNewHireChange}
						name="email"
						placeholder={email}
					/>
				</div>
				<div>
					<label>{langData.form_exports.phone}</label>
					<input
						className="form-control mb-5"
						onChange={onNewHireChange}
						name="phone"
						placeholder={phone}
					/>
				</div>
				<div>
					<label>{langData.form_exports.alt_business_name}</label>
					<input
						className="form-control mb-5"
						onChange={onNewHireChange}
						name="alt_business_name"
						placeholder={langData.form_exports.alt_business_name_placeholder}
					/>
				</div>
				<div>
					<label>{langData.form_exports.instructions}</label>
					<textarea
						className="form-control mb-5 min-textarea-height"
						onChange={onNewHireChange}
						name="instructions"
						placeholder={langData.placeholders.enter_instructions_for_new_hire}
					/>
				</div>
				<strong className="mb-2">Posting Dates</strong>
				<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>Please specify the dates durring which you would like the job opportunity to be visible to potential applicants</p>
				<div className="start-date">
					<label>{langData.form_exports.start_date}</label>
					<DatePicker
						name="start_date"
						className="form-control mb-5"
						value={startDate}
						onChange={(value, e) => handleStartDate(value, e)} />
				</div>
				<div className="end-date">
					<label>{langData.form_exports.end_date}</label>
					<DatePicker
						name="end_date"
						className="form-control mb-5"
						value={endDate}
						onChange={(value, e) => handleEndDate(value, e)} />
				</div>
				<form className='mb-5'>
					<strong className="mb-2">{langData.form_exports.required_certs}</strong>
					<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>(Optional) You may select all certifications required for an applicant to be considered.</p>
					<select className="form-select mt-2 mb-3" aria-label="Default select example" onChange={onCertChange} value={"default_option"}>
						<option key={"default_option"} value={"default_option"}>{langData.form_exports.choose_certs}</option>
						{certList.map((cert, i) => {
							return (
								<option key={i} value={i}>{cert.name}</option>
							);
						})}
					</select>
					{requiredList.map((cert, i) => {
						return (
							<div className='card mb-1'>
								<div className="d-flex justify-content-between mb-1">
									<p className="ml-3 mt-1">
										{cert.name}
									</p>
									<button className="btn btn-outline-danger py-0 px-2 mt-1 mr-1" onClick={onRequiredDelete} value={i}>X</button>
								</div>
							</div>
						);
					})}
				</form>
				<div className="mt-2">
					{/* <strong className="mb-2">{langData.form_exports.custom_options}</strong>
					<div className="d-flex mb-2">
						<input className="mt-1 mr-3" type="checkbox" onChange={() => setCustomInvite(!customInvite)} />
						<p style={{ fontSize: '0.9rem', marginTop: '-1px', marginLeft: '-7px' }}>{langData.form_exports.customize_invite}</p>
					</div> */}
					{/* {customInvite && */}
						<>
							<div>
								<strong>{langData.form_exports.custom_apply_link}</strong>
								<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>If you have a link you'd like the applicant to use to complete an application, or schedule an interview, you may enter it here.</p>
								<input
									className="form-control mb-5"
									onChange={onInviteChange}
									name="link"
									placeholder={langData.placeholders.enter_hire_link}
								/>
							</div>
							{/* <div>
								<strong>{langData.form_exports.applicant_instructions}</strong>
								<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>If there's something your applicant needs to know, this is the place to list it.</p>
								<input
									className="form-control mb-5"
									onChange={onInviteChange}
									name="message"
									placeholder={langData.placeholders.enter_hire_instructions}
								/>
							</div> */}
						</>
					{/* } */}
					{/* <div className="d-flex mb-2">
						<input className="mt-1 mr-3" type="checkbox" onChange={() => setCustomQuestions(!customQuestions)} />
						<p style={{ fontSize: '0.9rem', marginTop: '-1px', marginLeft: '-7px' }}>{langData.form_exports.custom_questions}</p>
					</div> */}
					{/* {customQuestions && */}
						<div>
							<strong>Custom Questions</strong>
							<p style={{ fontSize: '0.9rem', fontStyle: 'italic', marginBottom: '0.5rem' }}>You can pre-screen your applicants by adding as many questions as you'd like.</p>
							<form>
								<label>{langData.form_exports.enter_custom_questions}</label>
								{questionList.map((question, i) => {
									return (
										<input
											className="form-control mb-4"
											onChange={(e) => onQuestionChange(e, i)}
											placeholder={`Enter Question ${i + 1}`}
										/>
									)
								})}
							</form>
						</div>
					{/* } */}
				</div>
				<button type="submit" className="btn btn-outline-primary mt-3">
					{langData.form_exports.submit}
                </button>
			</form>
		</div >
	);
}



export function InviteEmployees({ admin_email, admin_id, job_id, job_title }) {
	const { langData } = useContext(LangContext);
	const [employeeList, setEmployeeList] = useState([]);
	const [selectedEmp, setSelectedEmp] = useState();

	const onEmpChange = (e) => {
		// console.log(e.target.value);
		setSelectedEmp(e.target.value);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			const customInvite = await axios.get(`${process.env.REACT_APP_API_URL}/invite/custom/${admin_id}/${job_id}`);
			let customMessage = `Congratulations, you've been invited to apply to ${job_title}!`
			let customLink = customInvite.data.link;

			// console.log(customLink);

			if (customInvite.data.message !== null) {
				customMessage = customInvite.data.message;
			}

			await axios.post(`${process.env.REACT_APP_API_URL}/invite/`, {
				account_id: selectedEmp,
				event_id: job_id,
				link: customLink,
				message: customMessage
			});

			toast.success(langData.toast.invite_sent);
		} catch (err) {
			// console.log(err);
		}
	}

	useEffect(() => {
		(async () => {
			await axios.get(`${process.env.REACT_APP_API_URL}/account/employees/${admin_email}`)
				.then((emps) => {
					let empList = emps.data;
					empList.sort();
					setEmployeeList(empList);
					// console.log(empList);
				});
		})()
	}, []);

	return (
		<form className="form-background" onSubmit={onSubmit}>
			<label>{langData.form_exports.invite_employee}{job_title}</label>
			<select onChange={onEmpChange} className="form-select" aria-label="Default select example">
				<option key="default">{langData.form_exports.select_employee}</option>
				{employeeList.map((emp, i) => {
					return <option value={emp.id} key={i}>{emp.first_name}&nbsp;{emp.last_name}</option>
				})}
			</select>
			<button className="btn btn-primary mt-3" type="submit">{langData.form_exports.send_invite}</button>
		</form>
	)

}

export function JobInvite({ invite, deleteCallback }) {
	const { langData } = useContext(LangContext);
	const [job, setJob] = useState({});
	const [business, setBusiness] = useState(null);
	const { userData } = useContext(UserContext);

	useEffect(() => {
		//Get Job Info
		// console.log(invite);
		(async () => {
			await axios.get(`${process.env.REACT_APP_API_URL}/job/jobs/${invite.event_id}`)
				.then(({ data }) => {
					setJob(data);
					// console.log(data);
				});
		})()
	}, []);

	//Add to current job
	const acceptJob = async (e) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/job/apply`, {
			account_id: userData.user.userId,
			job_id: e.target.value
		});
	}

	//Delete job 
	const deleteJob = async (e) => {
		// sendNotification() This will notify the admin that the applicant turned down the rejection
		deleteCallback(e);
	}

	return (
		<div className="form-background">
			<div>
				<p>{langData.form_exports.congratulations}{business}</p>
			</div>
			<div>
				<strong>{langData.form_exports.job_title}</strong>
				<p>{job.title}</p>
			</div>
			<div>
				<strong>{langData.form_exports.job_industry}</strong>
				<p>{job.industry}</p>
			</div>
			<div>
				<strong>{langData.form_exports.job_desc}</strong>
				<p>{job.description}</p>
			</div>
			<button onClick={acceptJob} value={job.id} className="btn btn-outline-primary mr-3">
				{langData.form_exports.accept}
			</button>
			<button onClick={deleteJob} value={invite.id} className="btn btn-outline-primary">
				{langData.form_exports.deny}
			</button>
		</div>
	);
}

export function EditJobForm({ job_id }) {
	const { langData } = useContext(LangContext);
	const [form, setForm] = useState({});

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			await axios.put(`${process.env.REACT_APP_API_URL}/job/jobs/${job_id}`, form);
		} catch (err) {
			// console.log(err);
		}
	}

	const onFormChange = (e) => {
		let newForm = form;
		newForm[e.target.name] = e.target.value;
		setForm({ ...newForm });
	}

	return (
		<form onSubmit={onSubmit}>
			<input
				className="m-3"
				onChange={onFormChange}
				name="industry"
				placeholder={langData.form_exports.enter_industry}
			/>
			<input
				className="m-3"
				onChange={onFormChange}
				name="description"
				placeholder={langData.form_exports.enter_desc}
			/>
			<button className="btn btn-outline-primary" type="submit">
				{langData.form_exports.submit}
			</button>
		</form>
	);
}

export function ChangeClassification({ id, old_class, email, level }) {
	const { userData } = useContext(UserContext);
    const [form, setForm] = useState({ });
	const [bypassForm, setBypassForm] = useState(false)
	const navigate = useNavigate();

	const onApproveSubmit = async (e) => {
		e.preventDefault();
		try {
			const changeSubmit = await axios.post(`${process.env.REACT_APP_API_URL}/account/request/classification`, 
			{  account_id: id, old_classification: old_class, new_classification: form.classification, email: email })
			// console.log(changeSubmit)

			// navigate('/')
			// navigate('/super')
			toast.success("Classification Change Sent For Approval!")
		} catch (err) {
			toast.error("Classification Change Already Requested!")
		}
		
	}

	const onBypassDropdown = async (e) => {
		setBypassForm(!bypassForm)
	}

	const onBypassSubmit = async (e) => {
		// setBypassForm(!bypassForm)
		console.log(form)
		e.preventDefault();
		const changeSubmit = await axios.post(`${process.env.REACT_APP_API_URL}/account/classification/employee/${id}`, 
		{ 
			classification: form.classification, 
			admin_id: form.admin,
			reason: form.reason,
			description: "Bypass Classification Change" 
		})
		// console.log(changeSubmit)

		// navigate('/')
		// navigate('/super')
		toast.success("Classification Changed!")
	}

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
		// console.log(form)
    };

	useEffect(() => {
		// console.log(email)
	})

	return (
		<div className="form-background">
			<strong>Change Employee Classification</strong>
			<select 
				className="form-select mt-3" 
				aria-label="Default select example"
                name="classification"
                onChange={onChange}
			>
				<option selected>Change Employee Classification</option>
				<option disabled={old_class == 'trainee'} value="trainee">Trainee</option>
				<option disabled={old_class == 'apprentice'} value="apprentice">Apprentice</option>
				<option disabled={old_class == 'fitter'} value="fitter">Fitter</option>
				<option disabled={old_class == 'fitter (Commercial)'} value="fitter (Commercial)">Commercial Fitter</option>
				<option disabled={old_class == 'fitter (Multi-Family)'} value="fitter (Multi-Family)">Multi-Family Fitter</option>
				<option disabled={old_class == 'other'} value="other">Other</option>
			</select>
            <div className="form-group mt-3">
                <strong for="exampleFormControlTextarea1">Reason For Classification Change</strong>
                <textarea 
                    className="form-control" 
                    id="exampleFormControlTextarea1" 
                    rows="3"
                    name="reason"
                    onChange={onChange}
                >
                </textarea>
            </div>
            {/* {!bypassForm ? (<button onClick={onApproveSubmit} className="btn btn-primary mt-3">Submit For Approval</button>) : (null)}
			{!bypassForm ? (<button onClick={onBypassDropdown} className="btn btn-primary mt-3">Submit And Bypass Approval</button>) : (null)}  */}
			<div><button onClick={onBypassSubmit} className="btn btn-primary mt-3">Submit</button></div>
		</div>
	)
}

export default { CreateJobForm, InviteEmployees, JobInvite, EditJobForm, ChangeClassification };
