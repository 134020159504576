import React, { useState, useContext } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './super.css';
import LangContext from '../context/LangContext';
import { UserInfo } from '../exportcomponents/PopupExports';
import Popup from 'reactjs-popup';

const UnpaidAccount = ({ account_id, is_employee }) => {
    const [buttonDisable, setButtonDisable] = useState(false);
    const stripe = useStripe();
    const { langData } = useContext(LangContext);
    const navigate = useNavigate()

    function noMash(callback, id) {
        setButtonDisable(true);
        setTimeout(() => setButtonDisable(false), 5000);
        callback();
    }

    const deactivate = async () => {
        // console.log('Deactivate: ', account_id)
        
    }

    const moveToHistory = async () => {
		setButtonDisable(true);
		setTimeout(() => {setButtonDisable(false)}, 1500);
		await axios.post(`${process.env.REACT_APP_API_URL}/account/history/${account_id}`);
		toast.success("Accounts Deactivated");
        // navigate('/')
        // navigate('/super')
	}

    const reactivate = async () => {
        // console.log('Reactivate', account_id);
        const customerData = await axios.get(`${process.env.REACT_APP_API_URL}/payment/id/${account_id}`);
        const session = await axios.post(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, { 
            account_id,
            customer_id: customerData.data.customerId,
            mode: 'subscription', 
            payment_method_types: ['card'], 
            price: customerData.data.subscription_price, 
            success_url: `${process.env.REACT_APP_VIEW_URL}/onboard?pending_payment=${account_id}` 
        });
        return stripe.redirectToCheckout({
            sessionId: session.data.id
        });
    }

    return (
        // <div className="m-2">
        <div className="unpaid-account p-2 pt-2">
            <div className="d-flex justify-content-around align-items-center h-100">
                <strong style={{ color: '#FFFFFF77', fontSize: 30 }}>{langData.super_functionality.payment_failed}</strong>
                <div className='d-flex justify-content-around'>
                    <Popup
                        modal
                        trigger={<button className="act-btn-outline mr-5">Info</button>}>
                        <UserInfo id={account_id}/>
                    </Popup>
                    {!is_employee && (
                        <button className="act-btn-outline mr-5" onClick={() => noMash(reactivate)} disabled={buttonDisable}>{langData.super_functionality.update_card}</button>
                    )}
                    <button className="act-btn-outline" onClick={() => noMash(moveToHistory)} disabled={buttonDisable}>{langData.super_functionality.deactivate}</button>
                </div>
            </div>
        </div>
    )
}

export default UnpaidAccount
