import { useContext } from 'react';
import LangContext from '../../context/LangContext';

const EventHelp = () => {
    const { langData: { events: eventLang }} = useContext(LangContext);
    
    return (
        <div className="form-background popup-scrollable wide-popup event-popup">
            <p className="text-sm mb-1">{eventLang.help_sub_header}</p>
            <h2 className="h2 mb-3">{eventLang.help_header}</h2>
            <div className="d-flex flex-row mt-5 mb-5">
                <button className="btn btn-primary help-button w-50 mr-1">
                    <span className="help-button-content">{eventLang.call}</span>
                    <span className="help-button-number">(951) 221-3405</span>
                </button>
                <button className="btn btn-outline-primary help-button w-50 ml-1">
                    <span className="help-button-content">{eventLang.text}</span>
                    <span className="help-button-number">(951) 223-8706</span>
                </button>
            </div>

            <div className="mb-3">
                <h3 className="h3 mb-3">FAQs</h3>
                <div className="mb-2">
                    <b>{eventLang.how_to_join}</b>
                    <p>{eventLang.how_to_join_answer}</p>
                </div>
                <hr />
                <div className="mb-2">
                    <b>{eventLang.how_to_get_credit}</b>
                    <p>{eventLang.how_to_get_credit_answer}</p>
                </div>
                <hr />
                <div className="mb-2">
                    <b>{eventLang.are_there_discounts}</b>
                    <p>{eventLang.are_there_discounts_answer}</p>
                </div>
                <hr />
                <div className="">
                    <b>{eventLang.what_about_refund}</b>
                    <p>{eventLang.what_about_refund_answer}</p>
                </div>
            </div>
        </div>
    )
}

export default EventHelp