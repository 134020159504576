import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';

import axios from "axios"
import { toast } from 'react-toastify';
import { Validator } from '../exportcomponents/ValidatorExports';

const AdminChange = ({ employee, liveRefresh=undefined }) => {
    const { langData } = useContext(LangContext);
    const [referenceId, setReferenceId] = useState("");
    const [leaveTokens, setLeaveTokens] = useState(true);
    const [oldBusinessName, setOldBusinessName] = useState(true);
    const [newRef, setNewRef] = useState(null)
    const [admins, setAdmins] = useState([])

    const onChange = (e) => {
        setNewRef(e.target.value)
        // console.log(newRef.newRef)
        // console.log(e.target.name);
        // console.log(e.target.value);
    };

    const submit = async (e, to_individual) => {
        e.preventDefault();
        try {
            // Setup form and validate data
            const new_admin = to_individual ? 'to_individual' : newRef
            const invalid_count = Validator({ newRef }, ['newRef']);
            console.log(new_admin)
            if (invalid_count.length > 0 & !to_individual) return toast.error(langData.toast.please_enter_all_required)

            // Submit change request
            const changeRequest = await axios.put(`${process.env.REACT_APP_API_URL}/account/adminchange/${employee.id}/${new_admin}`, { admin_change: true, leave_tokens: leaveTokens });
            console.log("and to not embarras ourselves, especially in front of carol: ", changeRequest.data)
            toast.success(changeRequest.data);
            if (liveRefresh) await liveRefresh()
        }
        catch (err) {
            toast.error(err.response.message);
        }
    };

    async function asyncAdminChangeSetup() {
        setReferenceId(employee.admin_email)
        setOldBusinessName(employee.business_name)

        const admin_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/admins`)
        setAdmins(admin_data.data)
    }

    useEffect(() => { asyncAdminChangeSetup() }, [])

    return (
        <div>
            <form className="form-background" onSubmit={submit}>
                <div className="form-group mb-3">
                    <h2 className="h2 mb-3">Change Employer</h2>
                    <label htmlFor="reference" className='fw-light'>Current Employer (CTP)</label>
                    <input
                        class="w-100"
                        value={oldBusinessName}
                        type="reference"
                        name="reference"
                        disabled="disabled"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="reference">{langData.admin_change.new_ref_num}</label>
                    <select 
                        class="w-100"
                        onChange={onChange}>
                        {admins.length == 0 ? (
                            <option>Loading CTP's</option>
                        ) : (admins.map((admin) => (
                            <option value={admin.business_number}>{admin.business_name}</option>
                        )))}
                    </select>
                    {/* <input
                        type="reference"
                        className="form-control"
                        name="newRef"
                        onChange={onChange}
                        placeholder={langData.placeholders.new_ref_num}
                    /> */}
                </div>
                {/* <div className="form-group d-flex justify-content-between w-100">
                    <label htmlFor="reference" className='fw-light'>Leave Tokens with CTP</label>
                    <input
                        checked={leaveTokens}
                        onClick={() => setLeaveTokens(!leaveTokens)}
                        type="checkbox"
                    />
                </div> */}
                <div className='mt-3 d-flex justify-content-start align-items-center'>
                    <input 
                        className="reset-show-password h-25" 
                        type="checkbox" 
                        checked={leaveTokens}
                        onClick={() => setLeaveTokens(!leaveTokens)}/>
                    <span className='reset-show-password mb-1 ml-2'>Leave Tokens with CTP</span>
                </div>
                <div className='d-flex justify-content-between mt-3'>
                    <button type="submit" className="btn btn-primary submit">
                        {langData.admin_change.submit}
                    </button>
                    <button className='btn btn-warning' onClick={(e) => submit(e, true)}>
                        {langData.admin_change.to_individual}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AdminChange
