import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import './admin.css'

export const EnrollSingle = ({employee, userData, super_enrollment}) => {
    if (!employee) employee = {}; // circumventing undefined errs :)
    const navigate = useNavigate();
    const [courseList, setCourseList] = useState([{Name: 'Loading Courses.....'}]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [learner, setLearner] = useState({});
    const [admin, setAdmin] = useState({});
    const [buttonDisable, setButtonDisable] = useState(false);
    const [useAdminTokens, setUseAdminTokens] = useState(false);
    const [useBoth, setUseBoth] = useState(false);
    const [employeeData, setEmployeeData] = useState({});

    const onEnroll = async () => {
        setButtonDisable(true);
        setTimeout(() => {setButtonDisable(false)}, 3000);
        let supplementary_id_param = undefined;
        // // console.log('RYAN USED ME AS AN EMPLOYEE OBJECT: ', learner);
        
        let account_id_param = employee.id || learner.account_id;
        if (useBoth) {
            supplementary_id_param = admin.account_id;
        } else if (useAdminTokens) {
            // // console.log('oops')
            account_id_param = admin.account_id;
        }
        let enroll_params = {
            account_id: account_id_param,
            course_id: selectedCourse.Id,
            learner_id: learner.learner_id,
            cost: selectedCourse.LearnerCost,
            supplementary_id: supplementary_id_param
        }
        // // console.log(enroll_params);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/absorb/enroll`, enroll_params);
            toast.success('User Enrolled Successfully');
        } catch (err) {
            if (err.response.status === 422) return toast.error("This user's Learner ID is either missing invalid, please contact an administrator");
            toast.error('There was an error enrolling the user in this course');
        }

        navigate('/');
        navigate(super_enrollment ? '/super' : '/admin');
    }

    const onCourseChange = (e) => {
        let selected = courseList.filter((course_i) => course_i.Id == e.target.value);
        // // console.log(selected)
        setSelectedCourse(selected[0]);
        setButtonDisable(selected[0].Status > 0)
    }

    const fetchCourseData = async (learnerData) => {
        try {
            const courseData = await axios.get(`${process.env.REACT_APP_API_URL}/absorb/courses/${learnerData.learner_id}`);
                
            // // console.log(courseData.data);
            setCourseList(courseData.data);
            setSelectedCourse(courseData.data[0]);
            setButtonDisable(courseData.data[0].Status > 0);
        } catch (err) {
            // // console.log(err);
        }
    }

    const COURSE_NAME_MAX_CHAR = 50
    const truncateCourseName = (course_name) => {
        return course_name.substring(0, Math.min(course_name.length, COURSE_NAME_MAX_CHAR)) + (course_name.length > COURSE_NAME_MAX_CHAR ? '...' : '')
    }

    const fetchLearnerData = async () => {
        try {
            // // console.log(userData, employee)
            let adminData = { data: null }
            const learnerData = await axios.get(`${process.env.REACT_APP_API_URL}/absorb/learner/${employee.id}`);
            if (userData.userId) adminData = await axios.get(`${process.env.REACT_APP_API_URL}/absorb/learner/${userData.userId}`);

            // // console.log(adminData.data);
            // // console.log(learnerData.data);
            
            setLearner(learnerData.data);
            setAdmin(adminData.data);
            fetchCourseData(learnerData.data);
        } catch (err) {
            // // console.log(err);
        }
    }

    useEffect(() => {
        if (super_enrollment) { 
            employee = super_enrollment;
            setEmployeeData(super_enrollment);
            if (super_enrollment.admin_email && super_enrollment.admin_email !== "") {
                axios.get(`${process.env.REACT_APP_API_URL}/account/admin/${super_enrollment.admin_email}`)
                .then(({data}) => {
                    userData = { userId: data.account_id }
                    fetchLearnerData();
                })
                .catch(err => toast.error(err));
            } else {
                userData = {}
                // // console.log(employee);
                fetchLearnerData();
            }

        } else if (userData != undefined) { 
            setEmployeeData(employee);
            fetchLearnerData();
        }
    }, [userData, super_enrollment]);

    return(
        <div className="form-background w-100">
            
            <h2 className="h2">New Enrollment</h2>
            <div className="d-flex justify-content-between">
                <strong>Enrolling:</strong>
                <p>{employeeData.email}</p>
            </div>
            <div className="d-flex justify-content-between">
                <strong className="enroll-single course-label">In:</strong>
                <p className='text-end'>{selectedCourse.Name ? selectedCourse.Name : '...Loading Courses'}</p>
            </div>
            <select
                onChange={onCourseChange}
                className="form-select enroll-single">
                {courseList.map((course, i) => {
                    return (
                        <option
                            disabled={course.Status > -1}
                            value={course.Id}
                            >{truncateCourseName(course.Name)} - { course.Status < 0 ? (`${course.LearnerCost} Tokens`) : (course.Status < 3 ? ('Already Enrolled') : ('Course Completed')) }</option>
                    )
                })}
            </select>
            {admin ? (
                <>
                    <label className="enroll-single center-header mt-2">Available Tokens</label>
                    <label className="enroll-single sub-italic ml-2">Select the tokens you'd like to use</label>
                    <p 
                        style={(useAdminTokens && !useBoth)  ? { backgroundColor: '#e9ecef', color: 'gray', cursor: 'pointer' } : { backgroundColor: '#0d6efd', color: 'white', cursor: 'pointer', borderRadius: "15px 15px 15px 15px", transition: '800ms' }}
                        onClick={() => setUseAdminTokens(!useAdminTokens)}
                        className="d-flex justify-content-between p-3 mt-2">
                        <strong style={{ color: (useAdminTokens && !useBoth) ? 'gray' : 'white'}}>Employee Tokens: </strong>{learner.tokens}
                    </p>
                    <p
                        style={(!useAdminTokens && !useBoth) ? { backgroundColor: '#e9ecef', color: 'gray', cursor: 'pointer'} : { backgroundColor: '#0d6efd', color: 'white', cursor: 'pointer', borderRadius: "15px 15px 15px 15px", transition: '800ms' }}
                        onClick={() => setUseAdminTokens(!useAdminTokens)}
                        className="d-flex justify-content-between p-3 mt-2">
                        <strong style={{ color: (!useAdminTokens && !useBoth) ? 'gray' : 'white'}}>Business Tokens: </strong>{admin.tokens}
                    </p>
                    <div className="d-flex mt-3">
                        <input
                            type="checkbox"
                            onChange={() => setUseBoth(!useBoth)}
                            className="enroll-single check-centered mx-1">
                        </input>
                        <p>Use Both</p>
                    </div>
                </>
            ) : (
                <>
                    <div className="mt-2 d-flex justify-content-between">
                        <strong>Tokens:</strong>
                        <p>{learner.tokens}</p>
                    </div>
                </>
            )}
            <div className="mt-3">
                <button 
                    onClick={() => onEnroll()}
                    className="btn btn-outline-primary"
                    disabled={buttonDisable}>
                    Enroll
                </button>
            </div>
        </div>
    )
}

export const EnrollAll = (employees) => {
    return (
        <div className="form-background">
            <p>Enroll All</p>
        </div>
    )
}