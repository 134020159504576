import axios from 'axios'
// import { jwtDecode } from 'jwt-decode'

class EventsApi {
    base_url;
    constructor() {
        this.base_url = process.env.REACT_APP_API_URL
    }

    async getEvents() {
        const email = localStorage.getItem('event-email')
        return axios.get(`${this.base_url}/event${email ? `?email=${email}` : ''}`)
    }

    async createEvent(form, benefits) {
        return axios.post(`${this.base_url}/event/`, { ...form, benefits })
    }

    async editEvent(form, benefits) {
        return axios.put(`${this.base_url}/event/`, { ...form, benefits })
    }

    async updateAttendance(event_id, attendance) {
        return axios.put(`${this.base_url}/event/attendance`, { event_id, attendance })
    }

    async deleteAttendee(registration_id) {
        return axios.delete(`${this.base_url}/event/attendance/${registration_id}`)
    }

    async deleteEvent(event_id) {
        return axios.delete(`${this.base_url}/event/${event_id}`)
    }

    async toggleEventClosed(event_id) {
        return axios.put(`${this.base_url}/event/toggle-closed/${event_id}`)
    }

    async startRegistration(event_id, email, registration) {
        return axios.post(`${this.base_url}/event/register`, { event_id, email, registration })
    }

    async getAbsorbConflictOptions() {
        return axios.get(`${this.base_url}/absorb/courses/undefined`) // Yes, this is supposed to be undefined :)
    }

    async getSuperEnroller(event_id) {
        return axios.get(`${this.base_url}/event/super-enroller?event_id=${event_id}`)
    }

    async addSuperEnroller(account_id, event_id, is_individual) {
        return axios.post(`${this.base_url}/event/super-enroller`, { account_id, event_id, is_individual })
    }
}

export default EventsApi