import React, { useState, useEffect } from 'react';

const PaymentClassDisplay = ({ users }) => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        users && setUserList(users);
    }, []);

    return (
        <div>
            {userList.map((user) => {
                return (
                    <p>{user.first_name}</p>
                )
            })}
        </div>
    );
}

export default PaymentClassDisplay;