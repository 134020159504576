import React, { useContext, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import axios from "axios";
import { toast } from 'react-toastify';
import "./super.css";

import { Validator } from '../exportcomponents/ValidatorExports';
import LangContext from '../context/LangContext';

import PrivacyPolicy from '../exportcomponents/LegalExports/LegalExports';
import TermsAndConditions from '../exportcomponents/TermsAndConditions';

const OnboardAdmin = ({ redirectCallback }) => {
    const stripe = useStripe();
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({ language: "English", address_2: " " });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [termsAndConds, setTermsAndConds] = useState(false);

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const submit = async (e, redirect_later) => {
        e.preventDefault();

        setButtonDisable(true);
        setTimeout(() => {
            setButtonDisable(false);
        }, 3000);

        try {
            const valid = Validator(form, ['admin_email', 'email', 'first_name', 'last_name', 'phone', 'address_1', 'city', 'state', 'zip', 'language', 'password', 'passwordCheck'])
                if(form.password == form.passwordCheck) {
                    if (valid.length == 0) {
                        // TODO: CTP SIGN UP
                        const session = await axios.post(`${process.env.REACT_APP_API_URL}/account/admin`, { ...form, send_welcome_email: true });
                        const quote = await axios.post(`${process.env.REACT_APP_API_URL}/quote/`, { business_name: form.admin_email, email: form.email, account_id: session.data.account_id });

                        console.log("session:", session.data)
                        console.log("quote:", quote.data)

                        // 1. post account
                        // 2. post quote
                        // 3. (email stripe link?) send email
                        // 4. send verify email email
                        // 5. (super stripe redirect?) stripe redirect

                        // const quoteData = await axios.get(`${process.env.REACT_APP_API_URL}/quote/id/${quote_id}`);
                        // const { email, business_name } = quoteData.data
                
                        await axios.put(`${process.env.REACT_APP_API_URL}/quote/${quote.data.id}`, {
                            subscription_price: 2000, 
                            down_price: 1000, 
                            fitter_price: 45, 
                            apprentice_price: 35,
                            act_apprentice_price: 195,
                            trainee_price: 35, 
                            other_price: 25,
                        });
                
                        console.log("redirect_later: ", redirect_later)
                        if (redirect_later) {
                            await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email/admin-onboard`, {
                                to: form.email,
                                business_name: form.admin_email
                            })
                            toast.success(langData.toast.finish_sign_email_sent);
                        } else {
                            await stripe.redirectToCheckout({ sessionId: session.data.sessionId });
                        }

                        toast.success(langData.toast.account_pending);
                } else {
                    toast.error("Passwords Don't Match!");
                }
            } else {
                toast.error(langData.toast.please_enter_all_required);
            }
            
        } catch (err) {
            console.log("YOU LIKE POTATERZ: ", err)
            toast.error(err.response.status == 401 ? "Email Already In Use" : err.message);
        }
    };

    // const submit = async (e) => {
    //     e.preventDefault();

    //     // console.log("TESTING FORM: ", form)
    //     // return

    //     setButtonDisable(true);
    //     setTimeout(() => {
    //         setButtonDisable(false);
    //     }, 3000);
        
    //     try {
    //         const valid = Validator(form, ['admin_email', 'email', 'first_name', 'last_name', 'phone', 'address_1', 'city', 'state', 'zip', 'language', 'password', 'passwordCheck'])
    //         if(form.password == form.passwordCheck) {
    //             if (valid.length == 0) {
    //                 const session = await axios.post(`${process.env.REACT_APP_API_URL}/account/admin`, form);
    //                 const quote = await axios.post(`${process.env.REACT_APP_API_URL}/quote/`, { business_name: form.admin_email, email: form.email, account_id: session.data.account_id });
    //                 redirectCallback(session.data.sessionId, quote.data.id, "", true);
    //             } else {
    //                 toast.error(langData.toast.please_enter_all_required); 
    //             }
    //         } else {
    //             toast.error("Passwords Don't Match!");
    //         }
    //     } catch (err) {
    //         console.log("I LEIK BABANERSA: ", err.status)
    //         toast.error(err.response.status == 401 ? "Email Already In Use" : err.message);
    //     }
    // }

    return (
		<div className="form-background popup-scrollable">
            <h2 className="h2 mb-3">Add CTP</h2>
            <form>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.your_email}</label>
                        {!form.email ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="email"
                        className="mb-2"
                        name="email"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_email}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="admin_email">{langData.onboard_admin.business_name}</label>
                        {!form.admin_email ? 
                            <p className="float-right text-danger">* Required</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="text"
                        className="mb-2"
                        name="admin_email"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_company_name}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="displayName">{langData.onboard_admin.first_name}</label>
                        {!form.first_name ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="first_name"
                        className="mb-2"
                        name="first_name"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_first_name}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="displayName">{langData.onboard_admin.last_name}</label>
                        {!form.last_name ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="last_name"
                        className="mb-2"
                        name="last_name"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_last_name}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.phone}</label>
                        {!form.phone ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="phone"
                        className="mb-2"
                        name="phone"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_phone_number}
                    />
                </div>
                <div className="">
                <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.address_1}</label>
                        {!form.address_1 ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="address_1"
                        className="mb-2"
                        name="address_1"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_address}
                    />
                </div>
                <div className="">
                    <label htmlFor="displayName">{langData.onboard_admin.address_2}</label>
                    <input
                        type="address_2"
                        className="mb-2"
                        name="address_2"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_address_2}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">City</label>
                        {!form.city ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="city"
                        className="mb-2"
                        name="city"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_city}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.state}</label>
                        {!form.state ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="state"
                        className="mb-2"
                        name="state"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_state}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.zip}</label>
                        {!form.zip ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="zip"
                        className="mb-2"
                        name="zip"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_zip}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.language}</label>
                        {!form.language ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <select
                        className="mb-2"
                        name="language"
                        aria-label="Default select example"
                        onChange={onChange}
                    >
                        <option value="English">{langData.onboard_admin.english}</option>
                        <option value="Spanish">{langData.onboard_admin.spanish}</option>
                    </select>
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.password}</label>
                        {!form.password ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="password"
                        className="mb-2"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.placeholders.password}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.onboard_admin.password_2}</label>
                        {!form.passwordCheck ? 
                            <p className="float-right text-danger">{langData.onboard_admin.required}</p>
                            : 
                            null
                        }
                    </div>
                    <input
                        type="password"
                        className="mb-4"
                        name="passwordCheck"
                        onChange={onChange}
                        placeholder={langData.placeholders.password_2}
                    />
                </div>
                
                {/* <div>
                    <strong style={{fontSize: '20px', marginTop: '3vh'}}>{langData.sign_up.what_to_expect}</strong>
                    <p>{langData.sign_up.excited}&nbsp;
                        <strong>{langData.sign_up.no_charge_till_approved}</strong>
                    </p>
                </div> */}
                {/* {privacyPolicy ? (
                    <div>
                        <label 
                            onClick={() => setPrivacyPolicy(!privacyPolicy)}
                            style={{ color: 'purple' }}
                            >{langData.sign_up.hide_policy}
                        </label>
                        <PrivacyPolicy />
                    </div>
                ) : (
                    null
                )}
                <div>
                    <label 
                        onClick={() => setPrivacyPolicy(!privacyPolicy)}
                        style={privacyPolicy ? { color: 'purple' } : { color: 'blue' }}
                        >{privacyPolicy ? "Hide" : "View"}&nbsp;{langData.sign_up.privacy_policy}
                    </label>
                </div>
                {termsAndConds ? (
                    <div>
                        <label 
                            onClick={() => setTermsAndConds(!termsAndConds)}
                            style={{ color: 'purple' }}
                            >{langData.sign_up.hide_terms}
                        </label>
                        <TermsAndConditions />
                    </div>
                ) : (
                    null
                )}
                <div>
                    <label 
                        onClick={() => setTermsAndConds(!termsAndConds)}
                        style={privacyPolicy ? { color: 'purple' } : { color: 'blue' }}
                        >{termsAndConds ? "Hide" : "View"}&nbsp;{langData.sign_up.terms_and_conditions}
                    </label>
                </div> */}
                <div>
                    <button
                        className="btn btn-outline-warning submit mt-2 w-100"
                        onClick={(e) => submit(e, false)} 
                        disabled={buttonDisable}>
                        {langData.onboard_admin.enter_now}
                    </button>
                    <button
                        className="btn btn-outline-success submit mt-2 w-100"
                        onClick={(e) => submit(e, true)} 
                        disabled={buttonDisable}>
                        {langData.onboard_admin.enter_later}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OnboardAdmin;

