import React, { useContext, useState, useEffect } from 'react'
import axios from "axios";
import { Validator } from '../exportcomponents/ValidatorExports';
import { toast } from 'react-toastify';

import UserContext from '../context/UserContext';
import LangContext from '../context/LangContext';
import { useNavigate } from 'react-router-dom';
import '../supercomponents/super.css'

const SuperResetPassword = ({ email, apprentice }) => {
    const { userData } = useContext(UserContext);
    const { langData } = useContext(LangContext);
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [showPassword, setShowPassword] = useState(false)

    const submit = async (e) => {
        e.preventDefault()

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/account/resetpassword`,
            {
                employee: email,
                password: form.password,
            })

            navigate("/")
            navigate(apprentice ? "/apprentice" : "/super")
            toast.success(langData.toast.employee_pass_changed);
        } catch {
            toast.error(langData.toast.invalid_credentials);
        }
    }

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form);
    };

    useEffect(() => {
        // (async () => {
        //     let approvedEmployees = []

        //     const userEmail = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userData.user.userId}`)
        //     // console.log(userEmail.data.status)

        //     await axios.get(`${process.env.REACT_APP_API_URL}/account/employees/${userEmail.data.admin_email}`)
        //         .then((emps) => {
        //             let empList = emps.data;
        //             empList.sort();
        //             for (let employee of empList) {
        //                 if (employee.status === true) {
        //                     approvedEmployees.push(employee)
        //                 }
        //             }
        //             setEmployeeList(approvedEmployees);
        //             // console.log(employeeList);
        //         });

        // })()
    }, []);

    return (
        <div className="form-background popup-auto-width">
            <form onSubmit={submit}>
                <h2 className="h2 mb-4">Reset Password</h2>

                {/* <div className="form-group">
                    <label>{langData.reset_password.change_pass_header}</label>
                    <select onChange={onEmpChange} className="form-select" aria-label="Default select example">
                        <option key="default">Select Employee</option>
                        {employeeList.map((emp, i) => {
                            return <option onChange={changePassword} value={emp.id} key={i}>{emp.first_name}&nbsp;{emp.last_name}</option>
                        })}
                    </select>
                </div> */}
                <div className="form-group">
                    <input
                        type={showPassword ? "text" : "password"}
                        // className="form-control"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.reset_password.new_pass_placeholder}
                    />
                </div>
                <div className='mt-3 d-flex justify-content-start align-items-center'>
                    <input className="reset-show-password h-25" type="checkbox" onInput={(e) => setShowPassword(e.target.checked)}/>
                    <span className='reset-show-password mb-1 ml-2'>Show Password</span>
                </div>
                <div>
                    <button type="submit" className="btn btn-primary submit mt-2 w-100">
                        Reset
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SuperResetPassword
